import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'hl-payment-success-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './payment-success-dialog.component.html',
  styles: [],
})
export class PaymentSuccessDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogData: any
  ) {}
}
