<div class="bg-white p-2 px-4 text-sm rounded-lg shadow">
  <div class="flex justify-between items-center">
    <span class="text-holismo-grey font-medium">{{
      note.addedAt | date : 'd. MMM yyyy'
    }}</span>
    <div class="flex items-center">
      <button
        [cdkMenuTriggerFor]="menu"
        [ngClass]="{
          'pointer-events-none': !!lifeFilters().selectedDate
        }"
        class="w-9 h-9 text-holismo-grey">
        <i class="fa-regular fa-ellipsis-vertical"></i>
      </button>
      <hl-is-favorite
        [ngClass]="{
          'pointer-events-none': !!lifeFilters().selectedDate
        }"
        [isFavorite]="note.isFocused"
        (toggleFavourite)="toggleFocused.emit($event)"></hl-is-favorite>
    </div>
  </div>
  <quill-view
    *ngIf="!isEdit"
    class="cursor-pointer hl-quill-view"
    [content]="note.text"></quill-view>
  <div class="flex flex-col items-end" *ngIf="isEdit">
    <quill-editor
      data-sl="mask"
      class="w-full hl-quill"
      [modules]="quillEditorOptions"
      placeholder="{{ 'DrawerNoteTabQuillPlaceholder' | translate }}"
      [formControl]="noteControl">
    </quill-editor>
    <div class="flex items-center justify-end gap-x-2">
      <button
        class="hover:bg-gray-300 py-2 px-4 self-end rounded text-gray-600"
        (click)="cancel()">
        {{ 'ButtonCancel' | translate }}
      </button>
      <button
        class="bg-gray-300 py-2 px-4 self-end rounded text-gray-600"
        (click)="save(noteControl.value)">
        {{ 'ButtonSave' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button cdkMenuItem (click)="isEdit = true" class="popup-menu-btn">
      <i class="fa-solid fa-pen-to-square mr-4"></i>
      <span>{{ 'ButtonEdit' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      (click)="deleteNote.emit(note.id)"
      class="popup-menu-btn-trash">
      <i class="fa-solid fa-trash mr-4"></i>
      <span>{{ 'ButtonDelete' | translate }}</span>
    </button>
  </div>
</ng-template>
