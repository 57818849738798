<div
  class="h-full max-w-full bg-white relative flex"
  [class.active-element]="hasActiveElement().item">
  <!-- Sidebar -->
  <hl-sidenav class="hidden md:block" />
  <div class="flex flex-col flex-1 max-w-full page-layout">
    <hl-header class="sticky top-0 bg-white z-20 hidden md:block" />
    <section class="overflow-hidden">
      <router-outlet />
    </section>
  </div>
  <aside
    [@inOutAnimation]
    *ngIf="hasActiveElement().item"
    class="fixed right-0 w-screen z-50 flex-grow h-screen lg:sticky lg:w-1/3 md:max-w-md lg:z-10 top-0 flex flex-col items-stretch px-5 bg-drawer text-white overflow-auto pb-[64px]">
    <ng-container *ngComponentOutlet="drawerComponent()"></ng-container>
  </aside>
  <hl-mobile-menu
    class="fixed w-screen z-50 h-16 md:hidden bottom-0 bg-drawer"></hl-mobile-menu>
</div>
