export enum WhenType {
  DAILY = 1,
  MONTHLY = 2,
  INTERVAL = 3,
}

export enum WhenInterval {
  EVERY_DAY = 1,
  EVERY_X_DAYS = 2,
}
