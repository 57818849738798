import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LifeItem } from '@life/models/life';
import { ChartPopoverDirective } from '@components/charts/priority-chart/priority-chart.component';
import * as d3 from 'd3';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hl-sunburst-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sunburst-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SunburstChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('chart') chart: ElementRef;
  @Input() elements: any;
  @Input() chartHeight: number;
  @Input() chartWidth: number;
  @ContentChild(ChartPopoverDirective)
  toolTipTmp: ChartPopoverDirective;
  activeItem: LifeItem | null;
  activeGroup: any;
  drillDownItem: any;
  ttPos = {
    'left.px': 0,
    'top.px': 0,
  };
  transitionInProgress = false;

  width = window.innerWidth - 48;
  height = window.innerHeight - 80;
  radius = this.width / 9;
  arcModifier = 0.5;
  radiusModifier = 1.16;
  chartElement: any;

  // define outer arc
  outerArc = d3
    .arc()
    .startAngle((d: any) => d.x0)
    .endAngle((d: any) => d.x1)
    .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(this.radius * 1.5)
    .innerRadius((d: any) => {
      return (d.y0 - this.arcModifier) * (this.radius * this.radiusModifier);
    })
    .outerRadius((d: any) =>
      Math.max(
        (d.y0 - this.arcModifier) * (this.radius * this.radiusModifier),
        (d.y1 - this.arcModifier) * (this.radius * this.radiusModifier)
      )
    );

  // define inner arc
  innerArc = d3
    .arc()
    .startAngle((d: any) => (d.target ? d.target.x0 : d.x0))
    .endAngle((d: any) => (d.target ? d.target.x1 : d.x1))
    .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(this.radius * 1.5)
    .innerRadius((d: any) => {
      return (
        ((d.target ? d.target.y1 - 1 : d.parent.y1) - this.arcModifier) *
        (this.radius * this.radiusModifier)
      );
    })
    .outerRadius((d: any) => {
      const innerRadius =
        ((d.target ? d.target.y1 - 1 : d.parent.y1) - this.arcModifier) *
        (this.radius * this.radiusModifier);
      const outerRadius = Math.max(
        ((d.target ? d.target.y0 : d.y0) - this.arcModifier) *
          (this.radius * this.radiusModifier),
        ((d.target ? d.target.y1 : d.y1) - this.arcModifier) *
          (this.radius * this.radiusModifier) -
          1
      );
      const multiplier = (outerRadius - innerRadius) / 5;

      return innerRadius + multiplier * d.data.satisfaction;
    });

  centerArc = d3
    .arc()
    .innerRadius(0)
    .outerRadius((d: any) => {
      return this.radius / 1.8;
    })
    .startAngle(0) //converting from degs to radians
    .endAngle((d: any) => {
      const fillPct = d.data.satisfaction || 0;
      return 2 * Math.PI * (fillPct / 5);
    });

  svg: any;
  nodes: any;
  centerNode: any;
  root: any;

  constructor(private cd: ChangeDetectorRef, private route: ActivatedRoute) {}

  createSvg(data: any) {
    this.root = this.partition(data);
    this.root.each((d: any) => (d.current = d));

    this.plotChart();

    const activeDrilldown = this.root.find(
      (x: any) => !!x.data.id && x.data.id === this.drillDownItem?.data?.id
    );

    if (activeDrilldown) {
      // avoid rendering chaos, so hide/show svg for a millisecond
      this.svg.attr('opacity', 0);
      setTimeout(() => {
        this.drillDownItem = null;
        this.drillDown(activeDrilldown, true);
        this.svg.attr('opacity', 1);
      });
    }
  }

  get hasActiveGroup() {
    return this.activeGroup?.node();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['elements'].firstChange) {
      this.renderChart(changes['elements'].currentValue);
    }
  }

  ngAfterViewInit() {
    this.renderChart(this.elements);

    d3.select('body').on('click', e => {
      const clickOnNode =
        e.target.classList.contains('node-group') ||
        !!e.target.closest('.node-group');
      const clickOnEdit =
        e.target.classList.contains('edit-button') ||
        !!e.target.closest('.edit-button');

      if (!clickOnNode && !clickOnEdit) {
        this.clearActiveItem();
      }
    });
  }

  get minDimension() {
    return Math.min(this.width, this.height);
  }

  renderChart(data: any) {
    this.height = window.innerHeight - 80;
    this.width = window.innerWidth - 48;
    this.radius = this.minDimension / 8.2;
    const element = this.chart.nativeElement;

    // cleanup first
    d3.select(element).select('svg').remove();

    // append svg with correct dimensions
    this.svg = d3
      .select(element)
      .append('svg')
      .attr('width', `${this.minDimension}`)
      .attr('height', `${this.minDimension}`)
      .style('margin', 'auto');

    this.createSvg(data);

    element.append(this.svg.node());

    this.activeGroup = d3.select('.active-group');

    if (this.activeGroup) {
      this.activeGroup.raise();
      this.activeGroup.select('.stroke-arc').raise();
    }
  }

  arcVisible = (d: any) => {
    return d.y1 <= 4 && d.y0 >= 1 && d.x1 > d.x0;
  };

  labelVisible = (d: any) => {
    return d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  };

  partition(data: any) {
    const root = d3
      .hierarchy(data)
      .sum(d => {
        return !d.children || d.children.length === 0 ? d.importance : 0;
      })
      .sort((a: any, b: any) => b.importance - a.importance);

    return d3.partition().size([2 * Math.PI, root.height + 1])(root);
  }

  plotChart() {
    // ARCS
    const arcs = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${this.minDimension / 2},${this.minDimension / 2})`
      )
      .attr('class', 'arcs')
      .selectAll('node-data')
      .data(this.root.descendants().slice(1));

    this.nodes = arcs
      .enter()
      .append('g')
      .attr('class', (d: any) => {
        return this.route.snapshot.queryParamMap.get('activeItemId') ===
          d.data.id
          ? 'node-group active-group'
          : 'node-group';
      })
      .attr('id', (d: any) => `node-${d.data.id}`)
      .attr('cursor', 'pointer')
      .on('click touch', this.clicked.bind(this))
      .on('mouseover touchstart', (event: any, item: any) => {
        if (!this.transitionInProgress) {
          // @ts-ignore
          d3.select(`#node-${item.data.id}`).style('filter', 'none').raise();

          d3.select(`#node-${item.data.id} .stroke-arc`)
            .style('cursor', 'pointer')
            .attr('stroke', item.data.darkColor)
            .raise();
        }
      })
      .on('mouseout', (event: any, item: any) => {
        if (
          this.activeItem?.id !== item.data.id &&
          this.route.snapshot.queryParamMap.get('activeItemId') !== item.data.id
        ) {
          d3.select(`#node-${item.data.id}`).style('filter', null).lower();

          d3.select(`#node-${item.data.id} .stroke-arc`)
            .style('cursor', 'pointer')
            .attr('stroke', 'white')
            .lower();
        }
      });

    // outer arc
    this.nodes
      .append('path')
      .attr('fill', (d: any) => {
        while (d.depth > 1) d = d.parent;
        return d.data.lightColor;
      })
      .classed('path', true)
      .attr('class', 'outer-arc')
      .attr('fill-opacity', (d: any) => (this.arcVisible(d.current) ? 0.6 : 0))
      .attr('pointer-events', (d: any) =>
        this.arcVisible(d.current) ? 'auto' : 'none'
      )
      .attr('d', (d: any) => this.outerArc(d.current))
      .attr('id', (d: any) => `outer-${d.data.id}`)
      .attr('stroke-width', '2px');

    // stroke arc
    this.nodes
      .append('path')
      .attr('fill', (d: any) => {
        while (d.depth > 1) d = d.parent;
        return d.data.lightColor;
      })
      .classed('active-stroke', (d: any) => {
        return (
          this.route.snapshot.queryParamMap.get('activeItemId') === d.data.id
        );
      })
      .attr('class', 'stroke-arc')
      .attr('fill-opacity', 0)
      .attr('pointer-events', (d: any) =>
        this.arcVisible(d.current) ? 'auto' : 'none'
      )
      .attr('d', (d: any) => this.outerArc(d.current))
      .attr('id', (d: any) => `stroke-${d.data.id}`)
      .attr('stroke-width', '2px')
      .attr('stroke', (d: any) => {
        return this.route.snapshot.queryParamMap.get('activeItemId') ===
          d.data.id
          ? d.data.darkColor
          : 'white';
      });

    // inner arc
    this.nodes
      .append('path')
      .attr('fill', (d: any) => {
        return d.data.mediumColor;
      })
      .attr('class', 'inner-arc')
      .attr('fill-opacity', (d: any) => (this.arcVisible(d.current) ? 1 : 0))
      .attr('pointer-events', (d: any) =>
        this.arcVisible(d.current) ? 'auto' : 'none'
      )
      .attr('d', (d: any) => this.innerArc(d.current))
      .attr('id', (d: any) => `inner-${d.data.id}`);

    // label
    this.nodes
      .append('text')
      .attr('dy', '0.5em')
      .attr('id', (d: any) => 'label' + d.data.id)
      .attr('text-anchor', (d: any) => this.textAnchor(d))
      .attr('class', 'arc-label')
      .style('font', (d: any) => this.textSize(d))
      .style('fill', (d: any) => d.data.darkColor)
      .attr('fill-opacity', (d: any) => +this.labelVisible(d.current))
      .style('user-select', 'none')
      .attr('transform', (d: any) => this.labelTransform(d, 0))
      .text((d: any) =>
        d.data.name.length < 17 ? d.data.name : d.data.name.slice(0, 15) + '...'
      );

    // CENTER NODE
    this.centerNode = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${this.minDimension / 2},${this.minDimension / 2})`
      )
      .attr('class', 'center-node')
      .attr('cursor', 'pointer')
      .datum(this.root)
      .on('click', (event: any, item: any) => this.clicked(event, item, true));

    this.centerNode
      .append('circle')
      .attr('r', this.radius / 1.8)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .attr('id', 'center-circle');

    this.centerNode
      .append('text')
      .attr('class', 'center-label')
      .attr('dy', '0.5em')
      .attr('text-anchor', 'middle')
      .style('font-size', '17px')
      .style('user-select', 'none')
      .classed('roboto-bold', true)
      .text((d: any) =>
        d.data
          ? d.data.name.length < 17
            ? d.data.name
            : d.data.name.slice(0, 15) + '...'
          : ''
      )
      .attr('pointer-events', 'none');

    this.centerNode
      .append('path')
      .attr('class', 'center-pie')
      .attr('d', (d: any) => this.centerArc(d.current));

    // this.centerNode
    //   .append('text')
    //   .attr('dy', '0em')
    //   .attr('text-anchor', 'middle')
    //   .style('font-size', '20px')
    //   .style('user-select', 'none')
    //   .classed('roboto-bold', true)
    //   .text((d: any) =>
    //     d.parent
    //       ? d.data.name.length < 17
    //         ? d.data.name
    //         : d.data.name.slice(0, 15) + '...'
    //       : '90%'
    //   )
    //   .attr('pointer-events', 'none');
  }

  clicked(event: any, p: any, fromCenter = false) {
    if (this.activeItem && this.activeItem.id !== p.data.id) {
      d3.select(`#node-${this.activeItem.id}`).lower();

      d3.select(`#node-${this.activeItem.id} .stroke-arc`)
        .style('cursor', 'pointer')
        .attr('stroke', 'white')
        .lower();
    }

    if (
      !!p.data.id &&
      !fromCenter &&
      (!this.activeItem || this.activeItem?.id !== p.data.id)
    ) {
      this.showPopover(event, p.data);
    } else if (p.data?.children?.length > 0 || fromCenter) {
      this.clearActiveItem();
      this.drillDown(p);
    }
  }

  drillDown(item: any, skipAnim = false) {
    this.drillDownItem = item.data.id ? item : null;
    this.centerNode.datum(item.parent || this.root);
    this.centerNode
      .select('.center-label')
      .text(() => {
        return item.data
          ? item.data.name.length < 12
            ? item.data.name
            : item.data.name.slice(0, 10) + '...'
          : '';
      })
      .raise();

    this.centerNode
      .select('#center-circle')
      .attr('fill-opacity', item.data.lightColor ? 1 : 0)
      .attr('fill', item.data.lightColor);

    this.centerNode
      .select('.center-pie')
      .attr('fill', item.data.mediumColor)
      .attr('d', () => this.centerArc(item));

    this.root.each(
      (d: any) =>
        (d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - item.x0) / (item.x1 - item.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - item.x0) / (item.x1 - item.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - item.depth),
          y1: Math.max(0, d.y1 - item.depth),
        })
    );

    const t = this.svg.transition().duration(skipAnim ? 0 : 750);

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    this.transitionInProgress = true;
    const filteredNodes = this.nodes
      .transition(t)
      .tween('data', (d: any) => {
        const i = d3.interpolate(d.current, d.target);
        return (t: any) => (d.current = i(t));
      })
      .filter((d: any) => {
        const node = d3.select(`#node-${d.data.id} .outer-arc`).node() as any;
        const label = d3.select(`#node-${d.data.id} .arc-label`).node() as any;
        label.setAttribute('fill-opacity', 0);
        const hidden = +node.getAttribute('fill-opacity') === 0;

        return !hidden || this.arcVisible(d.target);
      });

    filteredNodes
      .selectAll('.outer-arc')
      .attr('fill-opacity', (d: any, x: any) => {
        return this.arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0;
      })
      .attr('pointer-events', (d: any) =>
        this.arcVisible(d.target) ? 'auto' : 'none'
      )
      .attrTween('d', (d: any) => () => this.outerArc(d.current));

    filteredNodes
      .selectAll('.stroke-arc')
      .attr('pointer-events', (d: any) =>
        this.arcVisible(d.target) ? 'auto' : 'none'
      )
      .attrTween('d', (d: any) => () => this.outerArc(d.current))
      .on('end', (d: any) => {
        this.transitionInProgress = false;
        this.cd.detectChanges();
      });

    filteredNodes
      .selectAll('.inner-arc')
      .attr('fill-opacity', (d: any) => {
        return this.arcVisible(d.target) ? 1 : 0; // (d.children ? 0.6 : 0.4) : 0;
      })
      .attr('pointer-events', (d: any) =>
        this.arcVisible(d.target) ? 'auto' : 'none'
      )
      .attrTween('d', (d: any) => () => {
        return this.innerArc(d);
      });

    filteredNodes
      .selectAll('.arc-label')
      .attr('fill-opacity', (d: any) => {
        return this.labelVisible(d.target) ? 1 : 0; // (d.children ? 0.6 : 0.4) : 0;
      })
      .attrTween('text-anchor', (d: any) => () => this.textAnchor(d.target))
      .attrTween('transform', (d: any) => () => this.labelTransform(d, 0));
  }

  textSize = (d: any) => {
    return '12px roboto';
  };

  textAnchor(d: any) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    return x < 180 ? 'start' : 'end';
  }

  labelTransform(d: any, targetDepth: number) {
    const target = d.target ? d.target : d.current;
    const x = (((target.x0 + target.x1) / 2) * 180) / Math.PI;
    const arcModifier =
      !d.target || (d.target && targetDepth === 0)
        ? 0.5
        : x > 180
        ? -(targetDepth - 0.5)
        : 0;
    const y = (target.y0 - arcModifier) * this.radius * this.radiusModifier;

    const yModifier = arcModifier === -0.5 ? 20 : 0;
    return `rotate(${x - 90}) translate(${y + 10 + yModifier},0) rotate(${
      x < 180 ? 0 : 180
    })`;
  }

  showPopover(event: any, item: LifeItem) {
    this.clearActiveItem();
    this.activeItem = item;
    this.ttPos['left.px'] = event.pageX - (item.importance > 2 ? 300 : 0);
    this.ttPos['top.px'] = event.pageY - 55;
    this.cd.detectChanges();
  }

  clearActiveItem() {
    const node = d3.select(`#node-${this.activeItem?.id}`);
    if (node) {
      node.lower();

      node
        .select(`.stroke-arc`)
        .style('cursor', 'pointer')
        .attr('stroke', 'white')
        .lower();
    }

    this.activeItem = null;
    this.cd.detectChanges();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.clearActiveItem();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderChart(this.elements);
  }
}
