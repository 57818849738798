import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { AutocompleteOptionComponent } from '@components/inputs/autocomplete/autocomplete-option/autocomplete-option.component';
import { ENTER, ESCAPE } from '@angular/cdk/keycodes';
import { NgForOf, NgIf, NgStyle, NgClass, JsonPipe } from '@angular/common';
import { AutocompleteFilterPipe } from '@components/inputs/autocomplete/autocomplete-filter.pipe';
import { FormsModule } from '@angular/forms';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { FilterTextPipe } from '@core/pipes/filter-text.pipe';
import { FilterOptionPipe } from '@core/pipes/filter-option.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-autocomplete',
  templateUrl: './autocomplete.component.html',
  standalone: true,
  imports: [
    NgForOf,
    AutocompleteOptionComponent,
    AutocompleteFilterPipe,
    NgIf,
    FormsModule,
    StopPropagationDirective,
    OverlayModule,
    NgClass,
    NgStyle,
    JsonPipe,
    FilterTextPipe,
    FilterTextPipe,
    FilterOptionPipe,
    TranslateModule,
  ],
})
export class AutocompleteComponent implements AfterViewInit {
  @Input() options: { label: string; item: any }[];
  @Input() groupOptions: {
    label: string;
    darkColor: string;
    lightColor: string;
    icon: string;
    items: { label: string; item: any }[];
  }[] = [];
  @Input() usedOptions: string[];
  @Input() placeholder: string;
  @Input() autofocus = false;
  @Input() containerClass: string;
  @Input() inputClass: string;
  @Output() selectOption = new EventEmitter<{ label: string; item: any }>();
  @Output() hide = new EventEmitter();
  @ViewChild('input') input: ElementRef;
  @ViewChildren(AutocompleteOptionComponent)
  optionList: QueryList<AutocompleteOptionComponent>;

  private keyManager: ActiveDescendantKeyManager<AutocompleteOptionComponent>;

  query: string;
  showAutocomplete = false;
  usedOptionError = false;
  escaped = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.optionList)
      .withWrap()
      .withVerticalOrientation(true)
      .withTypeAhead(200);

    if (this.autofocus) {
      this.input.nativeElement.focus();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    if (this.keyManager.activeItem?.ref.nativeElement) {
      this.keyManager.activeItem.ref.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
    if (event.keyCode === ENTER) {
      this.select(
        this.keyManager.activeItem && this.optionList.length > 0
          ? this.keyManager.activeItem.option
          : this.findOption(this.groupOptions?.length > 0, this.query) || {
              label: '',
              item: { name: this.query },
            },
        true
      );
    } else if (event.keyCode === ESCAPE) {
      if (this.escaped) {
        this.hide.emit();
      } else {
        this.keyManager.setActiveItem(-1);
        this.escaped = true;
      }
    } else {
      this.usedOptionError = false;
      this.keyManager.onKeydown(event);
    }
  }

  findOption(isGroup: boolean, query: string) {
    if (isGroup) {
      return this.groupOptions
        .map(group => group.items)
        .reduce((acc, val) => acc.concat(val), [])
        .find(option => option.label === query);
    } else {
      return this.options.find(option => option.label === query);
    }
  }

  select(option: { label: string; item: any }, ignoreQuery = false) {
    if (
      (!this.usedOptions?.length ||
        !this.usedOptions?.find(
          opt => opt.toLowerCase() === option.item.name.toLowerCase()
        )) &&
      (this.query !== '' || ignoreQuery)
    ) {
      this.selectOption.emit(option);
      this.query = '';
      this.showAutocomplete = false;
      this.usedOptionError = false;
      this.keyManager.setActiveItem(-1);
      this.input.nativeElement.blur();
    } else {
      this.usedOptionError = true;
    }
  }

  timeoutAutocomplete() {
    setTimeout(() => {
      this.keyManager.setActiveItem(-1);
      this.showAutocomplete = false;
      this.usedOptionError = false;
      this.hide.emit();
      this.cd.detectChanges();
    }, 200);
  }
}
