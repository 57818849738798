import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ILifeSpecifics } from '@life/models/life-specifics';
export const OgLifeSpecificActions = createActionGroup({
  source: 'Life Specific',
  events: {
    selectLifeSpecific: props<{ id: string }>(),
    loadLifeSpecific: props<{ id: string; userId?: string }>(),
    loadLifeSpecificSuccess: props<{ specific: ILifeSpecifics }>(),
    loadLifeSpecificFail: props<{ error: string }>(),
    setLifeSpecificDescription: props<{ specific: ILifeSpecifics }>(),
    setSpecificImportance: props<{ specific: ILifeSpecifics }>(),
    setSpecificSatisfaction: props<{ specific: ILifeSpecifics }>(),
    setSpecificFocus: props<{ specific: ILifeSpecifics }>(),
    setLifeSpecificName: props<{ specific: ILifeSpecifics }>(),
    actuallySetLifeSpecificName: props<{ specific: ILifeSpecifics }>(),
    updateSpecificSuccess: props<{ specific: ILifeSpecifics }>(),
    setLifeSpecificsSortOrder: props<{ lifeSpecifics: ILifeSpecifics[] }>(),
    setLifeSpecificsSortOrderSuccess: props<{
      lifeSpecifics: ILifeSpecifics[];
    }>(),
    updateSpecificFail: props<{ error: string }>(),
    deleteSpecific: props<{ specificId: string }>(),
    deleteSpecificSuccess: props<{ specificId: string }>(),
    deleteSpecificFail: props<{ error: string }>(),
    addLifeSpecificFromTemplate: props<{
      lifeSpecific: ILifeSpecifics;
      lifeStateId: string;
    }>(),
    addLifeSpecific: props<{
      lifeSpecific: ILifeSpecifics;
      lifeStateId: string;
    }>(),
    noop: emptyProps(),
  },
});
