import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterText',
  standalone: true,
})
export class FilterTextPipe implements PipeTransform {
  transform(items: any[], query: string): any[] {
    if (!items) return [];
    if (!query || query.length == 0) return items;
    return items
      .filter((p: any) => {
        const items = p.items.filter(
          (p: any) => p.label?.toLowerCase().indexOf(query?.toLowerCase()) != -1
        );

        return items.length > 0;
      })
      .map(p => {
        return {
          label: p.label,
          icon: p.icon,
          lightColor: p.lightColor,
          darkColor: p.darkColor,
          items: p.items.filter(
            (p: any) =>
              p.label?.toLowerCase().indexOf(query?.toLowerCase()) != -1
          ),
        };
      });
  }
}
