import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ILifeActivity } from '@life/models/life-activity';
import { environment } from '../../../environments/environment';
import { IReminder } from '@life/models/reminder';

@Injectable({
  providedIn: 'root',
})
export class LifeActivityService {
  private readonly baseUrl = `${environment.apiUrl}/activity`;
  constructor(private http: HttpClient) {}

  getActivities(lifeFrom?: string | null, userId?: string) {
    const lifeFromQuery = lifeFrom ? `lifeFrom=${lifeFrom}` : '';
    const userQuery = userId ? `u=${encodeURIComponent(userId)}` : '';

    const query = [lifeFromQuery, userQuery].filter(v => !!v).join('&');

    return this.http.get<ILifeActivity[]>(`${this.baseUrl}?${query}`);
  }

  getActivity(activityId: string) {
    return this.http.get<ILifeActivity>(`${this.baseUrl}/${activityId}`);
  }

  getActivityReminder(activityId: string) {
    return this.http.get<IReminder>(`${this.baseUrl}/${activityId}/reminders`);
  }

  createActivity(activity: Partial<ILifeActivity>) {
    const description = activity.description
      ? `&description=${activity.description}`
      : '';
    const icon = activity.icon ? `&icon=${activity.icon}` : '';
    const lifeAreaId = activity.lifeAreaId
      ? `&lifeAreaId=${activity.lifeAreaId}`
      : '';
    return this.http.post<string>(
      `${this.baseUrl}?name=${activity.name}${description}${icon}${lifeAreaId}`,
      {}
    );
  }

  deleteActivity(activityId: string) {
    return this.http.delete(`${this.baseUrl}/${activityId}`);
  }

  moveActivity(activity: ILifeActivity, lifeAreaId: string) {
    return this.http.post(
      `${this.baseUrl}/${activity.id}/move?newLifeAreaId=${
        lifeAreaId === 'unasisgned' ? null : lifeAreaId
      }`,
      {}
    );
  }

  setActivityFocus(activity: ILifeActivity) {
    return this.http.post(`${this.baseUrl}/${activity.id}/focus`, {});
  }

  setActivityProp(
    activity: ILifeActivity,
    prop: 'importance' | 'satisfaction'
  ) {
    return this.http.post(
      `${this.baseUrl}/${activity.id}/${prop}/${activity[prop]}`,
      {}
    );
  }

  updateActivityProp(activity: ILifeActivity, prop: 'icon' | 'dayTime') {
    return this.http.patch(
      `${this.baseUrl}/${activity.id}/${prop}/${activity[prop]}`,
      {}
    );
  }

  updateActivityReminders(activity: ILifeActivity) {
    return this.http.post(
      `${this.baseUrl}/${activity.id}/reminder`,
      activity.reminders || []
    );
  }

  postActivityProp(
    activity: ILifeActivity,
    prop:
      | 'iwantto'
      | 'when'
      | 'sessionperday'
      | 'timerequired'
      | 'startend'
      | 'energy'
      | 'toggleonside',
    payload: any
  ) {
    const query = payload && prop !== 'startend' ? `?${payload}` : '';
    const body = prop === 'startend' ? payload : {};
    return this.http.post(
      `${this.baseUrl}/${activity.id}/${prop}${query}`,
      body
    );
  }

  postActivityQuantity(
    activity: ILifeActivity,
    number: number,
    unit: number,
    countUnit: number
  ) {
    const nr = number ? `number=${number}` : '';
    const un = unit ? `unit=${unit}` : '';
    const cnUnit = countUnit ? `countUnit=${countUnit}` : '';
    const query = [nr, un, cnUnit].filter(v => !!v).join('&');
    return this.http.post(
      `${this.baseUrl}/${activity.id}/quantitypersession?${query}`,
      {}
    );
  }

  addActivityTo(
    activityId: string,
    to: 'state' | 'specific',
    targetId: string
  ) {
    return this.http.post(
      `${this.baseUrl}/${activityId}/${to}/${targetId}`,
      {}
    );
  }

  removeActivityFrom(
    activityId: string,
    to: 'state' | 'specific',
    targetId: string
  ) {
    return this.http.delete(`${this.baseUrl}/${activityId}/${to}/${targetId}`);
  }

  updateActivityName(activity: ILifeActivity) {
    return this.http.patch(
      `${this.baseUrl}/${activity.id}/name/${activity.name}`,
      {}
    );
  }

  updateActivityDescription(activity: ILifeActivity) {
    return this.http.post(`${this.baseUrl}/${activity.id}/description`, {
      description: activity.description,
    });
  }

  reorderActivities(payload: { activityId: string; sortOrder: number }[]) {
    return this.http.post(`${this.baseUrl}/order`, payload);
  }
}
