<div cdkMenu class="gap-1 shadow px-1 bg-gray-100">
  <div class="w-full py-1 sticky top-0 z-50 bg-gray-100">
    <input
      type="text"
      [placeholder]="'LifeHeaderFiltersSearch' | translate"
      [formControl]="search"
      class="w-full text-filtershover cursor-text pl-2 peer relative z-10 h-7 rounded-md outline-none" />
  </div>
  <div class="h-[250px] overflow-auto">
    <div
      tabindex="0"
      *ngFor="let iconObject of filteredIcons"
      class="flex items-center justify-center flex-col">
      <h5 class="sticky top-0 bg-gray-100 w-full font-medium py-2 mt-2">
        {{ iconObject.title }}
      </h5>
      <div class="grid grid-cols-4 gap-1">
        <div
          *ngFor="let icon of iconObject.icons"
          cdkMenuItem
          (cdkMenuItemTriggered)="selectIcon.emit(icon.name)"
          class="flex items-center justify-center h-12 w-12 text-lg bg-white cursor-pointer hover:bg-gray-300">
          <i class="fa-solid" [ngClass]="icon.name"></i>
        </div>
      </div>
    </div>
  </div>
</div>
