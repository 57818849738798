import { Component, Input, TrackByFunction } from '@angular/core';
import { LifeAreaGroupComponent } from '@components/life-area-group/life-area-group.component';
import { ILifeArea } from '@life/models/life-area';
import * as fromLife from '@life/selectors/life-area.selectors';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InputSearchComponent } from '@app/components';
import { TranslateModule } from '@ngx-translate/core';
import { ILifeActivity } from '@life/models/life-activity';
import { LetDirective } from '@ngrx/component';
import {
  OgActivityActions,
  OgLifeSpecificActions,
  OgLifeStateActions,
} from '@life/actions';
import { RatingSelectComponent } from '@core/shared/rating-select/rating-select.component';
import { RatingComponent } from '@core/shared/rating/rating.component';
import { IsFavoriteComponent } from '@core/shared/is-favorite/is-favorite.component';
import { AppConstants } from '@core/constants/app-constants';
import { ILifeState } from '@life/models/life-state';
import { ChangeDetectionStrategy } from '@angular/core';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ActionsFilterComponent } from '@components/filters/actions-filter/actions-filter.component';
import { ImportanceFilterComponent } from '@components/filters/importance-filter/importance-filter.component';
import { IsFavoriteFilterComponent } from '@components/filters/is-favorite-filter/is-favorite-filter.component';
import { SatisfactionFilterComponent } from '@components/filters/satisfaction-filter/satisfaction-filter.component';
import { UrgencyFilterComponent } from '@components/filters/urgency-filter/urgency-filter.component';
import { ILifeFilter } from '@life/models/life-filter';
import { FormsModule } from '@angular/forms';
import { TruncateTextPipe } from '@core/pipes/truncate-text.pipe';

@Component({
  selector: 'hl-activity-tab-states',
  standalone: true,
  templateUrl: './activity-tab-states.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LifeAreaGroupComponent,
    CommonModule,
    MatSlideToggleModule,
    InputSearchComponent,
    TranslateModule,
    LetDirective,
    IsFavoriteComponent,
    RatingComponent,
    RatingSelectComponent,
    StopPropagationDirective,
    CdkMenuTrigger,
    ActionsFilterComponent,
    CdkMenu,
    CdkMenuItem,
    ImportanceFilterComponent,
    IsFavoriteFilterComponent,
    SatisfactionFilterComponent,
    UrgencyFilterComponent,
    FormsModule,
    TruncateTextPipe,
  ],
})
export class ActivityTabStatesComponent {
  @Input() activity: ILifeActivity;

  filters: ILifeFilter = {
    actions: [],
    importance: [],
    satisfaction: [],
    urgency: [],
    focus: [],
    search: '',
    assignedStates: false,
    activityId: undefined,
  };

  lifeAreas = this.store.selectSignal<ILifeArea[]>(
    fromLife.selectLifeUIActivities$(this.filters)
  );

  appConstants = AppConstants;

  hiddenStatesIds: string[] = [];

  constructor(private store: Store) {}

  get filterCount() {
    return (
      this.filters.focus.length +
      this.filters.urgency.length +
      this.filters.actions.length +
      this.filters.satisfaction.length +
      this.filters.importance.length
    );
  }

  clearFilters() {
    this.filters = {
      actions: [],
      importance: [],
      satisfaction: [],
      urgency: [],
      focus: [],
      search: '',
      assignedStates: this.filters.assignedStates,
      activityId: this.activity.id,
    };

    this.loadLifeAreas();
  }
  manageSearchFilter(event: string) {
    this.filters = { ...this.filters, search: event };

    this.loadLifeAreas();
  }

  manageImportanceFilters(importance: number[]) {
    this.filters.importance = importance;

    this.loadLifeAreas();
  }

  manageSatisfactionFilters(satisfaction: number[]) {
    this.filters.satisfaction = satisfaction;

    this.loadLifeAreas();
  }

  manageFavoriteFilters(focus: boolean[]) {
    this.filters.focus = focus;

    this.loadLifeAreas();
  }
  stateIsHidden(stateId: string) {
    return this.hiddenStatesIds.includes(stateId);
  }

  toggleSpecifics(stateId: string) {
    if (this.hiddenStatesIds.find(id => id === stateId)) {
      this.hiddenStatesIds = this.hiddenStatesIds.filter(id => id !== stateId);
    } else {
      this.hiddenStatesIds = [...this.hiddenStatesIds, stateId];
    }
  }

  toggleStateToActivity(lifeStateId: string) {
    if (this.hasLifeState(lifeStateId)) {
      this.store.dispatch(
        OgActivityActions.removeActivityFromLifeState({
          lifeStateId,
          activityId: this.activity.id,
        })
      );
    } else {
      this.store.dispatch(
        OgActivityActions.addActivityToLifeState({
          lifeStateId,
          activityId: this.activity.id,
        })
      );
    }
  }

  addSpecificToActivity(lifeSpecificId: string) {
    if (this.hasLifeSpecific(lifeSpecificId)) {
      this.store.dispatch(
        OgActivityActions.removeActivityFromLifeSpecific({
          lifeSpecificId,
          activityId: this.activity.id,
        })
      );
    } else {
      this.store.dispatch(
        OgActivityActions.addActivityToLifeSpecific({
          lifeSpecificId,
          activityId: this.activity.id,
        })
      );
    }
  }

  hasLifeState(lifeStateId: string) {
    return this.activity.stateIds.find(ids => ids === lifeStateId);
  }

  hasLifeSpecific(lifeSpecificId: string) {
    return this.activity.specificIds.find(ids => ids === lifeSpecificId);
  }

  updateLifeStateImportance(state: ILifeState, importance: number) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateImportance({
        lifeState: {
          ...state,
          importance,
        },
      })
    );
  }
  updateLifeStateSatisfaction(state: ILifeState, satisfaction: number) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateSatisfaction({
        lifeState: {
          ...state,
          satisfaction,
        },
      })
    );
  }
  updateLifeStateFocused(state: ILifeState, isFocused: boolean) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateFocus({
        lifeState: {
          ...state,
          isFocused,
        },
      })
    );
  }

  updateLifeSpecificImportance(spec: ILifeSpecifics, importance: number) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificImportance({
        specific: {
          ...spec,
          importance,
        },
      })
    );
  }

  updateLifeSpecificSatisfaction(spec: ILifeSpecifics, satisfaction: number) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificSatisfaction({
        specific: {
          ...spec,
          satisfaction,
        },
      })
    );
  }

  updateLifeSpecificFocused(spec: ILifeSpecifics, isFocused: boolean) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificFocus({
        specific: {
          ...spec,
          isFocused,
        },
      })
    );
  }

  loadLifeAreas() {
    this.lifeAreas = this.store.selectSignal<ILifeArea[]>(
      fromLife.selectLifeUIActivities$({
        ...this.filters,
        activityId: this.activity.id,
      })
    );
  }

  trackByLifeStateIndex: TrackByFunction<ILifeState> = index => index;
}
