import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Highlightable, ListKeyManagerOption } from '@angular/cdk/a11y';

@Component({
  selector: 'hl-autocomplete-option',
  templateUrl: './autocomplete-option.component.html',
  standalone: true,
})
export class AutocompleteOptionComponent
  implements Highlightable, ListKeyManagerOption
{
  @Input() option: any;
  @Input() disabled = false;
  private isActive = false;

  constructor(public ref: ElementRef) {}

  @HostBinding('class.bg-holismo-grey/20') get isOptionActive() {
    return this.isActive;
  }

  setActiveStyles() {
    this.isActive = true;
  }

  setInactiveStyles() {
    this.isActive = false;
  }

  getLabel() {
    return this.option.label;
  }
}
