import { createReducer, on } from '@ngrx/store';

import { ILifeFilter } from '@life/models/life-filter';
import { LifeFiltersActions } from '@life/actions';

export const lifeFiltersFeatureKey = 'life-filters';

export type FiltersState = ILifeFilter;

const initialState: FiltersState = {
  actions: sessionStorage.getItem('app.filter.actions')
    ? (JSON.parse(
        sessionStorage.getItem('app.filter.actions') as string
      ) as number[])
    : [],
  importance: sessionStorage.getItem('app.filter.importance')
    ? (JSON.parse(
        sessionStorage.getItem('app.filter.importance') as string
      ) as number[])
    : [],
  satisfaction: sessionStorage.getItem('app.filter.satisfaction')
    ? (JSON.parse(
        sessionStorage.getItem('app.filter.satisfaction') as string
      ) as number[])
    : [],
  urgency: sessionStorage.getItem('app.filter.urgency')
    ? (JSON.parse(
        sessionStorage.getItem('app.filter.urgency') as string
      ) as number[])
    : [],
  focus: sessionStorage.getItem('app.filter.focus')
    ? (JSON.parse(
        sessionStorage.getItem('app.filter.focus') as string
      ) as boolean[])
    : [],
  search: sessionStorage.getItem('app.filter.search')
    ? (JSON.parse(
        sessionStorage.getItem('app.filter.search') as string
      ) as string)
    : '',
};

export const reducer = createReducer(
  initialState,
  on(LifeFiltersActions.setLifeFiltersImportance, (state, { importance }) => {
    sessionStorage.setItem('app.filter.importance', JSON.stringify(importance));
    return { ...state, importance };
  }),
  on(LifeFiltersActions.setLifeFiltersActions, (state, { actions }) => {
    sessionStorage.setItem('app.filter.actions', JSON.stringify(actions));
    return { ...state, actions };
  }),
  on(LifeFiltersActions.setLifeFiltersUrgency, (state, { urgency }) => {
    sessionStorage.setItem('app.filter.urgency', JSON.stringify(urgency));
    return { ...state, urgency };
  }),
  on(
    LifeFiltersActions.setLifeFiltersSatisfaction,
    (state, { satisfaction }) => {
      sessionStorage.setItem(
        'app.filter.satisfaction',
        JSON.stringify(satisfaction)
      );
      return { ...state, satisfaction };
    }
  ),
  on(LifeFiltersActions.setLifeFiltersFocus, (state, { focus }) => {
    sessionStorage.setItem('app.filter.focus', JSON.stringify(focus));
    return { ...state, focus };
  }),
  on(LifeFiltersActions.setLifeFiltersSearch, (state, { search }) => {
    sessionStorage.setItem('app.filter.search', JSON.stringify(search));
    return { ...state, search };
  }),
  on(LifeFiltersActions.setLifeFiltersDate, (state, { date }) => {
    return { ...state, selectedDate: date };
  }),
  on(LifeFiltersActions.clearLifeFilters, state => {
    sessionStorage.removeItem('app.filter.search');
    sessionStorage.removeItem('app.filter.focus');
    sessionStorage.removeItem('app.filter.actions');
    sessionStorage.removeItem('app.filter.satisfaction');
    sessionStorage.removeItem('app.filter.urgency');
    sessionStorage.removeItem('app.filter.importance');
    return {
      ...state,
      actions: [],
      importance: [],
      satisfaction: [],
      urgency: [],
      focus: [],
      selectedDate: null,
    };
  })
);
