import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LifeWheelComponent } from '@life/views/life-wheel/life-wheel.component';
import { LifePriorityComponent } from '@life/views/life-priority/life-priority.component';
import { LifeComponent } from '@life/views/life/life.component';
import { LifeOverviewComponent } from '@life/views/life-overview/life-overview.component';

export const routes: Routes = [
  {
    path: '',
    component: LifeComponent,
    children: [
      {
        path: '',
        component: LifeOverviewComponent,
        pathMatch: 'full',
        data: { title: 'PageTitleLifeOverview' },
      },
      {
        path: 'wheel',
        component: LifeWheelComponent,
        data: { title: 'PageTitleLifeWheel' },
      },
      {
        path: 'priority',
        component: LifePriorityComponent,
        data: { title: 'PageTitleLifePriority' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LifeRoutingModule {}
