import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PricingTableComponent } from '@components/pricing-table/pricing-table.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'hl-pricing-table-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, PricingTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './pricing-table-dialog.component.html',
})
export class PricingTableDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    private router: Router,
    @Inject(DIALOG_DATA)
    public data: {
      disableClose: boolean;
      message: string;
      type: 'lifewheel' | 'lifearea';
    }
  ) {}

  handleMaybe(redirectOnClose: boolean) {
    if (redirectOnClose) {
      this.router.navigate(['/life']).then(() => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }
}
