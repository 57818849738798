export const environment = {
  production: true,
  apiUrl: 'https://api.holismo.com',
  auth0Config: {
    domain: 'login.holismo.com',
    clientId: 'AJ7Mz2TyWzUuJCM7FiU8AFA92pULHQXO',
    authorizationParams: {
      audience: 'https://api.holismo.com/',
      redirect_uri: window.location.origin,
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api.holismo.com/*',
          tokenOptions: {
            authorizationParams: {
              // The attached token should target this audience
              audience: 'https://api.holismo.com/',
            },
          },
        },
      ],
    },
  },
};
