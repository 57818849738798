import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { LifeService } from '@life/services';
import { LifeColorsActions, OgActivityActions } from '../actions';
import { IColor } from '@life/models/colors';
import { OgLifeActions } from '@life/actions';
import { Dialog } from '@angular/cdk/dialog';
import {
  selectCheckIfLifeSpecificNameExists$,
  selectLifeFilters$,
} from '@life/selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class LifeEffects {
  INIT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.init),
      map(() => LifeColorsActions.getAllColors())
    );
  });

  loadLifeColors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LifeColorsActions.getAllColors),
      switchMap(() =>
        this.lifeService.getColors().pipe(
          map((colors: IColor[]) => {
            return LifeColorsActions.getAllColorsSuccess({
              colors,
            });
          }),
          catchError(error =>
            of(LifeColorsActions.getAllColorsFail({ error: error.message }))
          )
        )
      )
    );
  });

  loadLifeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OgLifeActions.loadLifeAreasSuccess),
        concatLatestFrom(() => this.store.select(selectLifeFilters$)),
        tap(([{ lifeAreas, redirect, userId }, filters]) => {
          const hasDateFilter = filters.selectedDate;
          const newUrl =
            lifeAreas.length === 0 && !hasDateFilter ? '/life-setup' : '/life';

          if (!userId && (redirect || lifeAreas.length === 0)) {
            this.router.navigate([newUrl]);
          }
        })
      );
    },
    {
      dispatch: false,
    }
  );

  deleteLife$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.deleteLife),
      exhaustMap(() =>
        this.lifeService.deleteLife().pipe(
          map(() => {
            this.dialog.closeAll();
            return OgLifeActions.deleteLifeSuccess();
          }),
          catchError(error =>
            of(OgLifeActions.deleteLifeFail({ error: error.message }))
          )
        )
      )
    );
  });

  resetLife$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.resetLife),
      exhaustMap(() =>
        this.lifeService.resetLife().pipe(
          map(() => {
            this.dialog.closeAll();
            return OgLifeActions.resetLifeSuccess();
          }),
          catchError(error =>
            of(OgLifeActions.deleteLifeFail({ error: error.message }))
          )
        )
      )
    );
  });

  resetLifeSuccessHookLife$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.resetLifeSuccess, OgLifeActions.deleteLifeSuccess),
      map(() => {
        return OgLifeActions.loadLifeAreas({ redirect: true });
      })
    );
  });

  resetLifeSuccessHookActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.resetLifeSuccess, OgLifeActions.deleteLifeSuccess),
      map(() => {
        return OgActivityActions.loadActivities({ lifeFrom: null });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lifeService: LifeService,
    private router: Router,
    private dialog: Dialog,
    private store: Store
  ) {}
}
