<section
  class="life-area-container bg-gray-100 relative z-10 border-none rounded-md radius-lg shadow-none px-3 md:px-6 hover:outline cursor-pointer"
  [style.outline-color]="'gray'"
  tabindex="0"
  hlStopPropagation
  [class.pb-8]="showActivities">
  <div
    class="flex flex-wrap items-center justify-between py-3 md:py-0 md:px-3 h-auto md:h-16 md:pr-[53px] md:pl-0">
    <div class="flex items-center h-full w-full md:w-auto">
      <div
        class="flex items-center justify-center gap-x-3 h-full w-full md:w-auto">
        <i
          class="fa-solid w-5"
          [ngClass]="'fa-cloud'"
          [style.color]="'gray'"></i>
        <h6 class="text-base">
          {{ partsOfDayWithActivities.name }}
        </h6>
        <div
          hlStopPropagation
          class="flex items-center h-full md:pr-2 cursor-default flex-1">
          <button
            hlStopPropagation
            (click)="toggleActivitiesVisibility()"
            class="w-5 h-5 mr-3 bg-gray-300 flex items-center justify-center rounded text-[10px]">
            {{ partsOfDayWithActivities.activities.length }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showActivities" class="flex flex-col gap-y-2 life-states-list">
    <hl-activity
      *ngFor="let activity of partsOfDayWithActivities.activities"
      [activity]="activity"
      [defaultView]="false"></hl-activity>
  </div>
</section>
