import { Component, OnInit } from '@angular/core';
import { selectActivitiesSunburstUI$ } from '@life/selectors';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LifeItem } from '@life/models/life';
import { OgActivityActions, OgLifeActions } from '@life/actions';
import { ILifeArea } from '@life/models/life-area';
import { ILifeActivity } from '@life/models/life-activity';
import { PricingTableDialogComponent } from '@components/dialogs/pricing-table-dialog/pricing-table-dialog.component';
import { selectDisplayLifeWheel$ } from '@core/selectors/user.selectors';

@Component({
  selector: 'hl-activities-wheel',
  templateUrl: './activities-wheel.component.html',
  styles: [],
})
export class ActivitiesWheelComponent implements OnInit {
  elements = this.store.selectSignal(selectActivitiesSunburstUI$);
  displayLifeWheel = this.store.selectSignal(selectDisplayLifeWheel$);
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: Dialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (!this.displayLifeWheel()) {
      this.dialog.open(PricingTableDialogComponent, {
        maxWidth: '430px',
        panelClass: 'z-50',
        disableClose: true,
        data: {
          disableClose: true,
          message: this.translate.instant('DialogsUpgradePlanMessageLifeWheel'),
        },
      });
    }
  }

  openDetails(element: { type: string; id: string }) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId: element.id,
        activeItemType: element.type,
        activeTabIndex: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  handleElementFocus(isFocused: boolean, element: LifeItem) {
    this.store.dispatch(
      OgActivityActions.setActivityFocus({
        activity: {
          ...((<unknown>element) as ILifeActivity),
          isFocused,
        },
      })
    );
  }

  handleElementRating(importance: number, element: LifeItem) {
    switch (element.type) {
      case 'area':
        this.store.dispatch(
          OgLifeActions.setLifeAreaImportance({
            lifeArea: {
              ...((<unknown>element) as ILifeArea),
              importance,
            },
          })
        );
        return;
      case 'activity':
        this.store.dispatch(
          OgActivityActions.setActivityImportance({
            activity: {
              ...((<unknown>element) as ILifeActivity),
              importance,
            },
          })
        );
        return;
      default:
        return;
    }
  }

  handleElementSatisfaction(satisfaction: number, element: LifeItem) {
    switch (element.type) {
      case 'area':
        this.store.dispatch(
          OgLifeActions.setLifeAreaSatisfaction({
            lifeArea: {
              ...((<unknown>element) as ILifeArea),
              satisfaction,
            },
          })
        );
        return;
      case 'activity':
        this.store.dispatch(
          OgActivityActions.setActivitySatisfaction({
            activity: {
              ...((<unknown>element) as ILifeActivity),
              satisfaction,
            },
          })
        );
        return;
      default:
        return;
    }
  }
}
