import { Component } from '@angular/core';
import { FiltersBase } from '@components/filters/filters.base';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'hl-actions-filter',
  templateUrl: './actions-filter.component.html',
  standalone: true,
  imports: [TranslateModule, NgForOf],
})
export class ActionsFilterComponent extends FiltersBase {
  actions = Array.from(Array(6).keys());

  constructor(private translate: TranslateService) {
    super();
  }

  get filterLabel() {
    return this.translate.instant(
      location.pathname.indexOf('/activities') === 0
        ? 'LifeHeaderFiltersCriteria'
        : 'LifeHeaderFiltersActions'
    );
  }
}
