<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-sharp fa-regular fa-hourglass-start"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsSessionsTime' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-1" [cdkMenuTriggerFor]="menu">
      <span class="text-black" *ngIf="selectedTimeType && selectedDuration"
        >{{ selectedDuration }}
      </span>
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>

<ng-template #menu>
  <div cdkMenu class="flex shadow">
    <div
      class="flex flex-col px-3 pb-3 bg-white text-sm z-[10000] text-menugray rounded-b-md w-20 h-[200px] overflow-auto">
      <h4 class="text-center mt-3 bg-white sticky top-0">
        {{ 'DrawerActivityDropdownsSessionsTimeInterval1' | translate }}
      </h4>
      <button
        *ngFor="let duration of sessionDurationOptions[1]"
        class="py-[8px] popup-menu-btn block text-center w-full"
        (click)="handleDurationSelection(duration, 'hours')"
        [ngClass]="{
          'bg-filtershover text-white': selectedDurationHours === duration
        }">
        <span>{{ duration }}</span>
      </button>
    </div>
    <div
      class="flex flex-col px-3 pb-3 bg-white text-sm z-[10000] text-menugray rounded-b-md w-20 h-[200px] overflow-auto">
      <h4 class="text-center mt-3 bg-white sticky top-0">
        {{ 'DrawerActivityDropdownsSessionsTimeInterval2' | translate }}
      </h4>
      <button
        *ngFor="let duration of sessionDurationOptions[2]"
        class="py-[8px] popup-menu-btn block text-center w-full"
        (click)="handleDurationSelection(duration, 'minutes')"
        [ngClass]="{
          'bg-filtershover text-white': selectedDurationMinutes === duration
        }">
        <span>{{ duration }}</span>
      </button>
    </div>
  </div>
</ng-template>
