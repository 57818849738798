import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hl-verify',
  templateUrl: './verify.component.html',
})
export class VerifyComponent {
  emailReset = false;

  userEmail$ = this.auth.user$.pipe(
    filter(u => !!u),
    map(user => user?.email)
  );
  constructor(private http: HttpClient, public auth: AuthService) {}
  sendVerificationEmail() {
    this.http
      .post(`${environment.apiUrl}/resendverficiation`, {})
      .subscribe(_ => {
        this.emailReset = true;
      });
  }

  goToApp() {
    window.location.assign('/');
  }
}
