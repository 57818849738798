import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'hl-close-drawer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './close-drawer.component.html',
  styles: [],
})
export class CloseDrawerComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  closeDrawer() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId: null,
        activeItemType: null,
        activeTabIndex: null,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
