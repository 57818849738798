<div class="w-full flex flex-col py-3 text-gray-600 gap-4">
  <quill-editor
    data-sl="mask"
    [readOnly]="!!element.readonly"
    class="hl-quill sensitive smartlook-hide"
    placeholder="{{ 'DrawerAboutTabQuillPlaceholder' | translate }}"
    [formControl]="descriptionControl"
    (onBlur)="handleBlur()"
    [modules]="quillEditorOptions"></quill-editor>
  <div
    class="w-full flex flex-col rounded-lg overflow-hidden py-3 bg-white gap-y-3">
    <div class="flex items-center justify-between text-gray-400 mx-4">
      <span class="font-medium">{{ 'DrawerTabAboutGrowth' | translate }}</span>
      <button [cdkMenuTriggerFor]="menu">
        <span class="mr-2">{{ growthType.label }}</span>
        <i class="fa-thin fa-chevron-down"></i>
      </button>
    </div>
    <hl-chart
      *ngIf="stats"
      [height]="150"
      [dateFormat]="statsDateFormat"
      [stats]="stats"></hl-chart>
  </div>
  <button class="self-end" (click)="showMore = !showMore">
    <span class="mr-2">{{
      (showMore ? 'DrawerActivityShowLess' : 'DrawerActivityShowMore')
        | translate
    }}</span>
  </button>
  <div
    class="w-full flex flex-col rounded-lg overflow-hidden bg-white py-3 gap-y-3">
    <hl-life-area-dropdown
      [lifeAreas]="lifeAreas"
      (selectedLifeAreaChanged)="handleLifeAreaChange($event)"
      [selected]="element.lifeAreaId">
    </hl-life-area-dropdown>
    <hl-habit-dropdown
      (changeHabit)="handleHabitChange($event)"
      [selected]="element.iWantTo">
    </hl-habit-dropdown>
    <hl-interval-dropdown
      [when]="element.when"
      [weekdays]="element.weekdays || []"
      [days]="element.days || []"
      [whenInterval]="element.whenInterval"
      (intervalChange)="handleIntervalChange($event)">
    </hl-interval-dropdown>
    <hl-sessions-dropdown
      *ngIf="showMore"
      [selected]="element.sessionPerDay"
      (changeSessionsPerDay)="
        handleSessionsPerDayChange($event)
      "></hl-sessions-dropdown>
    <hl-sessions-time-dropdown
      *ngIf="showMore"
      [selectedTimeType]="element.timeRequiredInterval || 1"
      [selectedDurationHours]="element.timeRequiredIntervalHours"
      [selectedDurationMinutes]="element.timeRequiredIntervalMinutes"
      (changeSessionsTime)="handleSessionsTimeChange($event)">
    </hl-sessions-time-dropdown>
    <hl-sessions-quantity-dropdown
      [activity]="element"
      (changeQuantitySelection)="handleQuantitySelection($event)">
    </hl-sessions-quantity-dropdown>
    <!--    <hl-reminders-dropdown-->
    <!--      *ngIf="showMore"-->
    <!--      [reminders]="element.reminders || []"-->
    <!--      (remindersUpdated)="handleReminderChange($event)">-->
    <!--    </hl-reminders-dropdown>-->
    <hl-time-of-day-dropdown
      *ngIf="showMore"
      [selected]="element.dayTime"
      (changePartOfTheDay)="
        handlePartOfTheDayChange($event)
      "></hl-time-of-day-dropdown>
    <hl-energy-dropdown
      *ngIf="showMore"
      [selected]="element.energy || 0"
      (changeEnergy)="handleEnergyChange($event)">
    </hl-energy-dropdown>
    <hl-do-it-on-the-side-dropdown
      *ngIf="showMore"
      [selected]="element.canDoOnSide"
      (canDoInOnTheSideChange)="handleOnASideChange($event)">
    </hl-do-it-on-the-side-dropdown>
    <hl-start-end-dropdown
      [selectedStartDate]="element.startDate"
      [selectedEndDate]="element.endDate"
      (changeEndDate)="handleEndDateChange($event)"
      (changeStartDate)="handleStartDateChange($event)"></hl-start-end-dropdown>
  </div>
</div>
<ng-template #menu>
  <div class="popup-menu w-auto" cdkMenu>
    <button
      *ngFor="let option of growthOption"
      cdkMenuItem
      (click)="changeGrowthType(option)"
      class="popup-menu-btn">
      {{ option.label }}
    </button>
  </div>
</ng-template>
