<div class="flex flex-col gap-2">
  <div class="bg-gray-100 rounded-lg py-3 px-6 text-black/50">
    <span>{{ 'LifeHeaderFiltersImportance' | translate }}</span>
  </div>
  <div class="flex flex-col gap-0.5">
    <div
      class="flex justify-center items-center gap-2 px-3 py-2.5 hover:bg-icons-gray cursor-pointer"
      *ngFor="let filter of importance"
      (click)="applyFilter(filter)"
      [class.bg-icons-gray]="isFilterActive(filter)">
      <div
        class="rounded-full h-3 w-3"
        *ngFor="let element of ratingElements"
        [ngClass]="{
          'bg-gray-300': filter <= element,
          'bg-gray-600': filter > element
        }"></div>
    </div>
  </div>
</div>
