<section
  class="life-area-container relative z-10 border-none rounded-md radius-lg shadow-none px-3 md:px-6 -outline-offset-2 hover:outline -outline-offset-[3px] cursor-pointer"
  [style.outline-color]="lifeArea.mediumColor"
  tabindex="0"
  hlStopPropagation
  [style.background-color]="
    hasActiveElement().item && !lifeArea.isHighlighted
      ? convertedColor
      : lifeArea.lightColor
  "
  [class.area-highlighted]="lifeArea.isHighlighted"
  [class.pb-8]="showActivities">
  <div
    class="flex flex-wrap items-center justify-between py-3 md:py-0 md:px-3 h-auto md:h-16 md:pr-[53px] md:pl-0"
    [ngClass]="{ 'opacity-50': lifeArea.isChildSearchHit }">
    <div class="flex items-center h-full w-full md:w-auto">
      <div
        class="flex items-center justify-center gap-x-3 h-full w-full md:w-auto">
        <i
          class="fa-solid w-5"
          [ngClass]="lifeArea.icon"
          [style.color]="lifeArea.darkColor"></i>
        <h6 class="text-base" [ngStyle]="{ color: lifeArea.darkColor }">
          {{ lifeArea.name }}
        </h6>
        <div
          hlStopPropagation
          class="flex items-center h-full md:pr-2 cursor-default flex-1">
          <button
            hlStopPropagation
            (click)="toggleActivitiesVisibility()"
            class="w-5 h-5 mr-3 flex items-center justify-center rounded text-[10px] text-white"
            [style.background-color]="lifeArea.darkColor">
            {{ lifeAreaActivities.length }}
          </button>
        </div>
      </div>
    </div>
    <div
      hlStopPropagation
      class="flex pl-2 h-full items-center cursor-default gap-x-3 md:gap-x-6 w-full md:w-auto justify-end md:justify-start py-2 md:py-0">
      <hl-rating
        [rating]="lifeArea.importance"
        [type]="'lifeArea'"
        (updateRating)="setLifeAreaImportance($event)"></hl-rating>
      <hl-rating-select
        [satisfaction]="lifeArea.satisfaction"
        [satisfactionZeroClass]="'text-white'"
        (setSatisfaction)="setLifeAreaSatisfaction($event)"></hl-rating-select>
      <hl-rating
        [type]="'lifeArea'"
        [rating]="lifeArea.urgency"
        [readonly]="true"></hl-rating>
    </div>
  </div>
  <div
    *ngIf="showActivities"
    [id]="lifeArea.id"
    class="flex flex-col gap-y-2 life-states-list">
    <hl-activity
      *ngFor="let activity of lifeAreaActivities"
      [activity]="activity"
      [defaultView]="false"></hl-activity>
  </div>
</section>
