<aside
  class="layout-sidenav h-screen sticky top-0 w-12 text-base gap-3 flex flex-col space-y-10 py-3 items-center justify-between bg-sidenav text-sidenavicon font-roboto">
  <div class="flex flex-col">
    <a
      class="flex items-center rounded-[5px] mb-[16px] text-black justify-center"
      routerLink="/life">
      <button class="w-9 h-9 flex items-center justify-center rounded-[5px]">
        <svg
          width="50"
          height="50"
          viewBox="0 0 189 166"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M142 113C142 125.703 131.703 136 119 136C106.297 136 96 125.703 96 113C96 100.297 106.297 90 119 90C131.703 90 142 100.297 142 113Z"
            fill="#4D4D4D" />
          <path
            d="M81.4096 0.0932617C57.7069 7.98466 37.0889 23.1355 22.4773 43.3986C7.8658 63.6617 0.00187836 88.0094 0 112.991L27.9594 112.993C27.9608 93.881 33.9771 75.2539 45.1556 59.7517C56.3341 44.2494 72.1079 32.6584 90.2416 26.6211L81.4096 0.0932617Z"
            fill="#4D4D4D" />
          <path
            d="M136.441 165.162C146.042 161.951 154.57 156.154 161.087 148.407C167.604 140.661 171.857 131.266 173.377 121.257C174.896 111.248 173.624 101.015 169.701 91.6826C165.777 82.3506 159.354 74.2826 151.139 68.3671C142.924 62.4516 133.235 58.9182 123.141 58.1561C113.046 57.394 102.937 59.4327 93.9268 64.0477C84.9166 68.6626 77.3549 75.6749 72.0746 84.312C66.7942 92.9491 64.0001 102.876 64 112.999L91.9753 113C91.9754 108.025 93.3483 103.148 95.9428 98.9039C98.5373 94.66 102.253 91.2145 106.68 88.9469C111.107 86.6793 116.074 85.6775 121.035 86.052C125.995 86.4265 130.755 88.1626 134.792 91.0693C138.828 93.9759 141.984 97.9402 143.912 102.526C145.84 107.111 146.465 112.139 145.718 117.057C144.972 121.975 142.882 126.591 139.68 130.398C136.478 134.204 132.287 137.053 127.57 138.63L136.441 165.162Z"
            fill="#4D4D4D" />
          <path
            d="M188.604 60.8053C177.649 46.1963 162.375 35.4046 144.946 29.9589C127.516 24.5133 108.816 24.6897 91.4922 30.4632C74.1688 36.2368 59.1011 47.3147 48.4237 62.1278C37.7463 76.9408 32.0004 94.738 32 112.998L59.9833 112.999C59.9836 100.612 63.8813 88.5392 71.1244 78.4907C78.3674 68.4422 88.5886 60.9275 100.34 57.011C112.091 53.0945 124.777 52.9748 136.6 56.6689C148.424 60.363 158.785 67.6835 166.216 77.5936L188.604 60.8053Z"
            fill="#4D4D4D" />
        </svg>
      </button>
    </a>
    <div class="flex flex-col gap-y-3 mt-4">
      <a
        class="flex items-center rounded-md"
        routerLink="/life"
        routerLinkActive="text-white bg-filtershover"
        [matTooltip]="'Life' | translate"
        matTooltipPosition="right">
        <button class="side-nav-btn">
          <i class="fa-thin fa-bullseye fa-xl"></i>
        </button>
      </a>
      <a
        class="flex items-center rounded-md"
        routerLink="/activities"
        routerLinkActive="text-white bg-filtershover"
        [matTooltip]="'Activities' | translate"
        matTooltipPosition="right">
        <button class="side-nav-btn">
          <i class="fa-thin fa-clipboard-check fa-xl"></i>
        </button>
      </a>
    </div>
  </div>
  <div class="pb-2 mt-auto flex flex-col">
    <a
      class="flex text-xl rounded-[5px] text-black items-center justify-center mb-[15px] z-10">
      <button class="side-nav-btn" id="newsfeed-launcher">
        <i
          [matBadge]="unreadCount"
          matBadgeSize="small"
          matBadgePosition="above after"
          [matBadgeHidden]="unreadCount === 0"
          class="fa-thin fa-bell"></i>
      </button>
    </a>
    <!--    <a-->
    <!--      class="flex text-xl rounded-[5px] text-black items-center justify-center mb-[15px] z-10"-->
    <!--      [cdkMenuTriggerFor]="helpMenu"-->
    <!--      [cdkMenuPosition]="[-->
    <!--        {-->
    <!--          originX: 'end',-->
    <!--          originY: 'bottom',-->
    <!--          overlayX: 'end',-->
    <!--          overlayY: 'bottom'-->
    <!--        }-->
    <!--      ]"-->
    <!--      routerLinkActive="text-white bg-filtershover">-->
    <!--      <button class="side-nav-btn">-->
    <!--        <i class="fa-thin fa-circle-question"></i>-->
    <!--      </button>-->
    <!--    </a>-->
    <a
      class="flex text-xl z-20 items-center rounded-[5px] text-black justify-center mb-[15px]"
      [cdkMenuTriggerFor]="userMenu"
      [cdkMenuPosition]="[
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetX: 49
        }
      ]"
      routerLinkActive="text-white bg-filtershover">
      <button class="side-nav-btn">
        <i class="fa-thin fa-user"></i>
      </button>
    </a>
  </div>
</aside>

<ng-template #userMenu>
  <div cdkMenu class="popup-menu">
    <button
      cdkMenuItem
      (click)="openAccountSettings('profile')"
      class="popup-menu-btn">
      <i class="fa-light fa-user mr-4"></i>
      <span>{{ 'ContextMenuAccount' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      (click)="openAccountSettings('metric')"
      class="popup-menu-btn">
      <i class="fa-light fa-gear mr-4"></i>
      <span>{{ 'ContextMenuSettings' | translate }}</span>
    </button>
    <button cdkMenuItem routerLink="/logout" class="popup-menu-btn-trash">
      <i class="fa-light fa-arrow-right-from-bracket mr-4"></i>
      <span>{{ 'ContextMenuLogout' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #helpMenu>
  <div
    cdkMenu
    class="ml-12 flex flex-col shadow p-3 bg-white text-sm z-10000 text-menugray rounded-lg">
    <button cdkMenuItem (click)="openOnboarding()" class="popup-menu-btn">
      <i class="fa-light fa-user mr-4"></i>
      <span>{{ 'ContextMenuOnboarding' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openContactSupport()" class="popup-menu-btn">
      <i class="fa-regular fa-comment mr-4"></i>
      <span>{{ 'ContextMenuContactSupport' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openHelpCenter()" class="popup-menu-btn">
      <i class="fa-solid fa-globe mr-4"></i>
      <span>{{ 'ContextMenuHelpCenter' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openFAQ()" class="popup-menu-btn">
      <i class="fa-regular fa-comments mr-4"></i>
      <span>{{ 'ContextMenuFAQ' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      (click)="openAnnouncementsPortal()"
      class="popup-menu-btn">
      <i class="fa-solid fa-bullhorn mr-4"></i>
      <span>{{ 'ContextMenuAnnouncements' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openReportBug()" class="popup-menu-btn">
      <i class="fa-solid fa-bug mr-4"></i>
      <span>{{ 'ContextMenuReportBug' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openFeatureRequest()" class="popup-menu-btn">
      <i class="fa-light fa-gear mr-4"></i>
      <span>{{ 'ContextMenuFeatureRequest' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      (click)="openKeyboardShortcuts()"
      class="popup-menu-btn">
      <i class="fa-regular fa-keyboard mr-4"></i>
      <span>{{ 'ContextMenuKeyboardShortcuts' | translate }}</span>
    </button>
  </div>
</ng-template>
