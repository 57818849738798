import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ILifeState } from '@life/models/life-state';
import { ILifeSpecifics } from '@life/models/life-specifics';

export const OgLifeStateActions = createActionGroup({
  source: 'Life State',
  events: {
    selectLifeState: props<{ id: string }>(),
    loadLifeState: props<{ id: string; userId?: string }>(),
    loadLifeStateSuccess: props<{ lifeState: ILifeState; userId?: string }>(),
    loadLifeStateFail: props<{ error: string }>(),
    addLifeState: props<{ lifeAreaId: string; lifeState: ILifeState }>(),
    addLifeStateFromTemplate: props<{
      lifeState: ILifeState;
      lifeStateId: string;
      lifeAreaId: string;
    }>(),
    addLifeStateFail: props<{ error: string }>(),
    setLifeStateImportance: props<{ lifeState: ILifeState }>(),
    setLifeStateName: props<{ lifeState: ILifeState }>(),
    actuallySetLifeStateName: props<{ lifeState: ILifeState }>(),
    setLifeSpecificsHidden: props<{ lifeState: ILifeState }>(),
    setLifeStateDescription: props<{ lifeState: ILifeState }>(),
    setLifeStateSatisfaction: props<{ lifeState: ILifeState }>(),
    setLifeStateFocus: props<{ lifeState: ILifeState }>(),
    updateLifeStateSuccess: props<{ lifeState: ILifeState }>(),
    updateLifeStateFail: props<{ error: string }>(),
    deleteLifeState: props<{ lifeState: ILifeState }>(),
    deleteLifeStateSuccess: props<{ lifeState: ILifeState }>(),
    deleteLifeStateFail: props<{ error: string }>(),
    setLifeStatesSortOrder: props<{ lifeStates: ILifeState[] }>(),
    setLifeStatesSortOrderSuccess: props<{ lifeStates: ILifeState[] }>(),
    noop: emptyProps(),
    moveLifeSpecificFromLifeState: props<{
      currentLifeStateId: string;
      targetLifeStateId: string;
      lifeSpecificId: string;
      updatedLifeSpecifics: ILifeSpecifics[];
    }>(),
    moveLifeSpecificFromLifeStateSuccess: props<{
      targetLifeState: ILifeState;
    }>(),
  },
});
