<div class="w-full flex flex-col py-3 text-gray-600 gap-4">
  <div class="flex flex-col w-full gap-2" *ngIf="!lifeFilters().selectedDate">
    <quill-editor
      data-sl="mask"
      class="w-full hl-quill"
      [modules]="quillEditorOptions"
      placeholder="{{ 'DrawerNoteTabQuillPlaceholder' | translate }}"
      [(ngModel)]="newNote.text">
    </quill-editor>
    <button
      class="bg-gray-300 py-2 px-4 self-end rounded text-gray-600"
      (click)="handleSubmit(newNote)">
      {{ 'ButtonSave' | translate }}
    </button>
  </div>
  <div *ngIf="notes.length > 0" class="flex flex-col gap-2">
    <div class="flex items-center justify-between">
      <span class="text-sm font-bold text-menugray">{{
        'DrawerNoteTabYourStory' | translate
      }}</span>
      <div class="flex gap-2">
        <hl-input-search (search)="noteFilter = $event"></hl-input-search>
        <button
          [ngClass]="{ 'bg-filtershover text-white': focusFilters.length > 0 }"
          [cdkMenuTriggerFor]="focusFilter"
          class="w-9 h-9 bg-filters text-black rounded-[50%] hover:bg-filtershover hover:text-white">
          <i class="fa-thin fa-filter"></i>
        </button>
      </div>
    </div>
    <div *ngFor="let note of filteredNotes" class="flex flex-col gap-1">
      <hl-note
        data-sl="mask"
        [note]="note"
        (deleteNote)="deleteNote.emit($event)"
        (editNote)="updateNote.emit($event)"
        (toggleFocused)="updateFocused($event, note)"></hl-note>
    </div>
  </div>
</div>
<ng-template #focusFilter>
  <div class="flex bg-white shadow flex-col gap-1 p-3 w-[150px]" cdkMenu>
    <hl-is-favorite-filter
      [hideTitle]="true"
      [activeFilters]="focusFilters"
      (filterApplied)="focusFilters = $event"></hl-is-favorite-filter>
    <button class="fixed outline-0 right-[-99999px]" cdkMenuItem>&nbsp;</button>
  </div>
</ng-template>
