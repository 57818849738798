<div class="flex items-center justify-between gap-x-4">
  <i *ngIf="options.icon" [class]="options.icon" class="text-lg"></i>
  <div class="flex flex-col">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
      <ng-container *ngIf="duplicatesCount"
        >[{{ duplicatesCount + 1 }}]</ng-container
      >
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      [class]="options.messageClass"
      [innerHTML]="message"></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      [class]="options.messageClass"
      [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>

  <button
    *ngIf="options.closeButton"
    (click)="remove()"
    type="button"
    class="fa-regular fa-xmark text-2xl"
    aria-label="Close"></button>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
