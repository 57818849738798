<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-sharp fa-solid fa-signal-bars"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsSessionsQuantity' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-1" [cdkMenuTriggerFor]="menu">
      <span class="text-black" *ngIf="selectedMeasureLabel"
        >{{ selectedNumber }} {{ selectedMeasureLabel }}</span
      >
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>

<ng-template #menu>
  <div cdkMenu class="flex shadow">
    <div
      class="h-[200px] overflow-auto block p-3 bg-white text-sm z-[10000] text-menugray rounded-b-md w-20">
      <button
        *ngFor="let qty of quantity"
        class="popup-menu-btn block text-center w-full"
        (click)="handleQtySelection(qty)"
        [ngClass]="{ 'bg-filtershover text-white': selectedNumber === qty }">
        <span>{{ qty }}</span>
      </button>
    </div>
    <div
      class="flex flex-col items-center justofy-center p-3 bg-white text-sm z-[10000] text-menugray rounded-b-md w-20">
      <button
        *ngFor="let unit of units"
        class="popup-menu-btn"
        [ngClass]="{
          'bg-filtershover text-white': selectedUnit === unit.value
        }"
        (click)="handleUnitSelection(unit.value)">
        <span
          >{{ unit.name }}
          <span *ngIf="userIsAdmin()">({{ unit.value }})</span></span
        >
      </button>
    </div>
    <div
      class="flex flex-col items-center justofy-center p-3 bg-white text-sm z-[10000] text-menugray rounded-b-md w-20">
      <button
        *ngFor="let measure of availableMeasures"
        class="popup-menu-btn"
        [ngClass]="{
          'bg-filtershover text-white': selectedCountUnit === measure.value
        }"
        (click)="handleMeasureSelection(measure.value)">
        <span
          >{{ measure.name }}
          <span *ngIf="userIsAdmin()">({{ measure.value }})</span></span
        >
      </button>
    </div>
  </div>
</ng-template>
