import { createSelector } from '@ngrx/store';
import { lifeColorsFeatureKey } from '@life/reducers/life-colors.reducer';
import { selectLifeFeatureState } from '@life/reducers';

export const selectLifeColorsFeature = createSelector(
  selectLifeFeatureState,
  state => state[lifeColorsFeatureKey]
);

export const selectLifeColors = createSelector(
  selectLifeColorsFeature,
  state => state
);
