import { Component, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
@Component({
  selector: 'hl-account',
  templateUrl: './account.component.html',
})
export class AccountComponent {
  @Input() mobileView: boolean;

  rawTranslations = false;

  constructor(public auth: AuthService) {}
}
