export const QuillEditorOptions = {
  toolbar: [
    [{ header: 1 }, { header: 2 }], // custom button values
    ['bold', 'italic', 'underline', 'code-block'], // toggled buttons

    [{ list: 'ordered' }, { list: 'bullet' }],

    ['link', 'image', 'video'],
  ],
};
