import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppService } from '@core/services/app.service';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectProduct$ } from '@core/selectors/user.selectors';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'hl-pricing-table',
  standalone: true,
  imports: [CommonModule, TranslateModule, LetDirective],
  templateUrl: './pricing-table.component.html',
  styles: [],
})
export class PricingTableComponent {
  paymentInProgress = false;
  isMonthlyPayment = true;
  premium = this.store.selectSignal(selectProduct$);
  constructor(private appService: AppService, private store: Store) {}

  setIsMonthlyPayment(isMonthly: boolean) {
    this.isMonthlyPayment = isMonthly;
  }

  getPrice() {
    const price = this.premium().prices?.filter((p: any) => {
      return this.isMonthlyPayment
        ? p.recurring.interval === 'month'
        : p.recurring.interval === 'year';
    });
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: price[0]?.currency,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(price[0]?.unit_amount / 100);
  }

  purchase() {
    this.paymentInProgress = true;
    const price = this.premium().prices?.filter((p: any) => {
      return this.isMonthlyPayment
        ? p.recurring.interval === 'month'
        : p.recurring.interval === 'year';
    });
    const redirectStamp = `hl-${Date.now()}`;
    localStorage.setItem('hl-ts', redirectStamp);

    this.appService
      .subscribeToHolismo(
        price[0]?.id,
        `${window.location.origin}?paymentSuccess=${redirectStamp}`
      )
      .subscribe(x => {
        location.href = x;
        this.paymentInProgress = false;
      });
  }
}
