import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  Signal,
  TrackByFunction,
} from '@angular/core';
import * as fromLife from '@life/selectors';
import { Store } from '@ngrx/store';
import { ILifeArea } from '@life/models/life-area';
// @ts-ignore
import autoScroll from 'dom-autoscroller';
import {
  selectLifeAreaStateNames$,
  selectLifeColors,
  selectLifeFilters$,
  selectLifeStateSpecificNames$,
} from '@life/selectors';
import { IColor } from '@life/models/colors';
import {
  OgLifeActions,
  OgLifeSpecificActions,
  OgLifeStateActions,
} from '@life/actions';
import { DragulaService } from 'ng2-dragula';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService } from '@core/services/app.service';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PricingTableDialogComponent } from '@components/dialogs/pricing-table-dialog/pricing-table-dialog.component';
import { selectLifeAreasCount$ } from '@core/selectors/user.selectors';

@Component({
  selector: 'hl-life-area-table',
  templateUrl: './life-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeOverviewComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);
  lifeAreas = this.store.selectSignal<ILifeArea[]>(fromLife.selectLifeUI$);
  lifeAreasCount = this.store.selectSignal(selectLifeAreasCount$);
  availableLifeAreas = this.store.selectSignal(
    fromLife.selectAvailableLifeAreas$
  );
  lifeFilters = this.store.selectSignal(selectLifeFilters$);
  allColors: Signal<IColor[]>;

  constructor(
    private store: Store,
    private dragulaService: DragulaService,
    private appService: AppService,
    private dialog: Dialog,
    private translate: TranslateService
  ) {
    dragulaService.createGroup('LIFE_AREAS', {
      moves: (el, container, handle, sibling) => {
        return window.innerWidth > 768
          ? !!handle?.classList.contains('drag-handle') ||
              !!handle?.closest('.drag-handle')
          : !!handle?.classList.contains('drag-handle-mobile');
      },
      accepts: (el, target, source, sibling) => {
        const hasDisabledAreas =
          document.querySelectorAll('.disabled-area').length > 0;
        return hasDisabledAreas
          ? !!sibling && !sibling?.classList.contains('disabled-area')
          : true;
      },
    });

    dragulaService
      .drag('LIFE_AREAS')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ el }) => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: 'AREA' })
        );
      });

    dragulaService
      .drop('LIFE_AREAS')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: null })
        );
      });

    dragulaService.createGroup('LIFE_STATES', {
      moves: (el, container, handle) => {
        return window.innerWidth > 768
          ? !!handle?.classList.contains('drag-handle-state') ||
              !!handle?.closest('.drag-handle-state')
          : !!handle?.classList.contains('drag-handle-state-mobile');
      },
      accepts: (el, target, source, sibling) => {
        const lifeAreaId = target?.id as string;
        const stateName = el?.getAttribute('data-name') as string;
        const availableStateNames = this.store.selectSignal(
          selectLifeAreaStateNames$(lifeAreaId)
        );

        if (
          target?.id !== source?.id &&
          availableStateNames().includes(stateName)
        ) {
          document.body.classList.add('gu-forbidden');
          return false;
        } else {
          document.body.classList.remove('gu-forbidden');
          return true;
        }
      },
    });

    dragulaService
      .drag('LIFE_STATES')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ el }) => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: 'STATE' })
        );
      });

    dragulaService
      .dropModel('LIFE_STATES')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ sourceModel, targetModel, source, target, item }) => {
        if (sourceModel.find(el => el.id === item.id)) {
          const updatedLifeStates = sourceModel.map((x, index) => ({
            ...x,
            sortOrder: index,
          }));

          this.store.dispatch(
            OgLifeStateActions.setLifeStatesSortOrder({
              lifeStates: updatedLifeStates,
            })
          );
        } else {
          const updatedLifeStates = targetModel.map((x, index) => ({
            ...x,
            sortOrder: index,
          }));
          this.store.dispatch(
            OgLifeActions.moveLifeStateFromLifeArea({
              lifeStateId: item.id,
              currentLifeAreaId: source.id,
              targetLifeAreaId: target.id,
              updatedLifeStates,
            })
          );
        }
      });

    dragulaService
      .drop('LIFE_STATES')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: null })
        );
      });

    dragulaService.createGroup('LIFE_SPECIFICS', {
      moves: (el, container, handle) => {
        return window.innerWidth > 768
          ? !!handle?.classList.contains('drag-handle-specific') ||
              !!handle?.closest('.drag-handle-specific')
          : !!handle?.classList.contains('drag-handle-specific-mobile');
      },
      accepts: (el, target, source, sibling) => {
        const lifeStateId = target?.id as string;
        const specificName = el?.getAttribute('data-name') as string;
        const availableSpecificNames = this.store.selectSignal(
          selectLifeStateSpecificNames$(lifeStateId)
        );

        if (
          target?.id !== source?.id &&
          availableSpecificNames().includes(specificName)
        ) {
          document.body.classList.add('gu-forbidden');
          return false;
        } else {
          document.body.classList.remove('gu-forbidden');
          return true;
        }
      },
    });

    dragulaService
      .drag('LIFE_SPECIFICS')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: 'SPECIFIC' })
        );
      });

    dragulaService
      .drop('LIFE_SPECIFICS')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: null })
        );
      });

    dragulaService
      .cancel('LIFE_SPECIFICS')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(
          OgLifeActions.setLifeDragElement({ activeDragElement: null })
        );
      });

    dragulaService
      .dropModel('LIFE_SPECIFICS')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ el, sourceModel, targetModel, source, target, item }) => {
        if (sourceModel.find(el => el.id === item.id)) {
          const updatedLifeSpecifics = sourceModel.map((x, index) => ({
            ...x,
            sortOrder: index,
          }));

          this.store.dispatch(
            OgLifeSpecificActions.setLifeSpecificsSortOrder({
              lifeSpecifics: updatedLifeSpecifics,
            })
          );
        } else {
          const updatedLifeSpecifics = targetModel.map((x, index) => ({
            ...x,
            sortOrder: index,
          }));
          this.store.dispatch(
            OgLifeStateActions.moveLifeSpecificFromLifeState({
              lifeSpecificId: item.id,
              currentLifeStateId: source.id,
              targetLifeStateId: target.id,
              updatedLifeSpecifics,
            })
          );
        }
      });
  }

  ngOnInit() {
    // todo: do we need this?
    this.store.dispatch(OgLifeActions.loadTemplates());

    this.allColors = this.store.selectSignal(selectLifeColors);

    const scroller = autoScroll(
      // can also be an array of elements if they're { overflow: auto; max-height: XXpx } containers.
      // i.e. [someViewChild.nativeElement]
      window,
      {
        margin: 30,
        maxSpeed: 25,
        scrollWhenOutside: true,
        autoScroll: () => {
          // Only scroll when the pointer is down, and there is a child being dragged.
          return scroller.down; //&& drake.dragging; todo: do we rly need the check
        },
      }
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('LIFE_AREAS');
    this.dragulaService.destroy('LIFE_STATES');
    this.dragulaService.destroy('LIFE_SPECIFICS');
  }

  addNewLifeArea({ item }: { item: ILifeArea }) {
    if (this.lifeAreas().length >= this.lifeAreasCount()) {
      this.dialog.open(PricingTableDialogComponent, {
        maxWidth: '430px',
        panelClass: 'z-50',
        data: {
          message: this.translate.instant('DialogsUpgradePlanMessage', {
            value: this.lifeAreasCount(),
          }),
        },
      });

      return;
    }

    if (item.id) {
      this.store.dispatch(
        OgLifeActions.addLifeAreaFromTemplate({
          lifeArea: item,
        })
      );
    } else {
      this.store.dispatch(
        OgLifeActions.addLifeArea({
          lifeArea: {
            ...item,
            icon: 'fa-light fa-store-slash',
            darkColor: this.allColors()[0].darkColor,
            mediumColor: this.allColors()[0].mediumColor,
            lightColor: this.allColors()[0].lightColor,
          },
        })
      );
    }
  }

  updateLifeAreas(la: ILifeArea[]) {
    const updatedLifeAreas = la.map((x, index) => ({
      ...x,
      sortOrder: index,
    }));

    this.store.dispatch(
      OgLifeActions.setLifeAreasSortOrder({
        lifeAreas: updatedLifeAreas,
      })
    );
  }

  trackByLifeAreaIndex: TrackByFunction<ILifeArea> = (index, lifeArea) => {
    return lifeArea.id + lifeArea.sortOrder;
  };

  touchmove() {
    // do nothing
  }
}
