import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from '@angular/cdk/dialog';
import { AppConstants } from '@core/constants/app-constants';
import { OgLifeActions, OgLifeSpecificActions } from '@life/actions';

@Component({
  selector: 'hl-life-specific',
  templateUrl: './life-specific.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeSpecificComponent {
  @Input() lifeSpecific: ILifeSpecifics;
  @Input() color: string;
  @Input() mediumColor: string;
  @Input() first: boolean;

  canRename = false;
  appConstants = AppConstants;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private translate: TranslateService,
    private dialog: Dialog
  ) {}
  updateLifeSpecificImportance(importance: number) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificImportance({
        specific: {
          ...this.lifeSpecific,
          importance,
        },
      })
    );
  }

  updateLifeSpecificFocused(isFocused: boolean) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificFocus({
        specific: {
          ...this.lifeSpecific,
          isFocused,
        },
      })
    );
  }

  updateSatisfaction(satisfaction: number) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificSatisfaction({
        specific: {
          ...this.lifeSpecific,
          satisfaction,
        },
      })
    );
  }

  selectLifeSpecific(
    lifeSpecific: ILifeSpecifics,
    activeTabIndex = 0,
    force = false
  ) {
    if (lifeSpecific.isHighlighted && !force) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: null,
          activeItemType: null,
          activeTabIndex: null,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: lifeSpecific.id,
          activeItemType: 'specific',
          activeTabIndex,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  rename(value: string) {
    this.canRename = false;
    this.store.dispatch(
      OgLifeSpecificActions.setLifeSpecificName({
        specific: {
          ...this.lifeSpecific,
          name: value.trim(),
        },
      })
    );
  }
  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant(
          'ConfirmationDialogDeleteLifeSpecificTitle'
        ),
        message: this.translate.instant(
          'ConfirmationDialogDeleteLifeSpecificMessage',
          {
            name: this.lifeSpecific.name,
          }
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(
          OgLifeSpecificActions.deleteSpecific({
            specificId: this.lifeSpecific.id,
          })
        );
      }
    });
  }

  manageDrag(type: 'AREA' | 'STATE' | 'SPECIFIC' | null) {
    this.store.dispatch(
      OgLifeActions.setLifeDragElement({ activeDragElement: type })
    );
  }
}
