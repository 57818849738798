<div class="flex gap-3 items-center justify-end">
  <hl-input-search
    [initialValue]="lifeFilters().search"
    (search)="manageSearchFilter($event)"></hl-input-search>
  <button
    #btn
    #calendarMenuTrigger="cdkMenuTriggerFor"
    [cdkMenuTriggerFor]="calendarMenu"
    [ngClass]="{ 'bg-filtershover text-white': selectedDate() }"
    class="w-9 h-9 bg-filters text-black rounded-[50%] hover:bg-filtershover hover:text-white">
    <i class="fa-thin fa-calendar"></i>
  </button>
  <!--  <button-->
  <!--    [cdkMenuTriggerFor]="groupFilter"-->
  <!--    class="w-9 h-9 bg-filters rounded-[50%] text-black hover:bg-filtershover hover:text-white">-->
  <!--    <i class="fa-thin fa-diagram-subtask"></i>-->
  <!--  </button>-->
  <button
    *ngrxLet="filterCount() as filterCount"
    [cdkMenuTriggerFor]="filterMenu"
    [ngClass]="{ 'bg-filtershover text-white': filterCount > 0 }"
    class="w-9 h-9 bg-filters rounded-[50%] text-black hover:bg-filtershover hover:text-white">
    <i class="fa-thin fa-filter"></i>
  </button>
</div>

<ng-template #calendarMenu>
  <div
    id="filter-calendar"
    #calendarMenuContainer
    class="flex bg-white shadow flex-col gap-1 p-3 w-[300px]"
    cdkMenuBar
    *ngrxLet="{ selectedDate: selectedDate(), lifeDates: lifeDates() } as vm">
    <mat-calendar
      class="holismo-calendar"
      [minDate]="vm.lifeDates.startDate"
      [maxDate]="maxDate"
      [selected]="vm.selectedDate"
      [dateClass]="highLightDates"
      (selectedChange)="selectDate($event)">
    </mat-calendar>
    <button class="fixed outline-0 right-[-99999px]" cdkMenuItem>&nbsp;</button>
    <button
      *ngIf="selectedDate"
      cdkMenuItem
      (click)="selectDate(null)"
      class="border rounded text-sidenavicon inline-block justify-center self-center border-sidenavicon px-2">
      {{ 'LifeFilterCalendarReset' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #filterMenu>
  <div
    class="flex bg-white shadow flex-col gap-6 py-3 md:py-7 px-4 md:px-9 -mr-1 max-w-full scale-90 overflow-auto md:scale-100"
    cdkMenu>
    <div class="flex items-center justify-between">
      <span class="text-black/70 text-base">{{
        'LifeHeaderFiltersTitle' | translate
      }}</span>
      <div
        class="flex items-center gap-2"
        *ngrxLet="filterCount() as filterCount">
        <div
          *ngIf="filterCount > 0"
          class="w-6 h-6 bg-purple text-sm text-white rounded flex items-center justify-center">
          {{ filterCount }}
        </div>
        <button
          class="text-black/30 text-base"
          cdkMenuItem
          (click)="clearFilters()">
          {{ 'LifeHeaderFiltersClear' | translate }}
        </button>
      </div>
    </div>
    <div
      class="flex gap-1 justify-center flex-wrap sm:flex-nowrap"
      *ngrxLet="lifeFilters() as lifeFilters">
      <hl-actions-filter
        [activeFilters]="lifeFilters.actions"
        (filterApplied)="manageActiveFilters($event)"></hl-actions-filter>
      <hl-importance-filter
        [activeFilters]="lifeFilters.importance"
        (filterApplied)="
          manageImportanceFilters($event)
        "></hl-importance-filter>
      <hl-satisfaction-filter
        [activeFilters]="lifeFilters.satisfaction"
        (filterApplied)="
          manageSatisfactionFilters($event)
        "></hl-satisfaction-filter>
      <hl-urgency-filter
        [activeFilters]="lifeFilters.urgency"
        (filterApplied)="manageUrgencyFilters($event)"></hl-urgency-filter>
      <hl-is-favorite-filter
        [activeFilters]="lifeFilters.focus"
        (filterApplied)="manageFavoriteFilters($event)"></hl-is-favorite-filter>
    </div>
  </div>
</ng-template>

<ng-template #groupFilter>
  <div
    class="flex bg-white shadow flex-col gap-1 p-3 w-[214px] text-sm text-menugray rounded-lg"
    cdkMenu>
    <button
      cdkMenuItem
      class="flex items-center p-2 rounded text-menugray hover:bg-gray-500/20"
      (click)="manageGroupFilter('name')">
      Name
    </button>
    <button
      cdkMenuItem
      class="flex items-center p-2 rounded text-menugray hover:bg-gray-500/20"
      (click)="manageGroupFilter('lifeArea')">
      Life Area
    </button>
    <button
      cdkMenuItem
      class="flex items-center p-2 rounded text-menugray hover:bg-gray-500/20"
      (click)="manageGroupFilter('importance')">
      Importance
    </button>
    <button
      cdkMenuItem
      class="flex items-center p-2 rounded text-menugray hover:bg-gray-500/20"
      (click)="manageGroupFilter('satisfaction')">
      Satisfaction
    </button>
    <button
      cdkMenuItem
      class="flex items-center p-2 rounded text-menugray hover:bg-gray-500/20"
      (click)="manageGroupFilter('dayTime')">
      DayTime
    </button>
  </div>
</ng-template>
