<ng-container *ngIf="activeItem$ | async as lifeArea">
  <section>
    <header class="drawer-header">
      <div class="flex items-center justify-between mt-0.5">
        <div class="flex items-center gap-2">
          <button
            [ngClass]="{
              'pointer-events-none': lifeArea.readonly
            }"
            [cdkMenuTriggerFor]="colorAndIconMenu"
            tabindex="0">
            <i
              class="fa-solid w-5"
              [ngClass]="lifeArea.icon"
              [style.color]="lifeArea.darkColor"></i>
          </button>
          <h6
            class="text-base flex flex-1"
            data-sl="mask"
            [ngStyle]="{ color: lifeArea.darkColor }">
            <hl-inline-edit
              [editable]="canRename"
              (setValue)="rename($event, lifeArea)"
              (cancel)="canRename = false"
              [value]="lifeArea.name"></hl-inline-edit>
          </h6>
        </div>
        <div class="flex gap-x-3 text-holismo-grey">
          <button
            [ngClass]="{
              'pointer-events-none': lifeArea.readonly
            }"
            [cdkMenuTriggerFor]="menu">
            <i class="fa-solid px-3 fa-ellipsis-vertical"></i>
          </button>
          <hl-close-drawer></hl-close-drawer>
        </div>
      </div>
      <ul class="tab-container">
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(lifeArea.id, 0)"
          (keyup.enter)="selectTab(lifeArea.id, 0)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 0,
            'bg-gray-300': activeTab !== 0
          }">
          {{ 'DrawerTabAbout' | translate }}
        </li>
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(lifeArea.id, 1)"
          (keyup.enter)="selectTab(lifeArea.id, 1)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 1,
            'bg-gray-300': activeTab !== 1
          }">
          {{ 'DrawerTabNotes' | translate }}
          <span *ngIf="notes().length > 0">({{ notes().length }})</span>
        </li>
      </ul>
    </header>
    <div class="w-full flex flex-col items-center text-headingdark">
      <div class="tab-content w-full">
        <div class="flex flex-col w-full" *ngIf="activeTab === 0">
          <hl-tab-about
            #tabAboutComponent
            (updateDescription)="updateLifeAreaDescription($event, lifeArea)"
            [element]="lifeArea"></hl-tab-about>
        </div>
        <div *ngIf="activeTab === 1">
          <hl-tab-note
            [notes]="notes()"
            (addNewNote)="addLifeAreaNote($event, lifeArea)"
            (updateNote)="updateLifeAreaNote($event)"
            (updateNoteFocused)="updateLifeAreaNoteFocus($event)"
            (deleteNote)="deleteNote($event)"></hl-tab-note>
        </div>
      </div>
    </div>
  </section>

  <ng-template #menu>
    <div cdkMenu class="popup-menu">
      <button cdkMenuItem (click)="canRename = true" class="popup-menu-btn">
        <i class="w-3 fa-regular fa-pen mr-4"></i>
        <span> {{ 'ContextMenuRename' | translate }}</span>
      </button>
      <button
        [cdkMenuTriggerFor]="iconMenu"
        cdkMenuItem
        hlStopPropagation
        class="popup-menu-btn justify-between">
        <div>
          <i class="fa-solid w-3 mr-3" [ngClass]="lifeArea.icon"></i>
          <span> {{ 'ContextMenuChangeIcon' | translate }}</span>
        </div>
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        cdkMenuItem
        [cdkMenuTriggerFor]="colorPickerMenu"
        hlStopPropagation
        class="popup-menu-btn justify-between">
        <div>
          <i class="fa-regular fa-droplet mr-3 w-3"></i>
          <span> {{ 'ContextMenuChangeColor' | translate }}</span>
        </div>
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        cdkMenuItem
        hlStopPropagation
        (click)="deleteLifeArea(lifeArea)"
        class="popup-menu-btn-trash">
        <i class="w-3 fa-solid fa-trash mr-4"></i>
        <span> {{ 'ContextMenuDelete' | translate }}</span>
      </button>
    </div>
  </ng-template>

  <ng-template #colorAndIconMenu>
    <div cdkMenu class="popup-menu">
      <button
        [cdkMenuTriggerFor]="iconMenu"
        cdkMenuItem
        hlStopPropagation
        class="popup-menu-btn justify-between">
        <div>
          <i class="mr-4 fa-solid w-3" [ngClass]="lifeArea.icon"></i>
          <span> {{ 'ContextMenuChangeIcon' | translate }}</span>
        </div>
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <button
        cdkMenuItem
        [cdkMenuTriggerFor]="colorPickerMenu"
        hlStopPropagation
        class="popup-menu-btn justify-between">
        <div>
          <i class="w-3 fa-regular fa-droplet mr-4"></i>
          <span> {{ 'ContextMenuChangeColor' | translate }}</span>
        </div>
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </div>
  </ng-template>
  <ng-template #iconMenu>
    <div cdkMenu class="popup-menu p-0 shadow-none">
      <hl-icon-select
        [icons]="[]"
        (selectIcon)="setLifeAreaIcon($event, lifeArea)"></hl-icon-select>
    </div>
  </ng-template>

  <ng-template #colorPickerMenu>
    <div cdkMenu class="popup-menu w-[125px]">
      <hl-color-select
        cdkMenuItem
        [colors]="allColors()"
        (selectColor)="setLifeAreaColor($event, lifeArea)"></hl-color-select>
    </div>
  </ng-template>
</ng-container>
