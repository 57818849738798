import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';

@Component({
  selector: 'hl-do-it-on-the-side-dropdown',
  standalone: true,
  templateUrl: './do-it-on-the-side-dropdown.component.html',
  imports: [CdkMenuModule, TranslateModule],
})
export class DoItOnTheSideDropdownComponent {
  @Input() selected: boolean | undefined = false;
  @Output() canDoInOnTheSideChange = new EventEmitter<boolean>();

  handleSelection(selection: boolean) {
    this.selected = selection;
    this.canDoInOnTheSideChange.emit(selection);
  }
}
