<div class="flex flex-col gap-2">
  <div class="bg-gray-100 rounded-lg py-3 px-6 text-black/50">
    <span>{{ 'LifeHeaderFiltersUrgency' | translate }}</span>
  </div>
  <div class="flex flex-col gap-0.5">
    <div
      class="flex items-center gap-2 px-3 py-2.5 hover:bg-icons-gray cursor-pointer"
      *ngFor="let filter of urgencyFilters"
      [class.bg-icons-gray]="activeFilters.includes(filter)"
      (click)="applyFilter(filter)">
      <div
        class="h-3 w-3 fa-kit fa-holoflame"
        *ngFor="let element of ratingElements"
        [ngClass]="{
          'text-gray-300': filter <= element,
          'text-gray-600': filter > element
        }"></div>
    </div>
  </div>
</div>
