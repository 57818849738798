import { Injectable } from '@angular/core';

import { debounceTime, exhaustMap, fromEvent, merge, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@auth0/auth0-angular';
import { OgUserActions } from '@core/actions/user.actions';
import { AppService } from '@core/services/app.service';
import { IUser } from '@app/models';
import { Store } from '@ngrx/store';
import { selectUserSettings$ } from '@core/selectors/user.selectors';
import { OgLifeActions } from '@life/actions';
import { IReminder } from '@life/models/reminder';

@Injectable()
export class UserEffects {
  clicks$ = fromEvent(document, 'click');
  keys$ = fromEvent(document, 'keydown');
  mouse$ = fromEvent(document, 'mousemove');

  idle$ = createEffect(
    () => {
      return merge(this.clicks$, this.keys$, this.mouse$).pipe(
        // 20 minute inactivity timeout
        switchMap(() => timer(20 * 60 * 1000)),
        map(() => {
          this.auth.logout({
            logoutParams: { returnTo: document.location.origin },
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  initUserLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.initUser),
      map(() => OgUserActions.loadUser())
    );
  });

  initUserLoadProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.initUser),
      map(() => OgUserActions.loadProduct())
    );
  });

  initUserLoadReminders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.initUser),
      map(() => OgUserActions.loadReminders())
    );
  });

  initUserLoadLife$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.initUser),
      map(() => OgLifeActions.init({}))
    );
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.loadUser),
      exhaustMap(() => {
        return this.appService.fetchUser().pipe(
          map((user: IUser) => {
            return OgUserActions.loadUserSuccess({ user });
          }),
          catchError(({ error }) =>
            of(
              OgUserActions.loadUserFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.updateUser),
      concatLatestFrom(() => this.store.select(selectUserSettings$)),
      exhaustMap(([userSettingsPatch, userSettings]) => {
        return this.appService
          .updateUserSettings({
            ...userSettings,
            ...userSettingsPatch,
          })
          .pipe(
            map(() => {
              return OgUserActions.loadUser();
            }),
            catchError(({ error }) =>
              of(
                OgUserActions.loadUserFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  loadProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.loadProduct),
      exhaustMap(() => {
        return this.appService.fetchProduct().pipe(
          map((product: any) => {
            return OgUserActions.loadProductSuccess({ product });
          }),
          catchError(({ error }) =>
            of(
              OgUserActions.loadProductFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  loadReminders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.loadReminders),
      exhaustMap(() => {
        return this.appService.fetchReminders().pipe(
          map((reminders: IReminder[]) => {
            return OgUserActions.loadRemindersSuccess({ reminders });
          }),
          catchError(({ error }) =>
            of(
              OgUserActions.loadRemindersFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  toggleReminder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.toggleReminder),
      exhaustMap(({ reminderId }) => {
        return this.appService.toggleReminder(reminderId).pipe(
          map(() => {
            return OgUserActions.loadReminders();
          }),
          catchError(({ error }) =>
            of(
              OgUserActions.loadRemindersFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  updateReminderTimes = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgUserActions.updateReminderTimes),
      debounceTime(500),
      switchMap(({ reminderId, times }) => {
        return this.appService.setReminderTimes(reminderId, times).pipe(
          map(() => {
            return OgUserActions.loadReminders();
          }),
          catchError(({ error }) =>
            of(
              OgUserActions.loadRemindersFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  constructor(
    private auth: AuthService,
    private actions$: Actions,
    private store: Store,
    private appService: AppService
  ) {}
}
