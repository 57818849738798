import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ILifeNote } from '@life/models/life-note';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LifeNoteService {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  addLifeNote(note: Partial<ILifeNote>) {
    return this.http.post<string>(
      `${this.baseUrl}/${note.elementType}/${note.elementId}/notes`,
      {
        text: note.text,
      }
    );
  }

  setLifeNoteFocus(note: ILifeNote) {
    return this.http.post<string>(`${this.baseUrl}/note/${note.id}/focus`, {});
  }

  updateNoteText(note: ILifeNote) {
    return this.http.post(`${this.baseUrl}/note/${note.id}`, {
      text: note.text,
    });
  }

  loadNotes(
    type: 'lifearea' | 'specific' | 'state' | 'activity',
    typeId: string,
    lifeFrom?: number | null
  ) {
    const query = lifeFrom
      ? `?lifeFrom=${new Date(lifeFrom).toISOString()}`
      : '';
    return this.http.get<ILifeNote[]>(
      `${this.baseUrl}/${type}/${typeId}/notes${query}`
    );
  }

  deleteNote(noteId: string) {
    return this.http.delete(`${this.baseUrl}/note/${noteId}`);
  }
}
