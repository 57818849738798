<div class="w-full relative z-10">
  <hl-priority-chart class="w-full block" [elements]="elements()">
    <ng-template hlChartPopover let-element let-hide="hide">
      <hl-life-action-tooltip
        [element]="element"
        (updateElementFocused)="handleElementFocus($event, element); hide()"
        (updateElementRating)="handleElementRating($event, element); hide()"
        (updateElementSatisfaction)="
          handleElementSatisfaction($event, element); hide()
        "
        (openDetails)="openDetails(element); hide()" />
    </ng-template>
  </hl-priority-chart>
</div>
