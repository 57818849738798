import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from '@core/reducers/user.reducer';
import { selectReminders } from '@core/reducers/user.reducer';
import { ReminderType } from '@life/models/reminder';

export const selectUserState = createFeatureSelector<fromUser.UserState>(
  fromUser.userFeatureKey
);
export const selectUser$ = createSelector(selectUserState, fromUser.selectUser);
export const selectUserSettings$ = createSelector(
  selectUser$,
  user => user?.settingsDto
);

export const selectUserSettingsFirstDayOfWeek$ = createSelector(
  selectUser$,
  user => user?.settingsDto?.firstDayOfWeek || 0
);

export const selectUserSettingsMetric$ = createSelector(
  selectUser$,
  user => user && user.settingsDto.metric
);

export const selectUserSettingsTimeZone$ = createSelector(
  selectUser$,
  user => user && user.settingsDto.timeZone
);

export const selectUserMetadata$ = createSelector(
  selectUser$,
  user => user && user.metadata
);

export const selectUserSubscription$ = createSelector(
  selectUser$,
  user => user && user.subscription
);

export const selectUserRoles$ = createSelector(
  selectUserState,
  fromUser.selectUserRoles
);
export const selectDisplayLifeWheel$ = createSelector(
  selectUserState,
  fromUser.selectDisplayLifeWheel
);

export const selectUserReminders$ = createSelector(
  selectUserState,
  fromUser.selectReminders
);

export const selectReminderByType$ = (type: ReminderType) =>
  createSelector(
    selectUserReminders$,
    reminders =>
      reminders.find(r => r.type === type) || {
        times: [],
        isActive: false,
        id: 'nan',
        type,
      }
  );

export const selectLifeAreasCount$ = createSelector(
  selectUserState,
  fromUser.selectLifeAreasCount
);

export const selectProductInfo$ = createSelector(
  selectUserState,
  fromUser.selectProduct
);

export const selectProduct$ = createSelector(
  selectProductInfo$,
  productInfo => {
    const premium: { id: string; name: string; description: string } =
      productInfo.product.find((p: any) => p.name === 'Premium');

    const prices = productInfo.prices?.filter(
      (p: any) => p.product === premium.id
    );
    return {
      product: premium,
      prices,
    };
  }
);

export const selectPrices$ = createSelector(selectProductInfo$, productInfo => {
  return productInfo.prices;
});

export const selectLifeStartDate$ = createSelector(
  selectUserState,
  fromUser.selectLifeStartDate
);

export const selectUserHasRole$ = (rolename: string) =>
  createSelector(selectUserRoles$, roles => roles.includes(rolename));
