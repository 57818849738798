import { createReducer, on } from '@ngrx/store';

import { LayoutActions } from '@app/core/actions';

export const layoutFeatureKey = 'layout';

export interface LayoutState {
  showSidenav: boolean;
  showDrawer: boolean;
}

const initialState: LayoutState = {
  showSidenav: false,
  showDrawer: false,
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.closeSidenav, (state): LayoutState => {
    return { ...state, showSidenav: false };
  }),
  on(LayoutActions.openSidenav, (state): LayoutState => {
    return { ...state, showSidenav: true };
  }),
  on(LayoutActions.openDrawer, (state): LayoutState => {
    return { ...state, showDrawer: true };
  }),
  on(LayoutActions.closeDrawer, (state): LayoutState => {
    return { ...state, showDrawer: false };
  })
);

export const selectShowSidenav = (state: LayoutState) => state.showSidenav;
export const selectShowDrawer = (state: LayoutState) => state.showDrawer;
