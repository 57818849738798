<div class="flex w-full items-center px-3">
  <div
    class="w-full flex items-center border-b justify-between py-3"
    cdkMenuBar>
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-light fa-link-simple"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsInterval' | translate
      }}</span>
    </div>
    <div
      class="flex items-center gap-1"
      #btn
      #masterMenuTrigger="cdkMenuTriggerFor"
      [cdkMenuTriggerFor]="menu">
      <span class="text-black" *ngIf="selected">{{ selected }}</span>
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>
<ng-template #menu>
  <div cdkMenu #mainMenuContainer class="popup-menu gap-y-1">
    <button
      cdkMenuItem
      class="popup-menu-btn gap-1"
      [ngClass]="{ 'bg-filtershover text-white': when === 1 }"
      [cdkMenuTriggerFor]="daysMenu">
      <i class="fa-light fa-chevron-left"></i>
      <span>{{ 'DrawerActivityMetadataWhenOptionDaily' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      class="popup-menu-btn gap-1"
      [ngClass]="{ 'bg-filtershover text-white': when === 2 }"
      [cdkMenuTriggerFor]="calendarMenu">
      <i class="fa-light fa-chevron-left"></i>
      <span>{{ 'DrawerActivityMetadataWhenOptionMonthly' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      class="popup-menu-btn gap-1"
      [ngClass]="{ 'bg-filtershover text-white': when === 3 }"
      [cdkMenuTriggerFor]="intervalMenu">
      <i class="fa-light fa-chevron-left"></i>
      <span>{{ 'DrawerActivityMetadataWhenOptionInterval' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #daysMenu>
  <div #daysMenuContainer cdkMenu class="popup-menu w-36 gap-y-1">
    <button
      *ngFor="let day of daysOfTheWeek"
      class="popup-menu-btn"
      [ngClass]="{
        'flex items-center justify-between bg-filtershover text-white':
          weekdays && weekdays.includes(day.day)
      }"
      (click)="handleDailySelection(day.day)">
      <span>{{ day.translationTerm | translate }}</span>
      <i *ngIf="weekdays.includes(day.day)" class="fa-duotone fa-check"></i>
    </button>
  </div>
</ng-template>
<ng-template #calendarMenu>
  <div
    tabindex="0"
    #calendarMenuContainer
    class="flex bg-white shadow flex-col gap-1 p-3 w-[300px] rounded-md"
    cdkMenu>
    <div>
      <mat-calendar
        #calendar
        class="holismo-calendar holismo-interval-calendar"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [dateClass]="selectedDates"
        (selectedChange)="selectDate($event, calendar)">
      </mat-calendar>
    </div>
  </div>
</ng-template>
<ng-template #intervalMenu>
  <div class="h-[400px] overflow-auto shadow rounded-b-md">
    <div cdkMenu class="popup-menu w-36">
      <button
        cdkMenuItem
        class="popup-menu-btn"
        [ngClass]="{
          'flex items-center justify-between bg-filtershover text-white':
            whenInterval === 1
        }"
        (cdkMenuItemTriggered)="handleIntervalSelection(1)">
        <span>{{
          'DrawerActivityMetadataWhenOptionIntervalAll' | translate
        }}</span>
        <i *ngIf="whenInterval === 1" class="fa-duotone fa-check"></i>
      </button>
      <button
        cdkMenuItem
        *ngFor="let interval of intervalOptions"
        class="popup-menu-btn"
        [ngClass]="{
          'flex items-center justify-between bg-filtershover text-white':
            whenInterval === interval
        }"
        (cdkMenuItemTriggered)="handleIntervalSelection(interval)">
        <span>{{
          'DrawerActivityMetadataWhenOptionIntervalValue'
            | translate : { value: interval }
        }}</span>
        <i *ngIf="whenInterval === interval" class="fa-duotone fa-check"></i>
      </button>
    </div>
  </div>
</ng-template>
