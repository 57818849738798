<div class="bg-white" *ngrxLet="premium() as premium">
  <div class="bg-gray-200 rounded-lg p-1 text-xs w-full flex">
    <button
      class="py-2 w-[50%] rounded-lg"
      [ngClass]="{
        'bg-purple text-white': isMonthlyPayment,
        'bg-gray-200 text-black': !isMonthlyPayment
      }"
      (click)="setIsMonthlyPayment(true)">
      {{ 'PricingTableMonthly' | translate }}
    </button>
    <button
      class="py-2 w-[50%] rounded-lg pr-1"
      [ngClass]="{
        'bg-purple text-white': !isMonthlyPayment,
        'bg-gray-200 text-black': isMonthlyPayment
      }"
      (click)="setIsMonthlyPayment(false)">
      {{ 'PricingTableYearly' | translate
      }}<span class="rounded-xl bg-pink text-white px-1 ml-1">{{
        'PricingTableYearlyPct' | translate
      }}</span>
    </button>
  </div>
  <div class="mt-4">
    <h2 class="font-bold text-base">{{ premium.product.name }}</h2>
    <span class="text-xs">{{ premium.product.description }}</span>
  </div>
  <div class="flex mt-4">
    <span class="font-bold text-3xl">{{ getPrice() }}</span>
    <div class="flex flex-col text-xs leading-3 justify-center ml-1">
      <span>{{ 'PricingTablePer' | translate }}</span>
      <span>{{
        (isMonthlyPayment ? 'PricingTableMonth' : 'PricingTableYear')
          | translate
      }}</span>
    </div>
  </div>
  <button
    (click)="purchase()"
    class="text-center bg-holismo-purple flex justify-center items-center w-full text-white rounded-md py-3 text-xs mt-4">
    <div *ngIf="paymentInProgress" aria-label="Loading..." role="status">
      <svg class="animate-spin w-6 h-6 stroke-white" viewBox="0 0 256 256">
        <line
          x1="128"
          y1="32"
          x2="128"
          y2="64"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="195.9"
          y1="60.1"
          x2="173.3"
          y2="82.7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="224"
          y1="128"
          x2="192"
          y2="128"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="195.9"
          y1="195.9"
          x2="173.3"
          y2="173.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="128"
          y1="224"
          x2="128"
          y2="192"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="60.1"
          y1="195.9"
          x2="82.7"
          y2="173.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="32"
          y1="128"
          x2="64"
          y2="128"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
        <line
          x1="60.1"
          y1="60.1"
          x2="82.7"
          y2="82.7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"></line>
      </svg>
    </div>
    <span *ngIf="!paymentInProgress">{{
      'PricingTableUpgrade' | translate
    }}</span>
  </button>
  <div
    class="flex flex-col text-xs mt-4"
    [innerHTML]="'PricingTableFeatures' | translate"></div>
</div>
