import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';

@Component({
  imports: [
    CommonModule,
    TranslateModule,
    CdkMenuModule,
    StopPropagationDirective,
    CdkMenuTrigger,
  ],
  selector: 'hl-feature-request-dialog',
  templateUrl: './feature-request-dialog.component.html',
  standalone: true,
})
export class FeatureRequestDialogComponent {
  rawTranslations = false;
  selectedTab: string;
  selectedModule = this.translate.instant('DialogsFeatureRequestSelectModule');
  chooseModule(module: string) {
    this.selectedModule = module;
  }

  constructor(private translate: TranslateService) {}
}
