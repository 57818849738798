import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { ILifeSpecifics } from '@life/models/life-specifics';
import {
  OgActivityActions,
  OgLifeActions,
  OgLifeSpecificActions,
} from '@life/actions';
import { calculateUrgency } from '@app/util';

export const lifeSpecificsFeatureKey = 'life-specifics';

export interface LifeSpecificsState extends EntityState<ILifeSpecifics> {
  lifeSpecifics?: ILifeSpecifics[];
  error?: string | null;
}

export const adapter = createEntityAdapter<ILifeSpecifics>();

const initialState: LifeSpecificsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(OgLifeActions.loadLifeAreas, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(
    OgLifeSpecificActions.updateSpecificSuccess,
    OgLifeSpecificActions.setSpecificFocus,
    OgLifeSpecificActions.setLifeSpecificDescription,
    OgLifeSpecificActions.actuallySetLifeSpecificName,
    (state, { specific }) => {
      return adapter.updateOne(
        {
          id: specific.id,
          changes: {
            ...specific,
          },
        },
        state
      );
    }
  ),
  on(
    OgLifeSpecificActions.setSpecificSatisfaction,
    OgLifeSpecificActions.setSpecificImportance,
    (state, { specific }) => {
      return adapter.updateOne(
        {
          id: specific.id,
          changes: {
            ...specific,
            urgency: calculateUrgency(specific),
          },
        },
        state
      );
    }
  ),
  on(OgLifeSpecificActions.loadLifeSpecificSuccess, (state, { specific }) => {
    return adapter.upsertOne(specific, state);
  }),
  on(
    OgLifeSpecificActions.setLifeSpecificsSortOrder,
    (state, { lifeSpecifics }) => {
      return adapter.upsertMany(lifeSpecifics, state);
    }
  ),
  on(OgLifeSpecificActions.deleteSpecificSuccess, (state, { specificId }) => {
    return adapter.removeOne(specificId, state);
  }),
  on(
    OgActivityActions.removeActivityFromLifeSpecific,
    (state, { lifeSpecificId, activityId }) => {
      const ls = state.entities[lifeSpecificId];
      return adapter.updateOne(
        {
          id: lifeSpecificId,
          changes: {
            ...ls,
            activityIds: ls?.activityIds.filter(id => id !== activityId),
          },
        },
        state
      );
    }
  ),
  on(
    OgActivityActions.addActivityToLifeSpecific,
    (state, { lifeSpecificId, activityId }) => {
      const ls = state.entities[lifeSpecificId];
      return adapter.updateOne(
        {
          id: lifeSpecificId,
          changes: {
            ...ls,
            activityIds: [...(ls ? ls.activityIds : []), activityId],
          },
        },
        state
      );
    }
  )
);

export const {
  selectEntities: selectLifeSpecificsEntities,
  selectAll: selectLifeSpecifics,
} = adapter.getSelectors();
