export enum ReminderType {
  PLAN = 1,
  REVIEW = 2,
  ACTIVITY = 3,
}

export interface IReminder {
  id: string;
  activityId?: string;
  type: ReminderType;
  times: string[];
  isActive: boolean;
}
