import { createSelector } from '@ngrx/store';
import {
  selectLifeActivities,
  selectLifeActivityEntities,
} from '@life/reducers/life-activity.reducer';
import { lifeActivityFeatureKey } from '@life/reducers/life-activity.reducer';
import { selectLifeFeatureState } from '@life/reducers';
import { selectLife$ } from '@life/selectors/life-area.selectors';

export const selectLifeActivitiesState = createSelector(
  selectLifeFeatureState,
  state => state[lifeActivityFeatureKey]
);

export const selectLifeActivities$ = createSelector(
  selectLifeActivitiesState,
  selectLifeActivities
);

export const selectLifeActivitiesEntities$ = createSelector(
  selectLifeActivitiesState,
  selectLifeActivityEntities
);

export const selectCheckIfActivityNameExists$ = (activityName: string) =>
  createSelector(selectLifeActivities$, activities => {
    const activity = activities.find(
      a => a.name.trim().toLowerCase() === activityName.trim().toLowerCase()
    );
    return !!activity;
  });
