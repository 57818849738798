import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { map, tap } from 'rxjs/operators';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { routerNavigatedAction } from '@ngrx/router-store';

import * as fromRoot from '@app/reducers';
import { filter } from 'rxjs';
import {
  OgActivityActions,
  OgLifeActions,
  OgLifeSpecificActions,
  OgLifeStateActions,
} from '@life/actions';

@Injectable()
export class RouterEffects {
  updateTitle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        concatLatestFrom(() => this.store.select(fromRoot.selectRouteData)),
        filter(([, data]) => !!data['title']),
        map(([, data]) => `${this.translate.instant(data['title'])} - Holismo`),
        tap(title => this.titleService.setTitle(title))
      );
    },
    {
      dispatch: false,
    }
  );

  selectItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.store.select(fromRoot.selectQueryParams)),
      map(([, params]) => params),
      filter(params => !!params['activeItemId'] && !!params['activeItemType']),
      map(params => {
        switch (params['activeItemType']) {
          case 'state':
            return OgLifeStateActions.selectLifeState({
              id: params['activeItemId'],
            });
          case 'area':
            return OgLifeActions.selectLifeArea({ id: params['activeItemId'] });
          case 'specific':
            return OgLifeSpecificActions.selectLifeSpecific({
              id: params['activeItemId'],
            });
          case 'activity':
            return OgActivityActions.selectActivity({
              activityId: params['activeItemId'],
            });
          default:
            return OgLifeActions.noop();
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private titleService: Title,
    private translate: TranslateService
  ) {}
}
