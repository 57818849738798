import { Component, Inject } from '@angular/core';
import { AccountSettingsDialogComponent } from '@app/components';
import { AuthService } from '@auth0/auth0-angular';
import { AppService } from '@core/services/app.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'hl-settings',
  templateUrl: './settings.component.html',
  styles: [],
})
export class SettingsComponent extends AccountSettingsDialogComponent {
  constructor(
    override auth: AuthService,
    public override appService: AppService,
    override store: Store,
    override translate: TranslateService,
    override dialog: Dialog
  ) {
    super(auth, appService, store, translate, dialog, { activeTab: 'x' });

    this.appService
      .openCustomerPortal(window.location.origin)
      .subscribe((customerPortalData: any) => {
        this.customerPortalUrl = customerPortalData.url;
      });
  }
}
