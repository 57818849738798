import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { AppService } from '@core/services/app.service';
import { ILifeActivity } from '@life/models/life-activity';
import { Store } from '@ngrx/store';
import {
  selectUserHasRole$,
  selectUserSettingsMetric$,
} from '@core/selectors/user.selectors';

@Component({
  selector: 'hl-sessions-quantity-dropdown',
  standalone: true,
  templateUrl: './sessions-quantity-dropdown.component.html',
  imports: [CommonModule, CdkMenuModule, TranslateModule],
})
export class SessionsQuantityDropdownComponent implements OnChanges {
  @Input() activity: ILifeActivity;

  selectedNumber: number;
  selectedUnit: number;
  selectedCountUnit: number;
  selectedMetric = this.store.selectSignal(selectUserSettingsMetric$);
  userIsAdmin = this.store.selectSignal(selectUserHasRole$('Admin'));

  @Output() changeQuantitySelection = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private appService: AppService,
    private store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const a = changes['activity'].currentValue;
    if (a) {
      this.selectedNumber = a.quantityPerSession;
      this.selectedUnit = a.quantityPerSessionUnit;
      this.selectedCountUnit = a.countUnit;
    }
  }

  quantity = Array.from(Array(1000).keys()).map(i => i + 1);
  units = [
    {
      name: this.translate.instant('AccountsSettingsMetricCount'),
      value: 1,
      metric: [
        {
          value: 1,
          name: 'Times',
        },
        {
          value: 2,
          name: 'Steps',
        },
        {
          value: 3,
          name: 'Pages',
        },
      ],
      imperial: [
        {
          value: 1,
          name: 'Times',
        },
        {
          value: 2,
          name: 'Steps',
        },
        {
          value: 3,
          name: 'Pages',
        },
      ],
    },
    {
      name: this.translate.instant('AccountsSettingsMetricLength'),
      value: 2,
      metric: [
        {
          value: 1,
          name: 'km',
        },
        {
          value: 2,
          name: 'm',
        },
      ],
      imperial: [
        {
          value: 1,
          name: 'miles',
        },
        {
          value: 2,
          name: 'yards',
        },
        {
          value: 3,
          name: 'feet',
        },
        {
          value: 4,
          name: 'inch',
        },
      ],
    },
    {
      name: this.translate.instant('AccountsSettingsMetricVolume'),
      value: 3,
      metric: [
        {
          value: 1,
          name: 'Liter',
        },
        {
          value: 2,
          name: 'dl',
        },
        {
          value: 3,
          name: 'cl',
        },
        {
          value: 4,
          name: 'ml',
        },
        {
          value: 5,
          name: 'Cups',
        },
      ],
      imperial: [
        {
          value: 1,
          name: 'gallon',
        },
        {
          value: 2,
          name: 'pint',
        },
        {
          value: 3,
          name: 'quart',
        },
      ],
    },
    {
      name: this.translate.instant('AccountsSettingsMetricWeight'),
      value: 4,
      metric: [
        {
          value: 1,
          name: 'kg',
        },
        {
          value: 2,
          name: 'g',
        },
      ],
      imperial: [
        {
          value: 1,
          name: 'pounds',
        },
        {
          value: 2,
          name: 'ounces',
        },
      ],
    },
    {
      name: this.translate.instant('AccountsSettingsMetricEnergy'),
      value: 5,
      metric: [
        {
          value: 1,
          name: 'kJoule',
        },
        {
          value: 2,
          name: 'joule',
        },
        {
          value: 3,
          name: 'cal',
        },
        {
          value: 4,
          name: 'kcal',
        },
      ],
      imperial: [
        {
          value: 1,
          name: 'kJoule',
        },
        {
          value: 2,
          name: 'joule',
        },
        {
          value: 3,
          name: 'cal',
        },
        {
          value: 4,
          name: 'kcal',
        },
      ],
    },
  ];

  get availableMeasures() {
    const units = this.units.find(m => m.value === this.selectedUnit);
    return this.selectedMetric() === 1 ? units?.metric : units?.imperial;
  }

  get selectedMeasureLabel() {
    return this.availableMeasures?.find(m => m.value === this.selectedCountUnit)
      ?.name;
  }

  handleQtySelection(qty: number) {
    this.selectedNumber = qty;

    this.emitChange();
  }
  handleUnitSelection(unit: number) {
    this.selectedUnit = unit;

    this.emitChange();
  }

  handleMeasureSelection(measure: number) {
    this.selectedCountUnit = measure;

    this.emitChange();
  }

  emitChange() {
    this.changeQuantitySelection.emit({
      number: this.selectedNumber,
      unit: this.selectedUnit,
      countUnit: this.selectedCountUnit,
    });
  }
}
