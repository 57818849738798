import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';

@Component({
  selector: 'hl-inline-edit',
  templateUrl: './inline-edit.component.html',
  standalone: true,
  imports: [NgIf, AutoSizeInputModule, StopPropagationDirective],
})
export class InlineEditComponent implements OnChanges {
  @ViewChild('input') input: ElementRef;

  @Input() editable: boolean;
  @Input() value: string;

  @Output() setValue = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();

  canEdit: boolean;
  ngOnChanges(changes: SimpleChanges) {
    this.canEdit = changes['editable']?.currentValue;

    if (this.canEdit) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      });
    }
  }

  updateValue(event: any) {
    const value = event?.target?.value;
    if (value) {
      this.setValue.emit(event?.target?.value);
    }
  }

  onBlur() {
    this.input.nativeElement.value = this.value;
    this.cancel.emit();
  }
}
