import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'hl-logout',
  templateUrl: './logout.component.html',
  styles: [],
})
export class LogoutComponent {
  constructor(auth: AuthService) {
    setTimeout(() => {
      auth.logout({ logoutParams: { returnTo: document.location.origin } });
    }, 5000);
  }
}
