import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';

@Component({
  selector: 'hl-input-search',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    StopPropagationDirective,
  ],
  templateUrl: './input-search.component.html',
})
export class InputSearchComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() initialValue = '';
  searchControl: FormControl;
  isExpanded = false;

  @Output() search = new EventEmitter<string>();

  ngOnInit() {
    this.searchControl = new FormControl(this.initialValue);

    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(val => {
        this.search.emit(val as string);
      });
  }

  expand() {
    this.isExpanded = true;
    this.searchInput.nativeElement.focus();
  }

  collapse(event: any) {
    if (event.relatedTarget?.id !== 'search-close') {
      this.isExpanded = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.isExpanded = false;
    this.searchInput.nativeElement.blur();
  }

  reset() {
    this.searchControl.reset();
    this.searchInput.nativeElement.focus();
  }
}
