import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLife from '@app/life/selectors';
import { LifeAreaDrawerComponent } from '@life/drawers/life-area-drawer/life-area-drawer.component';
import { LifeStateDrawerComponent } from '@life/drawers/life-state-drawer/life-state-drawer.component';
import { LifeSpecificsDrawerComponent } from '@life/drawers/life-specifics-drawer/life-specifics-drawer.component';
import { ActivityDrawerComponent } from '@app/activities/drawers/activity-drawer/activity-drawer.component';
import { ElementType } from '@life/models/element.type';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'hl-layout',
  templateUrl: './layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ flex: 0, opacity: 0.7 }),
        animate('.2s ease-in', style({ flex: 1, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ flex: 1, opacity: 1 }),
        animate('.2s ease-out', style({ flex: 0 })),
      ]),
    ]),
  ],
})
export class LayoutComponent {
  hasActiveElement = this.store.selectSignal(fromLife.selectActiveDrawerItem$);

  readonly drawerComponents = [
    LifeAreaDrawerComponent,
    LifeStateDrawerComponent,
    LifeSpecificsDrawerComponent,
  ];

  drawerComponent = computed(() => {
    const type = this.hasActiveElement().type;
    switch (type) {
      case ElementType.LIFE_AREA: {
        return LifeAreaDrawerComponent;
      }
      case ElementType.LIFE_SPECIFIC: {
        return LifeSpecificsDrawerComponent;
      }
      case ElementType.LIFE_STATE: {
        return LifeStateDrawerComponent;
      }
      case ElementType.LIFE_ACTIVITY: {
        return ActivityDrawerComponent;
      }
      default: {
        return null;
      }
    }
  });
  constructor(private store: Store) {}
}
