<div class="bg-[url('/assets/images/logout.png')] bg-cover bg-center h-screen">
  <div class="">
    <div
      class="flex flex-col sm:text-5xl text-3xl sm:leading-[70px] h-screen md:w-5/12 md:p-14 md:bg-blackopacity w-full p-8">
      <div class="w-56 h-16">
        <img src="assets/images/logo.svg" alt="Holismo" />
      </div>
      <div class="mt-14" [innerHTML]="'LogoutMessage' | translate"></div>
    </div>
  </div>
</div>
