<div class="relative z-[11]">
  <hl-sunburst-chart [elements]="elements()">
    <ng-template hlChartPopover let-element let-hide="hide">
      <hl-life-action-tooltip
        [element]="element"
        (updateElementFocused)="handleElementFocus($event, element); hide()"
        (updateElementRating)="handleElementRating($event, element); hide()"
        (updateElementSatisfaction)="
          handleElementSatisfaction($event, element); hide()
        "
        (openDetails)="openDetails(element); hide()" />
    </ng-template>
  </hl-sunburst-chart>
</div>
