import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { ILifeActivity } from '@life/models/life-activity';
import { OgActivityActions } from '@life/actions';
import { calculateUrgency } from '@app/util';

export const lifeActivityFeatureKey = 'life-activities';

export interface LifeActivityState extends EntityState<ILifeActivity> {
  error?: string | null;
}

export const adapter = createEntityAdapter<ILifeActivity>();

const initialState: LifeActivityState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(OgActivityActions.loadActivitiesSuccess, (state, { activities }) => {
    return adapter.setAll(activities, state);
  }),
  on(
    OgActivityActions.reLoadActivitiesSuccess,
    OgActivityActions.setActivitiesSortOrder,
    (state, { activities }) => {
      return adapter.upsertMany(activities, state);
    }
  ),
  on(OgActivityActions.addActivitySuccess, (state, { activity }) => {
    return adapter.addOne(activity, state);
  }),
  on(OgActivityActions.loadActivitySuccess, (state, { activity }) => {
    return adapter.upsertOne(activity, state);
  }),
  on(OgActivityActions.deleteActivitySuccess, (state, { activity }) => {
    return adapter.removeOne(activity.id, state);
  }),
  on(
    OgActivityActions.setActivityImportance,
    OgActivityActions.setActivitySatisfaction,

    (state, { activity }) => {
      return adapter.updateOne(
        {
          id: activity.id,
          changes: {
            ...activity,
            urgency: calculateUrgency(activity),
          },
        },
        state
      );
    }
  ),
  on(
    OgActivityActions.setActivityDescription,
    OgActivityActions.setActivityIcon,
    OgActivityActions.setActivityFocus,
    OgActivityActions.setActivityDayTime,
    OgActivityActions.actuallySetActivityName,
    OgActivityActions.setActivityNameSuccess,
    OgActivityActions.setActivityIconSuccess,
    OgActivityActions.updateActivity,
    OgActivityActions.updateActivitySuccess,
    (state, { activity }) => {
      return adapter.updateOne(
        {
          id: activity.id,
          changes: {
            ...activity,
          },
        },
        state
      );
    }
  ),
  on(OgActivityActions.moveActivity, (state, { activity, lifeAreaId }) => {
    return adapter.updateOne(
      {
        id: activity.id,
        changes: {
          ...activity,
          lifeAreaId,
        },
      },
      state
    );
  }),
  on(
    OgActivityActions.removeActivityFromLifeState,
    (state, { lifeStateId, activityId }) => {
      const activity = state.entities[activityId];
      return adapter.updateOne(
        {
          id: activityId,
          changes: {
            ...activity,
            stateIds: activity?.stateIds.filter(id => id !== lifeStateId),
          },
        },
        state
      );
    }
  ),
  on(
    OgActivityActions.removeActivityFromLifeSpecific,
    (state, { lifeSpecificId, activityId }) => {
      const activity = state.entities[activityId];
      return adapter.updateOne(
        {
          id: activityId,
          changes: {
            ...activity,
            specificIds: activity?.specificIds.filter(
              id => id !== lifeSpecificId
            ),
          },
        },
        state
      );
    }
  ),
  on(
    OgActivityActions.addActivityToLifeState,
    (state, { lifeStateId, activityId }) => {
      const activity = state.entities[activityId];
      return adapter.updateOne(
        {
          id: activityId,
          changes: {
            ...activity,
            stateIds: [...(activity ? activity.stateIds : []), lifeStateId],
          },
        },
        state
      );
    }
  ),
  on(
    OgActivityActions.addActivityToLifeSpecific,
    (state, { lifeSpecificId, activityId }) => {
      const activity = state.entities[activityId];
      return adapter.updateOne(
        {
          id: activityId,
          changes: {
            ...activity,
            specificIds: [
              ...(activity ? activity.specificIds : []),
              lifeSpecificId,
            ],
          },
        },
        state
      );
    }
  )
);

export const {
  selectEntities: selectLifeActivityEntities,
  selectAll: selectLifeActivities,
} = adapter.getSelectors();
