import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'hl-is-favorite-filter',
  templateUrl: './is-favorite-filter.component.html',
  standalone: true,
  imports: [TranslateModule, NgIf],
})
export class IsFavoriteFilterComponent {
  @Input() hideTitle = false;
  @Input() activeFilters: boolean[];
  @Output() filterApplied = new EventEmitter<boolean[]>();

  isFilterActive(filter: boolean) {
    return this.activeFilters?.includes(filter);
  }

  applyFavoriteFilter(action: boolean) {
    let payload = [...this.activeFilters];
    if (payload.includes(action)) {
      payload = payload.filter(x => x !== action);
    } else {
      payload.push(action);
    }

    this.filterApplied.emit(payload);
  }
}
