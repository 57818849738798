import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LifeNoteService } from '@life/services';
import { OgLifeNoteActions } from '../actions';

import { ILifeNote } from '@life/models/life-note';

@Injectable()
export class LifeNoteEffects {
  addLifeNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.addNote),
      exhaustMap(({ note }) =>
        this.lifeNoteService.addLifeNote(note).pipe(
          map(noteId => {
            return OgLifeNoteActions.addNoteSuccess({
              note: {
                ...note,
                id: noteId,
                addedAt: new Date().toUTCString(),
              } as ILifeNote,
            });
          }),
          catchError(error =>
            of(OgLifeNoteActions.addNoteFail({ error: error.message }))
          )
        )
      )
    );
  });

  addLifeNoteFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.updateNoteFocus),
      exhaustMap(({ note }) =>
        this.lifeNoteService.setLifeNoteFocus(note).pipe(
          map((res: string) => {
            // todo: GET BY ID FOR NOTES!!!
            return OgLifeNoteActions.updateNoteSuccess({ note });
          }),
          catchError(error =>
            of(OgLifeNoteActions.addNoteFail({ error: error.message }))
          )
        )
      )
    );
  });

  updateNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.updateNote),
      exhaustMap(({ note }) =>
        this.lifeNoteService.updateNoteText(note).pipe(
          map(() => {
            // todo: GET BY ID FOR NOTES!!!
            return OgLifeNoteActions.updateNoteSuccess({ note });
          }),
          catchError(error =>
            of(OgLifeNoteActions.addNoteFail({ error: error.message }))
          )
        )
      )
    );
  });

  deleteNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.deleteNote),
      exhaustMap(({ noteId }) =>
        this.lifeNoteService.deleteNote(noteId).pipe(
          map(() => {
            // todo: GET BY ID FOR NOTES!!!
            return OgLifeNoteActions.deleteNoteSuccess({ noteId });
          }),
          catchError(error =>
            of(OgLifeNoteActions.deleteNoteFail({ error: error.message }))
          )
        )
      )
    );
  });

  loadLifeAreaNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.loadLifeAreaNotes),
      switchMap(({ lifeAreaId, lifeFrom }) =>
        this.lifeNoteService.loadNotes('lifearea', lifeAreaId, lifeFrom).pipe(
          map(notes => {
            const mappedNotes = notes.map(note => ({
              ...note,
              elementId: lifeAreaId,
            }));
            return OgLifeNoteActions.loadNotesSuccess({ notes: mappedNotes });
          }),
          catchError(error =>
            of(OgLifeNoteActions.loadNotesFail({ error: error.message }))
          )
        )
      )
    );
  });

  loadLifeActivityNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.loadLifeActivityNotes),
      switchMap(({ activityId, lifeFrom }) =>
        this.lifeNoteService.loadNotes('activity', activityId, lifeFrom).pipe(
          map(notes => {
            const mappedNotes = notes.map(note => ({
              ...note,
              elementId: activityId,
            }));
            return OgLifeNoteActions.loadNotesSuccess({ notes: mappedNotes });
          }),
          catchError(error =>
            of(OgLifeNoteActions.loadNotesFail({ error: error.message }))
          )
        )
      )
    );
  });

  loadLifeStateNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.loadLifeStateNotes),
      exhaustMap(({ lifeStateId, lifeFrom }) =>
        this.lifeNoteService.loadNotes('state', lifeStateId, lifeFrom).pipe(
          map(notes => {
            const mappedNotes = notes.map(note => ({
              ...note,
              elementId: lifeStateId,
            }));
            return OgLifeNoteActions.loadNotesSuccess({ notes: mappedNotes });
          }),
          catchError(error =>
            of(OgLifeNoteActions.loadNotesFail({ error: error.message }))
          )
        )
      )
    );
  });

  loadLifeSpecificNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeNoteActions.loadLifeSpecificNotes),
      exhaustMap(({ lifeSpecificId, lifeFrom }) =>
        this.lifeNoteService
          .loadNotes('specific', lifeSpecificId, lifeFrom)
          .pipe(
            map(notes => {
              const mappedNotes = notes.map(note => ({
                ...note,
                elementId: lifeSpecificId,
              }));
              return OgLifeNoteActions.loadNotesSuccess({ notes: mappedNotes });
            }),
            catchError(error =>
              of(OgLifeNoteActions.loadNotesFail({ error: error.message }))
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private lifeNoteService: LifeNoteService
  ) {}
}
