import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { ILifeArea } from '@app/life/models/life-area';
import { OgLifeActions } from '@life/actions';

export const lifeTemplatesFeatureKey = 'life-templates';

export interface LifeTemplatesState extends EntityState<ILifeArea> {
  loading?: boolean;
  error?: string | null;
}

export const adapter = createEntityAdapter<ILifeArea>();

const initialState: LifeTemplatesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(OgLifeActions.loadTemplates, (state: LifeTemplatesState) => {
    return { ...state, loading: true };
  }),
  on(OgLifeActions.loadTemplatesSuccess, (state, { lifeArea }) => {
    return adapter.setAll(lifeArea, state);
  })
);

export const {
  selectIds: selectLifeTemplatesIds,
  selectEntities: selectLifeTemplatesEntities,
  selectAll: selectLifeTemplates,
} = adapter.getSelectors();
