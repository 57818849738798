import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILifeArea } from '@life/models/life-area';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-life-area-dropdown',
  standalone: true,
  templateUrl: './life-area-dropdown.component.html',
  imports: [CdkMenuModule, CommonModule, TranslateModule],
})
export class LifeAreaDropdownComponent {
  @Input() lifeAreas: ILifeArea[];
  @Input() selected: string | undefined;

  unnasignedLifeArea: ILifeArea = {
    id: 'unassigned',
    darkColor: '#4d4d4d',
    mediumColor: '#A6A6A6',
    lightColor: '#f1f3f6',
    description: '',
    activityIds: [],
    icon: 'fa-ban',
    importance: 0,
    notes: [],
    satisfaction: 0,
    sortOrder: 0,
    stateIds: [],
    states: [],
    statesHidden: false,
    urgency: 0,
    name: 'Unassigned',
    activities: [],
  };

  get selectedLifeArea() {
    return (
      this.lifeAreas.find(area => area.id === this.selected) ||
      (this.unnasignedLifeArea as ILifeArea)
    );
  }

  @Output() selectedLifeAreaChanged = new EventEmitter<ILifeArea>();

  handleSelection(lifeArea: ILifeArea) {
    this.selected = lifeArea.id;
    this.selectedLifeAreaChanged.emit(lifeArea);
  }
}
