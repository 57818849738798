import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MatSliderModule } from '@angular/material/slider';

@Component({
  selector: 'hl-energy-dropdown',
  standalone: true,
  templateUrl: './energy-dropdown.component.html',
  imports: [MatSliderModule, CdkMenuModule, TranslateModule],
})
export class EnergyDropdownComponent {
  @Input() selected: number;

  @Output() changeEnergy = new EventEmitter<number>();

  handleSelection(event: any) {
    const selection = Number(event.target.value);
    this.selected = selection;
    this.changeEnergy.emit(selection);
  }
}
