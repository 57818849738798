import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { selectLifeStartDate$ } from '@life/selectors';
import { Store } from '@ngrx/store';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { DatePipe, NgIf } from '@angular/common';

@Component({
  selector: 'hl-start-end-dropdown',
  standalone: true,
  templateUrl: './start-end-dropdown.component.html',
  imports: [
    TranslateModule,
    MatDatepickerModule,
    CdkMenuModule,
    DatePipe,
    NgIf,
  ],
})
export class StartEndDropdownComponent {
  @ViewChild('calendarStartMenuTrigger')
  calendarStartMenuTrigger: CdkMenuTrigger;
  @ViewChild('calendarEndMenuTrigger') calendarEndMenuTrigger: CdkMenuTrigger;
  @Input() minDate: string;
  @Input() selectedStartDate: string | undefined | null;
  @Input() selectedEndDate: string | undefined | null;

  @Output() changeStartDate = new EventEmitter<string | null>();
  @Output() changeEndDate = new EventEmitter<string | null>();

  constructor(private store: Store) {}
  lifeStartDate = this.store.selectSignal(selectLifeStartDate$);

  selectStartDate(event: Date | null) {
    this.calendarStartMenuTrigger.close();
    this.changeStartDate.emit(event ? event.toISOString() : null);
    this.selectedStartDate = event ? event.toISOString() : null;
  }
  selectEndDate(event: Date | null) {
    this.calendarEndMenuTrigger.close();
    this.changeEndDate.emit(event ? event.toISOString() : null);
    this.selectedEndDate = event ? event.toISOString() : null;
  }

  get getStartDate() {
    return this.selectedStartDate ? new Date(this.selectedStartDate) : null;
  }

  get getEndDate() {
    return this.selectedEndDate ? new Date(this.selectedEndDate) : null;
  }

  get minEndDate() {
    const date = this.selectedStartDate || this.lifeStartDate();
    return date ? new Date(date) : null;
  }

  get maxStartDate() {
    return this.selectedEndDate ? new Date(this.selectedEndDate) : null;
  }
}
