import { createAction, props } from '@ngrx/store';

export const setLifeFiltersActions = createAction(
  '[Life/Filters] Set Filter Actions',
  props<{ actions: number[] }>()
);

export const setLifeFiltersImportance = createAction(
  '[Life/Filters] Set Filter Importance',
  props<{ importance: number[] }>()
);

export const setLifeFiltersUrgency = createAction(
  '[Life/Filters] Set Filter Urgency',
  props<{ urgency: number[] }>()
);

export const setLifeFiltersSatisfaction = createAction(
  '[Life/Filters] Set Filter Satisfaction',
  props<{ satisfaction: number[] }>()
);

export const setLifeFiltersFocus = createAction(
  '[Life/Filters] Set Filter Focus',
  props<{ focus: boolean[] }>()
);

export const setLifeFiltersSearch = createAction(
  '[Life/Filters] Set Filter Search',
  props<{ search: string }>()
);

export const setLifeFiltersDate = createAction(
  '[Life/Filters] Set Filter Date',
  props<{ date: number | null }>()
);

export const clearLifeFilters = createAction('[Life/Filters] Clear Filters');
