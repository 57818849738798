import {
  NgModule,
  isDevMode,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { metaReducers, ROOT_REDUCERS } from '@app/reducers';
import { AppRoutingModule } from '@app/app-routing.module';
import { RouterEffects, UserEffects } from '@app/core/effects';
import { LifeEffects } from '@life/effects/life.effects';
import { CoreModule } from '@app/core';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { LifeAreaEffects } from '@life/effects/life-area.effects';
import { ToastrModule } from 'ngx-toastr';

import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateDefaultParser,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LifeStateEffects } from '@life/effects/life-state.effects';
import { LifeSpecificsEffects } from '@life/effects/life-specifics.effects';
import { ToastComponent } from '@components/toast/toast.component';
import { QuillModule } from 'ngx-quill';
import { TranslateHelperService } from '@core/services/translate-helper.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LifeActivityEffects } from '@life/effects/life-activity.effects';
import { LifeTemplatesEffects } from '@life/effects/life-templates.effects';
import { DragulaModule } from 'ng2-dragula';
import { environment } from '../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { selectUserSettingsFirstDayOfWeek$ } from '@core/selectors/user.selectors';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

class HolismoMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private translateHelperService: TranslateHelperService) {}
  handle(params: MissingTranslationHandlerParams) {
    return this.translateHelperService.useRawTranslations
      ? `{${params.key}}`
      : params.key;
  }
}

class HolismoTranslateParser extends TranslateDefaultParser {
  constructor(private translateHelperService: TranslateHelperService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  override interpolate(expr: string | Function, params?: any): string {
    return this.translateHelperService.useRawTranslations
      ? params?.key
      : super.interpolate(expr, params);
  }
}

class CustomDateAdapter extends NativeDateAdapter {
  firstDayOfWeek = this.store.selectSignal(selectUserSettingsFirstDayOfWeek$);
  constructor(matDateLocale: string, platform: Platform, private store: Store) {
    super(matDateLocale, platform);
  }

  override getFirstDayOfWeek(): number {
    return this.firstDayOfWeek();
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot({
      bgsColor: '#9021a2',
      bgsOpacity: 1,
      bgsPosition: 'center-left',
      bgsSize: 26,
      bgsType: 'ball-spin-clockwise',
      blur: 5,
      delay: 0,
      fastFadeOut: true,
      fgsColor: '#9021a2',
      fgsPosition: 'center-center',
      fgsSize: 60,
      fgsType: 'ball-spin-clockwise',
      gap: 0,
      logoPosition: 'center-center',
      logoSize: 120,
      logoUrl: '',
      masterLoaderId: 'master',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(40,40,40,0.3)',
      pbColor: '#9021a2',
      pbDirection: 'ltr',
      pbThickness: 10,
      hasProgressBar: true,
      text: '',
      textColor: '#FFFFFF',
      textPosition: 'center-center',
      maxTime: -1,
      minTime: 300,
    }),
    NgxUiLoaderHttpModule.forRoot({
      delay: 1000,
    }),
    AppRoutingModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: HolismoMissingTranslationHandler,
        deps: [TranslateHelperService],
      },
      parser: {
        provide: TranslateParser,
        useClass: HolismoTranslateParser,
        deps: [TranslateHelperService],
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      toastComponent: ToastComponent,
      iconClasses: {
        error: 'bg-toast-error',
        info: 'bg-toast-info',
        success: 'bg-toast-success',
        warning: 'bg-toast-warning',
      },
      closeButton: true,
      // @ts-ignore
      icon: null,
    }),
    AuthModule.forRoot(environment.auth0Config),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability and strictActionImmutability are enabled by default
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: true }),
    EffectsModule.forRoot(
      UserEffects,
      RouterEffects,
      LifeEffects,
      LifeAreaEffects,
      LifeTemplatesEffects,
      LifeStateEffects,
      LifeSpecificsEffects,
      LifeActivityEffects
    ),
    CoreModule,
    ServiceWorkerModule.register('./sw-master.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately',
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    DragulaModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform, Store],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
