import { createReducer, on } from '@ngrx/store';

import { LifeColorsActions } from '@life/actions';
import { IColor } from '@life/models/colors';

export const lifeColorsFeatureKey = 'life-colors';

export type ColorsState = IColor[];

const initialState: ColorsState = [];

export const reducer = createReducer(
  initialState,
  on(LifeColorsActions.getAllColorsSuccess, (state, { colors }) => {
    return colors;
  })
);
