import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { Store } from '@ngrx/store';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { AuthService } from '@auth0/auth0-angular';
import { LetDirective } from '@ngrx/component';
import { AppService } from '@core/services/app.service';
import { AccountSettingsComponent } from '@components/account-settings/account-settings.component';
import { PricingTableComponent } from '@components/pricing-table/pricing-table.component';
import { ConfirmationDialogComponent } from '@app/components';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ITimeZone } from '@life/models/time-zone';
import { startWith } from 'rxjs';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import {
  selectReminderByType$,
  selectUserSettingsFirstDayOfWeek$,
  selectUserSettingsMetric$,
  selectUserSettingsTimeZone$,
} from '@core/selectors/user.selectors';
import { OgUserActions } from '@core/actions/user.actions';
import { IReminder, ReminderType } from '@life/models/reminder';
import { formatTimeString } from '@app/util';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'hl-account-settings-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CdkMenuModule,
    StopPropagationDirective,
    LetDirective,
    CdkMenuTrigger,
    AccountSettingsComponent,
    PricingTableComponent,
    ReactiveFormsModule,
    MatSlideToggleModule,
    FormsModule,
    MatDialogModule,
  ],
  templateUrl: './account-settings-dialog.component.html',
})
export class AccountSettingsDialogComponent implements OnInit {
  @Input() mobileView: boolean;
  rawTranslations = false;
  selectedTab: string;
  customerPortalUrl: string;
  selectedDayOfWeek = this.store.selectSignal(
    selectUserSettingsFirstDayOfWeek$
  );
  selectedMetric = this.store.selectSignal(selectUserSettingsMetric$);
  selectedTimeZone = this.store.selectSignal(selectUserSettingsTimeZone$);
  planMyDayReminder = this.store.selectSignal(
    selectReminderByType$(ReminderType.PLAN)
  );
  reviewMyDayReminder = this.store.selectSignal(
    selectReminderByType$(ReminderType.REVIEW)
  );

  timeZones: any[];
  search = new FormControl('');
  constructor(
    public auth: AuthService,
    public appService: AppService,
    protected store: Store,
    protected translate: TranslateService,
    protected dialog: Dialog,
    @Inject(DIALOG_DATA) public dialogData: { activeTab: string }
  ) {
    this.selectedTab = dialogData.activeTab;

    appService
      .openCustomerPortal(window.location.origin)
      .subscribe((customerPortalData: any) => {
        this.customerPortalUrl = customerPortalData.url;
      });
  }

  ngOnInit() {
    this.appService.fetchTimezones().subscribe(timezones => {
      this.search.valueChanges.pipe(startWith('')).subscribe(res => {
        this.timeZones = timezones.filter(timezone =>
          !res ? true : timezone.name.toLowerCase().includes(res.toLowerCase())
        );
      });
      const timeZoneStand = timezones.find(
        timeZone => timeZone.id === this.selectedTimeZone()
      );
    });
  }

  clearSearch() {
    this.search.setValue('');
  }

  chooseDay(day: number) {
    this.store.dispatch(OgUserActions.updateUser({ firstDayOfWeek: day }));
  }

  chooseTimeZone(timezone: ITimeZone) {
    this.store.dispatch(OgUserActions.updateUser({ timeZone: timezone.id }));
  }

  chooseMetric(metric: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('ConfirmationMetricChangeTitle'),
        message: this.translate.instant('ConfirmationMetricChangeMessage', {
          name: this.translate.instant(
            metric === 2
              ? 'MetricContextMenuImperial'
              : 'MetricContextMenuMetric'
          ),
        }),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(OgUserActions.updateUser({ metric }));
      }
    });
  }

  chooseLanguage(language: string) {
    this.translate.use(language);
  }

  openCustomerPortal() {
    location.href = this.customerPortalUrl;
  }

  updateReminderActive(event: MatSlideToggleChange, reminder: IReminder) {
    this.store.dispatch(
      OgUserActions.toggleReminder({ reminderId: reminder.id })
    );
  }

  updateReminderTimes(time: string, reminder: IReminder) {
    this.store.dispatch(
      OgUserActions.updateReminderTimes({
        times: [formatTimeString(time)],
        reminderId: reminder.id,
      })
    );
  }
}
