<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-light fa-thumbs-up"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsOnTheSide' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-1" [cdkMenuTriggerFor]="menu">
      <span class="text-black">{{
        selected
          ? ('YesTranslationTerm' | translate)
          : ('NoTranslationTerm' | translate)
      }}</span>
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>
<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button cdkMenuItem class="popup-menu-btn" (click)="handleSelection(true)">
      <span>{{ 'YesTranslationTerm' | translate }}</span>
    </button>
    <button cdkMenuItem class="popup-menu-btn" (click)="handleSelection(false)">
      <span>{{ 'NoTranslationTerm' | translate }}</span>
    </button>
  </div>
</ng-template>
