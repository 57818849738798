<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-duotone fa-fence"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsSession' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-1" [cdkMenuTriggerFor]="menu">
      <span *ngIf="selected" class="text-black">{{ selected }} times</span>
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>

<ng-template #menu>
  <div class="h-[400px] overflow-auto shadow rounded-b-md">
    <div cdkMenu class="popup-menu w-20">
      <button
        *ngFor="let session of sessionOptions"
        cdkMenuItem
        class="popup-menu-btn flex justify-center"
        (click)="handleSelection(session)">
        <span>{{ session }}</span>
      </button>
    </div>
  </div>
</ng-template>
