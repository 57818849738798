import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  Input,
  OnChanges,
  OnInit,
  Signal,
  SimpleChanges,
  TrackByFunction,
} from '@angular/core';
import { ILifeState } from '@life/models/life-state';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ILifeSpecifics } from '@life/models/life-specifics';
import * as fromLifeSpecifics from '@life/selectors/life-specifics.selectors';
import { selectLifeStateIds$ } from '@life/selectors/life-state.selectors';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { AppConstants } from '@core/constants/app-constants';
import { selectLifeDragElement$ } from '@life/selectors';
import { OgLifeStateActions, OgLifeSpecificActions } from '@life/actions';
@Component({
  selector: 'hl-life-state',
  templateUrl: './life-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeStateComponent implements OnChanges, OnInit {
  @Input() lifeState: ILifeState;
  @Input() lifeAreaName: string;
  @Input() color: string;
  @Input() mediumColor: string;

  showAddNew = false;
  canRename = false;
  expandSpecifics = false;
  appConstants = AppConstants;

  availableLifeSpecifics: Signal<{
    used: string[];
    available: { label: string; item: ILifeSpecifics }[];
  }>;

  lifeStateIds: Signal<any[]>;

  dragElement = this.store.selectSignal(selectLifeDragElement$);

  isDragOver = computed(() => {
    return this.dragElement() === 'SPECIFIC';
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private translate: TranslateService,
    private dialog: Dialog,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['lifeState']?.currentValue ||
      changes['lifeAreaName']?.currentValue
    ) {
      this.availableLifeSpecifics = this.store.selectSignal(
        fromLifeSpecifics.selectAvailableLifeSpecifics$(
          this.lifeState.name,
          this.lifeAreaName
        )
      );
    }
  }

  ngOnInit() {
    this.lifeStateIds = this.store.selectSignal(selectLifeStateIds$);
  }

  updateLifeStateImportance(importance: number) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateImportance({
        lifeState: {
          ...this.lifeState,
          importance,
        },
      })
    );
  }

  updateLifeStateFocused(isFocused: boolean) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateFocus({
        lifeState: {
          ...this.lifeState,
          isFocused,
        },
      })
    );
  }

  updateLifeStateSatisfaction(satisfaction: number) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateSatisfaction({
        lifeState: {
          ...this.lifeState,
          satisfaction,
        },
      })
    );
  }

  toggleLifeSpecificsVisibility(specificsHidden: boolean) {
    this.store.dispatch(
      OgLifeStateActions.setLifeSpecificsHidden({
        lifeState: {
          ...this.lifeState,
          specificsHidden,
        },
      })
    );
  }

  selectLifeState(lifeState: ILifeState, activeTabIndex = 0, force = false) {
    if (lifeState.isHighlighted && !force) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: null,
          activeItemType: null,
          activeTabIndex: null,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: lifeState.id,
          activeItemType: 'state',
          activeTabIndex,
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  addNewLifeSpecific(lifeSpecific: any) {
    if (lifeSpecific.item.id) {
      this.store.dispatch(
        OgLifeSpecificActions.addLifeSpecificFromTemplate({
          lifeSpecific: lifeSpecific.item,
          lifeStateId: this.lifeState.id,
        })
      );
    } else {
      this.store.dispatch(
        OgLifeSpecificActions.addLifeSpecific({
          lifeSpecific: lifeSpecific.item,
          lifeStateId: this.lifeState.id,
        })
      );
    }

    if (this.lifeState.specificsHidden) {
      this.store.dispatch(
        OgLifeStateActions.setLifeSpecificsHidden({ lifeState: this.lifeState })
      );
    }
  }

  rename(value: string) {
    this.canRename = false;
    this.store.dispatch(
      OgLifeStateActions.setLifeStateName({
        lifeState: {
          ...this.lifeState,
          name: value.trim(),
        },
      })
    );
  }

  toggleAddNew(expandItems?: boolean) {
    if (expandItems && this.lifeState.specificsHidden) {
      this.toggleLifeSpecificsVisibility(false);
    }
    this.showAddNew = !this.showAddNew;
  }

  deleteState() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('ConfirmationDialogDeleteLifeStateTitle'),
        message: this.translate.instant(
          'ConfirmationDialogDeleteLifeStateMessage',
          {
            name: this.lifeState.name,
          }
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(
          OgLifeStateActions.deleteLifeState({ lifeState: this.lifeState })
        );
      }
    });
  }

  manageSpecificsExpand(doExpand: boolean) {
    this.expandSpecifics = doExpand;
    this.cd.detectChanges();
  }

  trackByLifeSpecificsIndex: TrackByFunction<ILifeSpecifics> = (
    index,
    lifeSpecific
  ) => lifeSpecific.id + lifeSpecific.sortOrder;

  touchmove() {
    // do nothing
  }
}
