<div class="mt-4 select-none life-area-table">
  <div
    class="flex flex-col px-4 gap-y-1"
    dragula="LIFE_AREAS"
    [dragulaModel]="lifeAreas()"
    (dragulaModelChange)="updateLifeAreas($event)">
    <hl-life-area
      data-sl="mask"
      (touchmove)="touchmove()"
      class="block"
      *ngFor="let lifeArea of lifeAreas(); trackBy: trackByLifeAreaIndex"
      [ngClass]="{ 'disabled-area': lifeArea.disabled }"
      [lifeArea]="lifeArea"></hl-life-area>
  </div>
  <div class="flex flex-col px-4 mt-0.5" *ngIf="!lifeFilters().selectedDate">
    <hl-autocomplete
      *ngIf="availableLifeAreas() as availableLifeAreas"
      class="block mb-20"
      placeholder="{{ 'AddNewLifeAreaPlaceholder' | translate }}"
      inputClass="bg-gray-100 py-5 mt-0.5 px-6"
      [options]="availableLifeAreas.available"
      [usedOptions]="availableLifeAreas.used"
      (selectOption)="addNewLifeArea($event)"></hl-autocomplete>
  </div>
</div>
