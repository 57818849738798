import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[hlStopPropagation]',
  standalone: true,
})
export class StopPropagationDirective {
  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event.stopPropagation();
  }

  @HostListener('keydown', ['$event'])
  public onKeydown(event: Event): void {
    // @ts-ignore
    if (event.code !== 'Escape') {
      event.stopPropagation();
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyup(event: Event): void {
    event.stopPropagation();
  }
}
