import { Component } from '@angular/core';
import { SidenavComponent } from '@core/components';

@Component({
  selector: 'hl-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styles: [],
})
export class MobileMenuComponent extends SidenavComponent {
  isSettingsPage() {
    return (
      location.pathname === '/account' || location.pathname === '/settings'
    );
  }
}
