import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesModule } from '@app/activities/activities.module';
import { selectLifeActivitiesUI$ } from '@life/selectors';
import { Store } from '@ngrx/store';
import { OgActivityActions, OgLifeActions } from '@life/actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hl-activity',
  standalone: true,
  imports: [CommonModule, ActivitiesModule],
  templateUrl: './activity-preview.component.html',
  styles: [],
})
export class ActivityPreviewComponent {
  lifeActivities = this.store.selectSignal(selectLifeActivitiesUI$);
  constructor(private store: Store, private route: ActivatedRoute) {
    const userId = this.route.snapshot.paramMap.get('userId') || '';
    this.store.dispatch(
      OgLifeActions.loadLifeAreas({ redirect: false, userId })
    );
    this.store.dispatch(
      OgActivityActions.loadActivities({
        lifeFrom: null,
        userId,
      })
    );
  }
}
