import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, switchMap, take, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IUser } from '@app/models';
import { map } from 'rxjs/operators';
import { ITimeZone } from '@life/models/time-zone';
import { IReminder } from '@life/models/reminder';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private uid: string;

  constructor(
    private updates: SwUpdate,
    private push: SwPush,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    if (updates.isEnabled) {
      timer(6 * 60 * 60).subscribe(() =>
        updates.checkForUpdate().then(() => console.log('checking for updates'))
      );
    }
  }

  checkForUpdates(): void {
    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(evt => this.promptUser(evt));
  }

  private promptUser(evt: VersionReadyEvent): void {
    this.toastr
      .info('Click to update!', `New app version available! `, {
        disableTimeOut: true,
      })
      .onTap.pipe(take(1))
      .subscribe(() =>
        this.updates.activateUpdate().then(() => document.location.reload())
      );
  }

  fetchUser() {
    return this.http.get<IUser>(`${environment.apiUrl}/user`);
  }

  fetchTimezones() {
    return this.http.get<ITimeZone[]>(
      `${environment.apiUrl}/settings/timezones`
    );
  }

  fetchProduct() {
    return this.http.get(`${environment.apiUrl}/product`).pipe(
      switchMap((productData: any) => {
        return this.http.get(`${environment.apiUrl}/product/prices`).pipe(
          map((prices: any) => {
            return {
              product: productData.data,
              prices: prices.data,
            };
          })
        );
      })
    );
  }

  fetchSubscription() {
    return this.http.get<any>(`${environment.apiUrl}/user/subscription`);
  }

  fetchReminders() {
    return this.http.get<IReminder[]>(`${environment.apiUrl}/reminder`);
  }

  toggleReminder(reminderId: string) {
    return this.http.post(
      `${environment.apiUrl}/reminder/${reminderId}/toggleactivation`,
      {}
    );
  }

  setReminderTimes(reminderId: string, times: string[]) {
    return this.http.post(
      `${environment.apiUrl}/reminder/${reminderId}`,
      times
    );
  }

  deleteAccount() {
    return this.http.delete(`${environment.apiUrl}/user`);
  }
  subscribeToHolismo(pricingId: string, successUrl: string) {
    return this.http.post(
      `${environment.apiUrl}/subscribe?priceId=${pricingId}&successUrl=${successUrl}`,
      {},
      { responseType: 'text' }
    );
  }

  openCustomerPortal(returnUrl: string) {
    return this.http.get(
      `${environment.apiUrl}/user/portal?returnUrl=${returnUrl}`
    );
  }

  subscribeToNotifications() {
    this.push
      .requestSubscription({
        serverPublicKey:
          'BMfBW-aNxr5zRFT7jPld3gEWhbrhvovGykCrIr7rfAkD6--2h_CSEoFWy6MRvz9SngmDTihyFQqYZGEGXG7HpLw',
      })
      .then(sub => console.log('subbed'))
      .catch(err => console.error('Could not subscribe to notifications', err));
  }

  generateAppUid() {
    this.uid = uuidv4();
  }

  get appUid() {
    return this.uid;
  }

  updateUserSettings(settings: {
    language?: string;
    firstDayOfWeek?: number;
    metric?: number;
    timeZone?: string;
  }) {
    return this.http.post(`${environment.apiUrl}/settings`, {
      ...settings,
    });
  }
}
