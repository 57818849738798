import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { TimeRequiredIntervalType } from '@life/models/time-required-interval.type';

@Component({
  selector: 'hl-sessions-time-dropdown',
  standalone: true,
  templateUrl: './sessions-time-dropdown.component.html',
  imports: [CdkMenuModule, CommonModule, TranslateModule],
})
export class SessionsTimeDropdownComponent {
  @Input() selectedDurationMinutes: number;
  @Input() selectedDurationHours: number;
  @Input() selectedTimeType: TimeRequiredIntervalType = 1;

  @Output() changeSessionsTime = new EventEmitter<any>();

  sessionDurationOptions = {
    [TimeRequiredIntervalType.HOURS]: Array.from(Array(24).keys()).map(v => v),
    [TimeRequiredIntervalType.MINUTES]: Array.from(Array(60).keys()).map(
      v => v
    ),
  };
  constructor(private translate: TranslateService) {}

  get selectedDuration() {
    const hours = this.selectedDurationHours
      ? `${this.selectedDurationHours} ${this.translate.instant(
          'DrawerActivityDropdownsSessionsTimeInterval1'
        )} `
      : '';
    const minutes = this.selectedDurationMinutes
      ? `${this.selectedDurationMinutes} ${this.translate.instant(
          'DrawerActivityDropdownsSessionsTimeInterval2'
        )}`
      : '';
    return `${hours}${minutes}`;
  }

  handleDurationSelection(
    selection: number,
    selectedTimeType: 'hours' | 'minutes'
  ) {
    if (selectedTimeType === 'hours') {
      this.selectedDurationHours = selection;
    } else if (selectedTimeType === 'minutes') {
      this.selectedDurationMinutes = selection;
    }
    this.changeSessionsTime.emit({
      interval: TimeRequiredIntervalType.HOURS,
      hours: this.selectedDurationHours || 0,
      minutes: this.selectedDurationMinutes || 0,
      [selectedTimeType]: selection,
    });
  }
}
