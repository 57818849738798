import { Component, signal, Signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromLife from '@life/selectors/life-area.selectors';
import { Store } from '@ngrx/store';
import { CloseDrawerComponent } from '@life/components/close-drawer/close-drawer.component';
import { InlineEditComponent } from '@components/inputs/inline-edit/inline-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  OgActivityActions,
  OgLifeNoteActions,
  OgLifeSpecificActions,
} from '@life/actions';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';

import { TabAboutComponent } from '@life/components/tabs/tab-about/tab-about.component';
import { TabNoteComponent } from '@life/components/tabs/tab-note/tab-note.component';
import { TabActivityComponent } from '@life/components/tabs/tab-activity/tab-activity.component';

import { ILifeNote } from '@life/models/life-note';
import { LifeModule } from '@life/life.module';
import { ConfirmationDialogComponent } from '@components/dialogs';
import { Dialog } from '@angular/cdk/dialog';
import { ElementType } from '@life/models/element.type';
import { selectNotes$ } from '@life/selectors';
import { filter } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'hl-life-specifics-drawer',
  standalone: true,
  imports: [
    CommonModule,
    CloseDrawerComponent,
    InlineEditComponent,
    TranslateModule,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    TabAboutComponent,
    TabNoteComponent,
    LifeModule,
    TabActivityComponent,
    LetDirective,
  ],
  templateUrl: './life-specifics-drawer.component.html',
  styles: [],
})
export class LifeSpecificsDrawerComponent {
  activeItem: ILifeSpecifics;
  @ViewChild('tabAboutComponent') tabAboutComponent: TabAboutComponent;
  activeItem$ = this.store.select(fromLife.selectActiveDrawerItem$).pipe(
    filter(item => !!item),
    tap(({ item, activeTabIndex }) => {
      this.activeTab = +activeTabIndex;

      if (
        this.activeItem &&
        this.activeItem.id !== item?.id &&
        this.tabAboutComponent?.descriptionControl?.value !== undefined &&
        this.activeItem.description !==
          this.tabAboutComponent?.descriptionControl?.value
      ) {
        this.updateLifeSpecificDescription(
          this.tabAboutComponent?.descriptionControl?.value,
          this.activeItem
        );
      }

      this.activeItem = item as ILifeSpecifics;

      if (item?.id) {
        this.notes = this.store.selectSignal(selectNotes$(item.id));
      }
    }),
    map(({ item }) => item as ILifeSpecifics)
  );

  canRename = false;
  notes: Signal<ILifeNote[]> = signal([]);
  activeTab = 1;

  constructor(
    private store: Store,
    private dialog: Dialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  addNewNote(note: Partial<ILifeNote>, lifeSpecific: ILifeSpecifics) {
    const newNote = {
      text: note.text,
      elementType: ElementType.LIFE_SPECIFIC,
      elementId: lifeSpecific.id,
    };
    this.store.dispatch(OgLifeNoteActions.addNote({ note: newNote }));
  }

  deleteNote(noteId: string) {
    this.store.dispatch(OgLifeNoteActions.deleteNote({ noteId: noteId }));
  }

  rename(value: string, lifeSpecific: ILifeSpecifics) {
    this.canRename = false;
    this.store.dispatch(
      OgLifeSpecificActions.setLifeSpecificName({
        specific: {
          ...lifeSpecific,
          name: value.trim(),
        },
      })
    );
  }

  updateLifeSpecificFocused(isFocused: boolean, lifeSpecific: ILifeSpecifics) {
    this.store.dispatch(
      OgLifeSpecificActions.setSpecificFocus({
        specific: {
          ...lifeSpecific,
          isFocused,
        },
      })
    );
  }

  delete(lifeSpecific: ILifeSpecifics) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: 'Delete Life Specific?',
        message: `Do you really want to delete ${lifeSpecific.name} Life Specific?`,
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(
          OgLifeSpecificActions.deleteSpecific({
            specificId: lifeSpecific.id,
          })
        );
      }
    });
  }

  updateLifeSpecificNote(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNote({ note }));
  }

  updateLifeSpecificNoteFocus(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNoteFocus({ note }));
  }

  updateLifeSpecificDescription(
    description: string | null,
    lifeSpecific: ILifeSpecifics
  ) {
    this.store.dispatch(
      OgLifeSpecificActions.setLifeSpecificDescription({
        specific: {
          ...lifeSpecific,
          description,
        },
      })
    );
  }

  manageActivity(
    event: { activityId: string; add: boolean },
    specific: ILifeSpecifics
  ) {
    if (event.add) {
      this.store.dispatch(
        OgActivityActions.addActivityToLifeSpecific({
          lifeSpecificId: specific.id,
          activityId: event.activityId,
        })
      );
    } else {
      this.store.dispatch(
        OgActivityActions.removeActivityFromLifeSpecific({
          lifeSpecificId: specific.id,
          activityId: event.activityId,
        })
      );
    }
  }

  selectTab(activeItemId: string, activeTabIndex = 0) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId,
        activeItemType: 'specific',
        activeTabIndex,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  activityCount(specific: ILifeSpecifics) {
    return specific?.activityIds?.length;
  }
}
