import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
@Component({
  imports: [
    CommonModule,
    TranslateModule,
    CdkMenuModule,
    StopPropagationDirective,
    CdkMenuTrigger,
  ],
  selector: 'hl-report-bug-dialog',
  templateUrl: './report-bug-dialog.component.html',
  standalone: true,
})
export class ReportBugDialogComponent {
  rawTranslations = false;
  selectedTab: string;
  selectedTopic = this.translate.instant('DialogsReportBugSelectTopic');
  chooseTopic(topic: string) {
    this.selectedTopic = topic;
  }

  constructor(private translate: TranslateService) {}
}
