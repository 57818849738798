import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOption',
  standalone: true,
})
export class FilterOptionPipe implements PipeTransform {
  transform(items: any[], query: string): any[] {
    if (!items) return [];
    if (!query || query.length == 0) return items;
    return items.filter(
      (p: any) => p.label?.toLowerCase().indexOf(query?.toLowerCase()) != -1
    );
  }
}
