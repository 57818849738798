import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromLife from '@life/reducers/life-area.reducer';
import * as fromLifeArea from '@life/reducers/life-templates.reducer';
import * as fromLifeState from '@app/life/reducers/life-state.reducer';
import * as fromLifeSpecific from '@app/life/reducers/life-specifics.reducer';
import * as fromLifeNotes from '@app/life/reducers/life-note.reducer';
import * as fromLifeColors from '@app/life/reducers/life-colors.reducer';
import * as fromLifeFilters from '@app/life/reducers/life-filters.reducer';
import * as fromLifeActivities from '@app/life/reducers/life-activity.reducer';

export const lifeFeatureKey = 'life';

export interface LifeState {
  [fromLife.lifeAreaFeatureKey]: fromLife.LifeAreaState;
  [fromLifeArea.lifeTemplatesFeatureKey]: fromLifeArea.LifeTemplatesState;
  [fromLifeState.lifeStateFeatureKey]: fromLifeState.LifeState;
  [fromLifeSpecific.lifeSpecificsFeatureKey]: fromLifeSpecific.LifeSpecificsState;
  [fromLifeNotes.lifeNoteFeatureKey]: fromLifeNotes.NoteState;
  [fromLifeColors.lifeColorsFeatureKey]: fromLifeColors.ColorsState;
  [fromLifeFilters.lifeFiltersFeatureKey]: fromLifeFilters.FiltersState;
  [fromLifeActivities.lifeActivityFeatureKey]: fromLifeActivities.LifeActivityState;
}
/** Provide reducer in AoT-compilation happy way */
export function lifeReducers(state: LifeState | undefined, action: Action) {
  return combineReducers({
    [fromLife.lifeAreaFeatureKey]: fromLife.reducer,
    [fromLifeArea.lifeTemplatesFeatureKey]: fromLifeArea.reducer,
    [fromLifeState.lifeStateFeatureKey]: fromLifeState.reducer,
    [fromLifeSpecific.lifeSpecificsFeatureKey]: fromLifeSpecific.reducer,
    [fromLifeNotes.lifeNoteFeatureKey]: fromLifeNotes.reducer,
    [fromLifeColors.lifeColorsFeatureKey]: fromLifeColors.reducer,
    [fromLifeFilters.lifeFiltersFeatureKey]: fromLifeFilters.reducer,
    [fromLifeActivities.lifeActivityFeatureKey]: fromLifeActivities.reducer,
  })(state, action);
}

export const selectLifeFeatureState =
  createFeatureSelector<LifeState>(lifeFeatureKey);
