import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILifeArea } from '@life/models/life-area';
import { CommonModule } from '@angular/common';
import { TruncateTextPipe } from '@core/pipes/truncate-text.pipe';

@Component({
  selector: 'hl-life-area-group',
  standalone: true,
  templateUrl: './life-area-group.component.html',
  imports: [CommonModule, TruncateTextPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeAreaGroupComponent {
  @Input() lifeArea: ILifeArea;
  @Input() childElementsCount: number;
  @Input() childElementsExpanded = true;

  expandChildElements() {
    this.childElementsExpanded = !this.childElementsExpanded;
  }
}
