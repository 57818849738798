import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { LifeItem } from '@life/models/life';

@Component({
  selector: 'hl-life-action-tooltip',
  templateUrl: './life-action-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeActionTooltipComponent {
  @Input() element: LifeItem;
  @Output() updateElementRating = new EventEmitter<number>();
  @Output() updateElementSatisfaction = new EventEmitter<number>();
  @Output() updateElementFocused = new EventEmitter<boolean>();
  @Output() openDetails = new EventEmitter<LifeItem>();

  updateRating(rating: number) {
    this.updateElementRating.emit(rating);
  }
  updateSatisfaction(satisfaction: number) {
    this.updateElementSatisfaction.emit(satisfaction);
  }
  updateFocus(isFocused: boolean) {
    this.updateElementFocused.emit(isFocused);
  }
}
