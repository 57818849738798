import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ActivitiesRoutingModule } from './activities-routing.module';
import { ActivitiesComponent } from './views/activities/activities.component';
import { ActivitiesOverviewComponent } from './views/activities-overview/activities-overview.component';
import { ActivitiesWheelComponent } from './views/activities-wheel/activities-wheel.component';
import { ActivitiesPriorityComponent } from './views/activities-priority/activities-priority.component';
import { LifeAreaActivitiesComponent } from './containers/life-area-activities/life-area-activities.component';
import { RatingComponent } from '@core/shared/rating/rating.component';
import { RatingSelectComponent } from '@core/shared/rating-select/rating-select.component';
import { IsFavoriteComponent } from '@core/shared/is-favorite/is-favorite.component';
import { ActivitiesListComponent } from './containers/activities-list/activities-list.component';
import {
  AutocompleteComponent,
  ChartPopoverDirective,
  IconSelectComponent,
  PriorityChartComponent,
  SunburstChartComponent,
} from '@app/components';
import { ActivityMapperComponent } from '@core/shared/activity-mapper/activity-mapper.component';
import { PartOfDayActivitiesComponent } from './containers/part-of-day-activities/part-of-day-activities.component';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivityComponent } from '@app/activities/containers/activity/activity.component';
import { InlineEditComponent } from '@app/components';
import { DragulaModule } from 'ng2-dragula';
import { LifeModule } from '@life/life.module';

@NgModule({
  declarations: [
    ActivitiesComponent,
    ActivitiesOverviewComponent,
    ActivitiesWheelComponent,
    ActivitiesPriorityComponent,
    LifeAreaActivitiesComponent,
    ActivitiesListComponent,
    PartOfDayActivitiesComponent,
    ActivityComponent,
  ],
  imports: [
    CommonModule,
    ActivitiesRoutingModule,
    TranslateModule,
    RatingComponent,
    RatingSelectComponent,
    IsFavoriteComponent,
    AutocompleteComponent,
    ActivityMapperComponent,
    StopPropagationDirective,
    CdkMenu,
    CdkMenuItem,
    IconSelectComponent,
    CdkMenuTrigger,
    MatTooltipModule,
    InlineEditComponent,
    DragulaModule,
    ChartPopoverDirective,
    PriorityChartComponent,
    LifeModule,
    SunburstChartComponent,
  ],
  exports: [ActivitiesListComponent],
})
export class ActivitiesModule {}
