<section
  class="border-none rounded-md radius-lg shadow-none px-3 md:px-6 cursor-pointer"
  [ngClass]="{ 'md:pb-4': childElementsCount > 0 && childElementsExpanded }"
  [style.background-color]="lifeArea.lightColor">
  <div
    class="flex flex-wrap items-center justify-between py-3 md:py-0 md:px-3 h-auto md:pr-[53px] md:pl-0"
    [ngClass]="{
      'md:h-14': childElementsCount === 0,
      'md:h-16': childElementsCount > 0
    }">
    <div class="flex items-center h-full w-full md:w-auto">
      <div
        class="flex items-center justify-center gap-x-3 h-full w-full md:w-auto">
        <i
          class="fa-solid w-5"
          [ngClass]="lifeArea.icon"
          [style.color]="lifeArea.darkColor"></i>
        <h6 class="text-base" [ngStyle]="{ color: lifeArea.darkColor }">
          {{ lifeArea.name | truncateText : 50 }}
        </h6>
        <div class="flex items-center h-full md:pr-2 cursor-default flex-1">
          <button
            (click)="childElementsCount > 0 && expandChildElements()"
            class="w-5 h-5 mr-3 flex items-center justify-center rounded text-[10px] text-white"
            [style.background-color]="lifeArea.darkColor">
            {{ childElementsCount }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="childElementsExpanded && childElementsCount > 0"
    [id]="lifeArea.id"
    class="flex flex-col gap-y-2 pb-2">
    <ng-content></ng-content>
  </div>
</section>
