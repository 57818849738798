import { createSelector } from '@ngrx/store';
import { lifeFiltersFeatureKey } from '@life/reducers/life-filters.reducer';
import { selectLifeFeatureState } from '@life/reducers';

export const selectLifeFiltersState = createSelector(
  selectLifeFeatureState,
  state => state[lifeFiltersFeatureKey]
);

export const selectLifeFilters$ = createSelector(
  selectLifeFiltersState,
  state => state
);

export const selectLifeDateFilter$ = createSelector(
  selectLifeFilters$,
  filters => filters.selectedDate
);

export const selectLifeFiltersCount$ = createSelector(
  selectLifeFilters$,
  filters =>
    filters.focus.length +
    filters.urgency.length +
    filters.actions.length +
    filters.satisfaction.length +
    filters.importance.length
);
