import { Component } from '@angular/core';
import { FiltersBase } from '@components/filters/filters.base';
import { NgClass, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-satisfaction-filter',
  templateUrl: './satisfaction-filter.component.html',
  standalone: true,
  imports: [NgClass, NgForOf, TranslateModule],
})
export class SatisfactionFilterComponent extends FiltersBase {
  constructor() {
    super();
  }

  satisfactionFilters = Array.from(Array(5).keys())
    .reverse()
    .map(x => x + 1);

  satisfactionScore: any = {
    1: { icon: 'fa-face-disappointed', cssClass: 'icons-red' },
    2: { icon: 'fa-face-frown-slight', cssClass: 'icons-lightred' },
    3: { icon: 'fa-face-meh', cssClass: 'icons-yellow' },
    4: { icon: 'fa-face-smile', cssClass: 'icons-lightgreen' },
    5: { icon: 'fa-face-grin-wide', cssClass: 'icons-green' },
  };
}
