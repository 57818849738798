import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'hl-sessions-dropdown',
  standalone: true,
  templateUrl: './sessions-dropdown.component.html',
  imports: [CdkMenuModule, TranslateModule, CommonModule],
})
export class SessionsDropdownComponent {
  @Input() selected: number;

  @Output() changeSessionsPerDay = new EventEmitter<number>();

  sessionOptions = Array.from(Array(20).keys()).map(v => v + 1);

  handleSelection(selection: number) {
    this.selected = selection;
    this.changeSessionsPerDay.emit(selection);
  }
}
