import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';
import {
  LifeFiltersActions,
  OgActivityActions,
  OgLifeActions,
  OgLifeNoteActions,
  OgLifeStateActions,
} from '../actions';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@core/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { LifeActivityService } from '@life/services';
import { ILifeActivity } from '@life/models/life-activity';
import { Store } from '@ngrx/store';
import {
  selectCheckIfActivityNameExists$,
  selectLifeActivities$,
  selectLifeDateFilter$,
} from '@life/selectors';

@Injectable()
export class LifeActivityEffects {
  INIT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.init),
      map(() => OgActivityActions.loadActivities({ lifeFrom: null }))
    );
  });

  loadActivitiesAtDate = createEffect(() => {
    return this.actions$.pipe(
      ofType(LifeFiltersActions.setLifeFiltersDate),
      map(data => {
        const lifeFrom = data.date ? new Date(data.date).toISOString() : null;
        return OgActivityActions.loadActivities({ lifeFrom });
      })
    );
  });

  loadLifeActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.loadActivities),
      switchMap(({ lifeFrom, userId }) =>
        this.lifeActivityService.getActivities(lifeFrom, userId).pipe(
          map((activities: ILifeActivity[]) => {
            return OgActivityActions.loadActivitiesSuccess({
              activities: activities.map(activity => ({
                ...activity,
                name: this.translate.instant(activity.name),
              })),
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.loadActivitiesFail({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  reLoadLifeActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.reloadActivities),
      switchMap(() =>
        this.lifeActivityService.getActivities().pipe(
          map((activities: ILifeActivity[]) => {
            return OgActivityActions.reLoadActivitiesSuccess({
              activities: activities.map(activity => ({
                ...activity,
                name: this.translate.instant(activity.name),
              })),
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.loadActivitiesFail({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  loadLifeActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.loadActivity),
      switchMap(({ activityId }) =>
        this.lifeActivityService.getActivity(activityId).pipe(
          map((activity: ILifeActivity) => {
            return OgActivityActions.loadActivitySuccess({
              activity: {
                ...activity,
                name: this.translate.instant(activity.name),
              },
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.loadActivityFail({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  addLifeActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.addActivity),
      switchMap(({ activity }) => {
        return this.lifeActivityService.createActivity(activity).pipe(
          map(activityId => {
            this.toastr.success(
              this.translate.instant('ToastrCreateLifeActivitySuccess')
            );
            return OgActivityActions.loadActivity({ activityId });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.addActivityFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  deleteLifeActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.deleteActivity),
      switchMap(({ activity }) => {
        return this.lifeActivityService.deleteActivity(activity.id).pipe(
          map(() => {
            this.toastr.success(
              this.translate.instant('ToastrDeleteLifeActivitySuccess')
            );
            return OgActivityActions.deleteActivitySuccess({
              activity,
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.deleteActivityFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  moveLifeActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.moveActivity),
      switchMap(({ activity, lifeAreaId }) => {
        return this.lifeActivityService.moveActivity(activity, lifeAreaId).pipe(
          map(() => {
            return OgActivityActions.updateActivitySuccess({
              activity: { ...activity, lifeAreaId },
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.updateActivityFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  setLifeActivityFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityFocus),
      switchMap(({ activity }) => {
        return this.lifeActivityService.setActivityFocus(activity).pipe(
          map(() => {
            return OgActivityActions.updateActivitySuccess({
              activity,
            });
          }),
          catchError(error =>
            of(
              OgActivityActions.updateActivityFail({
                error: error.message,
              }),
              OgActivityActions.updateActivitySuccess({
                activity: {
                  ...activity,
                  isFocused: !activity.isFocused,
                },
              })
            )
          )
        );
      })
    );
  });

  setLifeActivityImportance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityImportance),
      switchMap(({ activity }) => {
        return this.lifeActivityService
          .setActivityProp(activity, 'importance')
          .pipe(
            map(() => {
              return OgActivityActions.loadActivity({
                activityId: activity.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  setLifeActivitySatisfaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivitySatisfaction),
      switchMap(({ activity }) => {
        return this.lifeActivityService
          .setActivityProp(activity, 'satisfaction')
          .pipe(
            map(() => {
              return OgActivityActions.loadActivity({
                activityId: activity.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeActivityIcon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityIcon),
      switchMap(({ activity }) => {
        return this.lifeActivityService
          .updateActivityProp(activity, 'icon')
          .pipe(
            map(() => {
              return OgActivityActions.updateActivitySuccess({
                activity,
              });
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeActivityDayTime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityDayTime),
      switchMap(({ activity }) => {
        return this.lifeActivityService
          .updateActivityProp(activity, 'dayTime')
          .pipe(
            map(() => {
              return OgActivityActions.updateActivitySuccess({
                activity,
              });
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeActivityReminders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityReminders),
      switchMap(({ activity }) => {
        return this.lifeActivityService.updateActivityReminders(activity).pipe(
          map(() => {
            return OgActivityActions.updateActivitySuccess({
              activity,
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.updateActivityFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  addActivityToLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.addActivityToLifeState),
      switchMap(({ activityId, lifeStateId }) => {
        return this.lifeActivityService
          .addActivityTo(activityId, 'state', lifeStateId)
          .pipe(
            map(() => {
              return OgActivityActions.noop();
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  removeActivityFromLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.removeActivityFromLifeState),
      switchMap(({ activityId, lifeStateId }) => {
        return this.lifeActivityService
          .removeActivityFrom(activityId, 'state', lifeStateId)
          .pipe(
            map(() => {
              return OgActivityActions.noop();
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  addActivityToLifeSpecific$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.addActivityToLifeSpecific),
      mergeMap(({ activityId, lifeSpecificId }) => {
        return this.lifeActivityService
          .addActivityTo(activityId, 'specific', lifeSpecificId)
          .pipe(
            map(() => {
              return OgActivityActions.noop();
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  removeActivityFromLifeSpecific$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.removeActivityFromLifeSpecific),
      mergeMap(({ activityId, lifeSpecificId }) => {
        return this.lifeActivityService
          .removeActivityFrom(activityId, 'specific', lifeSpecificId)
          .pipe(
            map(() => {
              return OgActivityActions.noop();
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  tryUpdateLifeActivityName = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityName),
      concatLatestFrom(({ activity }) =>
        this.store.select(selectCheckIfActivityNameExists$(activity.name))
      ),
      map(([{ activity }, nameExists]) => {
        if (!nameExists) {
          return OgActivityActions.actuallySetActivityName({ activity });
        } else {
          return OgActivityActions.updateActivityFail({
            error: this.translate.instant('LifeActivityUniqueName'),
          });
        }
      })
    );
  });

  updateLifeActivityName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.actuallySetActivityName),
      switchMap(({ activity }) => {
        return this.lifeActivityService.updateActivityName(activity).pipe(
          map(() => {
            return OgActivityActions.setActivityNameSuccess({
              activity: activity,
            });
          }),
          catchError(({ error }) =>
            of(
              OgActivityActions.setActivityNameFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  updateLifeActivityDescription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivityDescription),
      switchMap(({ activity }) => {
        return this.lifeActivityService
          .updateActivityDescription(activity)
          .pipe(
            map(() => {
              return OgLifeActions.noop();
            }),
            catchError(({ error }) =>
              of(OgActivityActions.updateActivityFail({ error: error.message }))
            )
          );
      })
    );
  });

  lifeActivityErrorHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgActivityActions.updateActivityFail,
          OgActivityActions.loadActivityFail,
          OgActivityActions.loadActivitiesFail,
          OgActivityActions.addActivityFail,
          OgActivityActions.setActivityNameFail,
          OgActivityActions.deleteActivityFail,
          OgActivityActions.setActivityIconFail
        ),
        tap(({ error }) => {
          this.toastr.error(error);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  updateLifeActivitySortOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.setActivitiesSortOrder),
      switchMap(({ activities }) => {
        const payload = activities.map((ls, index) => ({
          activityId: ls.id,
          sortOrder: index,
        }));
        return this.lifeActivityService.reorderActivities(payload).pipe(
          map(() => {
            return OgActivityActions.setActivitiesSortOrderSuccess({
              activities,
            });
          }),
          catchError(({ error }) =>
            of(OgLifeStateActions.updateLifeStateFail({ error: error.message }))
          )
        );
      })
    );
  });

  postLifeActivitProp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.updateActivity),
      switchMap(({ activity, prop, payload }) => {
        return this.lifeActivityService
          .postActivityProp(activity, prop, payload)
          .pipe(
            map(() => {
              return OgActivityActions.loadActivity({
                activityId: activity.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  activityMetricChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.updateActivityQuantity),
      switchMap(({ activity, number, unit, countUnit }) => {
        return this.lifeActivityService
          .postActivityQuantity(activity, number, unit, countUnit)
          .pipe(
            map(() => {
              return OgActivityActions.loadActivity({
                activityId: activity.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgActivityActions.updateActivityFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  selectActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgActivityActions.selectActivity),
      concatLatestFrom(() => this.store.select(selectLifeDateFilter$)),
      map(([{ activityId }, lifeFrom]) => {
        return OgLifeNoteActions.loadLifeActivityNotes({
          activityId,
          lifeFrom,
        });
      })
    );
  });

  lifeActivitySuccessHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgActivityActions.setActivitiesSortOrderSuccess,
          OgActivityActions.deleteActivitySuccess,
          OgActivityActions.updateActivitySuccess,
          OgActivityActions.addActivityToLifeState,
          OgActivityActions.addActivityToLifeSpecific,
          OgActivityActions.removeActivityFromLifeState,
          OgActivityActions.removeActivityFromLifeSpecific,
          OgActivityActions.setActivityImportance,
          OgActivityActions.setActivitySatisfaction
        ),
        distinctUntilChanged(),
        debounceTime(900),
        tap(() => {
          navigator.serviceWorker?.controller?.postMessage({
            value: 'updateLife',
            uid: this.appService.appUid,
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private lifeActivityService: LifeActivityService,
    private toastr: ToastrService,
    private appService: AppService,
    private translate: TranslateService,
    private store: Store
  ) {}
}
