import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  HostListener,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as d3 from 'd3';
import { LifeItem } from '@life/models/life';
import { ActivatedRoute } from '@angular/router';
import { delay, distinctUntilChanged, filter } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[hlChartPopover]',
  standalone: true,
})
export class ChartPopoverDirective {
  constructor(public tmp: TemplateRef<any>) {}
}

@Component({
  selector: 'hl-priority-chart',
  standalone: true,
  imports: [CommonModule, ChartPopoverDirective],
  templateUrl: './priority-chart.component.html',
})
export class PriorityChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('chart') chart: ElementRef;
  @Input() elements: { importance: number; satisfaction: number }[];
  @Input() chartHeight: number;
  @Input() chartWidth: number;
  @ContentChild(ChartPopoverDirective)
  toolTipTmp: ChartPopoverDirective;
  activeItem: LifeItem | null;
  activeGroup: any;
  ttPos = {
    'left.px': 0,
    'top.px': 0,
  };

  svg: any;

  height: number;
  width: number;
  innerWidth: number;
  innerHeight: number;

  constructor(public cd: ChangeDetectorRef, private route: ActivatedRoute) {}

  get hasActiveGroup() {
    return this.activeGroup?.node();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['elements'].firstChange) {
      this.plotChart();
      this.manageActiveGroup();
    }
  }

  ngAfterViewInit() {
    this.plotChart();
    this.manageActiveGroup();
    d3.select('body').on('click', e => {
      const clickOnNode =
        e.target.classList.contains('node-group') ||
        !!e.target.closest('.node-group');

      const clickOnEdit =
        e.target.classList.contains('edit-button') ||
        !!e.target.closest('.edit-button');

      if (!clickOnNode && !clickOnEdit) {
        this.clearActiveItem();
      }
    });

    this.route.queryParamMap
      .pipe(
        map(params => params.get('activeItemId')),
        distinctUntilChanged(),
        delay(501)
      )
      .subscribe(() => {
        this.plotChart();
        this.manageActiveGroup();
        this.cd.detectChanges();
      });
  }

  get x() {
    return d3
      .scaleLinear()
      .domain([0, 5])
      .range([0, this.innerWidth - 23]);
  }

  get y() {
    return d3.scaleLinear().domain([0, 5]).range([this.innerHeight, 0]);
  }

  get xAxis() {
    return d3.axisBottom(this.x).ticks(4);
  }

  get yAxis() {
    return d3.axisLeft(this.y).ticks(6);
  }

  get yAxisGrid() {
    return d3
      .axisLeft(this.y)
      .tickSize(-this.innerWidth + 23)
      .ticks(7);
  }

  get xAxisGrid() {
    return d3.axisBottom(this.x).tickSize(-this.innerHeight).ticks(5);
  }

  plotChart(
    width = this.chart.nativeElement.getBoundingClientRect().width,
    height = window.innerHeight - 80
  ) {
    this.height = height;
    this.width = width;
    this.innerWidth = this.width - 50;
    this.innerHeight = this.height - 20;

    const element = this.chart.nativeElement;

    // cleanup first
    d3.select(element).select('svg').remove();

    // append svg with correct dimensions
    this.svg = d3
      .select(element)
      .append('svg')
      .attr('width', `${this.width}`)
      .attr('height', `${this.height}`);

    // render Y axis with labels
    this.svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(50, -${(this.innerHeight / 6) * 0.66})`)
      .call(this.yAxis)
      .call((g: any) => g.select('.domain').remove())
      .call((g: any) => g.selectAll('.tick text').remove())
      .call((g: any) => g.selectAll('.tick line').remove())
      .call((g: any) =>
        g
          .selectAll('.tick')
          .append('foreignObject')
          .attr('width', 30)
          .attr('height', 30)
          .attr('x', -30)
          .attr('y', '0.5em')
          .html((i: number) => {
            let iconHtml = '';
            if (i === 0) {
              iconHtml =
                '<i class="text-2xl fa fa-face-disappointed text-icons-red"></i>';
            } else if (i === 1) {
              iconHtml =
                '<i class="text-2xl fa fa-face-frown-slight text-icons-lightred"></i>';
            } else if (i === 2) {
              iconHtml =
                '<i class="text-2xl fa fa-face-meh text-icons-yellow"></i>';
            } else if (i === 3) {
              iconHtml =
                '<i class="text-2xl fa fa-face-smile text-icons-lightgreen"></i>';
            } else if (i === 4) {
              iconHtml =
                '<i class="text-2xl fa fa-face-grin-wide text-icons-green"></i>';
            }
            return iconHtml;
          })
      );

    // render X axis with labels
    this.svg
      .append('g')
      .attr('class', 'x axis')
      .attr(
        'transform',
        `translate(${(this.innerWidth / 5) * 0.5 + 20}, ${this.innerHeight})`
      )
      .call(this.xAxis)
      .call((g: any) => g.select('.domain').remove())
      .call((g: any) => g.selectAll('.tick line').remove())
      .call((g: any) => g.selectAll('.tick text').remove())
      .call((g: any) =>
        g
          .selectAll('.tick')
          .append('foreignObject')
          .attr('width', 100)
          .attr('height', 30)
          .attr('x', -30)
          .attr('y', '0.5em')
          .html((i: number) => {
            let iconHtml = '';
            if (i === 0) {
              iconHtml =
                '<div class="w-28 flex justify-center items-center gap-x-1"><div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div></div>';
            } else if (i === 1) {
              iconHtml =
                '<div class="w-28 flex justify-center items-center gap-x-1">' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '</div>';
            } else if (i === 2) {
              iconHtml =
                '<div class="w-28 flex justify-center items-center gap-x-1">' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '</div>';
            } else if (i === 3) {
              iconHtml =
                '<div class="w-28 flex justify-center items-center gap-x-1">' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '</div>';
            } else if (i === 4) {
              iconHtml =
                '<div class="w-28 flex justify-center items-center gap-x-1">' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '<div class="rounded-full bg-gray-600 h-3 w-3 cursor-pointer"></div>' +
                '</div>';
            }
            return iconHtml;
          })
      );

    // render X/Y axis girds
    this.svg
      .append('g')
      .attr('class', 'y axis-grid')
      .attr('transform', 'translate(50, 0)')
      .call(this.yAxisGrid)
      .call((g: any) => g.select('.domain').remove())
      .call((g: any) => g.selectAll('.tick text').remove())
      .call((g: any) => g.selectAll('.tick line').attr('stroke', '#E0E0E0'));

    this.svg
      .append('g')
      .attr('class', 'x axis-grid')
      .attr('transform', 'translate(50,' + this.innerHeight + ')')
      .call(this.xAxisGrid)
      .call((g: any) => g.select('.domain').remove())
      .call((g: any) => g.selectAll('.tick text').remove())
      .call((g: any) => g.selectAll('.tick line').attr('stroke', '#E0E0E0'));

    const elements = this.svg
      .append('g')
      .attr('class', 'nodes')
      .selectAll('bots')
      .data(this.elements);

    const nodes = elements
      .enter()
      .append('g')
      .attr('class', (d: any) => {
        return this.route.snapshot.queryParamMap.get('activeItemId') === d.id
          ? 'node-group active-group'
          : 'node-group';
      })
      .attr('id', (d: LifeItem) => `node-${d.id}`)
      .attr('transform', (d: LifeItem) => {
        let importance = d.importance + Number(`0.${d.sortOrderFactor}`);
        let satisfaction = d.satisfaction + Number(`0.${d.sortOrderFactor}`);

        if (importance > 5.7) {
          importance = 5.65;
          importance =
            d.type === 'area' ? 5.65 : d.type === 'state' ? 5.75 : 5.5;
        } else if (importance <= 1.35 && d.type === 'area') {
          importance = 1.35;
        } else if (importance <= 1) {
          importance =
            d.type === 'area' ? 1.2 : d.type === 'state' ? 1.15 : 1.08;
        }

        if (satisfaction > 5.5) {
          satisfaction = 5.5;
        } else if (satisfaction < 1.75 && d.type === 'area') {
          satisfaction = 1.75;
        } else if (satisfaction < 1.2) {
          satisfaction = 1.2;
        }
        return `translate(${this.x(importance - 1)},${this.y(
          satisfaction - 1
        )})`;
      })
      .on('mouseover touchstart', (event: Event, item: LifeItem) => {
        // @ts-ignore
        const node = d3.select(`#node-${item.id}`);
        if (
          !this.checkNodeInside(node) &&
          this.checkNodeOverlap(node) &&
          !this.checkNodeIncluded(node)
        ) {
          node.raise();
        }
        node.style('filter', 'none').select('.outer-circle').attr('opacity', 1);
        node.style('cursor', 'pointer');
      })
      .on('mouseleave', (event: Event, item: LifeItem) => {
        const node = d3.select(`#node-${item.id}`);
        if (
          !this.checkNodeInside(node) &&
          this.checkNodeOverlap(node) &&
          !this.checkNodeIncluded(node)
        ) {
          node.lower();
        }
        if (
          this.activeItem?.id !== item.id &&
          this.route.snapshot.queryParamMap.get('activeItemId') !== item.id
        ) {
          node.style('filter', null).select('.outer-circle').attr('opacity', 0);
        }
        d3.selectAll('.axis').lower();
        d3.selectAll('.axis-grid').lower();
        d3.selectAll('.nodes').order();
      })
      .on('click', (event: Event, item: LifeItem) => {
        // @ts-ignore
        const node = d3.select(`#node-${item.id}`);
        node.select('.outer-circle').attr('opacity', 1);
        this.showPopover(event, item);
      });

    const outerCircle = nodes
      .append('circle')
      .attr('class', 'outer-circle')
      .attr('opacity', 0)
      .attr('transform', 'translate(50, 0)')
      .attr('r', (d: LifeItem) => 0)
      .style('fill', (d: LifeItem) => d.mediumColor)
      .transition()
      .duration(0)
      .attr('r', (d: LifeItem) => d.radius);

    const innerCircle = nodes
      .append('circle')
      .attr('transform', 'translate(50, 0)')
      .attr('r', (d: LifeItem) => 0)
      .style('fill', (d: LifeItem) => d.lightColor)
      .transition()
      .duration(0)
      .attr('r', (d: LifeItem) => d.radius - 4);

    nodes
      .filter((d: LifeItem) => d.radius !== 20)
      .append('text')
      .attr('transform', 'translate(50, 0)')
      .text((d: LifeItem) => d.name.substring(0, d.radius / 3))
      .attr('text-anchor', 'middle')
      .attr('fill', (d: LifeItem) => d.darkColor)
      .attr('font-size', (d: LifeItem) => {
        const len = d.name.substring(0, d.radius / 3).length + 2;
        let size = d.radius / 3;
        size *= 10 / len;
        size += 1;
        if (size > 26) {
          size = 26;
        }
        return Math.floor((size / 16) * 100) / 100 + 'em';
      })
      .attr('dy', '0.3em');
  }

  showPopover(event: any, item: LifeItem) {
    this.clearActiveItem();
    this.activeItem = item;
    this.ttPos['left.px'] = event.pageX - (item.importance > 2 ? 300 : 0);
    this.ttPos['top.px'] = event.pageY - 55;

    this.cd.detectChanges();
  }

  clearActiveItem() {
    const node = d3.select(`#node-${this.activeItem?.id}`);
    if (node) {
      node.select('.outer-circle').attr('opacity', 0);
    }

    this.activeItem = null;
    this.cd.detectChanges();
  }

  checkNodeInside(node: any) {
    const bounds = node.node().getBoundingClientRect();
    const allNodes = d3.selectAll('.node-group');

    return (
      allNodes.nodes().filter((n: any) => {
        const nId = n.getAttribute('id');
        // @ts-ignore
        const { top, left, bottom, right } = n.getBoundingClientRect();

        return (
          node.attr('id') !== nId &&
          left >= bounds.left &&
          top >= bounds.top &&
          bottom <= bounds.bottom &&
          right <= bounds.right
        );
      }).length > 0
    );
  }

  checkNodeIncluded(node: any) {
    const bounds = node.node().getBoundingClientRect();
    const allNodes = d3.selectAll('.node-group');

    return (
      allNodes.nodes().filter((n: any) => {
        const nId = n.getAttribute('id');
        // @ts-ignore
        const { top, left, bottom, right } = n.getBoundingClientRect();

        return (
          node.attr('id') !== nId &&
          bounds.left >= left &&
          bounds.top >= top &&
          bounds.bottom <= bottom &&
          bounds.right <= right
        );
      }).length > 0
    );
  }

  checkNodeOverlap(node: any) {
    const bounds = node.node().getBoundingClientRect();
    const allNodes = d3.selectAll('.node-group');

    return (
      allNodes.nodes().filter((n: any) => {
        const nId = n.getAttribute('id');
        // @ts-ignore
        const { top, left, bottom, right } = n.getBoundingClientRect();

        return (
          node.attr('id') !== nId &&
          !(
            bounds.top > bottom ||
            bounds.right < left ||
            bounds.bottom < top ||
            bounds.left > right
          )
        );
      }).length > 0
    );
  }

  manageActiveGroup() {
    this.activeGroup = d3.select('.active-group');

    if (this.activeGroup?.node()) {
      if (
        !this.checkNodeInside(this.activeGroup) &&
        this.checkNodeOverlap(this.activeGroup) &&
        !this.checkNodeIncluded(this.activeGroup)
      ) {
        this.activeGroup.raise();
      }
      this.activeGroup.select('.outer-circle').attr('opacity', 1);
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.clearActiveItem();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.plotChart();
    this.manageActiveGroup();
  }
}
