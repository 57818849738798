<div class="mt-4 select-none life-area-table">
  <div
    class="flex flex-col px-4 gap-y-1"
    id="life"
    *ngIf="lifeAreas() as lifeAreas">
    <hl-life-area
      class="block"
      *ngFor="let lifeArea of lifeAreas; trackBy: trackByLifeAreaIndex"
      [ngClass]="{ 'disabled-area': lifeArea.disabled }"
      [lifeArea]="lifeArea"></hl-life-area>
  </div>
</div>
