import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LifeStateService } from '@life/services/life-state.service';
import { AppService } from '@core/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import {
  selectCheckIfLifeStateNameExists$,
  selectLifeActivities$,
  selectLifeDateFilter$,
  selectLifeEntities$,
  selectLifeFilters$,
  selectLifeStatesEntities$,
} from '@life/selectors';
import { Store } from '@ngrx/store';
import {
  OgLifeActions,
  OgLifeNoteActions,
  OgLifeStateActions,
} from '@life/actions';
import { LifeService } from '@life/services';
import { ILifeArea } from '@life/models/life-area';
import { ILifeState } from '@life/models/life-state';

@Injectable()
export class LifeStateEffects {
  loadLifeAreasHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.loadLifeAreasSuccess),
      switchMap(({ lifeAreas, userId }) => {
        const stateIds = lifeAreas.map(la => la.stateIds).flat();
        return stateIds.map(id =>
          OgLifeStateActions.loadLifeState({ id, userId })
        );
      })
    );
  });

  loadLifeAreaHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.loadLifeAreaSuccess),
      switchMap(({ lifeArea }) => {
        if (lifeArea.stateIds?.length > 0) {
          return lifeArea.stateIds.map(id =>
            OgLifeStateActions.loadLifeState({ id })
          );
        } else {
          return of(OgLifeStateActions.noop());
        }
      })
    );
  });

  updateLifeStateImportance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeStateImportance),
      switchMap(({ lifeState }) => {
        return this.lifeStateService
          .updateLifeStateImportance(lifeState.id, lifeState.importance)
          .pipe(
            map(() => {
              return OgLifeStateActions.loadLifeState({
                id: lifeState.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgLifeStateActions.updateLifeStateFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  tryUpdateLifeStateName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeStateName),
      concatLatestFrom(({ lifeState }) =>
        this.store.select(selectCheckIfLifeStateNameExists$(lifeState))
      ),
      map(([{ lifeState }, nameExists]) => {
        if (!nameExists) {
          return OgLifeStateActions.actuallySetLifeStateName({ lifeState });
        } else {
          return OgLifeStateActions.updateLifeStateFail({
            error: this.translate.instant('LifeStateUniqueName'),
          });
        }
      })
    );
  });

  updateLifeStateName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.actuallySetLifeStateName),
      switchMap(({ lifeState }) => {
        return this.lifeStateService.updateLifeStateName(lifeState).pipe(
          map(() => {
            return OgLifeStateActions.updateLifeStateSuccess({
              lifeState,
            });
          }),
          catchError(({ error }) =>
            of(
              OgLifeStateActions.updateLifeStateFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  updateLifeStateDescription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeStateDescription),
      switchMap(({ lifeState }) => {
        return this.lifeStateService.updateLifeStateDescription(lifeState).pipe(
          map(() => {
            return OgLifeStateActions.noop();
          }),
          catchError(({ error }) =>
            of(
              OgLifeStateActions.updateLifeStateFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  updateLifeStateSatisfaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeStateSatisfaction),
      switchMap(({ lifeState }) => {
        return this.lifeStateService
          .updateLifeStateSatisfaction(lifeState.id, lifeState.satisfaction)
          .pipe(
            map(() => {
              return OgLifeStateActions.loadLifeState({
                id: lifeState.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgLifeStateActions.updateLifeStateFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeStateFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeStateFocus),
      switchMap(({ lifeState }) => {
        return this.lifeStateService.updateLifeStateFocus(lifeState.id).pipe(
          map(() => {
            return OgLifeStateActions.updateLifeStateSuccess({
              lifeState,
            });
          }),
          catchError(({ error }) =>
            of(
              OgLifeStateActions.updateLifeStateFail({
                error: error.message,
              }),
              OgLifeStateActions.updateLifeStateSuccess({
                lifeState: {
                  ...lifeState,
                  isFocused: !lifeState.isFocused,
                },
              })
            )
          )
        );
      })
    );
  });

  updateLifeStateSpecificsHidden$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeSpecificsHidden),
      switchMap(({ lifeState }) => {
        return this.lifeStateService
          .updateLifeStateSpecificsHidden(lifeState.id)
          .pipe(
            map(() => OgLifeStateActions.updateLifeStateSuccess({ lifeState })),
            catchError(({ error }) =>
              of(
                OgLifeStateActions.updateLifeStateFail({
                  error: error.message,
                }),
                OgLifeStateActions.updateLifeStateSuccess({
                  lifeState: {
                    ...lifeState,
                    specificsHidden: !lifeState.specificsHidden,
                  },
                })
              )
            )
          );
      })
    );
  });

  addLifeStateFromTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.addLifeStateFromTemplate),
      concatLatestFrom(({ lifeState, lifeAreaId }) =>
        this.store.select(
          selectCheckIfLifeStateNameExists$(lifeState, lifeAreaId)
        )
      ),
      exhaustMap(([{ lifeStateId, lifeAreaId, lifeState }, nameExists]) => {
        if (!nameExists) {
          return this.lifeStateService
            .addNewLifeStateFromTemplate(lifeStateId, lifeAreaId)
            .pipe(
              map(() => {
                this.toastr.success(
                  this.translate.instant('ToastrAddLifeStateSuccess')
                );
                return OgLifeActions.loadLifeArea({ id: lifeAreaId });
              }),
              catchError(({ error }) =>
                of(
                  OgLifeStateActions.addLifeStateFail({
                    error: error.message,
                  })
                )
              )
            );
        } else {
          return of(
            OgLifeStateActions.updateLifeStateFail({
              error: this.translate.instant('LifeStateUniqueName'),
            })
          );
        }
      })
    );
  });

  addCustomLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.addLifeState),
      concatLatestFrom(({ lifeAreaId, lifeState }) =>
        this.store.select(
          selectCheckIfLifeStateNameExists$(lifeState, lifeAreaId)
        )
      ),
      exhaustMap(([{ lifeState, lifeAreaId }, nameExists]) => {
        if (!nameExists) {
          return this.lifeStateService
            .addCustomLifeState(lifeAreaId, lifeState.name)
            .pipe(
              map(() => {
                this.toastr.success(
                  this.translate.instant('ToastrAddLifeStateSuccess')
                );
                return OgLifeActions.loadLifeArea({ id: lifeAreaId });
              }),
              catchError(({ error }) =>
                of(
                  OgLifeStateActions.addLifeStateFail({
                    error: error.message,
                  })
                )
              )
            );
        } else {
          return of(
            OgLifeStateActions.updateLifeStateFail({
              error: this.translate.instant('LifeStateUniqueName'),
            })
          );
        }
      })
    );
  });

  deleteLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.deleteLifeState),
      exhaustMap(({ lifeState }) =>
        this.lifeStateService.deleteLifeState(lifeState.id).pipe(
          map(() => {
            this.toastr.success(
              this.translate.instant('ToastrDeleteLifeStateSuccess')
            );
            return OgLifeStateActions.deleteLifeStateSuccess({ lifeState });
          }),
          catchError(({ error }) =>
            of(OgLifeStateActions.deleteLifeStateFail({ error: error.message }))
          )
        )
      )
    );
  });

  loadLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.loadLifeState),
      concatLatestFrom(() =>
        this.store
          .select(selectLifeFilters$)
          .pipe(map(filters => filters.selectedDate))
      ),
      mergeMap(([{ id, userId }, selectedDate]) =>
        this.lifeStateService.getLifeState(id, selectedDate, userId).pipe(
          map(lifeState => {
            return OgLifeStateActions.loadLifeStateSuccess({
              lifeState: {
                ...lifeState,
                name: this.translate.instant(lifeState.name),
              },
              userId,
            });
          }),
          catchError(({ error }) =>
            of(OgLifeStateActions.loadLifeStateFail({ error: error.message }))
          )
        )
      )
    );
  });

  updateLifeStatesSortOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.setLifeStatesSortOrder),
      switchMap(({ lifeStates }) => {
        const payload = lifeStates.map((ls, index) => ({
          levelId: ls.id,
          sortOrder: index,
        }));
        return this.lifeService.setOrder(payload).pipe(
          map(() => {
            return OgLifeStateActions.setLifeStatesSortOrderSuccess({
              lifeStates,
            });
          }),
          catchError(({ error }) =>
            of(OgLifeStateActions.updateLifeStateFail({ error: error.message }))
          )
        );
      })
    );
  });

  lifeStateErrorHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgLifeStateActions.deleteLifeStateFail,
          OgLifeStateActions.updateLifeStateFail,
          OgLifeStateActions.addLifeStateFail,
          OgLifeStateActions.loadLifeStateFail
        ),
        tap(({ error }) => {
          this.toastr.error(error);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  lifeStateSuccessHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgLifeStateActions.addLifeState,
          OgLifeStateActions.addLifeStateFromTemplate,
          OgLifeStateActions.moveLifeSpecificFromLifeStateSuccess,
          OgLifeStateActions.updateLifeStateSuccess,
          OgLifeStateActions.deleteLifeStateSuccess,
          OgLifeStateActions.setLifeStatesSortOrderSuccess,
          OgLifeStateActions.setLifeStateImportance,
          OgLifeStateActions.setLifeStateSatisfaction,
          OgLifeStateActions.setLifeStateSatisfaction
        ),
        distinctUntilChanged(),
        debounceTime(900),
        tap(() => {
          navigator.serviceWorker?.controller?.postMessage({
            value: 'updateLife',
            uid: this.appService.appUid,
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  // activityAdd$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(OgActivityActions.addActivityToLifeState),
  //     concatLatestFrom(() => this.store.select(selectLifeStatesEntities$)),
  //     map(([{ activityId, lifeStateId }, stateEntities]) => {
  //       const lifeState = stateEntities[lifeStateId];
  //       if (lifeState) {
  //         const activityIds = lifeState.activityIds;
  //
  //         return OgLifeStateActions.updateLifeStateSuccess({
  //           lifeState: {
  //             ...(lifeState as ILifeState),
  //             activityIds: [...activityIds, activityId],
  //           },
  //         });
  //       } else {
  //         return OgLifeStateActions.noop();
  //       }
  //     })
  //   );
  // });
  //
  // activityRemove$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(OgActivityActions.removeActivityFromLifeState),
  //     concatLatestFrom(() => this.store.select(selectLifeStatesEntities$)),
  //     map(([{ activityId, lifeStateId }, stateEntities]) => {
  //       const lifeState = stateEntities[lifeStateId];
  //       if (lifeState) {
  //         const activityIds = lifeState.activityIds.filter(
  //           id => id !== activityId
  //         );
  //
  //         return OgLifeStateActions.updateLifeStateSuccess({
  //           lifeState: {
  //             ...(lifeState as ILifeState),
  //             activityIds: activityIds,
  //           },
  //         });
  //       } else {
  //         return OgLifeStateActions.noop();
  //       }
  //     })
  //   );
  // });

  selectLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.selectLifeState),
      concatLatestFrom(() => this.store.select(selectLifeDateFilter$)),
      map(([{ id }, lifeFrom]) => {
        return OgLifeNoteActions.loadLifeStateNotes({
          lifeStateId: id,
          lifeFrom,
        });
      })
    );
  });

  moveLifeStateFromLifeArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.moveLifeStateFromLifeArea),
      switchMap(({ lifeStateId, targetLifeAreaId, updatedLifeStates }) => {
        return this.lifeStateService
          .moveStateToLifeArea(lifeStateId, targetLifeAreaId)
          .pipe(
            map(() => {
              return OgLifeStateActions.setLifeStatesSortOrder({
                lifeStates: updatedLifeStates,
              });
            }),
            catchError(({ error }) =>
              of(
                OgLifeStateActions.updateLifeStateFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  moveLifeStateFromLifeAreaHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.moveLifeStateFromLifeArea),
      concatLatestFrom(() => this.store.select(selectLifeEntities$)),
      switchMap(
        ([
          { lifeStateId, currentLifeAreaId, targetLifeAreaId },
          lifeAreaEntities,
        ]) => {
          const targetLifeArea = lifeAreaEntities[
            targetLifeAreaId
          ] as ILifeArea;
          const currentLifeArea = lifeAreaEntities[
            currentLifeAreaId
          ] as ILifeArea;

          if (targetLifeArea && currentLifeArea) {
            return of(
              OgLifeActions.updateLifeAreaSuccess({
                lifeArea: {
                  ...targetLifeArea,
                  statesHidden: false,
                  stateIds: [...targetLifeArea.stateIds, lifeStateId],
                },
              }),
              OgLifeActions.updateLifeAreaSuccess({
                lifeArea: {
                  ...currentLifeArea,
                  stateIds: currentLifeArea.stateIds.filter(
                    id => id !== lifeStateId
                  ),
                },
              })
            );
          } else {
            return of(OgLifeActions.noop());
          }
        }
      )
    );
  });

  deleteLifeAreaHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.deleteLifeAreaSuccess),
      concatLatestFrom(() => this.store.select(selectLifeStatesEntities$)),
      exhaustMap(([{ lifeArea }, lifeStateEntities]) => {
        const lifeStates = lifeArea.stateIds.map(id => lifeStateEntities[id]);

        return lifeStates
          .filter(ls => !!ls)
          .map(lifeState =>
            OgLifeStateActions.deleteLifeStateSuccess({
              lifeState: lifeState as ILifeState,
            })
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lifeService: LifeService,
    private lifeStateService: LifeStateService,
    private toastr: ToastrService,
    private appService: AppService,
    private translate: TranslateService,
    private store: Store
  ) {}
}
