<div class="relative" [ngClass]="containerClass">
  <input
    tabindex="0"
    cdkOverlayOrigin
    #autocompleteInput="cdkOverlayOrigin"
    #input
    [placeholder]="placeholder"
    [ngClass]="inputClass"
    [class.border-holismo-red]="usedOptionError"
    class="appearance-none text-sm rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    (keyup)="onKeyDown($event)"
    hlStopPropagation
    (focus)="showAutocomplete = true"
    (blur)="timeoutAutocomplete()"
    [(ngModel)]="query" />
  <small *ngIf="usedOptionError" class="block text-right text-holismo-red">
    {{ 'OptionUsed' | translate }}
  </small>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="autocompleteInput"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayViewportMargin]="10"
  [cdkConnectedOverlayOpen]="showAutocomplete">
  <ng-container *ngIf="!groupOptions?.length">
    <section
      class="w-80 top-full z-20 flex flex-col shadow bg-white text-md text-holismo-grey rounded-b-md">
      <hl-autocomplete-option
        (click)="select(option, true)"
        class="cursor-pointer hover:bg-holismo-grey/20"
        *ngFor="let option of options | filterOption : query; index as index"
        [option]="option">
        <div class="flex items-center gap-2 text-sm p-3">
          <i
            *ngIf="option.item?.icon"
            [ngClass]="option.item.icon"
            [ngStyle]="{ color: option.item.darkColor }"></i>
          <span
            [ngStyle]="
              option.item.darkColor
                ? { color: option.item.darkColor }
                : { color: 'inherit' }
            "
            >{{ option.label }}</span
          >
        </div>
      </hl-autocomplete-option>
    </section>
  </ng-container>
  <ng-container *ngIf="groupOptions">
    <section
      *ngIf="groupOptions.length > 0"
      class="w-80 top-full z-20 flex flex-col shadow bg-white text-md text-holismo-grey rounded-b-md max-h-[300px] overflow-auto">
      <div
        *ngFor="let grp of groupOptions | filterText : query; index as index">
        <h4
          [ngStyle]="{ backgroundColor: grp.lightColor }"
          class="w-full py-1 px-3 sticky top-0 z-50 bg-gray-100 flex items-center gap-2">
          <i
            *ngIf="grp.icon"
            [ngClass]="grp.icon"
            [ngStyle]="{ color: grp.darkColor }"></i>
          <span
            [ngStyle]="
              grp.darkColor ? { color: grp.darkColor } : { color: 'inherit' }
            "
            >{{ grp.label }}</span
          >
        </h4>

        <div class="flex flex-col p-3">
          <hl-autocomplete-option
            (click)="select(option, true)"
            class="cursor-pointer hover:bg-holismo-grey/20"
            *ngFor="let option of grp.items; index as index"
            [option]="option">
            <div class="flex items-center gap-2 text-sm p-2">
              <i
                *ngIf="option.item.icon"
                [ngClass]="option.item.icon"
                [ngStyle]="{ color: grp.darkColor }"></i>
              <span
                [ngStyle]="
                  grp.lightColor
                    ? { color: grp.darkColor }
                    : { color: 'inherit' }
                "
                >{{ option.label }}</span
              >
            </div>
          </hl-autocomplete-option>
        </div>
      </div>
    </section>
  </ng-container>
</ng-template>
