import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Signal,
  SimpleChanges,
  TrackByFunction,
} from '@angular/core';
import { ILifeArea } from '@life/models/life-area';
import { ILifeState } from '@life/models/life-state';

import { Store } from '@ngrx/store';
import { ConfirmationDialogComponent } from '@components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
  selectAvailableLifeStates$,
  selectLifeTemplatesId$,
  selectLifeDragElement$,
} from '@life/selectors';
import { TranslateService } from '@ngx-translate/core';
import { selectLifeColors } from '@life/selectors';

import { IColor } from '@life/models/colors';
import { OgLifeActions, OgLifeStateActions } from '@life/actions';
import { PricingTableDialogComponent } from '@components/dialogs/pricing-table-dialog/pricing-table-dialog.component';
import { AppService } from '@core/services/app.service';
import { selectLifeAreasCount$ } from '@core/selectors/user.selectors';

@Component({
  selector: 'hl-life-area',
  templateUrl: './life-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeAreaComponent implements OnChanges, OnInit {
  @Input() lifeArea: ILifeArea;

  @Output() updateLifeStateRating = new EventEmitter<{
    lifeState: ILifeState;
    rating: number;
  }>();

  @Output() updateLifeStateFocused = new EventEmitter<{
    lifeState: ILifeState;
    isFocused: boolean;
  }>();

  expandStates = false;

  availableLifeStates: Signal<{
    used: string[];
    available: { label: string; item: ILifeState }[];
  }>;

  lifeAreaIds: Signal<any>;

  allColors: Signal<IColor[]>;

  dragElement = this.store.selectSignal(selectLifeDragElement$);
  lifeAreasCount = this.store.selectSignal(selectLifeAreasCount$);
  isDragOver = computed(() => {
    return this.dragElement() === 'STATE' || this.dragElement() === 'SPECIFIC';
  });

  showAddNew = false;
  canRename = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private dialog: Dialog,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private appService: AppService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lifeArea'].currentValue) {
      this.availableLifeStates = this.store.selectSignal(
        selectAvailableLifeStates$(this.lifeArea.name)
      );
    }
  }

  ngOnInit() {
    this.allColors = this.store.selectSignal(selectLifeColors);

    this.lifeAreaIds = this.store.selectSignal(selectLifeTemplatesId$);
  }

  toggleLifeStatesVisibility(statesHidden: boolean) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaStatesHidden({
        lifeArea: { ...this.lifeArea, statesHidden },
      })
    );
  }

  setLifeAreaImportance(importance: number) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaImportance({
        lifeArea: {
          ...this.lifeArea,
          importance,
        },
      })
    );
  }

  setLifeAreaSatisfaction(satisfaction: number) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaSatisfaction({
        lifeArea: {
          ...this.lifeArea,
          satisfaction: satisfaction,
        },
      })
    );
  }

  deleteLifeArea() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('ConfirmationDialogDeleteLifeAreaTitle'),
        message: this.translate.instant(
          'ConfirmationDialogDeleteLifeAreaMessage',
          {
            name: this.lifeArea.name,
          }
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(
          OgLifeActions.deleteLifeArea({ lifeArea: this.lifeArea })
        );
      }
    });
  }

  selectLifeArea(lifeArea: ILifeArea) {
    if (lifeArea.disabled) {
      this.dialog.open(PricingTableDialogComponent, {
        maxWidth: '430px',
        panelClass: 'z-50',
        data: {
          message: this.translate.instant('DialogsUpgradePlanMessage', {
            value: this.lifeAreasCount(),
          }),
        },
      });
    } else if (lifeArea.isHighlighted) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: null,
          activeItemType: null,
          activeTabIndex: null,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: lifeArea.id,
          activeItemType: 'area',
          activeTabIndex: 0,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  addNewLifeState({ item }: { item: ILifeState }) {
    this.showAddNew = false;
    if (item.id) {
      this.store.dispatch(
        OgLifeStateActions.addLifeStateFromTemplate({
          lifeStateId: item.id,
          lifeState: item,
          lifeAreaId: this.lifeArea.id,
        })
      );
    } else {
      this.store.dispatch(
        OgLifeStateActions.addLifeState({
          lifeAreaId: this.lifeArea.id,
          lifeState: item,
        })
      );
    }

    if (this.lifeArea.statesHidden) {
      this.store.dispatch(
        OgLifeActions.setLifeAreaStatesHidden({ lifeArea: this.lifeArea })
      );
    }
  }

  toggleAddNew(expandItems?: boolean) {
    if (expandItems && this.lifeArea.statesHidden) {
      this.toggleLifeStatesVisibility(false);
    }
    this.showAddNew = !this.showAddNew;
  }

  trackByLifeStateIndex: TrackByFunction<ILifeState> = (index, lifeState) =>
    lifeState.id + lifeState.sortOrder;

  rename(value: string) {
    this.canRename = false;
    this.store.dispatch(
      OgLifeActions.setLifeAreaName({
        lifeArea: {
          ...this.lifeArea,
          name: value.trim(),
        },
      })
    );
  }

  setLifeAreaColor(colorObject: IColor) {
    const lifeAreaColorScheme = {
      darkColor: colorObject.darkColor,
      mediumColor: colorObject.mediumColor,
      lightColor: colorObject.lightColor,
    };
    this.store.dispatch(
      OgLifeActions.setLifeAreaColor({
        lifeArea: { ...this.lifeArea, ...lifeAreaColorScheme },
      })
    );
  }

  setLifeAreaIcon(icon: string) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaIcon({
        lifeArea: {
          ...this.lifeArea,
          icon: icon,
        },
      })
    );
  }

  manageStateExpand(doExpand: boolean) {
    this.expandStates = doExpand;
    this.cd.detectChanges();
  }

  touchmove() {
    // do nothing
  }
}
