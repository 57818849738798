import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: '',
  standalone: true,
})
export class FiltersBase {
  @Input() activeFilters: number[];
  @Output() filterApplied = new EventEmitter<number[]>();

  isFilterActive(filter: number) {
    return this.activeFilters?.includes(filter);
  }

  applyFilter(action: number) {
    let payload = [...this.activeFilters];
    if (payload.includes(action)) {
      payload = payload.filter(x => x !== action);
    } else {
      payload.push(action);
    }

    this.filterApplied.emit(payload);
  }
}
