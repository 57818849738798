import { Component, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { CommonModule } from '@angular/common';
import { AppService } from '@core/services/app.service';
import { ISocialMetadata } from '@app/models';
import { TranslateHelperService } from '@core/services/translate-helper.service';
import { Store } from '@ngrx/store';
import { OgLifeActions } from '@life/actions';
import { PromptDialogComponent } from '@components/dialogs/prompt-dialog/prompt-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  selectUserHasRole$,
  selectUserMetadata$,
} from '@core/selectors/user.selectors';

@Component({
  selector: 'hl-account-settings',
  standalone: true,
  templateUrl: './account-settings.component.html',
  imports: [
    AsyncPipe,
    LetDirective,
    TranslateModule,
    CommonModule,
    NgOptimizedImage,
  ],
})
export class AccountSettingsComponent {
  @Input() mobileView = true;
  rawTranslations = false;
  userMetadata = this.store.selectSignal(selectUserMetadata$);
  userIsAdmin = this.store.selectSignal(selectUserHasRole$('Admin'));
  constructor(
    public auth: AuthService,
    public appService: AppService,
    private translateHelperService: TranslateHelperService,
    private translate: TranslateService,
    private store: Store,
    private dialog: Dialog
  ) {}

  get userSignInProvider() {
    const providerMap: any = {
      'google-oauth2': {
        label: 'Google Account',
        image: 'google',
      },
      auth0: {
        label: 'E-Mail',
        image: 'email',
      },
      windowslive: {
        label: 'Microsoft Account',
        image: 'microsoft',
      },
      facebook: {
        label: 'Facebook Account',
        image: 'facebook',
      },
      linkedin: {
        label: 'LinkedIn Account',
        image: 'linkedin',
      },
    };

    const metadata = this.userMetadata() as ISocialMetadata;

    return providerMap[metadata.provider] || {};
  }

  deleteLife() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('PromptDialogDeleteLifeTitle'),
        message: this.translate.instant('PromptDialogDeleteLifeMessage'),
        promptData: 'DELETE',
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(OgLifeActions.deleteLife());
      }
    });
  }

  resetLife() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('PromptDialogResetLifeTitle'),
        message: this.translate.instant('PromptDialogResetLifeMessage'),
        promptData: 'RESET',
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        if (res) {
          this.store.dispatch(OgLifeActions.resetLife());
        }
      }
    });
  }

  deleteAccount() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('PromptDialogDeleteAccountTitle'),
        message: this.translate.instant('PromptDialogDeleteAccountMessage'),
        promptData: 'DELETE',
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.appService.deleteAccount().subscribe(_ => {
          this.auth.logout({
            logoutParams: { returnTo: document.location.origin },
          });
        });
      }
    });
  }

  toggleRawTranslations(value: boolean) {
    this.rawTranslations = !value;

    this.translateHelperService.useRawTranslations = this.rawTranslations;
    this.translate.use(this.rawTranslations ? 'de' : 'en');
  }
}
