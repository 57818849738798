import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { LetDirective } from '@ngrx/component';

import { LifeRoutingModule } from '@life/life-routing.module';
import { RatingComponent } from '@core/shared/rating/rating.component';
import { RatingSelectComponent } from '@core/shared/rating-select/rating-select.component';

import { IsFavoriteComponent } from '@core/shared/is-favorite/is-favorite.component';
import { LifeActionTooltipComponent } from '@life/components/life-action-tooltip/life-action-tooltip.component';

import {
  LifeSpecificComponent,
  LifeAreaComponent,
  LifeStateComponent,
} from '@life/containers';

import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import {
  AutocompleteComponent,
  InlineEditComponent,
  InputSearchComponent,
} from '@components/inputs';
import {
  ColorSelectComponent,
  IconSelectComponent,
} from '@components/selection';

import {
  LifeWheelComponent,
  LifePriorityComponent,
  LifeOverviewComponent,
  LifeComponent,
} from '@life/views';

import { LifeNoteEffects } from '@life/effects/life-note.effects';

import {
  ChartPopoverDirective,
  PriorityChartComponent,
  SunburstChartComponent,
} from '@components/charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivityMapperComponent } from '@core/shared/activity-mapper/activity-mapper.component';
import { DragulaModule } from 'ng2-dragula';

const COMPONENTS = [LifeActionTooltipComponent];

const CONTAINERS = [
  LifeAreaComponent,
  LifeStateComponent,
  LifeComponent,
  LifeSpecificComponent,
];
const VIEWS = [
  LifeOverviewComponent,
  LifeWheelComponent,
  LifePriorityComponent,
];
@NgModule({
  declarations: [...COMPONENTS, ...VIEWS, ...CONTAINERS],
  imports: [
    CommonModule,
    DialogModule,
    CdkMenuModule,
    ReactiveFormsModule,
    LifeRoutingModule,
    TranslateModule,
    StopPropagationDirective,
    AutocompleteComponent,
    InlineEditComponent,
    ColorSelectComponent,
    IconSelectComponent,
    LetDirective,
    InputSearchComponent,
    MatDatepickerModule,
    PriorityChartComponent,
    ChartPopoverDirective,
    SunburstChartComponent,
    RatingComponent,
    RatingSelectComponent,
    IsFavoriteComponent,
    EffectsModule.forFeature([LifeNoteEffects]),
    MatTooltipModule,
    ActivityMapperComponent,
    DragulaModule,
  ],
  exports: [IsFavoriteComponent, LifeActionTooltipComponent, LifeAreaComponent],
})
export class LifeModule {}
