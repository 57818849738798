import { NgModule } from '@angular/core';
import {
  provideRouter,
  RouterModule,
  Routes,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { LayoutComponent } from '@app/core/containers/layout/layout.component';
import { NotFoundComponent } from '@app/core/containers';
import { AuthGuard } from '@auth0/auth0-angular';
import { LogoutComponent } from '@core/containers/logout/logout.component';
import { ErrorComponent } from '@core/containers/error/error.component';
import { VerifyComponent } from '@core/containers/verify/verify.component';
import { AccountComponent } from '@core/containers/account/account.component';
import { SettingsComponent } from '@core/containers/settings/settings.component';
import { LifePreviewComponent } from '@app/preview/life/life-preview.component';
import { ActivityPreviewComponent } from '@app/preview/activity/activity-preview.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'life',
        loadChildren: () =>
          import('./life/life.module').then(m => m.LifeModule),
      },
      {
        path: 'activities',
        loadChildren: () =>
          import('./activities/activities.module').then(
            m => m.ActivitiesModule
          ),
      },
      {
        path: 'account',
        data: {
          accountSettings: true,
        },
        component: AccountComponent,
      },
      {
        path: 'settings',
        data: {
          accountSettings: true,
        },
        component: SettingsComponent,
      },
    ],
  },
  {
    path: 'life-setup',
    loadChildren: () =>
      import('@core/containers/life-setup/life-setup.module').then(
        m => m.LifeSetupModule
      ),
  },
  {
    path: 'holismo-bot/:userId/life',
    component: LifePreviewComponent,
  },
  {
    path: 'holismo-bot/:userId/activity',
    component: ActivityPreviewComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
@NgModule({
  exports: [RouterModule],
  providers: [
    provideRouter(routes, withComponentInputBinding(), withRouterConfig({})),
  ],
})
export class AppRoutingModule {}
