import { Component, Input, OnInit } from '@angular/core';
import { ILifeArea } from '@life/models/life-area';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromLife from '@life/selectors/life-area.selectors';
import { OgLifeActions } from '@life/actions';

@Component({
  selector: 'hl-life-area-activities',
  templateUrl: './life-area-activities.component.html',
})
export class LifeAreaActivitiesComponent implements OnInit {
  @Input() lifeArea: ILifeArea;
  @Input() lifeAreaActivities: any;

  convertedColor: string;
  hasActiveElement = this.store.selectSignal(fromLife.selectActiveDrawerItem$);
  showActivities = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    this.convertedColor = this.hexToRgba(this.lifeArea.darkColor, 0.1);
  }

  hexToRgba(hex: string, alpha: number) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  toggleActivitiesVisibility() {
    this.showActivities = !this.showActivities;
  }
  setLifeAreaImportance(importance: number) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaImportance({
        lifeArea: {
          ...this.lifeArea,
          importance,
        },
      })
    );
  }

  setLifeAreaSatisfaction(satisfaction: number) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaSatisfaction({
        lifeArea: {
          ...this.lifeArea,
          satisfaction: satisfaction,
        },
      })
    );
  }
}
