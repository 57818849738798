import { Component } from '@angular/core';
import { ILifeArea } from '@life/models/life-area';
import { Store } from '@ngrx/store';
import { ILifeActivity } from '@life/models/life-activity';
import { OgActivityActions } from '@life/actions';
import { selectLifeActivitiesUI$, selectLifeUI$ } from '@life/selectors';

@Component({
  selector: 'hl-activities-overview',
  templateUrl: './activities-overview.component.html',
  styles: [],
})
export class ActivitiesOverviewComponent {
  groupedBy = '';

  staticPartOfDay = [
    {
      name: 'Morning',
      activities: [
        {
          name: 'Sleep',
          icon: 'fa-moon',
          iconColor: 'red',
          satisfaction: 3,
          importance: 5,
        },
        {
          name: 'Stretch',
          icon: 'fa-person-swimming',
          iconColor: 'blue',
          satisfaction: 1,
          importance: 0,
        },
      ],
    },
    {
      name: 'Afternoon',
      activities: [
        {
          name: 'Stretch',
          icon: 'fa-person-swimming',
          iconColor: 'blue',
          satisfaction: 1,
          importance: 0,
        },
      ],
    },
    {
      name: 'Evening',
      activities: [
        {
          name: 'Sleep',
          icon: 'fa-moon',
          iconColor: 'red',
          satisfaction: 5,
          importance: 1,
        },
        {
          name: 'Stretch',
          icon: 'fa-person-swimming',
          iconColor: 'blue',
          satisfaction: 1,
          importance: 5,
        },
      ],
    },
    {
      name: 'All day',
      activities: [
        {
          name: 'Sleep',
          icon: 'fa-moon',
          iconColor: 'red',
          satisfaction: 3,
          importance: 5,
        },
        {
          name: 'Stretch',
          icon: 'fa-person-swimming',
          iconColor: 'blue',
          satisfaction: 1,
          importance: 0,
        },
        {
          name: 'Eat fruit',
          icon: 'fa-lemon',
          iconColor: 'green',
          satisfaction: 0,
          importance: 2,
        },
      ],
    },
  ];

  lifeAreas = this.store.selectSignal<ILifeArea[]>(selectLifeUI$);

  lifeActivities = this.store.selectSignal(selectLifeActivitiesUI$);
  constructor(private store: Store) {}

  addActivity(activity: Partial<ILifeActivity>) {
    this.store.dispatch(OgActivityActions.addActivity({ activity }));
  }
}
