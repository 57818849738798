import { createReducer, on } from '@ngrx/store';

import { IUser } from '@app/models';
import { OgUserActions } from '@core/actions/user.actions';
import { IReminder } from '@life/models/reminder';

export const userFeatureKey = 'user';

export interface UserState {
  user: IUser | null;
  lifeStartDate: string | null;
  displayLifeWheel: boolean;
  lifeAreasCount: number;
  roles: string[];
  reminders: IReminder[];
  product: any;
}

const initialState: UserState = {
  user: null,
  lifeStartDate: null,
  displayLifeWheel: false,
  lifeAreasCount: 0,
  roles: [],
  reminders: [],
  product: null,
};

export const reducer = createReducer(
  initialState,
  on(OgUserActions.initUser, (state, user): UserState => {
    return {
      ...state,
      lifeAreasCount: user.life_areas,
      roles: user.user_roles,
      displayLifeWheel: !!user.lifewheel_enabled,
    };
  }),
  on(OgUserActions.loadUserSuccess, (state, { user }): UserState => {
    return { ...state, user };
  }),
  on(OgUserActions.loadProductSuccess, (state, { product }): UserState => {
    return { ...state, product };
  }),
  on(OgUserActions.loadRemindersSuccess, (state, { reminders }): UserState => {
    return { ...state, reminders };
  }),
  on(
    OgUserActions.setLifeAreasCount,
    (state, { lifeAreasCount }): UserState => {
      return { ...state, lifeAreasCount };
    }
  ),
  on(
    OgUserActions.setDisplayLifeWheel,
    (state, { displayLifeWheel }): UserState => {
      return { ...state, displayLifeWheel };
    }
  ),
  on(OgUserActions.setUserRoles, (state, { roles }): UserState => {
    return { ...state, roles };
  })
);

export const selectUser = (state: UserState) => state.user;
export const selectLifeStartDate = (state: UserState) => state.lifeStartDate;
export const selectDisplayLifeWheel = (state: UserState) =>
  state.displayLifeWheel;
export const selectLifeAreasCount = (state: UserState) => state.lifeAreasCount;
export const selectUserRoles = (state: UserState) => state.roles;
export const selectProduct = (state: UserState) => state.product;
export const selectReminders = (state: UserState) => state.reminders;
