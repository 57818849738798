import {
  Component,
  EventEmitter,
  Input,
  Output,
  SecurityContext,
} from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { ILifeNote } from '@life/models/life-note';
import { CommonModule } from '@angular/common';
import { NoteComponent } from '@life/components/note/note.component';
import { InputSearchComponent } from '@components/inputs/input-search/input-search.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { LetDirective } from '@ngrx/component';
import { LifeModule } from '@life/life.module';
import { TranslateModule } from '@ngx-translate/core';
import { IsFavoriteFilterComponent } from '@components/filters/is-favorite-filter/is-favorite-filter.component';
import { QuillEditorOptions } from '../../../../../../quill.config';
import { Store } from '@ngrx/store';
import { selectLifeFilters$ } from '@life/selectors';

@Component({
  selector: 'hl-tab-note',
  templateUrl: './tab-note.component.html',
  standalone: true,
  imports: [
    QuillModule,
    FormsModule,
    CommonModule,
    NoteComponent,
    InputSearchComponent,
    CdkMenuTrigger,
    CdkMenu,
    LetDirective,
    LifeModule,
    TranslateModule,
    IsFavoriteFilterComponent,
    CdkMenuItem,
  ],
})
export class TabNoteComponent {
  @Input() notes: ILifeNote[] = [];

  @Output() addNewNote = new EventEmitter<Partial<ILifeNote>>();
  @Output() updateNote = new EventEmitter<ILifeNote>();
  @Output() updateNoteFocused = new EventEmitter<ILifeNote>();
  @Output() deleteNote = new EventEmitter<string>();

  quillEditorOptions = QuillEditorOptions;
  noteFilter: string;
  focusFilters: boolean[] = [];
  lifeFilters = this.store.selectSignal(selectLifeFilters$);
  constructor(private sanitizer: DomSanitizer, private store: Store) {}

  get filteredNotes() {
    return this.notes
      .filter(note => {
        const sanitized = this.sanitizer.sanitize(
          SecurityContext.HTML,
          note.text
        ) as string;
        const textEl = document.createElement('div');
        textEl.innerHTML = sanitized;
        const text = textEl.textContent as string;
        return this.noteFilter
          ? text.toLowerCase().indexOf(this.noteFilter.toLowerCase()) != -1
          : true;
      })
      .filter(note => {
        return this.focusFilters.length === 0
          ? true
          : this.focusFilters.includes(note.isFocused);
      })
      .sort((a, b) => {
        return new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime();
      });
  }

  newNote: Partial<ILifeNote> = {
    text: '',
  };

  handleSubmit(note: Partial<ILifeNote>) {
    this.addNewNote.emit(note);
    this.newNote = {
      text: '',
    };
  }

  updateFocused(isFocused: boolean, note: ILifeNote) {
    this.updateNoteFocused.emit({
      ...note,
      isFocused,
    });
  }
}
