import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ILifeArea } from '@life/models/life-area';
import { IColor } from '@life/models/colors';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LifeService {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getLife(lifeFrom?: string | null, userId?: string) {
    const lifeFromQuery = lifeFrom ? `lifeFrom=${lifeFrom}` : '';
    const userQuery = userId ? `u=${encodeURIComponent(userId)}` : '';

    const query = [lifeFromQuery, userQuery].filter(v => !!v).join('&');

    return this.http.get<{
      id: string;
      startDate: string;
      resetProgressDate: string;
      changeDates: string[];
      lifeAreas: ILifeArea[];
    }>(`${this.baseUrl}/life?${query}`);
  }

  deleteLife() {
    return this.http.delete(`${this.baseUrl}/life`);
  }

  resetLife() {
    return this.http.delete(`${this.baseUrl}/life/progress`);
  }

  getColors() {
    return this.http.get<IColor[]>(`${this.baseUrl}/lifearea/colors`);
  }

  setOrder(payload: { levelId: string; sortOrder: number }[]) {
    return this.http.post(`${this.baseUrl}/life/order`, payload);
  }
}
