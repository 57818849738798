<div
  class="flex flex-col px-4 gap-y-1 pb-[50px]"
  *ngrxLet="{
    selectedMetric: selectedMetric(),
    selectedDayOfWeek: selectedDayOfWeek(),
    selectedTimeZone: selectedTimeZone(),
    planMyDayReminder: planMyDayReminder(),
    reviewMyDayReminder: reviewMyDayReminder()
  } as vm">
  <h2 class="font-bold text-2xl">
    {{ 'AccountSettingsMetric' | translate }}
  </h2>

  <ng-template #metricMenu>
    <div
      cdkMenu
      class="flex flex-col shadow p-3 bg-white text-sm z-10000 text-menugray rounded-lg w-[100%]">
      <button
        cdkMenuItem
        (click)="chooseMetric(1)"
        [ngClass]="{
          'bg-filtershover text-white': vm.selectedMetric === 1
        }"
        class="h-9 w-44 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
        <span>{{ 'MetricContextMenuMetric' | translate }}</span>
      </button>
      <button
        cdkMenuItem
        (click)="chooseMetric(2)"
        [ngClass]="{
          'bg-filtershover text-white': vm.selectedMetric === 2
        }"
        class="h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
        <span>{{ 'MetricContextMenuImperial' | translate }}</span>
      </button>
    </div>
  </ng-template>
  <div class="container mx-auto py-4">
    <div class="grid grid-cols-3 text-slate-700">
      <div class="col-span-2 flex flex-col">
        <span class="font-medium">{{
          'AccountsSettingsMetricMain' | translate
        }}</span>
        <span class="text-sm text-slate-300 mt-2">
          {{ 'AccountsSettingsMetricDesc' | translate }}
        </span>
      </div>
      <div class="col-span-1 justify-self-end">
        <button
          [cdkMenuTriggerFor]="metricMenu"
          type="button"
          class="dropdown-button flex justify-between font-medium text-base text-slate-300">
          {{
            (vm.selectedMetric === 2
              ? 'MetricContextMenuImperial'
              : 'MetricContextMenuMetric'
            ) | translate
          }}
          <i
            class="fa-light fa-chevron-down ml-3 text-slate-400 flex text-base mt-1">
          </i>
        </button>
      </div>
    </div>
    <div class="grid md:grid-cols-3 grid-cols-1 mt-7">
      <span class="col-span-2">{{
        'AccountsSettingsMetricCount' | translate
      }}</span>
      <span
        *ngIf="vm.selectedMetric === 1"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricCountMetric' | translate }}</span
      >
      <span
        *ngIf="vm.selectedMetric === 2"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricCountImperial' | translate }}</span
      >
    </div>
    <div class="grid md:grid-cols-3 grid-cols-1 mt-4">
      <span class="col-span-2">{{
        'AccountsSettingsMetricLength' | translate
      }}</span>
      <span
        *ngIf="vm.selectedMetric === 1"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricLengthMetric' | translate }}</span
      >
      <span
        *ngIf="vm.selectedMetric === 2"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricLengthImperial' | translate }}</span
      >
    </div>
    <div class="grid md:grid-cols-3 grid-cols-1 mt-4">
      <span class="col-span-2">{{
        'AccountsSettingsMetricVolume' | translate
      }}</span>
      <span
        *ngIf="vm.selectedMetric === 1"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricVolumeMetric' | translate }}</span
      >
      <span
        *ngIf="vm.selectedMetric === 2"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricVolumeImperial' | translate }}</span
      >
    </div>
    <div class="grid md:grid-cols-3 grid-cols-1 mt-4">
      <span class="col-span-2">{{
        'AccountsSettingsMetricWeight' | translate
      }}</span>
      <span
        *ngIf="vm.selectedMetric === 1"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricWeightMetric' | translate }}</span
      >
      <span
        *ngIf="vm.selectedMetric === 2"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricWeightImperial' | translate }}</span
      >
    </div>
    <div class="grid md:grid-cols-3 grid-cols-1 mt-4">
      <span class="col-span-2">{{
        'AccountsSettingsMetricEnergy' | translate
      }}</span>
      <span
        *ngIf="vm.selectedMetric === 1"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricEnergyMetric' | translate }}</span
      >
      <span
        *ngIf="vm.selectedMetric === 2"
        class="col-span-1 flex md:justify-end text-slate-300"
        >{{ 'AccountsSettingsMetricEnergyImperial' | translate }}</span
      >
    </div>
  </div>
  <h2 class="font-bold text-2xl">
    {{ 'AccountSettingsTimeOfDay' | translate }}
  </h2>

  <ng-template #dayMenu>
    <div
      cdkMenu
      *ngrxLet="selectedDayOfWeek() as selectedDayOfWeek"
      class="flex flex-col shadow p-3 bg-white text-sm z-10000 text-menugray rounded-lg w-[100%]">
      <button
        cdkMenuItem
        (click)="chooseDay(1)"
        [ngClass]="{
          'bg-filtershover text-white': selectedDayOfWeek === 1
        }"
        class="h-9 w-44 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
        <span>{{ 'DayContextMenuMonday' | translate }}</span>
      </button>
      <button
        cdkMenuItem
        (click)="chooseDay(0)"
        [ngClass]="{
          'bg-filtershover text-white': selectedDayOfWeek === 0
        }"
        class="h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
        <span>{{ 'DayContextMenuSunday' | translate }}</span>
      </button>
    </div>
  </ng-template>
  <ng-template #timeZoneMenu>
    <div cdkMenu class="gap-1 shadow px-1 bg-gray-100">
      <div class="w-full py-1 sticky top-0 z-50 bg-gray-100">
        <input
          type="text"
          [placeholder]="'LifeHeaderFiltersSearch' | translate"
          [formControl]="search"
          class="w-full text-filtershover cursor-text pl-2 peer relative z-10 h-7 rounded-md outline-none" />
      </div>
      <div
        cdkMenu
        *ngrxLet="selectedTimeZone() as selectedTimeZone"
        class="flex flex-col shadow p-3 h-[400px] overflow-auto bg-white text-sm z-10000 text-menugray rounded-lg w-[100%]">
        <button
          cdkMenuItem
          *ngFor="let timezone of timeZones"
          (click)="chooseTimeZone(timezone)"
          [ngClass]="{
            'bg-filtershover text-white': timezone.name === selectedTimeZone
          }"
          class="flex items-start justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
          <span class="text-left">{{ timezone.name }}</span>
        </button>
      </div>
    </div>
  </ng-template>
  <div class="container mx-auto py-4">
    <div class="flex text-slate-700 flex-col mb-[24px] md:relative">
      <div
        class="flex justify-between"
        *ngrxLet="selectedTimeZone() as selectedTimeZone">
        <span class="font-medium">{{
          'AccountSettingsGeneralTimezone' | translate
        }}</span>
        <div class="flex justify-end">
          <button
            type="button"
            class="dropdown-button flex justify-between font-medium text-base text-slate-300"
            [cdkMenuPosition]="[
              {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top'
              }
            ]"
            (cdkMenuClosed)="clearSearch()"
            [cdkMenuTriggerFor]="timeZoneMenu">
            {{ selectedTimeZone }}
            <i
              class="fa-light fa-chevron-down ml-3 text-slate-400 flex text-base mt-1">
            </i>
          </button>
        </div>
      </div>
    </div>
    <div class="flex text-slate-700 flex-col mb-[86px]">
      <div class="flex justify-between">
        <span class="font-medium">{{
          'AccountSettingsGeneralFirstDayOfWeek' | translate
        }}</span>
        <div class="flex justify-end">
          <button
            type="button"
            class="dropdown-button flex justify-between font-medium text-base text-slate-300"
            [cdkMenuTriggerFor]="dayMenu">
            {{
              (vm.selectedDayOfWeek === 0
                ? 'DayContextMenuSunday'
                : 'DayContextMenuMonday'
              ) | translate
            }}
            <i
              class="fa-light fa-chevron-down ml-3 text-slate-400 flex text-base mt-1">
            </i>
          </button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 font-medium">
      <span> {{ 'AccountSettingsTimeOfDayMorning' | translate }}</span>
      <span class="flex justify-end text-slate-300">06:00 - 12:00</span>
      <span> {{ 'AccountSettingsTimeOfDayAfternoon' | translate }}</span>
      <span class="flex justify-end text-slate-300">12:00 - 18:00</span>
      <span> {{ 'AccountSettingsTimeOfDayEvening' | translate }}</span>
      <span class="flex justify-end text-slate-300">18:00 - 22:00</span>
      <span> {{ 'AccountSettingsTimeOfDayNight' | translate }}</span>
      <span class="flex justify-end text-slate-300">22:00 - 06:00</span>
    </div>
  </div>
  <h2 class="font-bold text-2xl">
    {{ 'AccountSettingsReminders' | translate }}
  </h2>

  <div class="container mx-auto py-4">
    <div class="grid grid-cols-3 gap-1">
      <div class="col-span-2 flex flex-col">
        <span class="font-medium">{{
          'AccountSettingsRemindersPlan' | translate
        }}</span>
        <span class="mt-2 text-gray-300">{{
          'AccountSettingsRemindersPlanInfo' | translate
        }}</span>
      </div>
      <div class="col-span-1 flex justify-between">
        <button class="flex">
          <input
            type="time"
            [ngModel]="vm.planMyDayReminder.times[0]"
            (ngModelChange)="updateReminderTimes($event, vm.planMyDayReminder)"
            class="appearance-none focus:outline-none self-baseline inline-flex" />
        </button>
        <div>
          <mat-slide-toggle
            [checked]="vm.planMyDayReminder.isActive"
            (change)="updateReminderActive($event, vm.planMyDayReminder)"
            color="#48494A"
            class="holismo-switch"
            disableRipple></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-1 mt-7">
      <div class="col-span-2 flex flex-col">
        <span class="font-medium">{{
          'AccountSettingsRemindersReflect' | translate
        }}</span>
        <span class="mt-2 text-gray-300">{{
          'AccountSettingsRemindersReflectInfo' | translate
        }}</span>
      </div>
      <div class="col-span-1 flex justify-between">
        <button class="flex">
          <input
            type="time"
            [ngModel]="vm.reviewMyDayReminder.times[0]"
            (ngModelChange)="
              updateReminderTimes($event, vm.reviewMyDayReminder)
            "
            class="appearance-none focus:outline-none self-baseline inline-flex" />
        </button>
        <div>
          <mat-slide-toggle
            [checked]="vm.reviewMyDayReminder.isActive"
            (change)="updateReminderActive($event, vm.reviewMyDayReminder)"
            color="#48494A"
            class="holismo-switch"
            disableRipple></mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
