import { createAction, props } from '@ngrx/store';
import { IColor } from '@life/models/colors';

export const getAllColors = createAction('[Colors] Get Colors');
export const getAllColorsFail = createAction(
  '[Colors] Get Colors Fail',
  props<{ error: string }>()
);

export const getAllColorsSuccess = createAction(
  '[Colors] Get Colors Success',
  props<{ colors: IColor[] }>()
);
