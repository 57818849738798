import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ILifeSpecifics } from '@life/models/life-specifics';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LifeSpecificsService {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  updateLifeSpecificSatisfaction(lifeSpecific: ILifeSpecifics) {
    return this.http.post(
      `${this.baseUrl}/specific/${lifeSpecific.id}/satisfaction/${lifeSpecific.satisfaction}`,
      {}
    );
  }

  updateLifeSpecificDescription(lifeSpecific: ILifeSpecifics) {
    return this.http.post(
      `${this.baseUrl}/specific/${lifeSpecific.id}/description`,
      {
        description: lifeSpecific.description,
      }
    );
  }

  updateLifeSpecificImportance(lifeSpecific: ILifeSpecifics) {
    return this.http.post(
      `${this.baseUrl}/specific/${lifeSpecific.id}/importance/${lifeSpecific.importance}`,
      {}
    );
  }

  updateLifeSpecificFocus(lifeSpecific: ILifeSpecifics) {
    return this.http.post(
      `${this.baseUrl}/specific/${lifeSpecific.id}/focus`,
      {}
    );
  }

  deleteLifeSpecific(lifeSpecificId: string) {
    return this.http.delete(`${this.baseUrl}/specific/${lifeSpecificId}`);
  }

  getLifeSpecific(
    lifeSpecificId: string,
    selectedDate: number | null | undefined,
    userId?: string
  ) {
    const lifeFromQuery = selectedDate
      ? `lifeFrom=${new Date(selectedDate).toISOString()}`
      : '';
    const userQuery = userId ? `u=${encodeURIComponent(userId)}` : '';

    const query = [lifeFromQuery, userQuery].filter(v => !!v).join('&');
    return this.http.get<ILifeSpecifics>(
      `${this.baseUrl}/specific/${lifeSpecificId}?${query}`
    );
  }

  addNewCustomLifeSpecific(lifeSpecificName: string, lifeStateId: string) {
    return this.http.post(
      `${this.baseUrl}/state/${lifeStateId}/specific/${lifeSpecificName}`,
      {}
    );
  }
  addNewLifeSpecificFromTemplate(
    lifeSpecific: Partial<ILifeSpecifics>,
    lifeStateId: string
  ) {
    return this.http.post(
      `${this.baseUrl}/state/${lifeStateId}/specific/${lifeSpecific.id}`,
      {}
    );
  }

  updateLifeSpecificName(lifeSpecific: ILifeSpecifics) {
    return this.http.patch(
      `${this.baseUrl}/specific/${lifeSpecific.id}/name/${lifeSpecific.name}`,
      {}
    );
  }

  moveSpecificToLifeState(specificId: string, stateId: string) {
    return this.http.post(
      `${this.baseUrl}/specific/${specificId}/move?newStateId=${stateId}`,
      {}
    );
  }
}
