import { Component, signal, Signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import * as fromLife from '@life/selectors/life-area.selectors';
import { CloseDrawerComponent } from '@life/components/close-drawer/close-drawer.component';
import { InlineEditComponent } from '@components/inputs/inline-edit/inline-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  OgActivityActions,
  OgLifeNoteActions,
  OgLifeStateActions,
} from '@life/actions';
import { ILifeState } from '@life/models/life-state';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { TabAboutComponent } from '@life/components/tabs/tab-about/tab-about.component';
import { TabNoteComponent } from '@life/components/tabs/tab-note/tab-note.component';
import { TabActivityComponent } from '@life/components/tabs/tab-activity/tab-activity.component';
import { ILifeNote } from '@life/models/life-note';
import { LifeModule } from '@life/life.module';
import { ConfirmationDialogComponent } from '@components/dialogs';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ElementType } from '@life/models/element.type';
import { selectNotes$ } from '@life/selectors';
import { filter } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
@Component({
  selector: 'hl-life-state-drawer',
  standalone: true,
  imports: [
    CommonModule,
    CloseDrawerComponent,
    InlineEditComponent,
    TranslateModule,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    TabNoteComponent,
    TabActivityComponent,
    TabAboutComponent,
    LifeModule,
    LetDirective,
  ],
  templateUrl: './life-state-drawer.component.html',
  styles: [],
})
export class LifeStateDrawerComponent {
  activeItem: ILifeState;
  @ViewChild('tabAboutComponent') tabAboutComponent: TabAboutComponent;
  activeItem$ = this.store.select(fromLife.selectActiveDrawerItem$).pipe(
    filter(item => !!item),
    tap(({ item, activeTabIndex }) => {
      this.activeTab = +activeTabIndex;

      if (
        this.activeItem &&
        this.activeItem.id !== item?.id &&
        this.tabAboutComponent?.descriptionControl?.value !== undefined &&
        this.activeItem.description !==
          this.tabAboutComponent?.descriptionControl?.value
      ) {
        this.setLifeStateDescription(
          this.tabAboutComponent?.descriptionControl?.value,
          this.activeItem
        );
      }

      this.activeItem = item as ILifeState;

      if (item?.id) {
        this.notes = this.store.selectSignal(selectNotes$(item.id));
      }
    }),
    map(({ item }) => item as ILifeState)
  );

  canRename = false;
  activeTab = 1;
  notes: Signal<ILifeNote[]> = signal([]);
  constructor(
    private store: Store,
    private translate: TranslateService,
    private dialog: Dialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  rename(value: string, lifeState: ILifeState) {
    this.canRename = false;
    this.store.dispatch(
      OgLifeStateActions.setLifeStateName({
        lifeState: {
          ...lifeState,
          name: value.trim(),
        },
      })
    );
  }

  addNewNote(note: Partial<ILifeNote>, lifeState: ILifeState) {
    const newNote = {
      text: note.text,
      elementType: ElementType.LIFE_STATE,
      elementId: lifeState.id,
    };
    this.store.dispatch(OgLifeNoteActions.addNote({ note: newNote }));
  }

  deleteNote(noteId: string) {
    this.store.dispatch(OgLifeNoteActions.deleteNote({ noteId: noteId }));
  }

  updateLifeStateFocused(isFocused: boolean, lifeState: ILifeState) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateFocus({
        lifeState: {
          ...lifeState,
          isFocused,
        },
      })
    );
  }

  deleteState(lifeState: ILifeState) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: 'Delete Life State?',
        message: `Do you really want to delete ${lifeState.name} Life State?`,
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(
          OgLifeStateActions.deleteLifeState({
            lifeState: lifeState,
          })
        );
      }
    });
  }

  updateLifeStateNote(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNote({ note }));
  }

  updateLifeStateNoteFocus(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNoteFocus({ note }));
  }

  setLifeStateDescription(description: string | null, lifeState: ILifeState) {
    this.store.dispatch(
      OgLifeStateActions.setLifeStateDescription({
        lifeState: {
          ...lifeState,
          description,
        },
      })
    );
  }

  manageActivity(
    event: { activityId: string; add: boolean },
    state: ILifeState
  ) {
    if (event.add) {
      this.store.dispatch(
        OgActivityActions.addActivityToLifeState({
          lifeStateId: state.id,
          activityId: event.activityId,
        })
      );
    } else {
      this.store.dispatch(
        OgActivityActions.removeActivityFromLifeState({
          lifeStateId: state.id,
          activityId: event.activityId,
        })
      );
    }
  }

  selectTab(activeItemId: string, activeTabIndex = 0) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId,
        activeItemType: 'state',
        activeTabIndex,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  activityCount(lifeState: ILifeState) {
    return lifeState?.activityIds.length;
  }
}
