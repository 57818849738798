import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  TrackByFunction,
} from '@angular/core';
import { ILifeActivity } from '@life/models/life-activity';
import { DragulaService } from 'ng2-dragula';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OgActivityActions } from '@life/actions';
import { Store } from '@ngrx/store';
import { selectAvailableActivities$ } from '@life/selectors';

@Component({
  selector: 'hl-activities-list',
  templateUrl: './activities-list.component.html',
})
export class ActivitiesListComponent implements OnDestroy {
  destroyRef = inject(DestroyRef);

  @Input() activitiesList: ILifeActivity[];
  @Input() showAddActivity = true;
  @Output() addActivity = new EventEmitter<Partial<ILifeActivity>>();

  availableActivities$ = this.store.selectSignal(selectAvailableActivities$);

  addNewActivity({ item }: { item: Partial<ILifeActivity> }) {
    this.addActivity.emit(item);
  }

  get usedActivities() {
    return this.activitiesList.map(x => x.name);
  }

  constructor(private dragulaService: DragulaService, private store: Store) {
    dragulaService.createGroup('LIFE_ACTIVITIES', {
      moves: (el, container, handle) => {
        return window.innerWidth > 768
          ? true
          : !!handle?.classList.contains('drag-handle-mobile');
      },
    });

    dragulaService
      .dropModel('LIFE_ACTIVITIES')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ sourceModel }) => {
        const updatedActivities = sourceModel.map((x, index) => ({
          ...x,
          sortOrder: index,
        }));

        this.store.dispatch(
          OgActivityActions.setActivitiesSortOrder({
            activities: updatedActivities,
          })
        );
      });
  }

  ngOnDestroy() {
    this.dragulaService.destroy('LIFE_ACTIVITIES');
  }

  trackByActivityId: TrackByFunction<ILifeActivity> = (index, activity) =>
    activity.id + activity.sortOrder;
}
