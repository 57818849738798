import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { IUser } from '@app/models';
import { IReminder } from '@life/models/reminder';

export const idleTimeout = createAction('[User] Idle Timeout');

export const OgUserActions = createActionGroup({
  source: 'User',
  events: {
    initUser: props<{
      life_areas: number;
      user_roles: string[];
      lifewheel_enabled: boolean;
    }>(),
    loadUser: emptyProps(),
    updateUser: props<{
      firstDayOfWeek?: number;
      language?: string;
      metric?: number;
      timeZone?: string;
    }>(),
    loadUserSuccess: props<{ user: IUser }>(),
    loadUserFail: props<{ error: string }>(),
    loadProduct: emptyProps(),
    loadProductSuccess: props<{ product: any }>(),
    loadProductFail: props<{ error: string }>(),
    loadReminders: emptyProps(),
    loadRemindersSuccess: props<{ reminders: IReminder[] }>(),
    loadRemindersFail: props<{ error: string }>(),
    toggleReminder: props<{ reminderId: string }>(),
    updateReminderTimes: props<{ times: string[]; reminderId: string }>(),
    setLifeAreasCount: props<{ lifeAreasCount: number }>(),
    setDisplayLifeWheel: props<{ displayLifeWheel: boolean }>(),
    setUserRoles: props<{ roles: string[] }>(),
  },
});
