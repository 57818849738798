import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ILifeState } from '@life/models/life-state';
import { concatMap, from } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LifeStateService {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  /////////////
  updateLifeStateImportance(stateId: string, importance: number) {
    return this.http.post(
      `${this.baseUrl}/state/${stateId}/importance/${importance}`,
      {}
    );
  }

  updateLifeStateSatisfaction(stateId: string, satisfaction: number) {
    return this.http.post(
      `${this.baseUrl}/state/${stateId}/satisfaction/${satisfaction}`,
      {}
    );
  }

  updateLifeStateDescription(lifeState: ILifeState) {
    return this.http.post(`${this.baseUrl}/state/${lifeState.id}/description`, {
      description: lifeState.description,
    });
  }

  updateLifeStateFocus(stateId: string) {
    return this.http.post(`${this.baseUrl}/state/${stateId}/focus`, {});
  }

  updateLifeStateSpecificsHidden(stateId: string) {
    return this.http.post(
      `${this.baseUrl}/state/${stateId}/specifics/toggle`,
      {}
    );
  }

  updateLifeStateName(lifeState: ILifeState) {
    return this.http.patch(
      `${this.baseUrl}/state/${lifeState.id}/name/${lifeState.name}`,
      {}
    );
  }

  addNewLifeStateFromTemplate(lifeStateId: string, areaId: string) {
    return this.http.put(
      `${this.baseUrl}/lifearea/${areaId}/state/${lifeStateId}`,
      {}
    );
  }

  addCustomLifeState(lifeAreaId: string, stateName: string) {
    return this.http.put(
      `${this.baseUrl}/lifearea/${lifeAreaId}/state/${stateName}`,
      {}
    );
  }

  deleteLifeState(lifeStateId: string) {
    return this.http.delete(`${this.baseUrl}/state/${lifeStateId}`);
  }

  getLifeState(
    lifeStateId: string,
    selectedDate: number | null | undefined,
    userId?: string
  ) {
    const lifeFromQuery = selectedDate
      ? `lifeFrom=${new Date(selectedDate).toISOString()}`
      : '';
    const userQuery = userId ? `u=${encodeURIComponent(userId)}` : '';

    const query = [lifeFromQuery, userQuery].filter(v => !!v).join('&');

    return this.http.get<ILifeState>(
      `${this.baseUrl}/state/${lifeStateId}?${query}`
    );
  }

  updateLifeStatesSortOrder(lifeStates: ILifeState[]) {
    return from(lifeStates).pipe(
      concatMap(lifeStae =>
        this.http.patch(
          `${this.baseUrl}/state/${lifeStae.id}/sortorder/${lifeStae.sortOrder}`,
          {}
        )
      ),
      toArray()
    );
  }

  moveStateToLifeArea(stateId: string, lifeAreaId: string) {
    return this.http.post(
      `${this.baseUrl}/state/${stateId}/move?newLifeAreaId=${lifeAreaId}`,
      {}
    );
  }
}
