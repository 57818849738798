<div class="flex items-center relative justify-center">
  <button class="block w-5 h-5" [cdkMenuTriggerFor]="menu" hlStopPropagation>
    <div
      *ngIf="satisfaction > 0"
      class="bg-white absolute w-3.5 h-4 rounded-full top-[3px] left-[3px]"></div>
    <i
      [ngClass]="[
        satisfactionScore[satisfaction]?.icon,
        satisfaction === 0
          ? satisfactionZeroClass
          : 'text-' + satisfactionScore[satisfaction]?.cssClass
      ]"
      class="fa-solid relative rounded-full text-xl">
    </i>
  </button>
  <ng-template #menu>
    <div
      class="bg-white relative left-[-4px] flex flex-col w-[47px] shadow ml-[-10px]"
      cdkMenu>
      <button
        *ngFor="let rate of ratingItems"
        cdkMenuItem
        (keydown.enter)="setSatisfaction.emit(rate)"
        (click)="setSatisfaction.emit(rate)"
        hlStopPropagation
        [ngClass]="['hover:bg-' + satisfactionScore[rate].cssClass + '/20']"
        class="flex items-center justify-center py-1 cursor-pointer">
        <i
          [ngClass]="[
            satisfactionScore[rate].icon,
            'text-' + satisfactionScore[rate].cssClass
          ]"
          class="text-2xl fa-solid"></i>
      </button>
    </div>
  </ng-template>
</div>
