<button (click)="toggleFavourite.emit(!isFavorite)" hlStopPropagation>
  <i
    class="fa-solid fa-star"
    [ngClass]="
      isFavorite
        ? 'text-yellow-500'
        : notFavoriteWhiteBg
        ? 'text-white'
        : 'text-icons-gray'
    "></i>
</button>
