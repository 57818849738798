import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { ILifeState } from '@life/models/life-state';
import {
  OgActivityActions,
  OgLifeActions,
  OgLifeStateActions,
} from '@life/actions';
import { calculateUrgency } from '@app/util';

export const lifeStateFeatureKey = 'life-state';

export interface LifeState extends EntityState<ILifeState> {
  states?: ILifeState[];
  error?: string | null;
  openLifeStates: string[];
}

export const adapter = createEntityAdapter<ILifeState>();

const initialState: LifeState = adapter.getInitialState({
  openLifeStates: [],
});

export const reducer = createReducer(
  initialState,
  on(OgLifeActions.loadLifeAreas, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(OgLifeStateActions.setLifeStatesSortOrder, (state, { lifeStates }) => {
    return adapter.upsertMany(lifeStates, state);
  }),
  on(
    OgLifeActions.moveLifeStateFromLifeArea,
    (state, { updatedLifeStates }) => {
      return adapter.upsertMany(updatedLifeStates, state);
    }
  ),
  on(
    OgLifeStateActions.updateLifeStateSuccess,
    OgLifeStateActions.setLifeSpecificsHidden,
    OgLifeStateActions.setLifeStateDescription,
    OgLifeStateActions.setLifeStateFocus,
    OgLifeStateActions.actuallySetLifeStateName,
    (state, { lifeState }) => {
      return adapter.updateOne(
        {
          id: lifeState.id,
          changes: {
            ...lifeState,
          },
        },
        state
      );
    }
  ),
  on(
    OgLifeStateActions.setLifeStateImportance,
    OgLifeStateActions.setLifeStateSatisfaction,
    (state, { lifeState }) => {
      return adapter.updateOne(
        {
          id: lifeState.id,
          changes: {
            ...lifeState,
            urgency: calculateUrgency(lifeState),
          },
        },
        state
      );
    }
  ),
  on(OgLifeStateActions.loadLifeStateSuccess, (state, { lifeState }) =>
    adapter.upsertOne(lifeState, state)
  ),
  on(OgLifeStateActions.deleteLifeStateSuccess, (state, { lifeState }) =>
    adapter.removeOne(lifeState.id, state)
  ),
  on(
    OgActivityActions.removeActivityFromLifeState,
    (state, { lifeStateId, activityId }) => {
      const lifeState = state.entities[lifeStateId];
      return adapter.updateOne(
        {
          id: lifeStateId,
          changes: {
            activityIds: lifeState?.activityIds.filter(id => id !== activityId),
          },
        },
        state
      );
    }
  ),
  on(
    OgActivityActions.addActivityToLifeState,
    (state, { lifeStateId, activityId }) => {
      const lifeState = state.entities[lifeStateId];
      return adapter.updateOne(
        {
          id: lifeStateId,
          changes: {
            activityIds: [
              ...(lifeState ? lifeState.activityIds : []),
              activityId,
            ],
          },
        },
        state
      );
    }
  )
);

export const {
  selectIds: selectLifeStateIds,
  selectEntities: selectLifeStatesEntities,
  selectAll: selectLifeStates,
} = adapter.getSelectors();
