import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LifeAreaService } from '@life/services';
import { TranslateService } from '@ngx-translate/core';
import { ILifeArea } from '@life/models/life-area';
import { OgLifeActions } from '@life/actions';

@Injectable()
export class LifeTemplatesEffects {
  loadTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.loadTemplates),
      switchMap(() =>
        this.lifeAreaService.getLifeAreas().pipe(
          map((lifeAreas: ILifeArea[]) => {
            const lifeTranslated = lifeAreas.map(lifeArea => {
              return {
                ...lifeArea,
                name: this.translate.instant(lifeArea.name),
                states: lifeArea.states.map(lifeState => {
                  return {
                    ...lifeState,
                    name: this.translate.instant(lifeState.name),
                    specifics: lifeState.specifics.map(specific => {
                      return {
                        ...specific,
                        name: this.translate.instant(specific.name),
                      };
                    }),
                  };
                }),
              };
            });
            return OgLifeActions.loadTemplatesSuccess({
              lifeArea: lifeTranslated,
            });
          }),
          catchError(({ error }) =>
            of(
              OgLifeActions.loadTemplatesFail({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  constructor(
    private actions$: Actions,
    private lifeAreaService: LifeAreaService,
    private translate: TranslateService
  ) {}
}
