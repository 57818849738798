import { Component, signal, Signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromLife from '@life/selectors/life-area.selectors';
import { Store } from '@ngrx/store';
import { QuillModule } from 'ngx-quill';
import { InlineEditComponent } from '@components/inputs/inline-edit/inline-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { ILifeArea } from '@life/models/life-area';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { CloseDrawerComponent } from '@life/components/close-drawer/close-drawer.component';
import { TabNoteComponent } from '@life/components/tabs/tab-note/tab-note.component';
import { TabAboutComponent } from '@life/components/tabs/tab-about/tab-about.component';
import { ColorSelectComponent } from '@components/selection/color-select/color-select.component';
import { IconSelectComponent } from '@components/selection/icon-select/icon-select.component';
import { ILifeNote } from '@life/models/life-note';

import { selectLifeColors, selectNotes$ } from '@life/selectors';
import { IColor } from '@life/models/colors';
import { ElementType } from '@life/models/element.type';
import { ConfirmationDialogComponent } from '@app/components';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OgLifeActions, OgLifeNoteActions } from '@life/actions';
import { filter } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'hl-life-area-drawer',
  standalone: true,
  imports: [
    CommonModule,
    QuillModule,
    InlineEditComponent,
    TranslateModule,
    CdkMenu,
    CdkMenuItem,
    StopPropagationDirective,
    CdkMenuTrigger,
    CloseDrawerComponent,
    TabAboutComponent,
    TabNoteComponent,
    ColorSelectComponent,
    IconSelectComponent,
  ],
  templateUrl: './life-area-drawer.component.html',
})
export class LifeAreaDrawerComponent {
  activeItem: ILifeArea;
  @ViewChild('tabAboutComponent') tabAboutComponent: TabAboutComponent;
  activeItem$ = this.store.select(fromLife.selectActiveDrawerItem$).pipe(
    filter(item => !!item),
    tap(({ item, activeTabIndex }) => {
      this.activeTab = +activeTabIndex;

      if (
        this.activeItem &&
        this.activeItem.id !== item?.id &&
        this.tabAboutComponent?.descriptionControl?.value !== undefined &&
        this.activeItem.description !==
          this.tabAboutComponent?.descriptionControl?.value
      ) {
        this.updateLifeAreaDescription(
          this.tabAboutComponent?.descriptionControl?.value,
          this.activeItem
        );
      }

      this.activeItem = item as ILifeArea;

      if (item?.id) {
        this.notes = this.store.selectSignal(selectNotes$(item.id));
      }
    }),
    map(({ item }) => item as ILifeArea)
  );

  canRename = false;
  allColors = this.store.selectSignal(selectLifeColors);
  notes: Signal<ILifeNote[]> = signal([]);
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: Dialog
  ) {}
  activeTab = 1;

  rename(value: string, lifeArea: ILifeArea) {
    this.canRename = false;
    this.store.dispatch(
      OgLifeActions.setLifeAreaName({
        lifeArea: {
          ...lifeArea,
          name: value.trim(),
        },
      })
    );
  }

  updateLifeAreaDescription(description: string | null, lifeArea: ILifeArea) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaDescription({
        lifeArea: {
          ...lifeArea,
          description,
        },
      })
    );
  }

  setLifeAreaColor(colorObject: IColor, lifeArea: ILifeArea) {
    const lifeAreaColorScheme = {
      darkColor: colorObject.darkColor,
      mediumColor: colorObject.mediumColor,
      lightColor: colorObject.lightColor,
    };
    this.store.dispatch(
      OgLifeActions.setLifeAreaColor({
        lifeArea: { ...lifeArea, ...lifeAreaColorScheme },
      })
    );
  }

  addLifeAreaNote(note: Partial<ILifeNote>, lifeArea: ILifeArea) {
    const newNote = {
      ...note,
      elementType: ElementType.LIFE_AREA,
      elementId: lifeArea.id,
    };
    this.store.dispatch(
      OgLifeNoteActions.addNote({
        note: newNote,
      })
    );
  }

  updateLifeAreaNote(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNote({ note }));
  }

  updateLifeAreaNoteFocus(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNoteFocus({ note }));
  }

  deleteNote(noteId: string) {
    this.store.dispatch(OgLifeNoteActions.deleteNote({ noteId: noteId }));
  }

  setLifeAreaIcon(icon: string, lifeArea: ILifeArea) {
    this.store.dispatch(
      OgLifeActions.setLifeAreaIcon({
        lifeArea: {
          ...lifeArea,
          icon: icon,
        },
      })
    );
  }
  deleteLifeArea(lifeArea: ILifeArea) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('ConfirmationDialogDeleteLifeAreaTitle'),
        message: this.translate.instant(
          'ConfirmationDialogDeleteLifeAreaMessage',
          {
            name: lifeArea.name,
          }
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(OgLifeActions.deleteLifeArea({ lifeArea }));
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            activeItemId: null,
            activeItemType: null,
            activeTabIndex: null,
          },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      }
    });
  }

  selectTab(activeItemId: string, activeTabIndex = 0) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId,
        activeItemType: 'area',
        activeTabIndex,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
