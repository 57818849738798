<div
  class="bg-[url('/assets/images/verify.png')] bg-cover bg-center h-screen flex justify-center items-center">
  <div class="w-[295px] h-auto bg-white rounded px-6 py-12">
    <div class="">
      <img src="assets/images/logo2.svg" alt="Holismo" />
    </div>
    <div class="flex flex-col items-center mt-10">
      <h3 class="text-xl font-bold">{{ 'VerifyTitle' | translate }}</h3>
      <div
        class="w-16 h-16 rounded-full bg-holismo-purple flex items-center justify-center my-8">
        <img src="assets/images/oauth/email-white.png" alt="Email" />
      </div>
    </div>
    <div class="text-sm">
      <span class="block mb-4 font-bold">{{
        'VerifyMessage' | translate
      }}</span>
      <ng-container *ngrxLet="userEmail$ as userEmail">
        <span
          class="block mb-4"
          *ngIf="!emailReset"
          [innerHTML]="
            'VerifyMessageSuccess' | translate : { value: userEmail }
          "></span>
      </ng-container>
      <span class="font-bold" *ngIf="emailReset">{{
        'VerifyEmailInfo' | translate
      }}</span>
      <span
        class="block"
        [innerHTML]="'VerifyEmailInfoMessage' | translate"></span>
      <div class="flex mt-12 justify-between">
        <button
          class="text-sm bg-holismo-purple text-white px-2 py-1 rounded-sm"
          (click)="sendVerificationEmail()">
          {{ 'VerifyButtonResend' | translate }}
        </button>
        <button
          class="text-sm px-2 text-holismo-purple rounded-sm"
          (click)="goToApp()">
          {{ 'VerifyButtonReturn' | translate
          }}<i class="fa-solid fa-arrow-right text-xs ml-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>
