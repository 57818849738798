import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ILifeArea } from '@life/models/life-area';
import { OgLifeActions } from '@life/actions';
import { calculateUrgency } from '@app/util';

export const lifeAreaFeatureKey = 'life-area';

export interface LifeAreaState extends EntityState<ILifeArea> {
  user?: string;
  resetProgressDate: string | null;
  changeDates: string[];
  startDate: string | null;
  startedDate?: string;
  loading?: boolean;
  loaded: boolean;
  error?: string | null;
  activeDragElement: 'AREA' | 'STATE' | 'SPECIFIC' | null;
  maxLifeAreas: number;
}

export const adapter = createEntityAdapter<ILifeArea>();

const initialState: LifeAreaState = adapter.getInitialState({
  activeDragElement: null,
  loaded: false,
  maxLifeAreas: 0,
  startDate: null,
  changeDates: [],
  resetProgressDate: null,
});

export const reducer = createReducer(
  initialState,
  on(OgLifeActions.loadLifeAreas, (state: LifeAreaState) => {
    return { ...state, loading: true };
  }),
  on(
    OgLifeActions.loadLifeAreasSuccess,
    (state, { lifeAreas, resetProgressDate, changeDates, startDate }) => {
      return adapter.setAll(lifeAreas, {
        ...state,
        loaded: true,
        resetProgressDate,
        changeDates,
        startDate,
      });
    }
  ),
  on(OgLifeActions.loadLifeAreasFail, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    OgLifeActions.updateLifeAreaSuccess,
    OgLifeActions.setLifeAreaDescription,
    OgLifeActions.setLifeAreaStatesHidden,
    OgLifeActions.setLifeAreaIcon,
    OgLifeActions.setLifeAreaColor,
    OgLifeActions.actuallySetLifeAreaName,
    OgLifeActions.loadLifeAreaSuccess,
    (state, { lifeArea }) => {
      return adapter.updateOne(
        {
          id: lifeArea.id,
          changes: {
            ...lifeArea,
          },
        },
        state
      );
    }
  ),
  on(
    OgLifeActions.setLifeAreaSatisfaction,
    OgLifeActions.setLifeAreaImportance,
    (state, { lifeArea }) => {
      return adapter.updateOne(
        {
          id: lifeArea.id,
          changes: {
            ...lifeArea,
            urgency: calculateUrgency(lifeArea),
          },
        },
        state
      );
    }
  ),
  on(OgLifeActions.deleteLifeAreaSuccess, (state, { lifeArea }) =>
    adapter.removeOne(lifeArea.id, state)
  ),
  on(OgLifeActions.setLifeAreasSortOrderSuccess, (state, { lifeAreas }) => {
    return adapter.updateMany(
      lifeAreas.map(la => ({
        id: la.id,
        changes: { sortOrder: la.sortOrder },
      })),
      state
    );
  }),
  on(OgLifeActions.setLifeDragElement, (state, { activeDragElement }) => ({
    ...state,
    activeDragElement,
  })),
  on(OgLifeActions.setMaxLifeAreas, (state, { maxLifeAreas }) => ({
    ...state,
    maxLifeAreas,
  })),
  on(OgLifeActions.deleteLife, state => ({
    ...state,
    loaded: false,
  }))
);

export const {
  selectIds: selectLifeAreaIds,
  selectEntities: selectLifeAreaEntities,
  selectAll: selectLifeAreas,
} = adapter.getSelectors();
