import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import * as d3 from 'd3';
import { formatDate } from '@angular/common';
import { IPeriodicStats } from '@life/models/periodic-stats';

@Component({
  selector: 'hl-chart',
  templateUrl: './chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chart: ElementRef;
  @Input() stats: IPeriodicStats;
  @Input() height: number;
  @Input() dateFormat = 'MMM dd';

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['stats'].firstChange) {
      this.createChart();
    }
  }

  ngAfterViewInit() {
    this.initChart();
    this.createChart();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.initChart();
    this.createChart();
  }

  svg: any;

  width: number;
  innerWidth: number;
  innerHeight: number;

  initChart() {
    const container =
      this.chart.nativeElement.parentElement.getBoundingClientRect();

    this.width = container.width;
    this.innerWidth = this.width - 40;
    this.innerHeight = this.height - 20;
  }

  createChart() {
    const element = this.chart.nativeElement;
    d3.select(element).select('svg').remove();
    this.svg = d3
      .select(element)
      .append('svg')
      .attr('width', `${this.width}`)
      .attr('height', `${this.height}`);

    const x = d3
      .scaleLinear()
      .domain([0, this.stats.date.length - 1])
      .range([0, this.innerWidth - 40]);

    const y = d3.scaleLinear().domain([0, 5]).range([this.innerHeight, 0]);

    const xAxis = d3
      .axisBottom(x)
      .tickFormat((d, i) => {
        return this.stats.date[i]?.fromDate
          ? formatDate(
              new Date(this.stats.date[i].fromDate),
              this.dateFormat,
              'en-US'
            )
          : '';
      })
      .ticks(this.stats.date.length);

    const yAxisGrid = d3.axisLeft(y).tickSize(-this.innerWidth).ticks(7);

    // Create grids.
    this.svg
      .append('g')
      .style('stroke-dasharray', '5 5')
      .attr('class', 'y axis')
      .attr('transform', 'translate(41,' + this.innerHeight + ')')
      .call(xAxis)
      // @ts-ignore
      .call(g => g.select('.domain').remove())
      // @ts-ignore
      .call(g => g.selectAll('.tick line').remove());

    this.svg
      .append('g')
      .style('stroke-dasharray', '6 6')
      .style('stroke', '#A6A6A6')
      .style('stroke-width', '0.5px')
      .attr('class', 'y axis-grid')
      .attr('transform', 'translate(20, 0)')
      .call(yAxisGrid)
      // @ts-ignore
      .call(g => g.select('.domain').remove())
      // @ts-ignore
      .call(g => g.selectAll('.tick text').remove());

    const dots = this.svg.append('g');
    dots
      .selectAll('dot')
      .data(this.stats.date)
      .enter()
      .append('foreignObject')
      .attr('transform', 'translate(45, -10)')
      .attr('x', (d: any, ls: any) => {
        return x(ls) - 10;
      })
      .attr('y', (d: any) => {
        return y(d.satisfaction - 1) - 15;
      })
      .attr('width', 20)
      .attr('height', 20)
      .html((d: any) => {
        const satisfaction = d.satisfaction;
        let iconHtml = '';
        if (satisfaction === 1) {
          iconHtml = '<i class="fa fa-face-disappointed text-icons-red"></i>';
        } else if (satisfaction === 2) {
          iconHtml =
            '<i class="fa fa-face-frown-slight text-icons-lightred"></i>';
        } else if (satisfaction === 3) {
          iconHtml = '<i class="fa fa-face-meh text-icons-yellow"></i>';
        } else if (satisfaction === 4) {
          iconHtml = '<i class="fa fa-face-smile text-icons-lightgreen"></i>';
        } else if (satisfaction === 5) {
          iconHtml = '<i class="fa fa-face-grin-wide text-icons-green"></i>';
        }
        return iconHtml;
      });
    // .transition()
    // .ease(d3.easeLinear)
    // .duration(500)
    // .attr('y', (d: any) => {
    //   return y(d.satisfaction) - 10;
    // });
  }
}
