<section class="w-screen px-3 h-full flex items-center justify-between">
  <div class="flex gap-x-4">
    <a
      class="flex text-xl z-20 items-center rounded-[5px] text-black justify-center"
      [ngClass]="{ 'text-white bg-filtershover': isSettingsPage() }">
      <button routerLink="/logout" class="side-nav-btn">
        <i class="fa-thin fa-arrow-right-from-bracket fa-flip-horizontal"></i>
      </button>
    </a>
    <!--    <a-->
    <!--      class="flex text-xl z-20 items-center rounded-[5px] text-black justify-center">-->
    <!--      <button class="side-nav-btn" id="newsfeed-launcher">-->
    <!--        <i-->
    <!--          [matBadge]="unreadCount"-->
    <!--          matBadgeSize="small"-->
    <!--          matBadgePosition="above after"-->
    <!--          [matBadgeHidden]="unreadCount === 0"-->
    <!--          class="fa-thin fa-bell"></i>-->
    <!--      </button>-->
    <!--    </a>-->
    <!--    <a-->
    <!--      class="flex text-xl rounded-[5px] text-black items-center justify-center z-10"-->
    <!--      [cdkMenuTriggerFor]="helpMenu"-->
    <!--      routerLinkActive="text-white bg-filtershover">-->
    <!--      <button class="side-nav-btn">-->
    <!--        <i class="fa-thin fa-circle-question"></i>-->
    <!--      </button>-->
    <!--    </a>-->
  </div>
  <!--  <button class="side-nav-btn text-xl">-->
  <!--    <i class="fa-thin fa-circle-ellipsis"></i>-->
  <!--  </button>-->
  <div class="flex gap-x-4">
    <a
      class="flex items-center rounded-md"
      routerLink="/life"
      routerLinkActive="text-white bg-filtershover"
      [matTooltip]="'Life' | translate"
      matTooltipPosition="right">
      <button class="side-nav-btn">
        <i class="fa-thin fa-bullseye fa-xl"></i>
      </button>
    </a>
    <a
      class="flex items-center rounded-md"
      routerLink="/activities"
      routerLinkActive="text-white bg-filtershover"
      [matTooltip]="'Activities' | translate"
      matTooltipPosition="right">
      <button class="side-nav-btn">
        <i class="fa-thin fa-clipboard-check fa-xl"></i>
      </button>
    </a>
  </div>
</section>

<ng-template #helpMenu>
  <div
    cdkMenu
    class="ml-12 flex flex-col shadow p-3 bg-white text-sm z-10000 text-menugray rounded-lg">
    <button cdkMenuItem (click)="openContactSupport()" class="popup-menu-btn">
      <i class="fa-regular fa-comment mr-4"></i>
      <span>{{ 'ContextMenuContactSupport' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openHelpCenter()" class="popup-menu-btn">
      <i class="fa-solid fa-globe mr-4"></i>
      <span>{{ 'ContextMenuHelpCenter' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openFAQ()" class="popup-menu-btn">
      <i class="fa-regular fa-comments mr-4"></i>
      <span>{{ 'ContextMenuFAQ' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openReportBug()" class="popup-menu-btn">
      <i class="fa-solid fa-bug mr-4"></i>
      <span>{{ 'ContextMenuReportBug' | translate }}</span>
    </button>
    <button cdkMenuItem (click)="openFeatureRequest()" class="popup-menu-btn">
      <i class="fa-light fa-gear mr-4"></i>
      <span>{{ 'ContextMenuFeatureRequest' | translate }}</span>
    </button>
  </div>
</ng-template>
