import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ILifeArea } from '@life/models/life-area';
import { concatMap, from } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LifeAreaService {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getLifeAreas() {
    return this.http.get<ILifeArea[]>(`${this.baseUrl}/lifearea`);
  }

  setLifeAreas(ids: string[]) {
    return this.http.post(`${this.baseUrl}/lifearea`, {
      ids,
    });
  }

  addCustomLifeArea(lifeArea: Partial<ILifeArea>) {
    return this.http.put(`${this.baseUrl}/lifearea`, lifeArea);
  }

  addLifeAreaFromTemplate(templateId: string) {
    return this.http.put(`${this.baseUrl}/lifearea/${templateId}`, {});
  }

  deleteLifeArea(lifeAreaId: string) {
    return this.http.delete(`${this.baseUrl}/lifearea/${lifeAreaId}`);
  }

  getLifeArea(lifeAreaId: string) {
    return this.http.get<ILifeArea>(`${this.baseUrl}/lifearea/${lifeAreaId}`);
  }

  addLifeAreaStateFromTemplate(templateId: string, stateId: string) {
    return this.http.put(
      `${this.baseUrl}/lifearea/${templateId}/state/${stateId}`,
      {}
    );
  }

  updateLifeAreaImportance(lifeAreaId: string, importance: number) {
    return this.http.post(
      `${this.baseUrl}/lifearea/${lifeAreaId}/importance/${importance}`,
      {}
    );
  }

  updateLifeAreaDescription(lifeArea: ILifeArea) {
    return this.http.post(
      `${this.baseUrl}/lifearea/${lifeArea.id}/description`,
      {
        description: lifeArea.description,
      }
    );
  }

  updateLifeAreaSortOrder(lifeAreas: ILifeArea[]) {
    return from(lifeAreas).pipe(
      concatMap(lifeArea =>
        this.http.patch(
          `${this.baseUrl}/lifearea/${lifeArea.id}/sortorder/${lifeArea.sortOrder}`,
          {}
        )
      ),
      toArray()
    );
  }

  updateLifeAreaSatisfaction(lifeAreaId: string, satisfaction: number) {
    return this.http.post(
      `${this.baseUrl}/lifearea/${lifeAreaId}/satisfaction/${satisfaction}`,
      {}
    );
  }
  updateLifeAreaStatesHidden(area: ILifeArea) {
    return this.http.post(
      `${this.baseUrl}/lifearea/${area.id}/states/toggle`,
      {}
    );
  }

  updateLifeAreaName(lifeArea: ILifeArea) {
    return this.http.patch(
      `${this.baseUrl}/lifearea/${lifeArea.id}/name/${lifeArea.name}`,
      {}
    );
  }

  updateLifeAreaIcon(lifeArea: ILifeArea) {
    return this.http.patch(
      `${this.baseUrl}/lifearea/${lifeArea.id}/icon/${lifeArea.icon}`,
      {}
    );
  }

  setLifeAreaColor(lifeArea: ILifeArea) {
    return this.http.patch(`${this.baseUrl}/lifearea/${lifeArea.id}/colors`, {
      darkColor: lifeArea.darkColor,
      lightColor: lifeArea.lightColor,
      mediumColor: lifeArea.mediumColor,
    });
  }
}
