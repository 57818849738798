<header
  *ngrxLet="displayLifeWheel() as displayLifeWheel"
  class="life-header h-auto md:h-16 max-w-full flex flex-wrap items-center justify-between px-1 md:px-5 text-lightgray relative">
  <div
    class="flex gap-x-2.5 w-full md:w-auto justify-center md:justify-start py-2 md:py-0">
    <a
      class="z-20 transition-colors duration-300 hover:text-filtershover"
      [routerLink]="['/', activeSegment]"
      [routerLinkActiveOptions]="{
        paths: 'exact',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored'
      }"
      routerLinkActive="text-black hover:text-black">
      <span class="mr-3">{{ 'LifeHeaderOverview' | translate }}</span>
    </a>
    <a
      *ngIf="displayLifeWheel"
      class="z-20 transition-colors duration-300 hover:text-filtershover"
      [routerLink]="['/', activeSegment, 'wheel']"
      [routerLinkActiveOptions]="{
        paths: 'exact',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored'
      }"
      routerLinkActive="text-black">
      <span class="text-opacity-50 mr-3">{{
        'LifeHeaderLifeWheel' | translate
      }}</span>
    </a>
    <a
      *ngIf="!displayLifeWheel"
      (click)="upsale()"
      class="z-20 cursor-pointer transition-colors duration-300 hover:text-filtershover">
      <span class="text-opacity-50 mr-3">{{
        'LifeHeaderLifeWheel' | translate
      }}</span>
    </a>
    <a
      class="z-20 transition-colors duration-300 hover:text-filtershover"
      [routerLink]="['/', activeSegment, 'priority']"
      [routerLinkActiveOptions]="{
        paths: 'exact',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored'
      }"
      routerLinkActive="text-black">
      <span class="text-opacity-50 mr-3">{{
        'LifeHeaderPriority' | translate
      }}</span>
    </a>
  </div>
  <div
    class="flex items-center text-white w-full md:w-auto py-1 md:py-0 justify-center md:justify-start">
    <hl-filters></hl-filters>
  </div>
  <div
    *ngIf="selectedDate() as selectedDate"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 rounded-md flex items-center px-2 bg-sidenavicon text-white">
    <h4>
      {{ 'LifeHeaderViewAtDate' | translate : { value: selectedDate | date } }}
      <button
        (click)="resetDateFilter()"
        class="bg-white text-sidenavicon mx-1 px-2 rounded">
        {{ 'LifeFilterCalendarReset' | translate }}
      </button>
    </h4>
  </div>
</header>
