<div
  class="flex items-center gap-x-1"
  [ngClass]="{
    '[&>.item:hover]:bg-gray-600 [&>.item:has(~.item:hover)]:bg-gray-600':
      !readonly
  }">
  <div
    [tabindex]="readonly ? -1 : 0"
    *ngFor="let rate of ratingItems"
    class="item text-gray-300"
    [ngClass]="[
      readonly
        ? 'fa-kit fa-holoflame text-sm cursor-auto'
        : 'hover:scale-125 rounded-full bg-gray-300 h-3 w-3 cursor-pointer'
    ]"
    [class.bg-gray-600]="!readonly && rate < rating"
    [class.text-gray-600]="readonly && rate < rating"
    (click)="updateRating.emit(rate + 1)"
    [class.bg-white]="
      !readonly &&
      rate >= rating &&
      (type === appConstants.lifeArea || inlineRating)
    "
    [class.text-white]="
      readonly && rate >= rating && type === appConstants.lifeArea
    "
    [class.bg-icons-gray]="
      !readonly && rate >= rating && type !== appConstants.lifeArea
    "
    [class.text-icons-gray]="
      readonly && rate >= rating && type !== appConstants.lifeArea
    "
    hlStopPropagation
    (keydown.enter)="updateRating.emit(rate + 1)"></div>
</div>
