<div
  class="flex flex-col"
  dragula="LIFE_ACTIVITIES"
  [dragulaModel]="activitiesList">
  <hl-activity
    data-sl="mask"
    *ngFor="let activity of activitiesList; trackBy: trackByActivityId"
    [activity]="activity"
    [defaultView]="true">
  </hl-activity>
</div>

<hl-autocomplete
  *ngIf="showAddActivity"
  class="block mb-20"
  placeholder="{{ 'AddNewLifeActivityPlaceholder' | translate }}"
  inputClass="bg-gray-100 py-4 px-6"
  [groupOptions]="availableActivities$()"
  [usedOptions]="usedActivities"
  (selectOption)="addNewActivity($event)">
</hl-autocomplete>
