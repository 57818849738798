<div class="mt-4 select-none">
  <div class="flex flex-col px-4 gap-y-1">
    <ng-container *ngIf="groupedBy === 'lifeArea'">
      <hl-life-area-activities
        *ngFor="let lifeArea of lifeAreas()"
        [lifeArea]="lifeArea"
        [lifeAreaActivities]="[]"></hl-life-area-activities>
    </ng-container>
    <ng-container *ngIf="groupedBy === ''">
      <hl-activities-list
        (addActivity)="addActivity($event)"
        [activitiesList]="lifeActivities()"></hl-activities-list>
    </ng-container>
    <ng-container *ngIf="groupedBy === 'partOfDay'">
      <hl-part-of-day-activities
        *ngFor="let partOfTheDay of staticPartOfDay"
        [partsOfDayWithActivities]="[]"></hl-part-of-day-activities>
    </ng-container>
  </div>
</div>
