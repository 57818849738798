import { createSelector } from '@ngrx/store';
import { lifeTemplatesFeatureKey } from '@life/reducers/life-templates.reducer';
import * as fromLifeArea from '@life/reducers/life-templates.reducer';
import { selectLifeFeatureState } from '@life/reducers';
import { selectLife$ } from '@life/selectors/life-area.selectors';
import { selectLifeActivities$ } from '@life/selectors/life-activity.selectors';

export const selectLifeTemplatesState = createSelector(
  selectLifeFeatureState,
  state => state[lifeTemplatesFeatureKey]
);

export const selectLifeTemplates$ = createSelector(
  selectLifeTemplatesState,
  fromLifeArea.selectLifeTemplates
);
export const selectLifeTemplatesEntities$ = createSelector(
  selectLifeTemplatesState,
  fromLifeArea.selectLifeTemplatesEntities
);

export const selectLifeTemplatesId$ = createSelector(
  selectLifeTemplatesState,
  fromLifeArea.selectLifeTemplatesIds
);
export const selectAvailableLifeAreas$ = createSelector(
  selectLifeTemplates$,
  selectLife$,
  (allLifeAreas, allLife) => {
    const used = [];
    const available = [];
    for (const lifeArea of allLifeAreas) {
      if (allLife.find(la => la.name === lifeArea.name)) {
        used.push(lifeArea);
      } else {
        available.push(lifeArea);
      }
    }
    return {
      available: available.map(lifeArea => ({
        label: lifeArea.name,
        item: lifeArea,
      })),
      used: used.map(lifeArea => lifeArea.name),
    };
  }
);

export const selectAvailableActivities$ = createSelector(
  selectLifeActivities$,
  selectLifeTemplates$,
  selectLife$,
  (lifeActivities, lifeTemplates, life) => {
    return lifeTemplates.map(lifeTemplate => {
      return {
        label: lifeTemplate.name,
        darkColor: lifeTemplate.darkColor,
        lightColor: lifeTemplate.lightColor,
        icon: lifeTemplate.icon,
        items: lifeTemplate.activities
          .filter(
            activity => !lifeActivities.find(la => la.name === activity.name)
          )
          .map(a => ({
            label: a.name,
            item: {
              ...a,
              lifeAreaId: life.find(la => la.name === lifeTemplate.name)?.id,
            },
          })),
      };
    });
  }
);
