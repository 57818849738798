import { ILifeState } from '@life/models/life-state';
import { ILifeArea } from '@life/models/life-area';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { ILifeActivity } from '@life/models/life-activity';

export function calculateUrgency(
  element: ILifeState | ILifeArea | ILifeSpecifics | ILifeActivity
) {
  if (element.satisfaction === 0) {
    return 0;
  } else {
    return (
      Math.round(
        (1 +
          (4 * (1.2 * element.importance - element.satisfaction + 4)) / 8 -
          0.5) *
          2
      ) / 2
    );
  }
}

export function formatTimeString(inputTime: string) {
  // Assuming inputTime is in the format "hh:mm"
  const [hours, minutes] = inputTime.split(':');

  // Create a new Date object with a dummy date (e.g., 2000-01-01)
  const dummyDate = new Date('2000-01-01T00:00:00');

  // Set the hours and minutes of the dummy date
  dummyDate.setUTCHours(+hours);
  dummyDate.setUTCMinutes(+minutes);

  const formattedTime = `${String(dummyDate.getUTCHours()).padStart(
    2,
    '0'
  )}:${String(dummyDate.getUTCMinutes()).padStart(2, '0')}:00.00`;

  return formattedTime;
}
