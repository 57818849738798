import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';
@Component({
  selector: 'hl-icon-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, CdkMenuModule],
  templateUrl: './icon-select.component.html',
})
export class IconSelectComponent implements OnInit {
  @Input() icons: string[];
  @Output() selectIcon = new EventEmitter<string>();

  search = new FormControl('');
  filteredIcons: any[];

  ngOnInit() {
    this.filteredIcons = this.hardCodedIcons;
    this.search.valueChanges.subscribe(res => {
      if (res) {
        this.filteredIcons = this.hardCodedIcons
          .map((item: any) => {
            const filteredIcons = item.icons.filter((icon: any) => {
              return icon.name.toLowerCase().indexOf(res.toLowerCase()) != -1;
            });
            if (filteredIcons.length > 0) {
              return { title: item.title, icons: filteredIcons };
            }
            return null;
          })
          .filter(item => item !== null);
      } else {
        this.filteredIcons = this.hardCodedIcons;
      }
    });
  }

  hardCodedIcons = [
    {
      title: 'Activities',
      icons: [
        { name: 'fa-bed', description: '' },
        { name: 'fa-alarm-clock', description: '' },
        { name: 'fa-shower', description: '' },
        { name: 'fa-toothbrush', description: '' },
        { name: 'fa-hands-bubbles', description: '' },
        { name: 'fa-mustache', description: '' },
        { name: 'fa-shirt', description: '' },
        { name: 'fa-bath', description: '' },
        { name: 'fa-couch', description: '' },
        { name: 'fa-head-side-medical', description: '' },
        { name: 'fa-person-praying', description: '' },
      ],
    },
    {
      title: 'Chores',
      icons: [
        { name: 'fa-cart-shopping', description: '' },
        { name: 'fa-broom-wide', description: '' },
        { name: 'fa-vacuum', description: '' },
        { name: 'fa-trash', description: '' },
        { name: 'fa-hose', description: '' },
        { name: 'fa-washing-machine', description: '' },
        { name: 'fa-car-wash', description: '' },
        { name: 'fa-car-wrench', description: '' },
        { name: 'fa-screwdriver-wrench', description: '' },
      ],
    },
    {
      title: 'Food',
      icons: [
        { name: 'fa-plate-utensils', description: '' },
        { name: 'fa-hat-chef', description: '' },
        { name: 'fa-user-chef', description: '' },
        { name: 'fa-grill-hot', description: '' },
        { name: 'fa-glass-water', description: '' },
        { name: 'fa-mug-tea', description: '' },
        { name: 'fa-coffee-bean', description: '' },
        { name: 'fa-mug-hot', description: '' },
        { name: 'fa-wine-bottle', description: '' },
        { name: 'fa-martini-glass', description: '' },
        { name: 'fa-carrot', description: '' },
        { name: 'fa-salad', description: '' },
        { name: 'fa-apple-whole', description: '' },
        { name: 'fa-banana', description: '' },
        { name: 'fa-cherries', description: '' },
        { name: 'fa-lemon', description: '' },
        { name: 'fa-bowl-hot', description: '' },
        { name: 'fa-wheat-slash', description: '' },
        { name: 'fa-cheese-swiss', description: '' },
        { name: 'fa-fish', description: '' },
        { name: 'fa-meat', description: '' },
        { name: 'fa-pizza-slice', description: '' },
        { name: 'fa-burger-fries', description: '' },
        { name: 'fa-cupcake', description: '' },
      ],
    },
    {
      title: 'Health',
      icons: [
        { name: 'fa-heart', description: '' },
        { name: 'fa-heart-pulse', description: '' },
        { name: 'fa-hand-holding-heart', description: '' },
        { name: 'fa-face-head-bandage', description: '' },
        { name: 'fa-lungs', description: '' },
        { name: 'fa-kidneys', description: '' },
        { name: 'fa-stomach', description: '' },
        { name: 'fa-tooth', description: '' },
        { name: 'fa-eye', description: '' },
        { name: 'fa-nose', description: '' },
        { name: 'fa-ear', description: '' },
        { name: 'fa-teeth', description: '' },
        { name: 'fa-lips', description: '' },
        { name: 'fa-skeleton', description: '' },
        { name: 'fa-bone', description: '' },
        { name: 'fa-hand', description: '' },
        { name: 'fa-weight-scale', description: '' },
        { name: 'fa-dna', description: '' },
        { name: 'fa-viruses', description: '' },
        { name: 'fa-stethoscope', description: '' },
        { name: 'fa-briefcase-medical', description: '' },
        { name: 'fa-syringe', description: '' },
        { name: 'fa-pills', description: '' },
        { name: 'fa-smoking', description: '' },
        { name: 'fa-ban-smoking', description: '' },
      ],
    },
    {
      title: 'Communication',
      icons: [
        { name: 'fa-phone', description: '' },
        { name: 'fa-thought-bubble', description: '' },
        { name: 'fa-comment', description: '' },
        { name: 'fa-comment-question', description: '' },
        { name: 'fa-comments', description: '' },
        { name: 'fa-inbox', description: '' },
        { name: 'fa-envelope', description: '' },
        { name: 'fa-thumbs-up', description: '' },
        { name: 'fa-thumbs-down', description: '' },
        { name: 'fa-hand-fist', description: '' },
      ],
    },
    {
      title: 'Education',
      icons: [
        { name: 'fa-highlighter', description: '' },
        { name: 'fa-book-font', description: '' },
        { name: 'fa-book-open-reader', description: '' },
        { name: 'fa-book-open-cover', description: '' },
        { name: 'fa-language', description: '' },
        { name: 'fa-user-graduate', description: '' },
        { name: 'fa-diploma', description: '' },
      ],
    },
    {
      title: 'People',
      icons: [
        { name: 'fa-user-vneck-hair', description: '' },
        { name: 'fa-user-vneck-hair-long', description: '' },
        { name: 'fa-user-tie-hair', description: '' },
        { name: 'fa-user-tie-hair-long', description: '' },
        { name: 'fa-person', description: '' },
        { name: 'fa-person-dress', description: '' },
        { name: 'fa-users', description: '' },
        { name: 'fa-head-side', description: '' },
        { name: 'fa-street-view', description: '' },
        { name: 'fa-user-group', description: '' },
        { name: 'fa-handshake-simple', description: '' },
        { name: 'fa-person-to-portal', description: '' },
        { name: 'fa-person-from-portal', description: '' },
        { name: 'fa-rings-wedding', description: '' },
        { name: 'fa-family', description: '' },
        { name: 'fa-baby', description: '' },
      ],
    },
    {
      title: 'Hobbies',
      icons: [
        { name: 'fa-bowling-ball-pin', description: '' },
        { name: 'fa-pool-8-ball', description: '' },
        { name: 'fa-chess-knight-piece', description: '' },
        { name: 'fa-cards', description: '' },
        { name: 'fa-dice', description: '' },
        { name: 'fa-fishing-rod', description: '' },
        { name: 'fa-golf-flag-hole', description: '' },
        { name: 'fa-ticket', description: '' },
        { name: 'fa-masks-theater', description: '' },
        { name: 'fa-sailboat', description: '' },
        { name: 'fa-kite', description: '' },
        { name: 'fa-person-sledding', description: '' },
        { name: 'fa-mask-snorkel', description: '' },
        { name: 'fa-campground', description: '' },
        { name: 'fa-puzzle-piece', description: '' },
        { name: 'fa-paintbrush-pencil', description: '' },
        { name: 'fa-game-console-handheld', description: '' },
        { name: 'fa-bench-tree', description: '' },
        { name: 'fa-person-snowmobiling', description: '' },
        { name: 'fa-flower-tulip', description: '' },
        { name: 'fa-telescope', description: '' },
      ],
    },
    {
      title: 'Sport',
      icons: [
        { name: 'fa-person-running', description: '' },
        { name: 'fa-person-hiking', description: '' },
        { name: 'fa-person-biking', description: '' },
        { name: 'fa-person-biking-mountain', description: '' },
        { name: 'fa-basketball', description: '' },
        { name: 'fa-baseball-bat-ball', description: '' },
        { name: 'fa-football', description: '' },
        { name: 'fa-hockey-sticks', description: '' },
        { name: 'fa-futbol', description: '' },
        { name: 'fa-dumbbell', description: '' },
        { name: 'fa-tennis-ball', description: '' },
        { name: 'fa-racquet', description: '' },
        { name: 'fa-table-tennis-paddle-ball', description: '' },
        { name: 'fa-person-swimming', description: '' },
        { name: 'fa-person-skiing', description: '' },
        { name: 'fa-person-snowboarding', description: '' },
        { name: 'fa-person-skiing-nordic', description: '' },
        { name: 'fa-person-skating', description: '' },
        { name: 'fa-bow-arrow', description: '' },
        { name: 'fa-badminton', description: '' },
        { name: 'fa-volleyball', description: '' },
        { name: 'fa-uniform-martial-arts', description: '' },
        { name: 'fa-boxing-glove', description: '' },
        { name: 'fa-horse-saddle', description: '' },
        { name: 'fa-trophy', description: '' },
        { name: 'fa-medal', description: '' },
      ],
    },
    {
      title: 'Media',
      icons: [
        { name: 'fa-music', description: '' },
        { name: 'fa-headphones', description: '' },
        { name: 'fa-guitar', description: '' },
        { name: 'fa-guitar-electric', description: '' },
        { name: 'fa-piano', description: '' },
        { name: 'fa-saxophone', description: '' },
        { name: 'fa-microphone', description: '' },
        { name: 'fa-turntable', description: '' },
        { name: 'fa-pencil', description: '' },
        { name: 'fa-typewriter', description: '' },
        { name: 'fa-book', description: '' },
        { name: 'fa-camera', description: '' },
        { name: 'fa-podcast', description: '' },
        { name: 'fa-camera-movie', description: '' },
        { name: 'fa-film', description: '' },
        { name: 'fa-palette', description: '' },
        { name: 'fa-image', description: '' },
        { name: 'fa-gamepad-modern', description: '' },
        { name: 'fa-newspaper', description: '' },
        { name: 'fa-mobile-screen-button', description: '' },
        { name: 'fa-laptop', description: '' },
        { name: 'fa-desktop', description: '' },
        { name: 'fa-tablet-screen', description: '' },
      ],
    },
    {
      title: 'Objects',
      icons: [
        { name: 'fa-briefcase', description: '' },
        { name: 'fa-box-archive', description: '' },
        { name: 'fa-folder-open', description: '' },
        { name: 'fa-clipboard-list', description: '' },
        { name: 'fa-clipboard-question', description: '' },
        { name: 'fa-bookmark', description: '' },
        { name: 'fa-list-radio', description: '' },
        { name: 'fa-key', description: '' },
        { name: 'fa-glasses', description: '' },
        { name: 'fa-sunglasses', description: '' },
        { name: 'fa-watch', description: '' },
        { name: 'fa-magnifying-glass', description: '' },
        { name: 'fa-thumbtack', description: '' },
        { name: 'fa-globe-stand', description: '' },
        { name: 'fa-badge-check', description: '' },
        { name: 'fa-tally', description: '' },
        { name: 'fa-calculator', description: '' },
        { name: 'fa-mask', description: '' },
        { name: 'fa-scale-balanced', description: '' },
        { name: 'fa-signs-post', description: '' },
        { name: 'fa-map-location-dot', description: '' },
        { name: 'fa-flag', description: '' },
        { name: 'fa-gear', description: '' },
        { name: 'fa-lightbulb-on', description: '' },
        { name: 'fa-hourglass-start', description: '' },
        { name: 'fa-bell', description: '' },
        { name: 'fa-cake-candles', description: '' },
        { name: 'fa-gift', description: '' },
        { name: 'fa-balloons', description: '' },
        { name: 'fa-teddy-bear', description: '' },
        { name: 'fa-wand-magic-sparkles', description: '' },
        { name: 'fa-scissors', description: '' },
        { name: 'fa-signature', description: '' },
        { name: 'fa-fingerprint', description: '' },
        { name: 'fa-atom-simple', description: '' },
        { name: 'fa-battery-low', description: '' },
        { name: 'fa-battery-bolt', description: '' },
      ],
    },
    {
      title: 'Money',
      icons: [
        { name: 'fa-money-bill', description: '' },
        { name: 'fa-credit-card', description: '' },
        { name: 'fa-dollar-sign', description: '' },
        { name: 'fa-sack-dollar', description: '' },
        { name: 'fa-piggy-bank', description: '' },
        { name: 'fa-chart-pie', description: '' },
        { name: 'fa-chart-simple', description: '' },
        { name: 'fa-signal', description: '' },
        { name: 'fa-chart-mixed', description: '' },
        { name: 'fa-chart-network', description: '' },
      ],
    },
    {
      title: 'Transport',
      icons: [
        { name: 'fa-bicycle', description: '' },
        { name: 'fa-moped', description: '' },
        { name: 'fa-motorcycle', description: '' },
        { name: 'fa-car', description: '' },
        { name: 'fa-van-shuttle', description: '' },
        { name: 'fa-rv', description: '' },
        { name: 'fa-taxi-bus', description: '' },
        { name: 'fa-bus-school', description: '' },
        { name: 'fa-train', description: '' },
        { name: 'fa-sailboat', description: '' },
        { name: 'fa-ferry', description: '' },
        { name: 'fa-ship', description: '' },
        { name: 'fa-plane', description: '' },
        { name: 'fa-plane-slash', description: '' },
        { name: 'fa-rocket', description: '' },
        { name: 'fa-compass', description: '' },
        { name: 'fa-earth-americas', description: '' },
      ],
    },
    {
      title: 'Building',
      icons: [
        { name: 'fa-shop', description: '' },
        { name: 'fa-landmark', description: '' },
        { name: 'fa-hospital', description: '' },
        { name: 'fa-church', description: '' },
        { name: 'fa-school-flag', description: '' },
        { name: 'fa-city', description: '' },
      ],
    },
    {
      title: 'Shapes',
      icons: [
        { name: 'fa-shapes', description: '' },
        { name: 'fa-circle', description: '' },
        { name: 'fa-circle-half-stroke', description: '' },
        { name: 'fa-circles-overlap', description: '' },
        { name: 'fa-diagram-venn', description: '' },
        { name: 'fa-triangle', description: '' },
        { name: 'fa-play', description: '' },
        { name: 'fa-square', description: '' },
        { name: 'fa-object-union', description: '' },
        { name: 'fa-object-subtract', description: '' },
        { name: 'fa-object-intersect', description: '' },
        { name: 'fa-object-exclude', description: '' },
        { name: 'fa-table-cells-large', description: '' },
        { name: 'fa-grid-2', description: '' },
        { name: 'fa-diamond', description: '' },
        { name: 'fa-shield', description: '' },
        { name: 'fa-shield-halved', description: '' },
        { name: 'fa-shield-quartered', description: '' },
        { name: 'fa-rectangles-mixed', description: '' },
        { name: 'fa-objects-column', description: '' },
        { name: 'fa-cube', description: '' },
        { name: 'fa-layer-group', description: '' },
        { name: 'fa-dice-d6', description: '' },
        { name: 'fa-cubes', description: '' },
      ],
    },
    {
      title: 'Nature',
      icons: [
        { name: 'fa-dog', description: '' },
        { name: 'fa-cat', description: '' },
        { name: 'fa-turtle', description: '' },
        { name: 'fa-flower', description: '' },
        { name: 'fa-trees', description: '' },
        { name: 'fa-tree-palm', description: '' },
        { name: 'fa-tree-decorated', description: '' },
        { name: 'fa-temperature-half', description: '' },
        { name: 'fa-sun', description: '' },
        { name: 'fa-snowflake', description: '' },
        { name: 'fa-wind', description: '' },
        { name: 'fa-star-sharp', description: '' },
        { name: 'fa-moon', description: '' },
        { name: 'fa-sparkles', description: '' },
      ],
    },
  ];
}
