import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ILifeActivity } from '@life/models/life-activity';

export const OgActivityActions = createActionGroup({
  source: 'Life Activity',
  events: {
    noop: emptyProps(),
    loadActivities: props<{ lifeFrom: string | null; userId?: string }>(),
    reloadActivities: emptyProps(),
    setActivitiesSortOrder: props<{ activities: ILifeActivity[] }>(),
    setActivitiesSortOrderSuccess: props<{ activities: ILifeActivity[] }>(),
    setActivitiesSortOrderFail: props<{ error: string }>(),
    loadActivitiesSuccess: props<{ activities: ILifeActivity[] }>(),
    reLoadActivitiesSuccess: props<{ activities: ILifeActivity[] }>(),
    loadActivitiesFail: props<{ error: string }>(),
    addActivity: props<{ activity: Partial<ILifeActivity> }>(),
    addActivitySuccess: props<{ activity: ILifeActivity }>(),
    addActivityFail: props<{ error: string }>(),
    selectActivity: props<{ activityId: string }>(),
    loadActivity: props<{ activityId: string }>(),
    loadActivitySuccess: props<{ activity: ILifeActivity }>(),
    loadActivityFail: props<{ error: string }>(),
    deleteActivity: props<{ activity: ILifeActivity }>(),
    deleteActivitySuccess: props<{ activity: ILifeActivity }>(),
    deleteActivityFail: props<{ error: string }>(),
    moveActivity: props<{ activity: ILifeActivity; lifeAreaId: string }>(),
    setActivityFocus: props<{ activity: ILifeActivity }>(),
    setActivityReminders: props<{ activity: ILifeActivity }>(),
    setActivityDescription: props<{ activity: ILifeActivity }>(),
    setActivityImportance: props<{ activity: ILifeActivity }>(),
    setActivityName: props<{ activity: ILifeActivity }>(),
    setActivityNameSuccess: props<{ activity: ILifeActivity }>(),
    setActivityNameFail: props<{ error: string }>(),
    actuallySetActivityName: props<{ activity: ILifeActivity }>(),
    setActivitySatisfaction: props<{ activity: ILifeActivity }>(),
    setActivityIcon: props<{ activity: ILifeActivity }>(),
    setActivityIconSuccess: props<{ activity: ILifeActivity }>(),
    setActivityIconFail: props<{ error: string }>(),
    setActivityDayTime: props<{ activity: ILifeActivity }>(),
    updateActivity: props<{
      activity: ILifeActivity;
      prop:
        | 'iwantto'
        | 'when'
        | 'sessionperday'
        | 'timerequired'
        | 'energy'
        | 'startend'
        | 'toggleonside';
      payload: any;
    }>(),
    updateActivityQuantity: props<{
      activity: ILifeActivity;
      number: number;
      unit: number;
      countUnit: number;
    }>(),
    updateActivitySuccess: props<{ activity: ILifeActivity }>(),
    updateActivityFail: props<{ error: string }>(),
    removeActivityFromLifeState: props<{
      activityId: string;
      lifeStateId: string;
    }>(),
    addActivityToLifeState: props<{
      activityId: string;
      lifeStateId: string;
    }>(),
    removeActivityFromLifeSpecific: props<{
      activityId: string;
      lifeSpecificId: string;
    }>(),
    addActivityToLifeSpecific: props<{
      activityId: string;
      lifeSpecificId: string;
    }>(),
  },
});
