<div>
  <div
    [style.outline-color]="
      activity.isHighlighted
        ? activity.darkColor || '#4d4d4d'
        : activity.mediumColor || '#A6A6A6'
    "
    style="outline-offset: -3px"
    class="drag-handle life-activity-container cursor-pointer bg-gray-100 rounded-t-md rounded-bl-md mb-1 hover:outline -outline-offset-[3px] rounded-md relative flex flex-wrap justify-between items-center px-3 py-1 md:py-0 h-12 md:h-12 z-10"
    [ngClass]="{
      'outline -outline-offset-[3px]': activity.isHighlighted
    }"
    (click)="selectActivity(activity)"
    (keydown.enter)="selectActivity(activity)"
    [style.background-color]="activity.lightColor || '#f1f3f6'"
    hlStopPropagation
    tabindex="0">
    <div class="flex flex-1 items-center gap-x-3 h-full max-w-full">
      <i
        class="fa-solid w-5"
        [cdkMenuTriggerFor]="iconMenu"
        [ngClass]="activity.icon"
        [style.color]="activity.darkColor"></i>
      <span
        class="text-sm max-w-[80px] md:max-w-full line-clamp-1"
        [ngStyle]="{ color: activity.darkColor }">
        <hl-inline-edit
          [editable]="canRename"
          (setValue)="rename($event)"
          (cancel)="canRename = false"
          [value]="activity.name"></hl-inline-edit
      ></span>
      <button
        class="w-4 h-4 text-[10px] rounded-[50%] text-white bg-filtershover hidden md:flex items-center justify-center flex-grow-0 flex-shrink-0"
        hlStopPropagation
        [ngClass]="{
          'pointer-events-none': activity.readonly
        }"
        [style.background-color]="activity.darkColor"
        [cdkMenuTriggerFor]="menu">
        <i class="fa-solid fa-ellipsis"></i>
      </button>
      <div class="flex items-center h-full md:pr-2 flex-1"></div>
    </div>
    <div
      hlStopPropagation
      [ngClass]="{
        'pointer-events-none': activity.readonly
      }"
      class="flex pl-2 cursor-default h-full items-center gap-x-3 md:gap-x-6 justify-end md:justify-start">
      <hl-activity-mapper
        [title]="'ConnectedVisionElementsTitle' | translate"
        [matTooltip]="'TooltipCriteria' | translate"
        [matTooltipShowDelay]="1500"
        data-sl="exclude"
        class="hidden md:block"
        [color]="activity.darkColor"
        [specifics]="activity.specifics"
        [states]="activity.states"
        (addNew)="selectActivity(activity, 1, true)" />
      <hl-rating
        [matTooltip]="'TooltipImportance' | translate"
        [matTooltipShowDelay]="1500"
        [rating]="activity.importance"
        [type]="'lifeArea'"
        (updateRating)="updateLifeActivityImportance($event)"></hl-rating>
      <hl-rating-select
        [matTooltip]="'TooltipSatisfaction' | translate"
        [matTooltipShowDelay]="1500"
        [satisfactionZeroClass]="'text-icons-gray'"
        [satisfaction]="activity.satisfaction"
        (setSatisfaction)="
          updateLifeActivitySatisfaction($event)
        "></hl-rating-select>
      <hl-rating
        class="hidden md:block"
        [matTooltip]="'TooltipUrgency' | translate"
        [type]="'lifeArea'"
        [matTooltipShowDelay]="1500"
        [rating]="activity.urgency"
        [readonly]="true"></hl-rating>
      <hl-is-favorite
        [matTooltip]="'TooltipFocus' | translate"
        [matTooltipShowDelay]="1500"
        [isFavorite]="activity.isFocused"
        [notFavoriteWhiteBg]="true"
        (toggleFavourite)="updateLifeActivityFocused($event)"></hl-is-favorite>
    </div>
  </div>
</div>
<ng-template #iconMenu>
  <div cdkMenu class="popup-menu p-0 shadow-none">
    <hl-icon-select
      [icons]="[]"
      (selectIcon)="updateLifeActivityIcon($event)"></hl-icon-select>
  </div>
</ng-template>

<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="canRename = true"
      class="popup-menu-btn">
      <i class="w-3 fa-regular fa-pen mr-4"></i>
      <span> {{ 'ContextMenuRename' | translate }}</span>
    </button>
    <button
      [cdkMenuTriggerFor]="iconMenu"
      cdkMenuItem
      hlStopPropagation
      class="popup-menu-btn justify-between">
      <div>
        <i class="w-3 mr-4 fa-solid" [ngClass]="activity.icon"></i>
        <span> {{ 'ContextMenuChangeIcon' | translate }}</span>
      </div>
      <i class="fa-solid fa-arrow-right"></i>
    </button>
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="deleteActivity()"
      class="popup-menu-btn-trash">
      <i class="w-3 fa-solid fa-trash mr-4"></i>
      <span> {{ 'ContextMenuDelete' | translate }}</span>
    </button>
  </div>
</ng-template>
