import { createActionGroup, props } from '@ngrx/store';
import { ILifeNote } from '@life/models/life-note';
export const OgLifeNoteActions = createActionGroup({
  source: 'Life Note',
  events: {
    loadLifeAreaNotes: props<{
      lifeAreaId: string;
      lifeFrom?: number | null | undefined;
    }>(),
    loadLifeActivityNotes: props<{
      activityId: string;
      lifeFrom?: number | null | undefined;
    }>(),
    loadLifeStateNotes: props<{
      lifeStateId: string;
      lifeFrom?: number | null | undefined;
    }>(),
    loadLifeSpecificNotes: props<{
      lifeSpecificId: string;
      lifeFrom?: number | null | undefined;
    }>(),
    loadNotesSuccess: props<{ notes: ILifeNote[] }>(),
    loadNotesFail: props<{ error: string }>(),
    addNote: props<{
      note: Partial<ILifeNote>;
    }>(),
    addNoteSuccess: props<{
      note: ILifeNote;
    }>(),
    addNoteFail: props<{ error: string }>(),
    updateNoteFocus: props<{ note: ILifeNote }>(),
    updateNote: props<{ note: ILifeNote }>(),
    updateNoteSuccess: props<{ note: ILifeNote }>(),
    updateNoteFail: props<{ error: string }>(),
    deleteNote: props<{ noteId: string }>(),
    deleteNoteSuccess: props<{ noteId: string }>(),
    deleteNoteFail: props<{ error: string }>(),
  },
});
