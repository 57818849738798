<div
  class="fixed z-20"
  data-sl="mask"
  *ngIf="toolTipTmp && activeItem"
  [ngStyle]="ttPos">
  <ng-container
    *ngTemplateOutlet="
      toolTipTmp.tmp;
      context: { $implicit: activeItem, hide: clearActiveItem.bind(this) }
    "></ng-container>
</div>
<div
  #chart
  data-sl="mask"
  class="chart w-full"
  [class.active-item]="hasActiveGroup"></div>
