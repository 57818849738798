import { createSelector } from '@ngrx/store';
import * as fromLife from '@life/reducers/life-area.reducer';
import { selectLifeFeatureState } from '@life/reducers';
import { selectLifeSpecificsEntities$ } from '@life/selectors/life-specifics.selectors';
import {
  selectLifeStateIds$,
  selectLifeStates$,
  selectLifeStatesEntities$,
} from '@life/selectors/life-state.selectors';
import { ILifeArea } from '@life/models/life-area';
import * as fromRoot from '@app/reducers';
import { ILifeState } from '@life/models/life-state';
import { selectLifeFilters$ } from '@life/selectors/life-filters.selectors';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { selectLifeNoteEntities$ } from '@life/selectors/life-notes.selectors';
import { ElementType } from '@life/models/element.type';
import { lifeAreaFeatureKey } from '@life/reducers/life-area.reducer';
import {
  selectLifeActivities$,
  selectLifeActivitiesEntities$,
} from '@life/selectors/life-activity.selectors';
import { ILifeActivity } from '@life/models/life-activity';
import { ILifeFilter } from '@life/models/life-filter';
import {
  isFilterApplied,
  isFilterHit,
  isSearchFilterApplied,
  isSearchHit,
  mapActivitiesFilterValues,
} from '@life/life.utils';
import { selectUserState } from '@core/selectors/user.selectors';
export const selectLifeState = createSelector(
  selectLifeFeatureState,
  state => state[lifeAreaFeatureKey]
);

export const selectLife$ = createSelector(
  selectLifeState,
  fromLife.selectLifeAreas
);
export const selectLifeEntities$ = createSelector(
  selectLifeState,
  fromLife.selectLifeAreaEntities
);

export const selectLifeIds$ = createSelector(
  selectLifeState,
  fromLife.selectLifeAreaIds
);

export const selectLifeDragElement$ = createSelector(
  selectLifeState,
  life => life.activeDragElement
);

export const selectLifeLoaded$ = createSelector(
  selectLifeState,
  life => life.loaded
);

export const selectMaxLifeAreas$ = createSelector(
  selectUserState,
  userState => userState.lifeAreasCount
);

export const selectLifeDates$ = createSelector(selectLifeState, life => ({
  startDate: new Date(life.startDate as string),
  changeDates: life.changeDates.map(dt => new Date(dt)),
  resetProgressDate: new Date(life.resetProgressDate as string),
}));

export const selectLifeAreas$ = createSelector(
  selectLife$,
  selectMaxLifeAreas$,
  (lifeAreas, maxLifeAreas) => {
    return lifeAreas
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((la, index) => ({
        ...la,
        disabled: index >= maxLifeAreas,
      }));
  }
);

export const selectLifeStartDate$ = createSelector(
  selectLifeState,
  life => new Date(life.startDate as string)
);

export const selectCheckIfLifeAreaNameExists$ = (lifeAreaName: string) =>
  createSelector(selectLife$, life => {
    const lifeArea = life.find(
      area =>
        area.name.trim().toLowerCase() === lifeAreaName.trim().toLowerCase()
    );
    return !!lifeArea;
  });

export const selectLifeAreaStateNames$ = (lifeAreaId: string) =>
  createSelector(
    selectLifeEntities$,
    selectLifeStatesEntities$,
    (lifeEntities, lifeStateEntities) => {
      const lifeArea = lifeEntities[lifeAreaId];

      if (lifeArea) {
        return (
          lifeArea.stateIds
            .filter(id => !!lifeStateEntities[id])
            // @ts-ignore
            .map(id => lifeStateEntities[id].name)
        );
      } else {
        return [];
      }
    }
  );

export const selectLifeUI$ = createSelector(
  selectLifeAreas$,
  selectLifeStatesEntities$,
  selectLifeStateIds$,
  selectLifeSpecificsEntities$,
  selectLifeFilters$,
  selectLifeActivitiesEntities$,
  fromRoot.selectQueryParams,
  (
    life,
    lifeStateEntities,
    lifeStateIds,
    lifeSpecificsEntities,
    lifeFilters,
    lifeActivityEntities,
    queryParams
  ) => {
    const { activeItemType, activeItemId } = queryParams;

    return life
      .map(lifeArea => {
        const states = lifeArea.stateIds
          .filter(id => !!lifeStateEntities[id])
          .map(id => {
            return {
              ...lifeStateEntities[id],
              isHighlighted: activeItemType === 'state' && activeItemId === id,
              readonly: !!lifeFilters.selectedDate,
              isChildSearchHit:
                isSearchHit(lifeFilters.search, lifeStateEntities[id]?.name) ||
                isFilterHit(
                  lifeFilters.urgency,
                  lifeStateEntities[id]?.urgency
                ) ||
                isFilterHit(
                  lifeFilters.satisfaction,
                  lifeStateEntities[id]?.satisfaction
                ) ||
                isFilterHit(
                  lifeFilters.focus,
                  lifeStateEntities[id]?.isFocused
                ) ||
                isFilterHit(
                  lifeFilters.importance,
                  lifeStateEntities[id]?.importance
                ) ||
                isFilterHit(
                  lifeFilters.actions,
                  mapActivitiesFilterValues(
                    lifeStateEntities[id]?.activityIds.length
                  )
                ),
            } as ILifeState;
          })
          .sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          });

        return {
          ...lifeArea,
          readonly: !!lifeFilters.selectedDate,
          isHighlighted:
            activeItemType === 'area' && activeItemId === lifeArea.id,
          isChildSearchHit:
            isSearchHit(lifeFilters.search, lifeArea.name) ||
            isFilterHit(lifeFilters.urgency, lifeArea.urgency) ||
            isFilterHit(lifeFilters.focus, undefined) ||
            isFilterHit(lifeFilters.satisfaction, lifeArea.satisfaction) ||
            isFilterHit(lifeFilters.importance, lifeArea.importance) ||
            isFilterHit(lifeFilters.actions, undefined),
          states: states
            .map(state => {
              const specificIds = state.specificIds;
              const specifics = specificIds
                .filter(id => !!lifeSpecificsEntities[id])
                .map(id => ({
                  ...lifeSpecificsEntities[id],
                  readonly: !!lifeFilters.selectedDate,
                  sortOrder: lifeSpecificsEntities[id]?.sortOrder as number,
                  activities: lifeSpecificsEntities[id]?.activityIds
                    .filter(id => !!lifeActivityEntities[id])
                    .map(id => lifeActivityEntities[id]),
                  lightColor: lifeArea.lightColor,
                  mediumColor: lifeArea.mediumColor,
                  darkColor: lifeArea.darkColor,
                  isChildSearchHit:
                    isSearchHit(
                      lifeFilters.search,
                      lifeSpecificsEntities[id]?.name
                    ) ||
                    isFilterHit(
                      lifeFilters.urgency,
                      lifeSpecificsEntities[id]?.urgency
                    ) ||
                    isFilterHit(
                      lifeFilters.satisfaction,
                      lifeSpecificsEntities[id]?.satisfaction
                    ) ||
                    isFilterHit(
                      lifeFilters.focus,
                      lifeSpecificsEntities[id]?.isFocused
                    ) ||
                    isFilterHit(
                      lifeFilters.importance,
                      lifeSpecificsEntities[id]?.importance
                    ) ||
                    isFilterHit(
                      lifeFilters.actions,
                      mapActivitiesFilterValues(
                        lifeSpecificsEntities[id]?.activityIds.length
                      )
                    ),
                  isHighlighted:
                    activeItemType === 'specific' && activeItemId === id,
                }))
                .filter(s => {
                  return (
                    isFilterApplied(lifeFilters.focus, s.isFocused) &&
                    isFilterApplied(lifeFilters.urgency, s.urgency) &&
                    isFilterApplied(lifeFilters.importance, s.importance) &&
                    isFilterApplied(lifeFilters.satisfaction, s.satisfaction) &&
                    isFilterApplied(
                      lifeFilters.actions,
                      mapActivitiesFilterValues(s?.activityIds?.length)
                    ) &&
                    isSearchFilterApplied(lifeFilters.search, s.name)
                  );
                })
                .sort((a, b) => {
                  return a.sortOrder - b.sortOrder;
                });

              return {
                ...state,
                activities: state.activityIds
                  .filter(id => !!lifeActivityEntities[id])
                  .map(id => lifeActivityEntities[id]),
                lightColor: lifeArea.lightColor,
                mediumColor: lifeArea.mediumColor,
                darkColor: lifeArea.darkColor,
                specifics,
              };
            })
            .filter(s => {
              return s.specifics?.length > 0
                ? true
                : isFilterApplied(lifeFilters.focus, s.isFocused) &&
                    isFilterApplied(lifeFilters.urgency, s.urgency) &&
                    isFilterApplied(lifeFilters.importance, s.importance) &&
                    isFilterApplied(lifeFilters.satisfaction, s.satisfaction) &&
                    isFilterApplied(
                      lifeFilters.actions,
                      mapActivitiesFilterValues(s?.activityIds?.length)
                    ) &&
                    isSearchFilterApplied(lifeFilters.search, s.name);
            }),
        };
      })
      .filter(s => {
        return s.states?.length > 0
          ? true
          : isFilterApplied(lifeFilters.focus, undefined) &&
              isFilterApplied(lifeFilters.urgency, s.urgency) &&
              isFilterApplied(lifeFilters.actions, undefined) &&
              isFilterApplied(lifeFilters.importance, s.importance) &&
              isFilterApplied(lifeFilters.satisfaction, s.satisfaction) &&
              isSearchFilterApplied(lifeFilters.search, s.name);
      }) as ILifeArea[];
  }
);

export const selectLifeUIActivities$ = (lifeFilters: ILifeFilter) =>
  createSelector(
    selectLife$,
    selectLifeStatesEntities$,
    selectLifeStateIds$,
    selectLifeSpecificsEntities$,
    selectLifeActivitiesEntities$,
    fromRoot.selectQueryParams,
    (
      life,
      lifeStateEntities,
      lifeStateIds,
      lifeSpecificsEntities,
      lifeActivityEntities,
      queryParams
    ) => {
      const { activeItemType, activeItemId } = queryParams;

      return life
        .map(lifeArea => {
          const states = lifeArea.stateIds
            .filter(id => !!lifeStateEntities[id])
            .map(id => {
              return {
                ...lifeStateEntities[id],
                isHighlighted:
                  activeItemType === 'state' && activeItemId === id,
                isChildSearchHit:
                  isSearchHit(
                    lifeFilters.search,
                    lifeStateEntities[id]?.name
                  ) ||
                  isFilterHit(
                    lifeFilters.urgency,
                    lifeStateEntities[id]?.urgency
                  ) ||
                  isFilterHit(
                    lifeFilters.satisfaction,
                    lifeStateEntities[id]?.satisfaction
                  ) ||
                  isFilterHit(
                    lifeFilters.focus,
                    lifeStateEntities[id]?.isFocused
                  ) ||
                  isFilterHit(
                    lifeFilters.importance,
                    lifeStateEntities[id]?.importance
                  ) ||
                  isFilterHit(
                    lifeFilters.actions,
                    mapActivitiesFilterValues(
                      lifeStateEntities[id]?.activityIds.length
                    )
                  ) ||
                  !isFilterHit(
                    <string[]>lifeStateEntities[id]?.activityIds,
                    lifeFilters.assignedStates
                      ? lifeFilters.activityId
                      : lifeStateEntities[id]?.activityIds[0]
                  ),
              } as ILifeState;
            })
            .sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });

          return {
            ...lifeArea,
            isHighlighted:
              activeItemType === 'area' && activeItemId === lifeArea.id,
            isChildSearchHit:
              isSearchHit(lifeFilters.search, lifeArea.name) ||
              isFilterHit(lifeFilters.urgency, lifeArea.urgency) ||
              isFilterHit(lifeFilters.focus, undefined) ||
              isFilterHit(lifeFilters.satisfaction, lifeArea.satisfaction) ||
              isFilterHit(lifeFilters.actions, undefined) ||
              isFilterHit(lifeFilters.importance, lifeArea.importance),
            states: states
              .map(state => {
                const specificIds = state.specificIds;
                const specifics = specificIds
                  .filter(id => !!lifeSpecificsEntities[id])
                  .map(id => ({
                    ...lifeSpecificsEntities[id],
                    sortOrder: lifeSpecificsEntities[id]?.sortOrder as number,
                    activities: lifeSpecificsEntities[id]?.activityIds
                      .filter(id => !!lifeActivityEntities[id])
                      .map(id => lifeActivityEntities[id]),
                    lightColor: lifeArea.lightColor,
                    mediumColor: lifeArea.mediumColor,
                    darkColor: lifeArea.darkColor,
                    isChildSearchHit:
                      isSearchHit(
                        lifeFilters.search,
                        lifeSpecificsEntities[id]?.name
                      ) ||
                      isFilterHit(
                        lifeFilters.urgency,
                        lifeSpecificsEntities[id]?.urgency
                      ) ||
                      isFilterHit(
                        lifeFilters.satisfaction,
                        lifeSpecificsEntities[id]?.satisfaction
                      ) ||
                      isFilterHit(
                        lifeFilters.focus,
                        lifeSpecificsEntities[id]?.isFocused
                      ) ||
                      isFilterHit(
                        lifeFilters.importance,
                        lifeSpecificsEntities[id]?.importance
                      ) ||
                      isFilterHit(
                        lifeFilters.actions,
                        mapActivitiesFilterValues(
                          lifeSpecificsEntities[id]?.activityIds.length
                        )
                      ),
                    isHighlighted:
                      activeItemType === 'specific' && activeItemId === id,
                  }))
                  .filter(s => {
                    return (
                      isFilterApplied(lifeFilters.focus, s.isFocused) &&
                      isFilterApplied(lifeFilters.urgency, s.urgency) &&
                      isFilterApplied(lifeFilters.importance, s.importance) &&
                      (lifeFilters.assignedStates
                        ? s.activityIds &&
                          s.activityIds.includes(
                            lifeFilters.activityId as string
                          )
                        : true) &&
                      isFilterApplied(
                        lifeFilters.satisfaction,
                        s.satisfaction
                      ) &&
                      isFilterApplied(
                        lifeFilters.actions,
                        mapActivitiesFilterValues(s?.activityIds?.length)
                      ) &&
                      isSearchFilterApplied(lifeFilters.search, s.name)
                    );
                  })
                  .sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                  });

                return {
                  ...state,
                  activities: state.activityIds
                    .filter(id => !!lifeActivityEntities[id])
                    .map(id => lifeActivityEntities[id]),
                  lightColor: lifeArea.lightColor,
                  mediumColor: lifeArea.mediumColor,
                  darkColor: lifeArea.darkColor,
                  specifics,
                };
              })
              .filter(s => {
                return s.specifics?.length > 0
                  ? true
                  : isFilterApplied(lifeFilters.focus, s.isFocused) &&
                      isFilterApplied(lifeFilters.urgency, s.urgency) &&
                      isFilterApplied(lifeFilters.importance, s.importance) &&
                      isFilterApplied(
                        lifeFilters.actions,
                        mapActivitiesFilterValues(s.activityIds.length)
                      ) &&
                      isFilterApplied(
                        lifeFilters.satisfaction,
                        s.satisfaction
                      ) &&
                      (lifeFilters.assignedStates
                        ? s.activityIds.includes(
                            lifeFilters.activityId as string
                          )
                        : true) &&
                      isSearchFilterApplied(lifeFilters.search, s.name);
              }),
          };
        })
        .filter(la => la.states.length > 0)
        .sort((a, b) => a.sortOrder - b.sortOrder) as ILifeArea[];
    }
  );

export const selectActiveDrawerItem$ = createSelector(
  selectLifeEntities$,
  selectLifeStatesEntities$,
  selectLifeSpecificsEntities$,
  selectLifeNoteEntities$,
  selectLifeActivitiesEntities$,
  fromRoot.selectQueryParams,
  selectLife$,
  selectLifeStates$,
  selectLifeFilters$,
  (
    lifeEntities,
    lifeStatesEntities,
    lifeSpecificsEntities,
    lifeNoteEntities,
    lifeActivityEntities,
    queryParams,
    lifeAreas,
    lifeStates,
    lifeFilters
  ) => {
    const { activeItemType, activeItemId, activeTabIndex } = queryParams;
    if (activeItemType === 'area') {
      let lifeArea = lifeEntities[activeItemId];
      if (lifeArea) {
        lifeArea = {
          ...lifeArea,
          readonly: !!lifeFilters.selectedDate,
        };
      }
      return {
        item: lifeArea || null,
        type: ElementType.LIFE_AREA,
        activeTabIndex,
      };
    } else if (activeItemType === 'state') {
      let lifeState = lifeStatesEntities[activeItemId];
      const la = lifeAreas.find(area =>
        area.stateIds.some(stateId => stateId === activeItemId)
      );

      if (lifeState) {
        lifeState = {
          ...lifeState,
          lightColor: la?.lightColor,
          darkColor: la?.darkColor,
          mediumColor: la?.mediumColor,
          readonly: !!lifeFilters.selectedDate,
        };
      }
      return {
        item: lifeState || null,
        type: ElementType.LIFE_STATE,
        activeTabIndex,
      };
    } else if (activeItemType === 'specific') {
      const findState = lifeStates.find(state =>
        state.specificIds.some(specificId => specificId === activeItemId)
      );
      const la = lifeAreas.find(area =>
        area.stateIds.some(stateId => stateId === findState?.id)
      );
      const lifeSpecific = {
        ...lifeSpecificsEntities[activeItemId],
        darkColor: la?.darkColor,
        mediumColor: la?.mediumColor,
        lightColor: la?.lightColor,
        readonly: !!lifeFilters.selectedDate,
      } as ILifeSpecifics;
      return {
        item: lifeSpecific || null,
        type: ElementType.LIFE_SPECIFIC,
        activeTabIndex,
      };
    } else if (activeItemType === 'activity') {
      let lifeActivity = lifeActivityEntities[activeItemId] as ILifeActivity;
      const la = lifeEntities[lifeActivity?.lifeAreaId as string];

      if (lifeActivity) {
        lifeActivity = {
          ...lifeActivity,
          darkColor: la?.darkColor,
          mediumColor: la?.mediumColor,
          lightColor: la?.lightColor,
          readonly: !!lifeFilters.selectedDate,
          specificIds: lifeActivity.specificIds.filter(
            id => !!lifeSpecificsEntities[id]
          ),
          stateIds: lifeActivity.stateIds.filter(
            id => !!lifeStatesEntities[id]
          ),
        };
      }

      return {
        type: ElementType.LIFE_ACTIVITY,
        item: lifeActivity || null,
        activeTabIndex,
      };
    } else {
      return {
        item: null,
      };
    }
  }
);

export const selectLifeAreasWithActivities$ = (lifeFilters: ILifeFilter) =>
  createSelector(
    selectLife$,
    selectLifeActivities$,
    (lifeAreas, activityList) => {
      const unassignedActivities = activityList.filter(
        activity => !activity.lifeAreaId
      );
      const res: ILifeArea[] = [];
      const las = lifeAreas
        .map(la => {
          const activities: ILifeActivity[] = activityList.filter(
            activity =>
              activity.lifeAreaId === la.id &&
              isFilterApplied(lifeFilters.focus, activity.isFocused) &&
              isFilterApplied(lifeFilters.importance, activity.importance) &&
              (lifeFilters.assignedStates
                ? lifeFilters.activityIds?.includes(activity.id)
                : true) &&
              isFilterApplied(
                lifeFilters.satisfaction,
                activity.satisfaction
              ) &&
              isSearchFilterApplied(lifeFilters.search, activity.name)
          );

          return {
            ...la,
            activities,
          } as ILifeArea;
        })
        .filter(la => la.activities.length > 0);
      res.push(...las);
      if (unassignedActivities.length > 0) {
        res.push({
          id: '-1',
          darkColor: '#4d4d4d',
          mediumColor: '#A6A6A6',
          lightColor: '#f1f3f6',
          description: '',
          activityIds: [],
          icon: 'fa-ban',
          importance: 0,
          notes: [],
          satisfaction: 0,
          sortOrder: 0,
          stateIds: [],
          states: [],
          statesHidden: false,
          urgency: 0,
          name: 'Unassigned',
          activities: unassignedActivities.filter(
            activity =>
              isFilterApplied(lifeFilters.focus, activity.isFocused) &&
              isFilterApplied(lifeFilters.importance, activity.importance) &&
              (lifeFilters.assignedStates
                ? lifeFilters.activityIds?.includes(activity.id)
                : true) &&
              isFilterApplied(
                lifeFilters.satisfaction,
                activity.satisfaction
              ) &&
              isSearchFilterApplied(lifeFilters.search, activity.name)
          ),
        });
      }
      return res;
    }
  );

export const selectLifeActivitiesUI$ = createSelector(
  selectLifeActivities$,
  selectLifeEntities$,
  selectLifeStatesEntities$,
  selectLifeSpecificsEntities$,
  selectLifeFilters$,
  fromRoot.selectQueryParams,
  (
    activities,
    lifeAreaEntities,
    lifeStateEntities,
    lifeSpecificEntities,
    lifeFilters,
    queryParams
  ) => {
    return activities
      .map(activity => {
        const lifeArea = activity.lifeAreaId
          ? lifeAreaEntities[activity.lifeAreaId]
          : null;
        const { activeItemType, activeItemId } = queryParams;

        return {
          ...activity,
          lightColor: lifeArea?.lightColor,
          mediumColor: lifeArea?.mediumColor,
          darkColor: lifeArea?.darkColor,
          specifics: activity.specificIds
            .filter(id => !!lifeSpecificEntities[id])
            .map(id => lifeSpecificEntities[id]),
          states: activity.stateIds
            .filter(id => !!lifeStateEntities[id])
            .map(id => lifeStateEntities[id]),
          isHighlighted:
            activity.id === activeItemId && activeItemType === 'activity',
          readonly: !!lifeFilters.selectedDate,
        } as ILifeActivity;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .filter(a => {
        return (
          isFilterApplied(lifeFilters.focus, a.isFocused) &&
          isFilterApplied(lifeFilters.urgency, a.urgency) &&
          isFilterApplied(lifeFilters.importance, a.importance) &&
          isFilterApplied(lifeFilters.satisfaction, a.satisfaction) &&
          isFilterApplied(
            lifeFilters.actions,
            mapActivitiesFilterValues(a.specificIds?.length, a.stateIds?.length)
          ) &&
          isSearchFilterApplied(lifeFilters.search, a.name)
        );
      });
  }
);
