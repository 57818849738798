<div class="flex flex-col gap-2">
  <div class="bg-gray-100 rounded-lg py-3 px-6 text-black/50">
    <span>{{ 'LifeHeaderFiltersSatisfaction' | translate }}</span>
  </div>
  <div class="flex flex-col gap-0.5">
    <button
      class="flex items-center justify-center gap-0.5 px-3 py-1.5 hover:bg-icons-gray cursor-pointer"
      *ngFor="let filter of satisfactionFilters"
      [class.bg-icons-gray]="isFilterActive(filter)"
      (click)="applyFilter(filter)">
      <i
        [ngClass]="[
          satisfactionScore[filter].icon,
          'text-' + satisfactionScore[filter].cssClass
        ]"
        class="fa-solid text-xl"></i>
    </button>
  </div>
</div>
