import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';

@Component({
  selector: 'hl-rating-select',
  standalone: true,
  templateUrl: './rating-select.component.html',
  imports: [
    NgClass,
    NgFor,
    NgIf,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    StopPropagationDirective,
  ],
})
export class RatingSelectComponent {
  @Input() satisfaction: number;
  @Input() satisfactionZeroClass: string;
  @Output() setSatisfaction = new EventEmitter<number>();

  ratingItems = Array.from(Array(5).keys())
    .reverse()
    .map(x => x + 1);

  satisfactionScore: any = {
    0: {
      icon: '',
      cssClass: '',
    },
    1: { icon: 'fa-face-disappointed', cssClass: 'icons-red' },
    2: { icon: 'fa-face-frown-slight', cssClass: 'icons-lightred' },
    3: { icon: 'fa-face-meh', cssClass: 'icons-yellow' },
    4: { icon: 'fa-face-smile', cssClass: 'icons-lightgreen' },
    5: { icon: 'fa-face-grin-wide', cssClass: 'icons-green' },
  };
}
