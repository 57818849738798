<div class="flex flex-col gap-2">
  <div class="bg-gray-100 rounded-lg py-3 px-6 text-black/50">
    <span>{{ filterLabel }}</span>
  </div>
  <div class="flex flex-col gap-0.5 items-center">
    <button
      [class.bg-icons-gray]="isFilterActive(action)"
      (click)="applyFilter(action)"
      class="w-full hover:bg-icons-gray py-1 px-2 text-black/30"
      *ngFor="let action of actions">
      <span>{{ action < 5 ? action : '4+' }}</span>
    </button>
  </div>
</div>
