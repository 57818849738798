<span data-sl="mask" *ngIf="!editable" class="select-none">{{ value }}</span>
<input
  data-sl="mask"
  *ngIf="editable"
  autoSizeInput
  [minWidth]="30"
  [maxWidth]="500"
  #input
  hlStopPropagation
  (keydown.enter)="updateValue($event)"
  (keydown.escape)="onBlur()"
  (blur)="updateValue($event)"
  class="appearance-none"
  [value]="value" />
