import {
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';
import { InputSearchComponent } from '@app/components';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { ILifeArea } from '@life/models/life-area';
import * as fromLife from '@life/selectors/life-area.selectors';
import { Store } from '@ngrx/store';
import { LetDirective } from '@ngrx/component';
import { LifeAreaGroupComponent } from '@components/life-area-group/life-area-group.component';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { IsFavoriteComponent } from '@core/shared/is-favorite/is-favorite.component';
import { RatingComponent } from '@core/shared/rating/rating.component';
import { RatingSelectComponent } from '@core/shared/rating-select/rating-select.component';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { ILifeState } from '@life/models/life-state';
import { ILifeActivity } from '@life/models/life-activity';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { OgActivityActions } from '@life/actions';
import { ILifeFilter } from '@life/models/life-filter';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ImportanceFilterComponent } from '@components/filters/importance-filter/importance-filter.component';
import { IsFavoriteFilterComponent } from '@components/filters/is-favorite-filter/is-favorite-filter.component';
import { SatisfactionFilterComponent } from '@components/filters/satisfaction-filter/satisfaction-filter.component';
import { FormsModule } from '@angular/forms';
import { TruncateTextPipe } from '@core/pipes/truncate-text.pipe';

@Component({
  selector: 'hl-tab-activity',
  templateUrl: './tab-activity.component.html',
  standalone: true,
  imports: [
    InputSearchComponent,
    MatSlideToggleModule,
    TranslateModule,
    LetDirective,
    LifeAreaGroupComponent,
    NgForOf,
    IsFavoriteComponent,
    NgIf,
    RatingComponent,
    RatingSelectComponent,
    StopPropagationDirective,
    NgStyle,
    CdkMenu,
    CdkMenuItem,
    ImportanceFilterComponent,
    IsFavoriteFilterComponent,
    SatisfactionFilterComponent,
    CdkMenuTrigger,
    NgClass,
    FormsModule,
    TruncateTextPipe,
  ],
})
export class TabActivityComponent {
  @Input() element: ILifeState | ILifeSpecifics;

  @Output() manageActivity = new EventEmitter<{
    activityId: string;
    add: boolean;
  }>();

  filters: ILifeFilter = {
    actions: [],
    importance: [],
    satisfaction: [],
    urgency: [],
    focus: [],
    search: '',
    assignedStates: false,
    activityIds: [],
  };

  lifeAreasWithActivities = this.store.selectSignal<ILifeArea[]>(
    fromLife.selectLifeAreasWithActivities$(this.filters)
  );

  constructor(private store: Store) {}

  get filterCount() {
    return (
      this.filters.focus.length +
      this.filters.urgency.length +
      this.filters.actions.length +
      this.filters.satisfaction.length +
      this.filters.importance.length
    );
  }

  manageSearchFilter(event: any) {
    this.filters = { ...this.filters, search: event };

    this.loadLifeAreasWithActivities();
  }

  manageImportanceFilters(importance: number[]) {
    this.filters.importance = importance;

    this.loadLifeAreasWithActivities();
  }

  manageSatisfactionFilters(satisfaction: number[]) {
    this.filters.satisfaction = satisfaction;

    this.loadLifeAreasWithActivities();
  }

  manageFavoriteFilters(focus: boolean[]) {
    this.filters.focus = focus;

    this.loadLifeAreasWithActivities();
  }

  clearFilters() {
    this.filters = {
      actions: [],
      importance: [],
      satisfaction: [],
      urgency: [],
      focus: [],
      search: '',
      assignedStates: this.filters.assignedStates,
      activityIds: this.element.activityIds,
    };

    this.loadLifeAreasWithActivities();
  }

  trackByLifeActivityIndex: TrackByFunction<ILifeActivity> = index => index;

  toggleActivity(activity: ILifeActivity) {
    this.manageActivity.emit({
      activityId: activity.id,
      add: !this.hasActivity(activity),
    });
  }
  hasActivity(activity: ILifeActivity) {
    return !!this.element.activityIds.find(id => id === activity.id);
  }

  updateActivityImportance(activity: ILifeActivity, importance: number) {
    this.store.dispatch(
      OgActivityActions.setActivityImportance({
        activity: { ...activity, importance },
      })
    );
  }
  updateActivitySatisfaction(activity: ILifeActivity, satisfaction: number) {
    this.store.dispatch(
      OgActivityActions.setActivitySatisfaction({
        activity: { ...activity, satisfaction },
      })
    );
  }
  updateActivityFocused(activity: ILifeActivity, isFocused: boolean) {
    this.store.dispatch(
      OgActivityActions.setActivityFocus({
        activity: { ...activity, isFocused },
      })
    );
  }

  loadLifeAreasWithActivities() {
    this.lifeAreasWithActivities = this.store.selectSignal<ILifeArea[]>(
      fromLife.selectLifeAreasWithActivities$({
        ...this.filters,
        activityIds: this.element.activityIds,
      })
    );
  }
}
