import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ILifeArea } from '@life/models/life-area';
import { ILifeState } from '@life/models/life-state';
import { ILifeSpecifics } from '@life/models/life-specifics';

export const OgLifeActions = createActionGroup({
  source: 'Life Area',
  events: {
    init: props<{ forceRedirect?: boolean }>(),
    sortLife: props<{
      lifeArea: ILifeArea[] | ILifeState[] | ILifeSpecifics[];
    }>(),
    sortLifeSuccess: emptyProps(),
    sortLifeFail: emptyProps(),
    loadTemplates: emptyProps(),
    loadTemplatesSuccess: props<{ lifeArea: ILifeArea[] }>(),
    loadTemplatesFail: props<{ error: string }>(),
    setMaxLifeAreas: props<{ maxLifeAreas: number }>(),
    setLifeAreas: props<{ ids: string[] }>(),
    setLifeAreasSuccess: emptyProps(),
    setLifeAreasFail: props<{ error: string }>(),
    deleteLife: emptyProps(),
    deleteLifeSuccess: emptyProps(),
    resetLife: emptyProps(),
    resetLifeSuccess: emptyProps(),
    deleteLifeFail: props<{ error: string }>(),
    loadLifeAreas: props<{
      redirect: boolean;
      lifeFrom?: string | null;
      userId?: string;
    }>(),
    loadLifeAreasSuccess: props<{
      lifeAreas: ILifeArea[];
      changeDates: string[];
      resetProgressDate: string;
      startDate: string;
      redirect?: boolean;
      userId?: string;
    }>(),
    loadLifeAreasFail: props<{ error: string }>(),
    selectLifeArea: props<{ id: string }>(),
    loadLifeArea: props<{ id: string }>(),
    loadLifeAreaSuccess: props<{ lifeArea: ILifeArea }>(),
    loadLifeAreaFail: props<{ error: string }>(),
    addLifeArea: props<{ lifeArea: ILifeArea }>(),
    addLifeAreaFromTemplate: props<{ lifeArea: ILifeArea }>(),
    addLifeAreaSuccess: props<{ lifeArea: ILifeArea }>(),
    addLifeAreaFail: props<{ error: string }>(),
    updateLifeArea: props<{ lifeArea: Partial<ILifeArea> }>(),
    updateLifeAreaSuccess: props<{ lifeArea: ILifeArea }>(),
    updateLifeAreaFail: props<{ error: string }>(),
    deleteLifeArea: props<{ lifeArea: ILifeArea }>(),
    deleteLifeAreaSuccess: props<{ lifeArea: ILifeArea }>(),
    deleteLifeAreaFail: props<{ error: string }>(),
    setLifeAreaImportance: props<{ lifeArea: ILifeArea }>(),
    setLifeAreaDescription: props<{ lifeArea: ILifeArea }>(),
    setLifeAreaStatesHidden: props<{ lifeArea: ILifeArea }>(),
    setLifeAreaSatisfaction: props<{ lifeArea: ILifeArea }>(),
    setLifeAreaName: props<{ lifeArea: ILifeArea }>(),
    actuallySetLifeAreaName: props<{ lifeArea: ILifeArea }>(),
    setLifeAreaIcon: props<{ lifeArea: ILifeArea }>(),
    setLifeAreaColor: props<{ lifeArea: ILifeArea }>(),
    setLifeAreasSortOrder: props<{ lifeAreas: ILifeArea[] }>(),
    setLifeAreasSortOrderSuccess: props<{ lifeAreas: ILifeArea[] }>(),
    setLifeDragElement: props<{
      activeDragElement: 'AREA' | 'STATE' | 'SPECIFIC' | null;
    }>(),
    noop: emptyProps(),
    moveLifeStateFromLifeArea: props<{
      currentLifeAreaId: string;
      targetLifeAreaId: string;
      lifeStateId: string;
      updatedLifeStates: ILifeState[];
    }>(),
  },
});
