import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivitiesComponent } from '@app/activities/views/activities/activities.component';
import { ActivitiesOverviewComponent } from '@app/activities/views/activities-overview/activities-overview.component';
import { ActivitiesWheelComponent } from '@app/activities/views/activities-wheel/activities-wheel.component';
import { ActivitiesPriorityComponent } from '@app/activities/views/activities-priority/activities-priority.component';

const routes: Routes = [
  {
    path: '',
    component: ActivitiesComponent,
    children: [
      {
        path: '',
        component: ActivitiesOverviewComponent,
        pathMatch: 'full',
        data: { title: 'Activities' },
      },
      {
        path: 'wheel',
        component: ActivitiesWheelComponent,
        data: { title: 'Activities Wheel' },
      },
      {
        path: 'priority',
        component: ActivitiesPriorityComponent,
        data: { title: 'Activities Priority' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActivitiesRoutingModule {}
