import { createSelector } from '@ngrx/store';
import { lifeStateFeatureKey } from '@life/reducers/life-state.reducer';
import * as fromLifeState from '@life/reducers/life-state.reducer';
import { selectLifeFeatureState } from '@life/reducers';
import { selectLifeTemplates$ } from '@life/selectors/life-templates.selectors';
import { ILifeState } from '@life/models/life-state';
import {
  selectLife$,
  selectLifeEntities$,
} from '@life/selectors/life-area.selectors';
import { selectLifeNoteEntities$ } from '@life/selectors/life-notes.selectors';
import { ILifeNote } from '@life/models/life-note';
import { selectLifeSpecificsEntities$ } from '@life/selectors/life-specifics.selectors';

export const selectLifeStatesState = createSelector(
  selectLifeFeatureState,
  state => state[lifeStateFeatureKey]
);

export const selectLifeStates$ = createSelector(
  selectLifeStatesState,
  fromLifeState.selectLifeStates
);
export const selectLifeStatesEntities$ = createSelector(
  selectLifeStatesState,
  fromLifeState.selectLifeStatesEntities
);

export const selectLifeStateIds$ = createSelector(
  selectLifeStatesState,
  fromLifeState.selectLifeStateIds
);

export const selectAvailableLifeStates$ = (lifeAreaName: string) =>
  createSelector(
    selectLife$,
    selectLifeTemplates$,
    selectLifeStates$,
    selectLifeStatesEntities$,
    (life, lifeAreas, lifeStates, lifeEntities) => {
      const used = [];
      const available = [];
      const usedLifeStateIds =
        life.find(lifeArea => lifeArea.name === lifeAreaName)?.stateIds || [];

      const usedLifeStates = usedLifeStateIds
        .map(id => lifeEntities[id])
        .filter(s => !!s) as ILifeState[];

      const lifeStatesPresetsFromLifeArea =
        lifeAreas.find(la => la.name === lifeAreaName)?.states || [];

      for (const lifeState of lifeStatesPresetsFromLifeArea) {
        if (usedLifeStates.find(ls => ls.name === lifeState.name)) {
          used.push(lifeState);
        } else {
          available.push(lifeState);
        }
      }

      return {
        used: used.map(ls => ls.name) || [],
        available: available.map(ls => ({ label: ls.name, item: ls })) || [],
      };
    }
  );

export const selectCheckIfLifeStateNameExists$ = (
  lifeState: ILifeState,
  lifeAreaId?: string
) =>
  createSelector(
    selectLife$,
    selectLifeStates$,
    selectLifeStatesEntities$,
    (life, states, lifeStateEntities) => {
      let la;
      if (!lifeAreaId) {
        la = life.find(la => la.stateIds.includes(lifeState.id)) || null;
      } else {
        la = life.find(la => la.id === lifeAreaId);
      }
      const statesOfThisArea = la?.stateIds.map(
        id => lifeStateEntities[id]
      ) as ILifeState[];

      const stateNameExists = statesOfThisArea.find(
        state => state?.name?.toLowerCase() === lifeState?.name?.toLowerCase()
      );
      return !!stateNameExists;
    }
  );

export const selectLifeStateNotes$ = (lifeStateId: string) =>
  createSelector(
    selectLifeStatesEntities$,
    selectLifeNoteEntities$,
    (lifeStateEntities, lifeNoteEntities) => {
      const lifeArea = lifeStateEntities[lifeStateId] as ILifeState;
      if (lifeArea?.notes) {
        return lifeArea.notes
          .filter(note => !!lifeNoteEntities[note.id])
          .map(note => lifeNoteEntities[note.id] as ILifeNote);
      } else {
        return [];
      }
    }
  );

export const selectLifeStateSpecificNames$ = (lifeStateId: string) =>
  createSelector(
    selectLifeStatesEntities$,
    selectLifeSpecificsEntities$,
    (lifeStateEntities, lifeSpecificEntities) => {
      const lifeState = lifeStateEntities[lifeStateId];

      if (lifeState) {
        return (
          lifeState.specificIds
            .filter(id => !!lifeSpecificEntities[id])
            // @ts-ignore
            .map(id => lifeSpecificEntities[id].name)
        );
      } else {
        return [];
      }
    }
  );
