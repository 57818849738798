import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';
import {
  OgLifeActions,
  OgLifeNoteActions,
  OgLifeSpecificActions,
  OgLifeStateActions,
} from '../actions';
import { ToastrService } from 'ngx-toastr';
import { LifeSpecificsService } from '@life/services/life-specifics.service';
import { AppService } from '@core/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import {
  selectCheckIfLifeSpecificNameExists$,
  selectLifeStatesEntities$,
  selectLifeFilters$,
  selectLifeSpecificsEntities$,
  selectLifeDateFilter$,
} from '@life/selectors';
import { Store } from '@ngrx/store';
import { ILifeState } from '@life/models/life-state';
import { LifeService } from '@life/services';

@Injectable()
export class LifeSpecificsEffects {
  loadLifeStateHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.loadLifeStateSuccess),
      switchMap(({ lifeState, userId }) => {
        if (lifeState.specificIds?.length > 0) {
          return lifeState.specificIds.map(id =>
            OgLifeSpecificActions.loadLifeSpecific({ id, userId })
          );
        } else {
          return of(OgLifeStateActions.noop());
        }
      })
    );
  });

  updateLifeSpecificsImportance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.setSpecificImportance),
      switchMap(({ specific }) => {
        return this.lifeSpecificsService
          .updateLifeSpecificImportance(specific)
          .pipe(
            map(() => {
              return OgLifeSpecificActions.loadLifeSpecific({
                id: specific.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgLifeSpecificActions.updateSpecificFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeSpecificsSatisfaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.setSpecificSatisfaction),
      switchMap(({ specific }) => {
        return this.lifeSpecificsService
          .updateLifeSpecificSatisfaction(specific)
          .pipe(
            map(() => {
              return OgLifeSpecificActions.loadLifeSpecific({
                id: specific.id,
              });
            }),
            catchError(({ error }) =>
              of(
                OgLifeSpecificActions.updateSpecificFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeSpecificsDescription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.setLifeSpecificDescription),
      switchMap(({ specific }) => {
        return this.lifeSpecificsService
          .updateLifeSpecificDescription(specific)
          .pipe(
            map(() => {
              return OgLifeSpecificActions.noop();
            }),
            catchError(({ error }) =>
              of(
                OgLifeSpecificActions.updateSpecificFail({
                  error: error.message,
                })
              )
            )
          );
      })
    );
  });

  updateLifeStateFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.setSpecificFocus),
      switchMap(({ specific }) => {
        return this.lifeSpecificsService.updateLifeSpecificFocus(specific).pipe(
          map(() => {
            return OgLifeSpecificActions.loadLifeSpecific({
              id: specific.id,
            });
          }),
          catchError(({ error }) =>
            of(
              OgLifeSpecificActions.updateSpecificFail({
                error: error.message,
              }),
              OgLifeSpecificActions.updateSpecificSuccess({
                specific: {
                  ...specific,
                  isFocused: !specific.isFocused,
                },
              })
            )
          )
        );
      })
    );
  });

  addCustomLifeSpecific$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.addLifeSpecific),
      concatLatestFrom(({ lifeSpecific, lifeStateId }) =>
        this.store.select(
          selectCheckIfLifeSpecificNameExists$(lifeSpecific, lifeStateId)
        )
      ),
      exhaustMap(([{ lifeSpecific, lifeStateId }, nameExists]) => {
        if (!nameExists) {
          return this.lifeSpecificsService
            .addNewCustomLifeSpecific(lifeSpecific.name, lifeStateId)
            .pipe(
              map(() => {
                this.toastr.success(
                  this.translate.instant('ToastrAddLifeSpecificSuccess')
                );
                return OgLifeStateActions.loadLifeState({ id: lifeStateId });
              }),
              catchError(({ error }) =>
                of(
                  OgLifeSpecificActions.updateSpecificFail({
                    error: error.message,
                  })
                )
              )
            );
        } else {
          return of(
            OgLifeSpecificActions.updateSpecificFail({
              error: this.translate.instant('LifeSpecificUniqueName'),
            })
          );
        }
      })
    );
  });

  addLifeSpecificFromTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.addLifeSpecificFromTemplate),
      concatLatestFrom(({ lifeSpecific, lifeStateId }) =>
        this.store.select(
          selectCheckIfLifeSpecificNameExists$(lifeSpecific, lifeStateId)
        )
      ),
      exhaustMap(([{ lifeSpecific, lifeStateId }, nameExists]) => {
        if (!nameExists) {
          return this.lifeSpecificsService
            .addNewLifeSpecificFromTemplate(lifeSpecific, lifeStateId)
            .pipe(
              map(() => {
                this.toastr.success(
                  this.translate.instant('ToastrAddLifeSpecificSuccess')
                );
                return OgLifeStateActions.loadLifeState({ id: lifeStateId });
              }),
              catchError(({ error }) =>
                of(
                  OgLifeSpecificActions.updateSpecificFail({
                    error: error.message,
                  })
                )
              )
            );
        } else {
          return of(
            OgLifeSpecificActions.updateSpecificFail({
              error: this.translate.instant('LifeSpecificUniqueName'),
            })
          );
        }
      })
    );
  });

  selectLifeSpecific$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.selectLifeSpecific),
      concatLatestFrom(() => this.store.select(selectLifeDateFilter$)),
      map(([{ id }, lifeFrom]) => {
        return OgLifeNoteActions.loadLifeSpecificNotes({
          lifeSpecificId: id,
          lifeFrom,
        });
      })
    );
  });

  getLifeSpecific$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.loadLifeSpecific),
      concatLatestFrom(() =>
        this.store
          .select(selectLifeFilters$)
          .pipe(map(filters => filters.selectedDate))
      ),
      mergeMap(([{ id, userId }, selectedDate]) =>
        this.lifeSpecificsService
          .getLifeSpecific(id, selectedDate, userId)
          .pipe(
            map(specific => {
              return OgLifeSpecificActions.loadLifeSpecificSuccess({
                specific: {
                  ...specific,
                  name: this.translate.instant(specific.name),
                },
              });
            }),
            catchError(({ error }) =>
              of(
                OgLifeSpecificActions.loadLifeSpecificFail({
                  error: error.message,
                })
              )
            )
          )
      )
    );
  });

  deleteLifeSpecific$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.deleteSpecific),
      exhaustMap(({ specificId }) =>
        this.lifeSpecificsService.deleteLifeSpecific(specificId).pipe(
          map(() => {
            this.toastr.success(
              this.translate.instant('ToastrDeleteLifeSpecificSuccess')
            );
            return OgLifeSpecificActions.deleteSpecificSuccess({ specificId });
          }),
          catchError(({ error }) =>
            of(
              OgLifeSpecificActions.deleteSpecificFail({ error: error.message })
            )
          )
        )
      )
    );
  });

  updateLifeSpecificsSortOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.setLifeSpecificsSortOrder),
      switchMap(({ lifeSpecifics }) => {
        const payload = lifeSpecifics.map((ls, index) => ({
          levelId: ls.id,
          sortOrder: index,
        }));
        return this.lifeSerivce.setOrder(payload).pipe(
          map(() => {
            return OgLifeSpecificActions.setLifeSpecificsSortOrderSuccess({
              lifeSpecifics,
            });
          }),
          catchError(({ error }) =>
            of(
              OgLifeSpecificActions.updateSpecificFail({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  lifeSpecificsErrorHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgLifeSpecificActions.deleteSpecificFail,
          OgLifeSpecificActions.updateSpecificFail,
          OgLifeSpecificActions.loadLifeSpecificFail
        ),
        tap(({ error }) => {
          this.toastr.error(error);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  lifeSpecificsSuccessHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgLifeSpecificActions.addLifeSpecific,
          OgLifeSpecificActions.addLifeSpecificFromTemplate,
          OgLifeSpecificActions.deleteSpecificSuccess,
          OgLifeSpecificActions.updateSpecificSuccess,
          OgLifeSpecificActions.setLifeSpecificsSortOrderSuccess,
          OgLifeSpecificActions.setSpecificImportance,
          OgLifeSpecificActions.setSpecificSatisfaction
        ),
        distinctUntilChanged(),
        debounceTime(900),
        tap(() => {
          navigator.serviceWorker?.controller?.postMessage({
            value: 'updateLife',
            uid: this.appService.appUid,
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  tryUpdateLifeSpecificName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.setLifeSpecificName),
      concatLatestFrom(({ specific }) =>
        this.store.select(selectCheckIfLifeSpecificNameExists$(specific))
      ),
      map(([{ specific }, nameExists]) => {
        if (!nameExists) {
          return OgLifeSpecificActions.actuallySetLifeSpecificName({
            specific,
          });
        } else {
          return OgLifeSpecificActions.updateSpecificFail({
            error: this.translate.instant('LifeSpecificUniqueName'),
          });
        }
      })
    );
  });

  updateLifeSpecificName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeSpecificActions.actuallySetLifeSpecificName),
      exhaustMap(({ specific }) =>
        this.lifeSpecificsService.updateLifeSpecificName(specific).pipe(
          map(() => {
            return OgLifeSpecificActions.updateSpecificSuccess({
              specific,
            });
          }),
          catchError(({ error }) =>
            of(
              OgLifeSpecificActions.updateSpecificFail({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  // activityAdd$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(OgActivityActions.addActivityToLifeState),
  //     concatLatestFrom(() => this.store.select(selectLifeSpecificsEntities$)),
  //     map(([{ activityId, lifeStateId }, specificEntities]) => {
  //       const lifeSpecific = specificEntities[lifeStateId];
  //       if (lifeSpecific) {
  //         const activityIds = lifeSpecific.activityIds;
  //
  //         return OgLifeStateActions.updateLifeStateSuccess({
  //           lifeState: {
  //             ...(lifeSpecific as ILifeState),
  //             activityIds: [...activityIds, activityId],
  //           },
  //         });
  //       } else {
  //         return OgLifeSpecificActions.noop();
  //       }
  //     })
  //   );
  // });
  //
  // activityRemove$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(OgActivityActions.removeActivityFromLifeState),
  //     concatLatestFrom(() => this.store.select(selectLifeSpecificsEntities$)),
  //     map(([{ activityId, lifeStateId }, specificEntities]) => {
  //       const lifeSpecific = specificEntities[lifeStateId];
  //       if (lifeSpecific) {
  //         const activityIds = lifeSpecific.activityIds.filter(
  //           id => id !== activityId
  //         );
  //
  //         return OgLifeStateActions.updateLifeStateSuccess({
  //           lifeState: {
  //             ...(lifeSpecific as ILifeState),
  //             activityIds: activityIds,
  //           },
  //         });
  //       } else {
  //         return OgLifeSpecificActions.noop();
  //       }
  //     })
  //   );
  // });

  moveLifeSpecificFromLifeState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.moveLifeSpecificFromLifeState),
      switchMap(
        ({ lifeSpecificId, targetLifeStateId, updatedLifeSpecifics }) => {
          return this.lifeSpecificsService
            .moveSpecificToLifeState(lifeSpecificId, targetLifeStateId)
            .pipe(
              map(() => {
                return OgLifeSpecificActions.setLifeSpecificsSortOrder({
                  lifeSpecifics: updatedLifeSpecifics,
                });
              }),
              catchError(({ error }) =>
                of(
                  OgLifeStateActions.updateLifeStateFail({
                    error: error.message,
                  })
                )
              )
            );
        }
      )
    );
  });

  moveLifeSpecificFromLifeHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.moveLifeSpecificFromLifeState),
      concatLatestFrom(() => this.store.select(selectLifeStatesEntities$)),
      switchMap(
        ([
          { lifeSpecificId, currentLifeStateId, targetLifeStateId },
          lifeStateEntities,
        ]) => {
          const targetLifeState = lifeStateEntities[
            targetLifeStateId
          ] as ILifeState;
          const currentLifeState = lifeStateEntities[
            currentLifeStateId
          ] as ILifeState;

          if (targetLifeState && currentLifeState) {
            return of(
              OgLifeStateActions.updateLifeStateSuccess({
                lifeState: {
                  ...targetLifeState,
                  specificsHidden: false,
                  specificIds: [...targetLifeState.specificIds, lifeSpecificId],
                },
              }),
              OgLifeStateActions.updateLifeStateSuccess({
                lifeState: {
                  ...currentLifeState,
                  specificIds: currentLifeState.specificIds.filter(
                    id => id !== lifeSpecificId
                  ),
                },
              })
            );
          } else {
            return of(OgLifeActions.noop());
          }
        }
      )
    );
  });

  deleteLifeStateHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeStateActions.deleteLifeStateSuccess),
      concatLatestFrom(() => this.store.select(selectLifeSpecificsEntities$)),
      exhaustMap(([{ lifeState }, lifeSpecificEntities]) => {
        const lifeStates = lifeState.specificIds.map(
          id => lifeSpecificEntities[id]
        );

        return lifeStates
          .filter(ls => !!ls)
          .map(lifeSpecific => {
            const specificId = lifeSpecific?.id as string;
            return OgLifeSpecificActions.deleteSpecificSuccess({
              specificId,
            });
          });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lifeSpecificsService: LifeSpecificsService,
    private lifeSerivce: LifeService,
    private toastr: ToastrService,
    private appService: AppService,
    private translate: TranslateService,
    private store: Store
  ) {}
}
