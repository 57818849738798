<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center justify-between py-3">
    <div class="flex items-center gap-x-2">
      <button
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white"
        #calendarStartMenuTrigger="cdkMenuTriggerFor"
        [cdkMenuTriggerFor]="calendarStartDateMenu">
        <i class="fa-thin fa-calendar-days"></i>
      </button>
      <span class="text-dropdowngray" *ngIf="!selectedStartDate">{{
        'StartDateTranslationTerm' | translate
      }}</span>
      <span class="text-dropdowngray">{{ selectedStartDate | date }}</span>
    </div>
    <div class="flex items-center gap-x-2">
      <button
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white"
        #calendarEndMenuTrigger="cdkMenuTriggerFor"
        [cdkMenuTriggerFor]="calendarEndDateMenu">
        <i class="fa-thin fa-calendar-days"></i>
      </button>
      <span class="text-dropdowngray" *ngIf="!selectedEndDate">{{
        'EndDateTranslationTerm' | translate
      }}</span>
      <span class="text-dropdowngray">{{ selectedEndDate | date }}</span>
    </div>
  </div>
</div>

<ng-template #calendarStartDateMenu>
  <div class="flex bg-white shadow flex-col gap-1 p-3 w-[300px]" cdkMenu>
    <mat-calendar
      class="holismo-calendar"
      [minDate]="lifeStartDate()"
      [maxDate]="maxStartDate"
      [selected]="getStartDate"
      (selectedChange)="selectStartDate($event)">
    </mat-calendar>
    <button
      *ngIf="getStartDate"
      (click)="selectStartDate(null)"
      class="border rounded text-sidenavicon inline-block justify-center self-center border-sidenavicon px-2">
      {{ 'Remove' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #calendarEndDateMenu>
  <div
    class="flex bg-white shadow flex-col gap-1 p-3 w-[300px]"
    cdkMenu="cdkMenu">
    <mat-calendar
      class="holismo-calendar"
      [minDate]="minEndDate"
      [selected]="getEndDate"
      (selectedChange)="selectEndDate($event)">
    </mat-calendar>
    <button
      *ngIf="getEndDate"
      (click)="selectEndDate(null)"
      class="border rounded text-sidenavicon inline-block justify-center self-center border-sidenavicon px-2">
      {{ 'Remove' | translate }}
    </button>
  </div>
</ng-template>
