<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-light fa-battery-full text-white"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsEnergy' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-x-2">
      <i class="fa-solid fa-battery-low text-red"></i>
      <mat-slider
        (change)="handleSelection($event)"
        min="-5"
        max="5"
        step="1"
        showTickMarks
        discrete>
        <input [value]="selected" matSliderThumb />
      </mat-slider>
      <i class="fa-solid fa-battery-full text-green"></i>
    </div>
  </div>
</div>
