import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { HabitType } from '@life/models/habit.type';

@Component({
  selector: 'hl-habit-dropdown',
  standalone: true,
  templateUrl: './habit-dropdown.component.html',
  imports: [TranslateModule, CdkMenuModule],
})
export class HabitDropdownComponent {
  @Input() selected: HabitType;

  @Output() changeHabit = new EventEmitter<HabitType>();

  constructor(private translate: TranslateService) {}

  handleSelection(selection: HabitType) {
    this.selected = selection;
    this.changeHabit.emit(selection);
  }

  get selectedLabel() {
    let changedSelection;
    switch (this.selected) {
      case HabitType.BUILD_HABIT:
        changedSelection = 'DrawerActivityDropdownsHabitOptionOne';
        break;
      case HabitType.MAINTAIN_HABIT:
        changedSelection = 'DrawerActivityDropdownsHabitOptionTwo';
        break;
      case HabitType.BREAK_HABIT:
        changedSelection = 'DrawerActivityDropdownsHabitOptionThree';
        break;
      default:
        changedSelection = '';
    }
    return changedSelection ? this.translate.instant(changedSelection) : '';
  }
}
