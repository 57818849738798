import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';

@Component({
  imports: [
    CommonModule,
    TranslateModule,
    CdkMenuModule,
    StopPropagationDirective,
    CdkMenuTrigger,
  ],
  selector: 'hl-announcements-dialog',
  templateUrl: './announcements-portal-dialog.component.html',
  standalone: true,
  // styleUrls: ['./support.component.scss']
})
export class AnnouncementsPortalDialogComponent {
  rawTranslations = false;
  selectedTab: string;
  selectedTopic = 'Topic / Subject';
  chooseTopic(topic: string) {
    this.selectedTopic = topic;
  }
}
