<div
  class="w-screen h-screen flex justify-center items-center min-h-screen"
  [style.background-color]="'rgba(130, 61, 144, 0.1)'">
  <div class="w-[609px] h-80">
    <div class="flex flex-col">
      <h2 class="font-bold text-8xl text-darkgray">404</h2>
      <h2
        class="font-bold text-[44px] mt-6 leading-[64px] text-purple"
        [innerHTML]="'NotFoundMessage' | translate"></h2>
      <button
        routerLink="/life"
        class="btn-primary h-20 w-72 rounded-lg mt-8 text-lg uppercase bg-purple">
        {{ 'NotFoundBackButton' | translate }}
      </button>
    </div>
  </div>
</div>
