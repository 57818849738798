import { Component } from '@angular/core';
import { FiltersBase } from '@components/filters/filters.base';
import { NgClass, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-urgency-filter',
  templateUrl: './urgency-filter.component.html',
  standalone: true,
  imports: [NgClass, NgForOf, TranslateModule],
})
export class UrgencyFilterComponent extends FiltersBase {
  urgencyFilters = Array.from(Array(6).keys()).reverse();
  ratingElements = Array.from(Array(5).keys());

  constructor() {
    super();
  }
}
