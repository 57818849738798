<div class="flex flex-col">
  <div class="flex items-center justify-between">
    <div class="flex gap-1 text-sm">
      <span>{{
        (filters.assignedStates
          ? 'ActivityDrawerAssignStatesTrue'
          : 'ActivityDrawerAssignStates'
        ) | translate
      }}</span>
      <mat-slide-toggle
        color="#48494A"
        class="holismo-switch"
        [(ngModel)]="filters.assignedStates"
        (ngModelChange)="loadLifeAreasWithActivities()"
        disableRipple></mat-slide-toggle>
    </div>
    <div class="flex gap-1 items-center justify-end flex-1">
      <hl-input-search
        [initialValue]="''"
        (search)="manageSearchFilter($event)"></hl-input-search>
      <button
        [cdkMenuTriggerFor]="filterMenu"
        [ngClass]="{ 'bg-filtershover text-white': filterCount > 0 }"
        class="w-9 h-9 bg-filters rounded-[50%] text-black hover:bg-filtershover hover:text-white">
        <i class="fa-thin fa-filter"></i>
      </button>
    </div>
  </div>
</div>
<div
  class="mt-4 select-none flex flex-col gap-2"
  data-sl="mask"
  *ngrxLet="lifeAreasWithActivities() as lifeAreasWithActivities">
  <hl-life-area-group
    *ngFor="let la of lifeAreasWithActivities; let i = index"
    [lifeArea]="la"
    [childElementsCount]="la.activities.length">
    <div
      hlStopPropagation
      *ngFor="
        let activity of lifeAreasWithActivities[i].activities;
        trackBy: trackByLifeActivityIndex
      ">
      <div
        hlStopPropagation
        tabindex="0"
        (keydown.enter)="toggleActivity(activity)"
        (click)="toggleActivity(activity)"
        [ngClass]="{
          'opacity-100 hover:opacity-60': hasActivity(activity),
          'opacity-50 hover:opacity-90': !hasActivity(activity)
        }"
        class="rounded-md relative flex flex-wrap justify-between items-center px-3 py-1 md:py-0 h-auto md:h-12 bg-white">
        <div class="flex flex-1 items-center gap-2">
          <div
            data-sl="mask"
            class="h-2.5 w-2.5 rounded-full"
            [ngStyle]="{ backgroundColor: la.darkColor }"></div>
          {{ activity.name | truncateText : 20 }}
        </div>
        <div hlStopPropagation class="flex items-center gap-3">
          <hl-rating
            [rating]="activity.importance"
            (updateRating)="
              updateActivityImportance(activity, $event)
            "></hl-rating>
          <hl-rating-select
            [satisfactionZeroClass]="'text-icons-gray'"
            [satisfaction]="activity.satisfaction"
            (setSatisfaction)="
              updateActivitySatisfaction(activity, $event)
            "></hl-rating-select>
          <hl-is-favorite
            [isFavorite]="activity.isFocused"
            (toggleFavourite)="
              updateActivityFocused(activity, $event)
            "></hl-is-favorite>
        </div>
      </div>
    </div>
  </hl-life-area-group>
</div>

<ng-template #filterMenu>
  <div
    class="flex bg-white shadow flex-col gap-6 py-3 md:py-7 px-4 md:px-9 -mr-1 max-w-full scale-90 overflow-auto md:scale-100"
    cdkMenu>
    <div class="flex items-center justify-between">
      <span class="text-black/70 text-base">{{
        'LifeHeaderFiltersTitle' | translate
      }}</span>
      <div class="flex items-center gap-2">
        <div
          *ngIf="filterCount > 0"
          class="w-6 h-6 bg-purple text-sm text-white rounded flex items-center justify-center">
          {{ filterCount }}
        </div>
        <button
          class="text-black/30 text-base"
          cdkMenuItem
          (click)="clearFilters()">
          {{ 'LifeHeaderFiltersClear' | translate }}
        </button>
      </div>
    </div>
    <div class="flex gap-1 justify-center flex-wrap sm:flex-nowrap">
      <hl-importance-filter
        [activeFilters]="filters.importance"
        (filterApplied)="
          manageImportanceFilters($event)
        "></hl-importance-filter>
      <hl-satisfaction-filter
        [activeFilters]="filters.satisfaction"
        (filterApplied)="
          manageSatisfactionFilters($event)
        "></hl-satisfaction-filter>
      <hl-is-favorite-filter
        [activeFilters]="filters.focus"
        (filterApplied)="manageFavoriteFilters($event)"></hl-is-favorite-filter>
    </div>
  </div>
</ng-template>
