<div
  (mouseenter)="manageSpecificsExpand(isDragOver())"
  (mouseleave)="manageSpecificsExpand(false)">
  <div
    [style.outline-color]="lifeState.isHighlighted ? color : mediumColor"
    style="outline-offset: -3px"
    class="drag-handle-state life-state-container hover:outline -outline-offset-[3px] rounded-md relative flex flex-wrap justify-between items-center px-3 py-1 md:py-0 h-10 md:h-12 bg-white z-10"
    [style.background-color]="lifeState.isHighlighted ? '#fff' : ''"
    (click)="selectLifeState(lifeState)"
    (keydown.enter)="selectLifeState(lifeState)"
    [ngClass]="{
      'rounded-t-md rounded-bl-md mb-1':
        (!lifeState.specificsHidden || lifeState.isChildSearchHit) &&
        lifeState.specifics.length > 0,
      'opacity-50': lifeState.isChildSearchHit,
      'outline -outline-offset-[3px]': lifeState.isHighlighted
    }"
    hlStopPropagation
    tabindex="0">
    <div class="flex flex-1 items-center gap-x-3 h-full">
      <div
        class="h-2.5 w-2.5 rounded-full drag-handle-state-mobile flex flex-grow-0 flex-shrink-0"
        [ngStyle]="{ backgroundColor: color }"></div>
      <span class="text-sm max-w-[80px] md:max-w-full line-clamp-1">
        <hl-inline-edit
          [editable]="canRename"
          (setValue)="rename($event)"
          (cancel)="canRename = false"
          [value]="lifeState.name"></hl-inline-edit
      ></span>
      <div class="flex items-center h-full md:pr-2 flex-1">
        <button
          hlStopPropagation
          (click)="
            lifeState.specifics.length > 0 || lifeState.readonly
              ? toggleLifeSpecificsVisibility(!lifeState.specificsHidden)
              : toggleAddNew()
          "
          class="w-5 h-5 mr-3 flex justify-center items-center rounded text-white text-[10px]"
          [ngStyle]="{ backgroundColor: color }">
          {{ lifeState.specifics.length }}
        </button>
        <div class="items-center gap-x-1 ml-auto md:ml-0 hidden md:flex">
          <button
            class="w-4 h-4 text-[10px] rounded-[50%] text-white"
            [ngStyle]="{ backgroundColor: color }"
            [ngClass]="{
              'pointer-events-none': lifeState.readonly
            }"
            hlStopPropagation
            [cdkMenuTriggerFor]="menu">
            <i class="fa-solid fa-ellipsis"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      hlStopPropagation
      [ngClass]="{
        'pointer-events-none': lifeState.disabled || lifeState.readonly
      }"
      class="flex pl-2 cursor-default h-full items-center gap-x-3 md:gap-x-6 justify-end md:justify-start">
      <hl-activity-mapper
        [title]="'ConnectedActionsTitle' | translate"
        [matTooltip]="'TooltipActions' | translate"
        [matTooltipShowDelay]="1500"
        data-sl="exclude"
        class="hidden md:block"
        [color]="lifeState.darkColor"
        [activities]="lifeState.activities"
        (addNew)="selectLifeState(lifeState, 1, true)"></hl-activity-mapper>
      <hl-rating
        [matTooltip]="'TooltipImportance' | translate"
        [matTooltipShowDelay]="1500"
        [rating]="lifeState.importance"
        [type]="appConstants.lifeState"
        (updateRating)="updateLifeStateImportance($event)"></hl-rating>
      <hl-rating-select
        [matTooltip]="'TooltipSatisfaction' | translate"
        [matTooltipShowDelay]="1500"
        [satisfactionZeroClass]="'text-icons-gray'"
        [satisfaction]="lifeState.satisfaction"
        (setSatisfaction)="
          updateLifeStateSatisfaction($event)
        "></hl-rating-select>
      <hl-rating
        class="hidden md:block"
        [matTooltip]="'TooltipUrgency' | translate"
        [matTooltipShowDelay]="1500"
        [rating]="lifeState.urgency"
        [type]="appConstants.lifeState"
        [readonly]="true"></hl-rating>
      <hl-is-favorite
        [matTooltip]="'TooltipFocus' | translate"
        [matTooltipShowDelay]="1500"
        [isFavorite]="lifeState.isFocused"
        (toggleFavourite)="updateLifeStateFocused($event)"></hl-is-favorite>
    </div>
  </div>
  <div
    dragula="LIFE_SPECIFICS"
    [dragulaModel]="lifeState.specifics"
    [id]="lifeState.id"
    [ngClass]="{
      'min-h-[40px]': expandSpecifics && lifeState.specifics.length === 0,
      'mt-1': lifeState.specifics.length === 0,
    }"
    class="flex flex-col gap-y-1 life-specifics-list">
    <hl-life-specific
      data-sl="mask"
      (touchmove)="touchmove()"
      [hidden]="
        lifeState.specificsHidden &&
        !lifeState.isChildSearchHit &&
        !expandSpecifics
      "
      *ngFor="
        let specific of lifeState.specifics;
        let i = index;
        let first = first;
        trackBy: trackByLifeSpecificsIndex
      "
      [attr.data-name]="specific.name"
      [lifeSpecific]="specific"
      [first]="first"
      [mediumColor]="mediumColor"
      [color]="color"></hl-life-specific>
  </div>
</div>

<hl-autocomplete
  *ngIf="showAddNew"
  [autofocus]="true"
  (hide)="toggleAddNew()"
  class="block"
  placeholder="{{ 'AddNewLifeSpecificPlaceholder' | translate }}"
  [options]="availableLifeSpecifics().available"
  [usedOptions]="availableLifeSpecifics().used"
  containerClass="pl-6 mt-1 mb-0"
  inputClass="py-2 pl-4"
  (selectOption)="addNewLifeSpecific($event)"></hl-autocomplete>
<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="canRename = true"
      class="popup-menu-btn">
      <i class="fa-regular fa-pen mr-4"></i>
      <span> {{ 'ContextMenuRename' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="toggleAddNew(true)"
      class="popup-menu-btn">
      <i class="fa-regular fa-circle-plus mr-4"></i>
      <span> {{ 'ContextMenuAddNewSpecific' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="deleteState()"
      class="popup-menu-btn-trash">
      <i class="fa-solid fa-trash mr-4"></i>
      <span> {{ 'ContextMenuDelete' | translate }}</span>
    </button>
  </div>
</ng-template>
