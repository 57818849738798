import { createSelector } from '@ngrx/store';
import { lifeSpecificsFeatureKey } from '@life/reducers/life-specifics.reducer';
import * as fromLifeSpecific from '@life/reducers/life-specifics.reducer';
import { selectLifeFeatureState } from '@life/reducers';
import { selectLifeTemplates$ } from '@life/selectors/life-templates.selectors';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { ILifeState } from '@life/models/life-state';
import {
  selectLifeStates$,
  selectLifeStatesEntities$,
} from '@life/selectors/life-state.selectors';
import { selectLifeNoteEntities$ } from '@life/selectors/life-notes.selectors';
import { ILifeNote } from '@life/models/life-note';

export const selectLifeSpecificsState = createSelector(
  selectLifeFeatureState,
  state => state[lifeSpecificsFeatureKey]
);

export const selectLifeSpecifics$ = createSelector(
  selectLifeSpecificsState,
  fromLifeSpecific.selectLifeSpecifics
);
export const selectLifeSpecificsEntities$ = createSelector(
  selectLifeSpecificsState,
  fromLifeSpecific.selectLifeSpecificsEntities
);

export const selectAvailableLifeSpecifics$ = (
  lifeStateName: string,
  lifeAreaName: string
) =>
  createSelector(
    selectLifeTemplates$,
    selectLifeStates$,
    selectLifeSpecifics$,
    selectLifeSpecificsEntities$,
    (lifeAreas, lifeStates, lifeSpecifics, lifeSpecificEntities) => {
      const used = [];
      const available = [];

      const usedLifeSpecificsIds =
        lifeStates.find(lifeState => lifeState.name === lifeStateName)
          ?.specificIds || [];

      const usedLifeSpecifics = usedLifeSpecificsIds
        .map(id => lifeSpecificEntities[id])
        .filter(s => !!s) as ILifeSpecifics[];

      const lifeSpecificsPresetsFromLifeArea =
        lifeAreas
          .find(la => la.name === lifeAreaName)
          ?.states.find(state => state.name === lifeStateName)?.specifics || [];

      for (const lifeSpecific of lifeSpecificsPresetsFromLifeArea) {
        if (usedLifeSpecifics.find(ls => ls.name === lifeSpecific.name)) {
          used.push(lifeSpecific);
        } else {
          available.push(lifeSpecific);
        }
      }

      return {
        used: used.map(ls => ls.name) || [],
        available: available.map(ls => ({ label: ls.name, item: ls })) || [],
      };
    }
  );

export const selectCheckIfLifeSpecificNameExists$ = (
  lifeSpecific: ILifeSpecifics,
  lifeStateId?: string
) =>
  createSelector(
    selectLifeStates$,
    selectLifeSpecifics$,
    selectLifeStatesEntities$,
    selectLifeSpecificsEntities$,
    (states, specifics, lifeStateEntities, lifeSpecificEntities) => {
      let state;
      if (!lifeStateId) {
        state = states.find(state =>
          state.specificIds.includes(lifeSpecific.id)
        );
      } else {
        state = lifeStateEntities[lifeStateId];
      }
      const specsOfThisState = state?.specificIds.map(
        id => lifeSpecificEntities[id]
      ) as ILifeSpecifics[];
      const specNameExists = specsOfThisState.find(
        spec => spec?.name?.toLowerCase() === lifeSpecific?.name?.toLowerCase()
      );
      return !!specNameExists;
    }
  );

export const selectLifeSpecificNotes$ = (lifeSpecificId: string) =>
  createSelector(
    selectLifeSpecificsEntities$,
    selectLifeNoteEntities$,
    (lifeSpecificsEntities, lifeNoteEntities) => {
      const lifeArea = lifeSpecificsEntities[lifeSpecificId] as ILifeState;
      if (lifeArea?.notes) {
        return lifeArea.notes
          .filter(note => !!lifeNoteEntities[note.id])
          .map(note => lifeNoteEntities[note.id] as ILifeNote);
      } else {
        return [];
      }
    }
  );
