<div class="overflow-y-auto min-w-[320px] rounded-2xl bg-white">
  <div class="w-full">
    <div class="p-6 mx-auto">
      <div class="mb-6" *ngIf="dialogData?.title">
        <h4 class="text-gray-600 text-2xl font-medium leading-normal">
          {{ dialogData.title }}
        </h4>
      </div>
      <div class="mb-6" *ngIf="dialogData?.message">
        <p class="text-gray-600">
          {{ dialogData.message }}
        </p>
      </div>
      <div class="flex items-center justify-end gap-x-2.5">
        <button class="btn-secondary" (click)="dialogRef.close(false)">
          {{ 'ButtonCancel' | translate }}
        </button>
        <button (click)="dialogRef.close(true)" class="btn-primary">
          {{ 'ButtonConfirm' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
