import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ILifeState } from '@life/models/life-state';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { ILifeActivity } from '@life/models/life-activity';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hl-activity-mapper',
  standalone: true,
  templateUrl: './activity-mapper.component.html',
  imports: [
    CommonModule,
    CdkMenu,
    CdkMenuItem,
    TranslateModule,
    CdkMenuTrigger,
  ],
})
export class ActivityMapperComponent {
  @Input() states: ILifeState[];
  @Input() specifics: ILifeSpecifics[];
  @Input() activities: ILifeActivity[];
  @Input() title: string | undefined;

  @Input() color: string | undefined;

  @Output() addNew = new EventEmitter();

  readonly satisfactionScore: any = {
    0: {
      icon: '',
      cssClass: '',
    },
    1: {
      icon: 'fa-face-disappointed',
      cssClass: 'icons-red',
    },
    2: {
      icon: 'fa-face-frown-slight',
      cssClass: 'icons-lightred',
    },
    3: { icon: 'fa-face-meh', cssClass: 'icons-yellow' },
    4: {
      icon: 'fa-face-smile',
      cssClass: 'icons-lightgreen',
    },
    5: { icon: 'fa-face-grin-wide', cssClass: 'icons-green' },
  };

  get statesAndSpecifics() {
    return [...(this.states || []), ...(this.specifics || [])];
  }
}
