<div class="bg-white shadow-md p-2 gap-x-2 rounded-3xl flex items-center">
  <div
    class="flex items-center p-2 rounded-2xl gap-x-2 h-7"
    [ngClass]="{
      'pointer-events-none': element.readonly
    }"
    [ngStyle]="{ 'background-color': element.mediumColor }">
    <hl-rating
      [inlineRating]="true"
      [rating]="element.importance"
      (updateRating)="updateRating($event)"></hl-rating>
    <hl-rating-select
      [satisfaction]="element.satisfaction"
      [satisfactionZeroClass]="'text-icons-gray'"
      (setSatisfaction)="updateSatisfaction($event)"></hl-rating-select>
    <hl-rating
      [rating]="element.urgency"
      [type]="'lifeArea'"
      [readonly]="true"></hl-rating>
    <hl-is-favorite
      *ngIf="element.type !== 'area'"
      [isFavorite]="element.isFocused"
      (toggleFavourite)="updateFocus($event)"></hl-is-favorite>
  </div>
  <button
    class="edit-button w-7 h-7 bg-filters text-black rounded-[50%] hover:bg-filtershover hover:text-white mr-2"
    (click)="openDetails.emit()">
    <i class="fa-solid fa-pen"></i>
  </button>
</div>
