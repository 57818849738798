import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'hl-error',
  templateUrl: './error.component.html',
  styles: [],
})
export class ErrorComponent {
  constructor(private auth: AuthService) {
    auth.error$.subscribe(e => {
      console.log(e);
    });
  }

  goToLogin() {
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }
}
