<div
  tabindex="0"
  (click)="selectLifeSpecific(lifeSpecific)"
  (keydown.enter)="selectLifeSpecific(lifeSpecific)"
  hlStopPropagation
  [style.background-color]="lifeSpecific.isHighlighted ? '#fff' : ''"
  [style.outline-color]="lifeSpecific.isHighlighted ? color : mediumColor"
  [ngClass]="{ 'outline -outline-offset-[3px]': lifeSpecific.isHighlighted }"
  class="drag-handle-specific life-specifics-container hover:outline -outline-offset-[3px] relative md:h-10 px-3 py-0.5 md:py-2 rounded-md flex flex-wrap justify-between items-center bg-white ml-6">
  <div
    class="life-specifics-connector hidden md:block absolute bottom-[20px] z-1 left-[-12px] w-[12px] border-l border-b"
    [style.border-color]="color"></div>
  <div class="flex flex-1 items-center gap-4 h-full">
    <span class="text-xs max-w-[80px] md:max-w-full line-clamp-1"
      ><hl-inline-edit
        [editable]="canRename"
        (setValue)="rename($event)"
        (cancel)="canRename = false"
        [value]="lifeSpecific.name"></hl-inline-edit>
    </span>
    <div class="h-full items-center md:pr-2 ml-auto hidden md:flex flex-1">
      <button
        class="w-4 h-4 text-[10px] rounded-[50%] text-white"
        [ngStyle]="{ backgroundColor: color }"
        [ngClass]="{
          'pointer-events-none': lifeSpecific.readonly
        }"
        hlStopPropagation
        [cdkMenuTriggerFor]="menu">
        <i class="fa-solid fa-ellipsis"></i>
      </button>
    </div>
  </div>
  <div
    hlStopPropagation
    [ngClass]="{
      'pointer-events-none': lifeSpecific.readonly
    }"
    class="flex h-full pl-2 cursor-default items-center gap-x-3 md:gap-x-6 justify-end md:justify-start">
    <hl-activity-mapper
      [title]="'ConnectedActionsTitle' | translate"
      [matTooltip]="'TooltipActions' | translate"
      [matTooltipShowDelay]="1500"
      data-sl="exclude"
      class="hidden md:block"
      [color]="lifeSpecific.darkColor"
      [activities]="lifeSpecific.activities"
      (addNew)="selectLifeSpecific(lifeSpecific, 1, true)"></hl-activity-mapper>
    <hl-rating
      [matTooltip]="'TooltipImportance' | translate"
      [matTooltipShowDelay]="1500"
      [rating]="lifeSpecific.importance"
      [type]="appConstants.lifeSpecifics"
      (updateRating)="updateLifeSpecificImportance($event)"></hl-rating>
    <hl-rating-select
      [matTooltip]="'TooltipSatisfaction' | translate"
      [matTooltipShowDelay]="1500"
      [satisfaction]="lifeSpecific.satisfaction"
      [satisfactionZeroClass]="'text-icons-gray'"
      (setSatisfaction)="updateSatisfaction($event)"></hl-rating-select>
    <hl-rating
      [matTooltip]="'TooltipUrgency' | translate"
      [matTooltipShowDelay]="1500"
      class="hidden md:block"
      [rating]="lifeSpecific.urgency"
      [type]="appConstants.lifeSpecifics"
      [readonly]="true"></hl-rating>
    <hl-is-favorite
      [matTooltip]="'TooltipFocus' | translate"
      [matTooltipShowDelay]="1500"
      [isFavorite]="lifeSpecific.isFocused"
      (toggleFavourite)="updateLifeSpecificFocused($event)"></hl-is-favorite>
  </div>
</div>
<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="canRename = true"
      class="popup-menu-btn">
      <i class="fa-regular fa-pen mr-4"></i>
      <span> {{ 'ContextMenuRename' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="delete()"
      class="popup-menu-btn-trash">
      <i class="fa-solid fa-trash mr-4"></i>
      <span> {{ 'ContextMenuDelete' | translate }}</span>
    </button>
  </div>
</ng-template>
