import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'hl-color-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './color-select.component.html',
})
export class ColorSelectComponent {
  @Input() colors: any[];

  @Output() selectColor = new EventEmitter<any>();
}
