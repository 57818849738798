const isFilterApplied = (filterArray: any[], value: any) => {
  return filterArray?.length > 0 ? filterArray.includes(value) : true;
};

const isSearchFilterApplied = (searchValue: string, value: any) => {
  return searchValue
    ? value.toLowerCase().indexOf(searchValue.toLowerCase()) != -1
    : true;
};

const isSearchHit = (searchValue: string, value: any) => {
  return searchValue
    ? value.toLowerCase().indexOf(searchValue.toLowerCase()) === -1
    : false;
};

const isFilterHit = (filterArray: any[], value: any) => {
  return filterArray.length > 0 ? !filterArray.includes(value) : false;
};

const mapActivitiesFilterValues = (
  firstLength: number | undefined,
  secondLength?: number | undefined
) => {
  const first = firstLength || 0;
  const second = secondLength || 0;
  const total = first + second;
  return total >= 5 ? 5 : total;
};

export {
  isFilterHit,
  isSearchHit,
  isFilterApplied,
  isSearchFilterApplied,
  mapActivitiesFilterValues,
};
