<div class="flex flex-col gap-2">
  <div
    class="bg-gray-100 rounded-lg py-3 px-6 text-black/50"
    *ngIf="!hideTitle">
    <span>{{ 'LifeHeaderFiltersFocus' | translate }}</span>
  </div>
  <div class="flex flex-col gap-0.5">
    <button
      class="flex items-center justify-center gap-0.5 px-3 py-1.5 hover:bg-icons-gray cursor-pointer"
      [class.bg-icons-gray]="isFilterActive(false)"
      (click)="applyFavoriteFilter(false)">
      <i
        class="fa-solid fa-star text-xl text-icons-gray"
        [class.text-white]="isFilterActive(false)"></i>
    </button>
    <button
      class="flex items-center justify-center gap-0.5 px-3 py-1.5 hover:bg-icons-gray cursor-pointer"
      [class.bg-icons-gray]="activeFilters.includes(true)"
      (click)="applyFavoriteFilter(true)">
      <i class="fa-solid fa-star text-xl text-icons-yellow"></i>
    </button>
  </div>
</div>
