import { createSelector } from '@ngrx/store';
import {
  lifeNoteFeatureKey,
  selectAllNotes,
  selectLifeNotesEntities,
} from '@life/reducers/life-note.reducer';
import { selectLifeFeatureState } from '@life/reducers';

export const selectLifeNoteState = createSelector(
  selectLifeFeatureState,
  state => state[lifeNoteFeatureKey]
);

export const selectAllElements$ = createSelector(
  selectLifeNoteState,
  selectAllNotes
);

export const selectLifeNoteEntities$ = createSelector(
  selectLifeNoteState,
  selectLifeNotesEntities
);

export const selectLifeNoteSearch$ = createSelector(
  selectLifeNoteState,
  state => state.search
);

export const selectNotes$ = (parentId: string) =>
  createSelector(selectAllElements$, selectLifeNoteSearch$, notes => {
    return notes.filter(note => note.elementId === parentId);
  });
