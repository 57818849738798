<div class="relative z-0 mx-auto">
  <input
    #searchInput
    [ngClass]="{
      'w-full bg-filtershover text-white cursor-text pl-12 pr-4': isExpanded
    }"
    [attr.aria-selected]="!!searchControl.value"
    type="text"
    role="menuitem"
    [formControl]="searchControl"
    (blur)="collapse($event)"
    (keyup.escape)="collapse($event)"
    [placeholder]="'LifeHeaderFiltersSearch' | translate"
    autocomplete="off"
    class="peer cursor-pointer pl-9 relative z-10 w-9 h-9 rounded-full text-black focus:bg-filtershover outline-none" />
  <button
    (click)="expand()"
    class="w-9 h-9 bg-filters text-black peer-hover:text-white peer-aria-selected:text-white peer-aria-selected:bg-filtershover peer-hover:bg-filtershover absolute inset-0 rounded-full z-20 peer-focus:bg-filtershover peer-focus:text-white hover:text-white hover:bg-filtershover">
    <i class="fa-thin fa-magnifying-glass"></i>
  </button>
  <button
    id="search-close"
    class="w-4 h-4 cursor-pointer bg-transparent text-white z-20 absolute top-1.5 right-2"
    *ngIf="!!searchControl.value && isExpanded"
    (click)="reset()">
    <i class="fa-thin fa-times"></i>
  </button>
</div>
