import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConstants } from '@core/constants/app-constants';
import { NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'hl-rating',
  standalone: true,
  templateUrl: './rating.component.html',
  imports: [NgFor, NgClass],
})
export class RatingComponent {
  @Input() rating: number;
  @Input() inlineRating: boolean;
  @Input() readonly = false;
  @Input() type: string;

  appConstants = AppConstants;

  @Output() updateRating = new EventEmitter<number>();

  ratingItems = Array.from(Array(5).keys());
}
