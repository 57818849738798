import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { ILifeNote } from '@life/models/life-note';
import { OgLifeNoteActions } from '@life/actions';

export const lifeNoteFeatureKey = 'life-notes';

export interface NoteState extends EntityState<ILifeNote> {
  error?: string | null;
  search?: string | null;
}

export const adapter = createEntityAdapter<ILifeNote>();

const initialState: NoteState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(OgLifeNoteActions.loadNotesSuccess, (state: NoteState, { notes }) =>
    adapter.setAll(notes, state)
  ),
  on(OgLifeNoteActions.addNoteSuccess, (state: NoteState, { note }) =>
    adapter.addOne(note as ILifeNote, state)
  ),
  on(
    OgLifeNoteActions.updateNote,
    OgLifeNoteActions.updateNoteSuccess,
    OgLifeNoteActions.updateNoteFocus,
    (state: NoteState, { note }) =>
      adapter.updateOne({ id: note.id, changes: { ...note } }, state)
  ),
  on(OgLifeNoteActions.deleteNote, (state: NoteState, { noteId }) =>
    adapter.removeOne(noteId, state)
  )
);

export const {
  selectIds: selectLifeNoteIds,
  selectEntities: selectLifeNotesEntities,
  selectAll: selectAllNotes,
} = adapter.getSelectors();
