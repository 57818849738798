import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectLifeDateFilter$ } from '@life/selectors';
import { LifeFiltersActions } from '@life/actions';
import { PricingTableDialogComponent } from '@components/dialogs/pricing-table-dialog/pricing-table-dialog.component';
import { selectDisplayLifeWheel$ } from '@core/selectors/user.selectors';
@Component({
  selector: 'hl-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  selectedDate = this.store.selectSignal(selectLifeDateFilter$);
  displayLifeWheel = this.store.selectSignal(selectDisplayLifeWheel$);
  get activeSegment() {
    // todo: check
    return this.location.path().split('?')[0].split('/')[1];
  }
  constructor(
    private location: Location,
    private dialog: Dialog,
    private translate: TranslateService,
    private store: Store
  ) {}

  upsale() {
    this.dialog.open(PricingTableDialogComponent, {
      maxWidth: '430px',
      panelClass: 'z-50',
      data: {
        disableClose: true,
        message: this.translate.instant('DialogsUpgradePlanMessageLifeWheel'),
      },
    });
  }

  resetDateFilter() {
    this.store.dispatch(LifeFiltersActions.setLifeFiltersDate({ date: null }));
  }
}
