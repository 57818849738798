import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { NgIf } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

type HLToastOptions = IndividualConfig<any> & {
  icon: string;
};

@Component({
  selector: 'hl-toast',
  templateUrl: './toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  preserveWhitespaces: false,
  standalone: true,
  imports: [NgIf],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ToastComponent extends Toast {
  override options: HLToastOptions;
  constructor(
    protected toasterService: ToastrService,
    public toasterPackage: ToastPackage
  ) {
    super(toasterService, toasterPackage);
  }
}
