<div class="flex flex-col">
  <div class="flex items-center justify-between">
    <div class="flex gap-1 text-sm">
      <span>{{
        (filters.assignedStates
          ? 'ActivityDrawerAssignStatesTrue'
          : 'ActivityDrawerAssignStates'
        ) | translate
      }}</span>
      <mat-slide-toggle
        class="holismo-switch"
        color="#48494A"
        disableRipple
        [(ngModel)]="filters.assignedStates"
        (ngModelChange)="loadLifeAreas()"></mat-slide-toggle>
    </div>
    <div class="flex gap-1 items-center justify-end flex-1">
      <hl-input-search
        [initialValue]="''"
        (search)="manageSearchFilter($event)"></hl-input-search>
      <button
        [cdkMenuTriggerFor]="filterMenu"
        [ngClass]="{ 'bg-filtershover text-white': filterCount > 0 }"
        class="w-9 h-9 bg-filters rounded-[50%] text-black hover:bg-filtershover hover:text-white">
        <i class="fa-thin fa-filter"></i>
      </button>
    </div>
  </div>
</div>
<div
  class="mt-4 select-none flex flex-col gap-2"
  *ngrxLet="lifeAreas() as lifeAreas">
  <hl-life-area-group
    data-sl="mask"
    *ngFor="let la of lifeAreas; let i = index"
    [lifeArea]="la"
    [childElementsCount]="la.states.length">
    <div
      data-sl="mask"
      hlStopPropagation
      *ngFor="
        let lifeState of lifeAreas[i].states;
        trackBy: trackByLifeStateIndex
      ">
      <div
        hlStopPropagation
        tabindex="0"
        (keydown.enter)="toggleStateToActivity(lifeState.id)"
        (click)="toggleStateToActivity(lifeState.id)"
        [ngClass]="{
          'opacity-100 hover:opacity-60': hasLifeState(lifeState.id),
          'opacity-50 hover:opacity-90': !hasLifeState(lifeState.id)
        }"
        class="rounded-md relative flex flex-wrap justify-between items-center px-3 py-1 md:py-0 h-auto md:h-12 bg-white">
        <div class="flex flex-1 items-center gap-2">
          <div
            class="h-2.5 w-2.5 rounded-full"
            [ngStyle]="{ backgroundColor: la.darkColor }"></div>
          {{ lifeState.name | truncateText : 15 }}
          <button
            hlStopPropagation
            (click)="toggleSpecifics(lifeState.id)"
            class="w-5 h-5 mr-3 flex justify-center items-center rounded text-white text-[10px]"
            [ngStyle]="{ backgroundColor: la.darkColor }">
            {{ lifeState.specifics.length }}
          </button>
        </div>
        <div hlStopPropagation class="flex items-center gap-3">
          <hl-rating
            [rating]="lifeState.importance"
            [type]="appConstants.lifeState"
            (updateRating)="
              updateLifeStateImportance(lifeState, $event)
            "></hl-rating>
          <hl-rating-select
            [satisfactionZeroClass]="'text-icons-gray'"
            [satisfaction]="lifeState.satisfaction"
            (setSatisfaction)="
              updateLifeStateSatisfaction(lifeState, $event)
            "></hl-rating-select>
          <hl-is-favorite
            [isFavorite]="lifeState.isFocused"
            (toggleFavourite)="
              updateLifeStateFocused(lifeState, $event)
            "></hl-is-favorite>
        </div>
      </div>
      <ng-container *ngIf="!stateIsHidden(lifeState.id)">
        <div
          hlStopPropagation
          *ngFor="
            let lifeSpecific of lifeState.specifics;
            let first = first;
            let last = last
          "
          class="ml-4 relative"
          tabindex="0"
          (click)="addSpecificToActivity(lifeSpecific.id)"
          (keyup.enter)="addSpecificToActivity(lifeSpecific.id)">
          <div
            class="life-specifics-connector hidden md:block absolute bottom-[20px] z-1 left-[-6px] w-[6px] border-l border-b"
            [ngClass]="first ? 'h-[24px]' : 'h-[45px]'"
            [style.border-color]="lifeSpecific.darkColor"></div>
          <div
            [ngClass]="{
              'opacity-100 hover:opacity-60': hasLifeSpecific(lifeSpecific.id),
              'opacity-50 hover:opacity-90': !hasLifeSpecific(lifeSpecific.id),
              'md-0': last
            }"
            class="flex flex-1 flex-wrap rounded-md justify-between items-center px-3 my-1 md:py-0 h-auto md:h-10 bg-white">
            <div class="flex items-center gap-2">
              <div>{{ lifeSpecific.name | truncateText : 20 }}</div>
            </div>
            <div hlStopPropagation class="flex items-center gap-3">
              <hl-rating
                [rating]="lifeSpecific.importance"
                [type]="appConstants.lifeSpecifics"
                (updateRating)="
                  updateLifeSpecificImportance(lifeSpecific, $event)
                "></hl-rating>
              <hl-rating-select
                [satisfactionZeroClass]="'text-icons-gray'"
                [satisfaction]="lifeSpecific.satisfaction"
                (setSatisfaction)="
                  updateLifeSpecificSatisfaction(lifeSpecific, $event)
                "></hl-rating-select>
              <hl-is-favorite
                [isFavorite]="lifeSpecific.isFocused"
                (toggleFavourite)="
                  updateLifeSpecificFocused(lifeSpecific, $event)
                "></hl-is-favorite>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </hl-life-area-group>
</div>
<ng-template #filterMenu>
  <div
    class="flex bg-white shadow flex-col gap-6 py-3 md:py-7 px-4 md:px-9 -mr-1 max-w-full scale-90 overflow-auto md:scale-100"
    cdkMenu>
    <div class="flex items-center justify-between">
      <span class="text-black/70 text-base">{{
        'LifeHeaderFiltersTitle' | translate
      }}</span>
      <div class="flex items-center gap-2">
        <div
          *ngIf="filterCount > 0"
          class="w-6 h-6 bg-purple text-sm text-white rounded flex items-center justify-center">
          {{ filterCount }}
        </div>
        <button
          class="text-black/30 text-base"
          cdkMenuItem
          (click)="clearFilters()">
          {{ 'LifeHeaderFiltersClear' | translate }}
        </button>
      </div>
    </div>
    <div class="flex gap-1 justify-center flex-wrap sm:flex-nowrap">
      <hl-importance-filter
        [activeFilters]="filters.importance"
        (filterApplied)="
          manageImportanceFilters($event)
        "></hl-importance-filter>
      <hl-satisfaction-filter
        [activeFilters]="filters.satisfaction"
        (filterApplied)="
          manageSatisfactionFilters($event)
        "></hl-satisfaction-filter>
      <hl-is-favorite-filter
        [activeFilters]="filters.focus"
        (filterApplied)="manageFavoriteFilters($event)"></hl-is-favorite-filter>
    </div>
  </div>
</ng-template>
