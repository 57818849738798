import { Component, Input, ChangeDetectorRef } from '@angular/core';

import { ILifeActivity } from '@life/models/life-activity';
import { Store } from '@ngrx/store';
import { OgActivityActions } from '@life/actions';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '@app/components';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-activity',
  templateUrl: './activity.component.html',
})
export class ActivityComponent {
  @Input() activity: ILifeActivity;
  @Input() defaultView: boolean;

  canRename = false;

  constructor(
    private store: Store,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: Dialog,
    private translate: TranslateService
  ) {}

  selectActivity(activity: ILifeActivity, activeTabIndex = 0, force = false) {
    if (activity.isHighlighted && !force) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: null,
          activeItemType: null,
          activeTabIndex: null,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: activity.id,
          activeItemType: 'activity',
          activeTabIndex,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  updateLifeActivityImportance(importance: number) {
    this.store.dispatch(
      OgActivityActions.setActivityImportance({
        activity: { ...this.activity, importance },
      })
    );
  }
  updateLifeActivitySatisfaction(satisfaction: number) {
    this.store.dispatch(
      OgActivityActions.setActivitySatisfaction({
        activity: { ...this.activity, satisfaction },
      })
    );
  }
  updateLifeActivityFocused(isFocused: boolean) {
    this.store.dispatch(
      OgActivityActions.setActivityFocus({
        activity: { ...this.activity, isFocused },
      })
    );
  }

  updateLifeActivityIcon(icon: string) {
    this.store.dispatch(
      OgActivityActions.setActivityIcon({
        activity: {
          ...this.activity,
          icon,
        },
      })
    );
  }

  deleteActivity() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant(
          'ConfirmationDialogDeleteLifeActivityTitle'
        ),
        message: this.translate.instant(
          'ConfirmationDialogDeleteLifeActivityMessage',
          {
            name: this.activity.name,
          }
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(
          OgActivityActions.deleteActivity({ activity: this.activity })
        );
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            activeItemId: null,
            activeItemType: null,
            activeTabIndex: null,
          },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      }
    });
  }

  rename(name: string) {
    this.canRename = false;
    this.store.dispatch(
      OgActivityActions.setActivityName({
        activity: { ...this.activity, name: name },
      })
    );
  }
}
