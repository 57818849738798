import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DayTimeType } from '@life/models/day-time.type';

@Component({
  selector: 'hl-time-of-day-dropdown',
  standalone: true,
  templateUrl: './time-of-day-dropdown.component.html',
  imports: [CommonModule, CdkMenuModule, TranslateModule],
})
export class TimeOfDayDropdownComponent {
  @Input() selected: DayTimeType | undefined;

  @Output() changePartOfTheDay = new EventEmitter<DayTimeType>();

  handleSelection(selection: DayTimeType) {
    this.selected = selection;
    this.changePartOfTheDay.emit(selection);
  }

  get selectedDayTime() {
    switch (this.selected) {
      case 1:
        return 'AccountSettingsTimeOfDayMorning';
      case 2:
        return 'AccountSettingsTimeOfDayAfternoon';
      case 3:
        return 'AccountSettingsTimeOfDayEvening';
      case 4:
        return 'AccountSettingsTimeOfDayNight';
      case 8:
        return 'AccountSettingsTimeOfDayAllDay';
      default:
        return '';
    }
  }
}
