import { Component, Input } from '@angular/core';

@Component({
  selector: 'hl-part-of-day-activities',
  templateUrl: './part-of-day-activities.component.html',
})
export class PartOfDayActivitiesComponent {
  @Input() partsOfDayWithActivities: any;

  showActivities = false;

  toggleActivitiesVisibility() {
    this.showActivities = !this.showActivities;
  }
}
