<div class="flex w-full items-center px-3">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-solid fa-sunrise text-white"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsTimeOfTheDay' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-1" [cdkMenuTriggerFor]="menu">
      <span class="text-black" *ngIf="selectedDayTime">{{
        selectedDayTime | translate
      }}</span>
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>
<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button
      cdkMenuItem
      class="popup-menu-btn flex gap-2"
      (click)="handleSelection(1)">
      <i class="fa-thin fa-sunrise"></i>
      <span>{{ 'AccountSettingsTimeOfDayMorning' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      class="popup-menu-btn flex gap-2"
      (click)="handleSelection(2)">
      <i class="fa-thin fa-sun"></i>
      <span>{{ 'AccountSettingsTimeOfDayAfternoon' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      class="popup-menu-btn flex gap-2"
      (click)="handleSelection(3)">
      <i class="fa-thin fa-sunset"></i>
      <span>{{ 'AccountSettingsTimeOfDayEvening' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      class="popup-menu-btn flex gap-2"
      (click)="handleSelection(4)">
      <i class="fa-thin fa-moon"></i>
      <span>{{ 'AccountSettingsTimeOfDayNight' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      class="popup-menu-btn flex gap-2"
      (click)="handleSelection(8)">
      <i class="fa-thin fa-timer"></i>
      <span>{{ 'AccountSettingsTimeOfDayAllDay' | translate }}</span>
    </button>
  </div>
</ng-template>
