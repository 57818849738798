import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateHelperService {
  get useRawTranslations(): boolean {
    return this._useRawTranslations;
  }

  set useRawTranslations(value: boolean) {
    this._useRawTranslations = value;
  }
  private _useRawTranslations = false;
}
