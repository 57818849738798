<div class="flex flex-row-reverse" data-sl="exclude">
  <div
    class="flex"
    [cdkMenuTriggerFor]="stateSpecificMenu"
    [cdkMenuPosition]="[
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 6
      }
    ]">
    <div
      *ngFor="let element of statesAndSpecifics; let i = index"
      class="w-5 h-5 rounded-full border hover:z-50 z-[{{ i }}] bg-{{
        satisfactionScore[element.satisfaction].cssClass
      }}"
      [ngClass]="i < statesAndSpecifics.length ? 'mr-[-6px]' : ''"></div>
  </div>
  <div
    class="flex"
    [cdkMenuTriggerFor]="activitiesMenu"
    [cdkMenuPosition]="[
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 6
      }
    ]">
    <div
      *ngFor="let element of activities; let i = index"
      class="hover:z-50 z-[{{ i }}] relative text-{{
        satisfactionScore[element.satisfaction].cssClass
      }}"
      [ngClass]="activities && i < activities.length ? 'mr-[-6px]' : ''">
      <div class="w-3 h-3 absolute top-[6px] left-[2px] bg-white z-[1]"></div>
      <i class="fa-solid fa-clipboard-check text-xl relative z-[1]"></i>
    </div>
  </div>
  <button
    *ngIf="
      statesAndSpecifics.length === 0 &&
      (!activities || activities.length === 0)
    "
    [ngClass]="{ 'w-5 h-5 border': !activities }"
    class="rounded-full text-sm flex items-center justify-center mr-[-8px] bg-white"
    [style.color]="color"
    (click)="addNew.emit()">
    <i *ngIf="!!activities" class="fa-kit fa-clipboard-thin-plus text-xl"></i>
    <i *ngIf="!activities" class="fa-solid fa-plus"></i>
  </button>
</div>

<ng-template #stateSpecificMenu>
  <div cdkMenu class="popup-menu gap-y-1">
    <h4 class="font-medium text-black mb-1">{{ title }}</h4>
    <div
      data-sl="mask"
      *ngFor="let element of statesAndSpecifics"
      cdkMenuItem
      (cdkMenuItemTriggered)="addNew.emit()"
      tabindex="-1"
      class="flex items-center justify-between cursor-pointer outline-0">
      <span>{{ element.name }}</span>
      <span
        ><i
          [ngClass]="[
            satisfactionScore[element.satisfaction].icon,
            element.satisfaction === 0
              ? 'text-white'
              : 'text-' + satisfactionScore[element.satisfaction].cssClass
          ]"
          class="fa-solid relative rounded-full text-xl">
        </i
      ></span>
    </div>
    <button class="fixed outline-0 right-[-99999px]" cdkMenuItem>&nbsp;</button>
  </div>
</ng-template>

<ng-template #activitiesMenu>
  <div cdkMenu class="popup-menu gap-y-1">
    <h4 class="font-medium text-black mb-1">{{ title }}</h4>
    <div
      data-sl="mask"
      cdkMenuItem
      (cdkMenuItemTriggered)="addNew.emit()"
      *ngFor="let element of activities"
      class="flex items-center justify-between cursor-pointer outline-0">
      <span>{{ element.name }}</span>
      <span
        ><i
          [ngClass]="[
            satisfactionScore[element.satisfaction].icon,
            element.satisfaction === 0
              ? 'text-white'
              : 'text-' + satisfactionScore[element.satisfaction].cssClass
          ]"
          class="fa-solid relative rounded-full text-xl">
        </i
      ></span>
    </div>
  </div>
</ng-template>
