<section
  (mouseenter)="manageStateExpand(isDragOver())"
  (mouseleave)="manageStateExpand(false)"
  style="outline-offset: -3px"
  class="life-area-container flex flex-col relative z-10 border-none rounded-md radius-lg shadow-none px-3 md:px-6 hover:outline -outline-offset-[3px] cursor-pointer"
  [style.outline-color]="
    lifeArea.isHighlighted ? lifeArea.darkColor : lifeArea.mediumColor
  "
  tabindex="0"
  (click)="selectLifeArea(lifeArea)"
  (keydown.enter)="selectLifeArea(lifeArea)"
  hlStopPropagation
  [style.background-color]="lifeArea.lightColor"
  [class.area-highlighted]="lifeArea.isHighlighted"
  [ngClass]="{
    'outline -outline-offset-[3px]': lifeArea.isHighlighted,
    'opacity-30': lifeArea.disabled
  }"
  [class.md:pb-2]="!lifeArea.statesHidden && lifeArea.states.length > 0"
  [class.md:pb-8]="lifeArea.states.length === 0 && showAddNew">
  <div
    class="flex flex-wrap items-center justify-between py-3 md:py-0 md:px-3 h-auto md:h-16 md:pr-[53px] md:pl-0"
    [ngClass]="{
      'opacity-50': lifeArea.isChildSearchHit,
      'drag-handle': !lifeArea.disabled
    }">
    <div class="flex flex-1 items-center h-full">
      <header class="flex items-center justify-center gap-x-3 h-full">
        <i
          class="fa-solid w-5"
          [ngClass]="lifeArea.icon"
          [style.color]="lifeArea.darkColor"></i>
        <h6
          class="text-base max-w-[80px] md:max-w-full line-clamp-1"
          [ngStyle]="{ color: lifeArea.darkColor }">
          <hl-inline-edit
            [editable]="canRename"
            (setValue)="rename($event)"
            (cancel)="canRename = false"
            [value]="lifeArea.name"></hl-inline-edit>
        </h6>
        <div hlStopPropagation class="flex items-center h-full md:pr-2 flex-1">
          <button
            [matTooltip]="'TooltipLifeStates' | translate"
            [matTooltipShowDelay]="1500"
            hlStopPropagation
            (click)="
              lifeArea.states.length > 0 || lifeArea.readonly
                ? toggleLifeStatesVisibility(!lifeArea.statesHidden)
                : toggleAddNew()
            "
            class="w-5 h-5 mr-3 flex items-center justify-center rounded text-[10px] text-white"
            [style.background-color]="lifeArea.darkColor">
            {{ lifeArea.states.length }}
          </button>
          <div class="items-center gap-x-1 hidden md:flex">
            <button
              [ngClass]="{
                'pointer-events-none': lifeArea.disabled || lifeArea.readonly
              }"
              class="w-4 h-4 text-[10px] rounded-[50%] text-white"
              hlStopPropagation
              [style.background-color]="lifeArea.darkColor"
              [cdkMenuTriggerFor]="menu">
              <i class="fa-solid fa-ellipsis"></i>
            </button>
          </div>
        </div>
      </header>
    </div>
    <div
      [ngClass]="{
        'pointer-events-none': lifeArea.disabled || lifeArea.readonly
      }"
      hlStopPropagation
      class="flex pl-2 h-full items-center cursor-default gap-x-3 md:gap-x-6 justify-end md:justify-start py-2 md:py-0">
      <hl-rating
        [matTooltip]="'TooltipImportance' | translate"
        [matTooltipShowDelay]="1500"
        [rating]="lifeArea.importance"
        [type]="'lifeArea'"
        (updateRating)="setLifeAreaImportance($event)"></hl-rating>
      <hl-rating-select
        [matTooltip]="'TooltipSatisfaction' | translate"
        [matTooltipShowDelay]="1500"
        [satisfaction]="lifeArea.satisfaction"
        [satisfactionZeroClass]="'text-white'"
        (setSatisfaction)="setLifeAreaSatisfaction($event)"></hl-rating-select>
      <hl-rating
        class="hidden md:block"
        [matTooltip]="'TooltipUrgency' | translate"
        [matTooltipShowDelay]="1500"
        [type]="'lifeArea'"
        [rating]="lifeArea.urgency"
        [readonly]="true"></hl-rating>
    </div>
  </div>
  <div
    dragula="LIFE_STATES"
    [dragulaModel]="lifeArea.states"
    [id]="lifeArea.id"
    class="flex flex-col gap-y-2 life-states-list"
    [ngClass]="{
      'mb-4':
        (!lifeArea.statesHidden && lifeArea.states.length > 0 && !showAddNew) ||
        expandStates ||
        lifeArea.isChildSearchHit,
      'min-h-[70px] mb-2': expandStates && lifeArea.states.length === 0,
      'pointer-events-none': lifeArea.disabled
    }">
    <hl-life-state
      data-sl="mask"
      (touchmove)="touchmove()"
      [hidden]="
        lifeArea.statesHidden && !lifeArea.isChildSearchHit && !expandStates
      "
      *ngFor="let lifeState of lifeArea.states; trackBy: trackByLifeStateIndex"
      [attr.data-name]="lifeState.name"
      [lifeState]="lifeState"
      [color]="lifeArea.darkColor"
      [mediumColor]="lifeArea.mediumColor"
      [lifeAreaName]="lifeArea.name"></hl-life-state>
  </div>
  <hl-autocomplete
    *ngIf="showAddNew && availableLifeStates() && !lifeArea.disabled"
    [autofocus]="true"
    [containerClass]="'mt-0 mb-3.5'"
    class="block"
    placeholder="{{ 'AddNewLifeStatePlaceholder' | translate }}"
    (hide)="toggleAddNew()"
    [options]="availableLifeStates().available"
    [usedOptions]="availableLifeStates().used"
    [inputClass]="lifeArea.states.length > 0 ? 'py-3 mt-2 px-6' : 'py-3 px-6'"
    (selectOption)="addNewLifeState($event)"></hl-autocomplete>
</section>
<ng-template #menu>
  <div cdkMenu class="popup-menu">
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="canRename = true"
      class="popup-menu-btn">
      <i class="w-3 fa-regular fa-pen mr-4"></i>
      <span> {{ 'ContextMenuRename' | translate }}</span>
    </button>
    <button
      [cdkMenuTriggerFor]="iconMenu"
      cdkMenuItem
      hlStopPropagation
      class="popup-menu-btn justify-between">
      <div>
        <i class="mr-3 fa-solid w-3" [ngClass]="lifeArea.icon"></i>
        <span> {{ 'ContextMenuChangeIcon' | translate }}</span>
      </div>
      <i class="fa-solid fa-arrow-right"></i>
    </button>
    <button
      cdkMenuItem
      [cdkMenuTriggerFor]="colorPickerMenu"
      hlStopPropagation
      class="popup-menu-btn justify-between">
      <div>
        <i class="w-3 fa-regular fa-droplet mr-3"></i>
        <span> {{ 'ContextMenuChangeColor' | translate }}</span>
      </div>
      <i class="fa-solid fa-arrow-right"></i>
    </button>
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="toggleAddNew(true)"
      class="popup-menu-btn">
      <i class="w-3 fa-regular fa-circle-plus mr-4"></i>
      <span> {{ 'ContextMenuAddNewState' | translate }}</span>
    </button>
    <button
      cdkMenuItem
      hlStopPropagation
      (click)="deleteLifeArea()"
      class="popup-menu-btn-trash">
      <i class="w-3 fa-solid fa-trash mr-4"></i>
      <span> {{ 'ContextMenuDelete' | translate }}</span>
    </button>
  </div>
</ng-template>
<ng-template #iconMenu>
  <div class="popup-menu p-0 shadow-none" cdkMenu>
    <hl-icon-select
      [icons]="[]"
      (selectIcon)="setLifeAreaIcon($event)"></hl-icon-select>
  </div>
</ng-template>

<ng-template #colorPickerMenu>
  <div cdkMenu class="popup-menu w-[125px]">
    <hl-color-select
      cdkMenuItem
      [colors]="allColors()"
      (selectColor)="setLifeAreaColor($event)"></hl-color-select>
  </div>
</ng-template>
