<div
  class="bg-[url('/assets/images/error.png')] bg-cover bg-center h-screen flex justify-center items-center">
  <div class="w-[295px] h-auto bg-white rounded px-6 py-12">
    <div class="">
      <img src="assets/images/logo2.svg" alt="Holismo" />
    </div>
    <div class="flex flex-col items-center mt-10">
      <h3 class="text-xl font-bold">{{ 'ErrorLoginMethod' | translate }}</h3>
      <div
        class="w-16 h-16 rounded-full bg-holismo-purple flex items-center justify-center my-8">
        <img src="assets/images/oauth/x-white.png" alt="Email" />
      </div>
    </div>
    <div class="text-sm">
      <span class="block mb-4">
        {{ 'ErrorLoginMethodMessage' | translate }}</span
      >
      <div class="flex mt-12 justify-end">
        <button
          class="text-sm px-2 text-holismo-purple rounded-sm"
          (click)="goToLogin()">
          {{ 'ErrorLoginRedirect' | translate
          }}<i class="fa-solid fa-arrow-right text-xs ml-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>
