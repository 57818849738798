import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'hl-is-favorite',
  standalone: true,
  templateUrl: './is-favorite.component.html',
  imports: [NgClass],
})
export class IsFavoriteComponent {
  @Input() isFavorite: boolean;
  @Input() notFavoriteWhiteBg: boolean = false;

  @Output() toggleFavourite = new EventEmitter<boolean>();
}
