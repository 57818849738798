import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLifePriorityUI$ } from '@life/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { LifeItem } from '@life/models/life';
import {
  OgLifeSpecificActions,
  OgLifeStateActions,
  OgLifeActions,
} from '@life/actions';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { ILifeState } from '@life/models/life-state';
import { ILifeArea } from '@life/models/life-area';

@Component({
  selector: 'hl-life-priority',
  templateUrl: './life-priority.component.html',
})
export class LifePriorityComponent {
  elements = this.store.selectSignal(selectLifePriorityUI$);
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  openDetails(element: { type: string; id: string }) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId: element.id,
        activeItemType: element.type,
        activeTabIndex: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  handleElementFocus(isFocused: boolean, element: LifeItem) {
    switch (element.type) {
      case 'specific':
        this.store.dispatch(
          OgLifeSpecificActions.setSpecificFocus({
            specific: {
              ...((<unknown>element) as ILifeSpecifics),
              isFocused,
            },
          })
        );
        break;
      case 'state':
        this.store.dispatch(
          OgLifeStateActions.setLifeStateFocus({
            lifeState: {
              ...((<unknown>element) as ILifeState),
              isFocused,
            },
          })
        );
        break;
      default:
        return;
    }
  }

  handleElementRating(importance: number, element: LifeItem) {
    switch (element.type) {
      case 'area':
        this.store.dispatch(
          OgLifeActions.setLifeAreaImportance({
            lifeArea: {
              ...((<unknown>element) as ILifeArea),
              importance,
            },
          })
        );
        return;
      case 'specific':
        this.store.dispatch(
          OgLifeSpecificActions.setSpecificImportance({
            specific: {
              ...((<unknown>element) as ILifeSpecifics),
              importance,
            },
          })
        );
        return;
      case 'state':
        this.store.dispatch(
          OgLifeStateActions.setLifeStateImportance({
            lifeState: {
              ...((<unknown>element) as ILifeState),
              importance,
            },
          })
        );
        return;
      default:
        return;
    }
  }

  handleElementSatisfaction(satisfaction: number, element: LifeItem) {
    switch (element.type) {
      case 'area':
        this.store.dispatch(
          OgLifeActions.setLifeAreaSatisfaction({
            lifeArea: {
              ...((<unknown>element) as ILifeArea),
              satisfaction,
            },
          })
        );
        return;
      case 'specific':
        this.store.dispatch(
          OgLifeSpecificActions.setSpecificSatisfaction({
            specific: {
              ...((<unknown>element) as ILifeSpecifics),
              satisfaction,
            },
          })
        );
        return;
      case 'state':
        this.store.dispatch(
          OgLifeStateActions.setLifeStateSatisfaction({
            lifeState: {
              ...((<unknown>element) as ILifeState),
              satisfaction,
            },
          })
        );
        return;
      default:
        return;
    }
  }
}
