<div
  class="flex flex-col gap-y-1"
  [ngClass]="[mobileView ? 'm-6 pb-[50px]' : '']">
  <div
    class="col-span-2 flex flex-col gap-y-3 text-slate-700"
    *ngrxLet="auth.user$ | async as user">
    <h2 class="font-bold text-2xl">
      {{ 'AccountSettingsProfile' | translate }}
    </h2>
    <div class="flex flex-col gap-4" [ngClass]="[mobileView ? 'pt-2 ' : 'p-8']">
      <div
        class=""
        [ngClass]="[
          mobileView
            ? 'flex flex-col'
            : 'items-center justify-between grid grid-cols-3 gap-4'
        ]">
        <span class="col-span-1">{{
          'AccountSettingsProfileEmail' | translate
        }}</span>
        <span
          class="col-span-2"
          [ngClass]="[mobileView ? 'mt-1' : 'col-span-2']"
          >{{ user?.email }}</span
        >
      </div>
      <div
        class=""
        [ngClass]="[
          mobileView
            ? 'flex flex-col '
            : 'grid grid-cols-3 gap-4 items-center justify-between mt-3'
        ]">
        <span class="col-span-1 flex self-baseline">{{
          'SignInMethod' | translate
        }}</span>
        <div class="col-span-2 flex" [ngClass]="[mobileView ? 'mt-1' : '']">
          <div class="flex items-center">
            <img
              [ngSrc]="
                '/assets/images/oauth/' + userSignInProvider.image + '.png'
              "
              alt="OAuth"
              class="w-10 h-10"
              height="80"
              width="80" />
          </div>
          <div class="flex flex-col ml-3">
            <span>{{ userSignInProvider.label }}</span>
            <span class="text-slate-400">{{ user?.email }}</span>
          </div>
        </div>
      </div>
      <div class="container" [ngClass]="[mobileView ? '' : 'mx-auto md:pt-24']">
        <div
          class="grid"
          *ngrxLet="userIsAdmin() as userIsAdmin"
          [ngClass]="[
            mobileView ? 'grid-cols-1' : 'grid-cols-3 gap-4 gap-y-8'
          ]">
          <div
            *ngIf="userIsAdmin"
            class="col-span-1 text-slate-700 flex flex-col"
            [ngClass]="[mobileView ? 'mt-2' : '']">
            <span>{{ 'AccountSettingsProfileAdmin' | translate }}</span>
          </div>
          <div
            *ngIf="userIsAdmin"
            class="col-span-2 flex"
            [ngClass]="[mobileView ? 'mt-1' : 'justify-end']">
            <button
              (click)="toggleRawTranslations(rawTranslations)"
              [ngClass]="{
                'text-red-500': rawTranslations,
                'text-slate-700': !rawTranslations
              }">
              <i class="fa-solid fa-gear-complex-code mr-2"></i>
              <span
                >{{ rawTranslations ? 'Disable' : 'Enable' }}
                {{ 'AccountSettingsProfileRawTranslations' | translate }}</span
              >
            </button>
          </div>
          <div
            class="col-span-2 text-slate-700 flex flex-col"
            [ngClass]="[mobileView ? 'mt-4' : '']">
            <span>{{ 'AccountSettingsProfileResetProgress' | translate }}</span>
            <span class="mt-1 text-slate-300">
              {{ 'AccountSettingsProfileResetProgressDescription' | translate }}
            </span>
          </div>
          <div
            class="col-span-1 flex"
            [ngClass]="[mobileView ? 'mt-1' : 'justify-end']">
            <button class="popup-menu-btn-trash" (click)="resetLife()">
              {{ 'AccountSettingsProfileResetData' | translate }}
            </button>
          </div>
          <div
            class="col-span-2 text-slate-700 flex flex-col"
            [ngClass]="[mobileView ? 'mt-4' : '']">
            <span>{{ 'AccountSettingsProfileDeleteAllData' | translate }}</span>
            <span class="mt-1 text-slate-300">
              {{ 'AccountSettingsProfileDeleteAllDataDescription' | translate }}
            </span>
          </div>
          <div
            class="col-span-1 flex"
            [ngClass]="[mobileView ? 'mt-1' : 'justify-end']">
            <button class="popup-menu-btn-trash" (click)="deleteLife()">
              {{ 'ButtonDeleteData' | translate }}
            </button>
          </div>
          <div
            class="col-span-2 text-slate-700 flex flex-col"
            [ngClass]="[mobileView ? 'mt-4' : '']">
            <span>{{ 'AccountSettingsProfileDeleteAccount' | translate }}</span>
            <span class="mt-1 text-slate-300">
              {{ 'AccountSettingsProfileDeleteAccountDescription' | translate }}
            </span>
          </div>
          <div
            class="col-span-1 flex"
            [ngClass]="[mobileView ? 'mt-1' : 'justify-end']">
            <button class="popup-menu-btn-trash" (click)="deleteAccount()">
              {{ 'AccountSettingsProfileDeleteAccount' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
