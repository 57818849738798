import { Component, TrackByFunction } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from '@app/components';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { LifeModule } from '@life/life.module';
import { Store } from '@ngrx/store';
import { ILifeArea } from '@life/models/life-area';
import * as fromLife from '@life/selectors/life-area.selectors';
import { OgLifeActions } from '@life/actions';
import { ActivatedRoute } from '@angular/router';
import { OgUserActions } from '@core/actions/user.actions';

@Component({
  selector: 'hl-life-preview',
  standalone: true,
  imports: [
    CommonModule,
    AutocompleteComponent,
    DragulaModule,
    TranslateModule,
    LifeModule,
  ],
  templateUrl: './life-preview.component.html',
  styles: [],
})
export class LifePreviewComponent {
  lifeAreas = this.store.selectSignal<ILifeArea[]>(fromLife.selectLifeUI$);
  constructor(private store: Store, private route: ActivatedRoute) {
    const userId = this.route.snapshot.paramMap.get('userId') || '';
    this.store.dispatch(
      OgUserActions.setLifeAreasCount({ lifeAreasCount: 100 })
    );
    this.store.dispatch(
      OgLifeActions.loadLifeAreas({ redirect: false, userId })
    );
  }

  trackByLifeAreaIndex: TrackByFunction<ILifeArea> = (index, lifeArea) => {
    return lifeArea.id + lifeArea.sortOrder;
  };
}
