import { Component } from '@angular/core';
import { selectActivitiesPriorityUI$ } from '@life/selectors';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { LifeItem } from '@life/models/life';
import { OgActivityActions } from '@life/actions';
import { ILifeActivity } from '@life/models/life-activity';

@Component({
  selector: 'hl-activities-priority',
  templateUrl: './activities-priority.component.html',
  styles: [],
})
export class ActivitiesPriorityComponent {
  elements = this.store.selectSignal(selectActivitiesPriorityUI$);
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  openDetails(element: { type: string; id: string }) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId: element.id,
        activeItemType: element.type,
        activeTabIndex: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  handleElementFocus(isFocused: boolean, element: LifeItem) {
    this.store.dispatch(
      OgActivityActions.setActivityFocus({
        activity: {
          ...((<unknown>element) as ILifeActivity),
          isFocused,
        },
      })
    );
  }

  handleElementRating(importance: number, element: LifeItem) {
    this.store.dispatch(
      OgActivityActions.setActivityImportance({
        activity: {
          ...((<unknown>element) as ILifeActivity),
          importance,
        },
      })
    );
  }

  handleElementSatisfaction(satisfaction: number, element: LifeItem) {
    this.store.dispatch(
      OgActivityActions.setActivitySatisfaction({
        activity: {
          ...((<unknown>element) as ILifeActivity),
          satisfaction,
        },
      })
    );
  }
}
