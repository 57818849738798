import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { Dialog } from '@angular/cdk/dialog';
import { AccountSettingsDialogComponent } from '@components/dialogs/account-settings-dialog/account-settings-dialog.component';
import { HelpCenterDialogComponent } from '@components/dialogs/help-center-dialog/help-center-dialog.component';
import { FAQDialogComponent } from '@components/dialogs/faq-dialog/faq-dialog.component';
import { AnnouncementsPortalDialogComponent } from '@components/dialogs/announcements-portal-dialog/announcements-portal-dialog.component';
import { ReportBugDialogComponent } from '@components/dialogs/report-bug-dialog/report-bug-dialog.component';
import { FeatureRequestDialogComponent } from '@components/dialogs/feature-request-dialog/feature-request-dialog.component';
import { KeyboardShortcutsDialogComponent } from '@components/dialogs/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.component';
import { OnboardingDialogComponent } from '@components/dialogs/onboarding-dialog/onboarding-dialog.component';
import { productFruits } from 'product-fruits';

@Component({
  selector: 'hl-sidenav',
  templateUrl: './sidenav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements AfterViewInit {
  unreadCount = 0;
  constructor(
    private auth: AuthService,
    private dialog: Dialog,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    const customLauncher = document.getElementById('newsfeed-launcher');

    productFruits.safeExec(pf => {
      // If you want to render a badge with number of unread items...
      //
      // If you want to get the initial number of unread items,
      // attach this event BEFORE the attachNewsWidgetToElement method call.
      // @ts-ignore
      window.productFruits.api.announcementsV2.listen(
        'newsfeed-unread-count-changed',
        (data: any) => {
          this.unreadCount = data.count;
          this.cd.detectChanges();
          // Render the count in your UI. We don't render badges automatically, it is up to you.
        }
      );

      // Later, when the PF JS API is available, call the following API method and pass the element instance.
      // @ts-ignore
      window.productFruits.api.announcementsV2.attachNewsWidgetToElement(
        customLauncher
      );
    });
  }

  openAccountSettings(activeTab = 'account') {
    this.dialog.open(AccountSettingsDialogComponent, {
      maxWidth: '95%',
      data: {
        activeTab,
      },
    });
  }

  openContactSupport() {
    //@ts-ignore
    window.productFruits.api.feedback.showModal();
  }
  openHelpCenter() {
    this.dialog.open(HelpCenterDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }

  openFAQ() {
    this.dialog.open(FAQDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }

  openAnnouncementsPortal() {
    this.dialog.open(AnnouncementsPortalDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }

  openReportBug() {
    this.dialog.open(ReportBugDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }

  openFeatureRequest() {
    this.dialog.open(FeatureRequestDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }

  openKeyboardShortcuts() {
    this.dialog.open(KeyboardShortcutsDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }

  openOnboarding() {
    this.dialog.open(OnboardingDialogComponent, {
      maxWidth: '95%',
      // data: {
      //   activeTab,
      // },
    });
  }
}
