import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import {
  MatCalendar,
  MatCalendarCellClassFunction,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { WhenInterval, WhenType } from '@life/models/when.type';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectUserSettingsFirstDayOfWeek$ } from '@core/selectors/user.selectors';

@Component({
  selector: 'hl-interval-dropdown',
  standalone: true,
  templateUrl: './interval-dropdown.component.html',
  imports: [
    TranslateModule,
    CdkMenuModule,
    CommonModule,
    MatDatepickerModule,
    FormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntervalDropdownComponent implements OnChanges {
  @Input() when: WhenType;
  @Input() whenInterval: WhenInterval | undefined;
  @Input() weekdays: number[] = [];
  @Input() days: number[] = [];

  @ViewChild('masterMenuTrigger') masterMenuTrigger: CdkMenuTrigger;
  @ViewChild('calendarMenuContainer') calendarMenuContainer: ElementRef;
  @ViewChild('daysMenuContainer') daysMenuContainer: ElementRef;
  @ViewChild('mainMenuContainer') mainMenuContainer: ElementRef;
  @ViewChild('btn') btn: ElementRef;
  @Output() intervalChange = new EventEmitter<{
    when: WhenType;
    whenInterval?: WhenInterval;
    weekdays?: number[];
    days?: number[];
  }>();

  selectedDays: Date[] = [];
  firstDayOfWeek = this.store.selectSignal(selectUserSettingsFirstDayOfWeek$);
  daysOfTheWeek = [
    {
      day: 1,
      translationTerm: 'DayContextMenuMonday',
    },
    {
      day: 2,
      translationTerm: 'DayContextMenuTuesday',
    },
    {
      day: 3,
      translationTerm: 'DayContextMenuWednesday',
    },
    {
      day: 4,
      translationTerm: 'DayContextMenuThursday',
    },
    {
      day: 5,
      translationTerm: 'DayContextMenuFriday',
    },
    {
      day: 6,
      translationTerm: 'DayContextMenuSaturday',
    },
    {
      day: 7,
      translationTerm: 'DayContextMenuSunday',
    },
  ];
  selectedInterval = 0;
  intervalOptions = Array.from(Array(30).keys()).map(i => i + 2);

  maxDate = new Date(2023, this.firstDayOfWeek() === 0 ? 0 : 4, 31);
  minDate = new Date(2023, this.firstDayOfWeek() === 0 ? 0 : 4, 1);

  constructor(private translate: TranslateService, private store: Store) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['days']?.currentValue) {
      this.selectedDays = changes['days'].currentValue.map(
        (day: number) => new Date(2023, 0, day)
      );
    }
  }

  selectDate(date: Date | null, calendar: MatCalendar<any>) {
    if (date) {
      if (
        this.selectedDays.find((d: Date) => d.getDate() === date?.getDate())
      ) {
        this.selectedDays = this.selectedDays.filter((d: Date) => {
          return d.getDate() !== date?.getDate();
        });
      } else {
        this.selectedDays = [...this.selectedDays, date];
      }

      this.intervalChange.emit({
        when: WhenType.MONTHLY,
        days: this.selectedDays.map(d => d.getDate()),
      });

      calendar.updateTodaysDate();
    }
  }

  selectedDates: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    let highlightClass = '';
    if (
      this.selectedDays.find((date: Date) => {
        return date.getDate() === cellDate.getDate();
      })
    ) {
      highlightClass = 'life-start-date';
    }

    return highlightClass;
  };

  handleDailySelection(day: number) {
    let payload;
    if (this.weekdays.includes(day)) {
      payload = this.weekdays.filter(d => d !== day);
    } else {
      payload = [...this.weekdays, day];
    }

    this.intervalChange.emit({ when: WhenType.DAILY, weekdays: payload });
  }

  handleIntervalSelection(interval: number) {
    this.selectedInterval = interval;
    this.intervalChange.emit({
      when: WhenType.INTERVAL,
      whenInterval: interval,
    });
  }

  get selected() {
    let returnValue: string | null = null;
    if (this.when === WhenType.DAILY) {
      returnValue = `${this.translate.instant(
        'DrawerActivityMetadataWhenOptionDaily'
      )} (${this.weekdays.length})`;
    } else if (this.when === WhenType.MONTHLY) {
      returnValue = `${this.translate.instant(
        'DrawerActivityMetadataWhenOptionMonthly'
      )} (${this.days.length})`;
    } else if (this.when === WhenType.INTERVAL) {
      if (this.whenInterval === 1) {
        returnValue = this.translate.instant(
          'DrawerActivityMetadataWhenOptionIntervalAll'
        );
      } else {
        returnValue = this.translate.instant(
          'DrawerActivityMetadataWhenOptionIntervalValue',
          { value: this.whenInterval }
        );
      }
    }
    return returnValue;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (
      this.masterMenuTrigger.isOpen() &&
      !this.calendarMenuContainer?.nativeElement.contains(event.target) &&
      !this.daysMenuContainer?.nativeElement.contains(event.target) &&
      !this.mainMenuContainer?.nativeElement.contains(event.target) &&
      !event.target.classList.contains('mat-calendar-body-cell-content') &&
      !event.target.classList.contains('mat-calendar-body-cell') &&
      !this.btn.nativeElement.contains(event.target)
    ) {
      this.masterMenuTrigger.close();
    }
  }
}
