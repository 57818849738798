import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, filter, of } from 'rxjs';
import { LifeAreaService } from '@life/services/life-area.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@core/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  selectCheckIfLifeAreaNameExists$,
  selectLifeDateFilter$,
} from '@life/selectors';
import * as fromRoot from '@app/reducers';

import {
  LifeFiltersActions,
  OgActivityActions,
  OgLifeActions,
  OgLifeNoteActions,
  OgLifeSpecificActions,
  OgLifeStateActions,
} from '@life/actions';
import { LifeService } from '@life/services';

@Injectable()
export class LifeAreaEffects {
  INIT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.init),
      map(({ forceRedirect }) =>
        OgLifeActions.loadLifeAreas({
          redirect: forceRedirect || location.pathname === '/',
        })
      )
    );
  });

  loadLifeAtDate = createEffect(() => {
    return this.actions$.pipe(
      ofType(LifeFiltersActions.setLifeFiltersDate),
      map(data => {
        const lifeFrom = data.date ? new Date(data.date).toISOString() : null;
        return OgLifeActions.loadLifeAreas({ lifeFrom, redirect: false });
      })
    );
  });

  selectAtDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LifeFiltersActions.setLifeFiltersDate),
      concatLatestFrom(() => this.store.select(fromRoot.selectQueryParams)),
      map(([, params]) => params),
      filter(params => !!params['activeItemId'] && !!params['activeItemType']),
      map(params => {
        switch (params['activeItemType']) {
          case 'state':
            return OgLifeStateActions.selectLifeState({
              id: params['activeItemId'],
            });
          case 'area':
            return OgLifeActions.selectLifeArea({ id: params['activeItemId'] });
          case 'specific':
            return OgLifeSpecificActions.selectLifeSpecific({
              id: params['activeItemId'],
            });
          case 'activity':
            return OgActivityActions.selectActivity({
              activityId: params['activeItemId'],
            });
          default:
            return OgLifeActions.noop();
        }
      })
    );
  });

  selectLifeAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.selectLifeArea),
      concatLatestFrom(() => this.store.select(selectLifeDateFilter$)),
      map(([{ id }, selectedDate]) => {
        return OgLifeNoteActions.loadLifeAreaNotes({
          lifeAreaId: id,
          lifeFrom: selectedDate,
        });
      })
    );
  });

  setLifeAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreas),
      switchMap(({ ids }) => {
        return this.lifeAreaService.setLifeAreas(ids).pipe(
          map(() => OgLifeActions.init({ forceRedirect: true })),
          catchError(({ error }) =>
            of(OgLifeActions.setLifeAreasFail({ error: error.message }))
          )
        );
      })
    );
  });

  loadLifeAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.loadLifeAreas),
      exhaustMap(({ redirect, lifeFrom, userId }) =>
        this.lifeService.getLife(lifeFrom, userId).pipe(
          map(({ lifeAreas, startDate, resetProgressDate, changeDates }) =>
            OgLifeActions.loadLifeAreasSuccess({
              lifeAreas: lifeAreas.map(la => ({
                ...la,
                name: this.translate.instant(la.name),
              })),
              redirect,
              startDate,
              changeDates,
              resetProgressDate,
              userId,
            })
          ),
          catchError(error =>
            of(OgLifeActions.loadLifeAreasFail({ error: error.message }))
          )
        )
      )
    );
  });

  updateLifeAreaStatesHidden$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaStatesHidden),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService.updateLifeAreaStatesHidden(lifeArea).pipe(
          map(() => OgLifeActions.updateLifeAreaSuccess({ lifeArea })),
          catchError(({ error }) =>
            of(
              OgLifeActions.updateLifeAreaFail({ error: error.message }),
              OgLifeActions.updateLifeAreaSuccess({
                lifeArea: {
                  ...lifeArea,
                  statesHidden: !lifeArea.statesHidden,
                },
              })
            )
          )
        );
      })
    );
  });

  updateLifeAreaImportance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaImportance),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService
          .updateLifeAreaImportance(lifeArea.id, lifeArea.importance)
          .pipe(
            map(() => {
              return OgLifeActions.loadLifeArea({ id: lifeArea.id });
            }),
            catchError(({ error }) =>
              of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
            )
          );
      })
    );
  });

  updateLifeAreaDescription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaDescription),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService.updateLifeAreaDescription(lifeArea).pipe(
          map(() => {
            return OgLifeActions.noop();
          }),
          catchError(({ error }) =>
            of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
          )
        );
      })
    );
  });

  updateLifeAreaSortOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreasSortOrder),
      switchMap(({ lifeAreas }) => {
        const payload = lifeAreas.map(la => ({
          levelId: la.id,
          sortOrder: la.sortOrder,
        }));
        return this.lifeService.setOrder(payload).pipe(
          map(() => {
            return OgLifeActions.setLifeAreasSortOrderSuccess({ lifeAreas });
          }),
          catchError(({ error }) =>
            of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
          )
        );
      })
    );
  });

  updateLifeAreaSatisfaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaSatisfaction),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService
          .updateLifeAreaSatisfaction(lifeArea.id, lifeArea.satisfaction)
          .pipe(
            map(() => {
              return OgLifeActions.loadLifeArea({ id: lifeArea.id });
            }),
            catchError(({ error }) =>
              of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
            )
          );
      })
    );
  });

  addLifeAreaFromTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.addLifeAreaFromTemplate),
      concatLatestFrom(({ lifeArea }) =>
        this.store.select(selectCheckIfLifeAreaNameExists$(lifeArea.name))
      ),
      exhaustMap(([{ lifeArea }, nameExists]) => {
        if (!nameExists) {
          return this.lifeAreaService.addLifeAreaFromTemplate(lifeArea.id).pipe(
            map(() => {
              this.toastr.success(
                this.translate.instant('ToastrAddLifeAreaSuccess')
              );
              // todo: check update upsert maybe?
              return OgLifeActions.loadLifeAreas({ redirect: false });
            }),
            catchError(({ error }) =>
              of(OgLifeActions.setLifeAreasFail({ error: error.message }))
            )
          );
        } else {
          return of(
            OgLifeActions.updateLifeAreaFail({
              error: this.translate.instant('LifeAreaUniqueName'),
            })
          );
        }
      })
    );
  });

  addCustomLifeArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.addLifeArea),
      concatLatestFrom(({ lifeArea }) =>
        this.store.select(selectCheckIfLifeAreaNameExists$(lifeArea.name))
      ),
      exhaustMap(([{ lifeArea }, nameExists]) => {
        if (!nameExists) {
          return this.lifeAreaService.addCustomLifeArea(lifeArea).pipe(
            map(() => {
              this.toastr.success(
                this.translate.instant('ToastrAddLifeAreaSuccess')
              );
              // todo: check update upsert maybe?
              return OgLifeActions.loadLifeAreas({ redirect: false });
            }),
            catchError(({ error }) =>
              of(OgLifeActions.setLifeAreasFail({ error: error.message }))
            )
          );
        } else {
          return of(
            OgLifeActions.updateLifeAreaFail({
              error: this.translate.instant('LifeAreaUniqueName'),
            })
          );
        }
      })
    );
  });

  deleteLifeArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.deleteLifeArea),
      exhaustMap(({ lifeArea }) =>
        this.lifeAreaService.deleteLifeArea(lifeArea.id).pipe(
          map(() => {
            this.toastr.success(
              this.translate.instant('ToastrDeleteLifeAreaSuccess')
            );
            return OgLifeActions.deleteLifeAreaSuccess({ lifeArea });
          }),
          catchError(({ error }) =>
            of(OgLifeActions.deleteLifeAreaFail({ error: error.message }))
          )
        )
      )
    );
  });

  getLifeArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.loadLifeArea),
      exhaustMap(({ id }) =>
        this.lifeAreaService.getLifeArea(id).pipe(
          map(lifeArea => {
            return OgLifeActions.loadLifeAreaSuccess({
              lifeArea: {
                ...lifeArea,
                name: this.translate.instant(lifeArea.name),
              },
            });
          }),
          catchError(({ error }) =>
            of(OgLifeActions.loadLifeAreaFail({ error: error.message }))
          )
        )
      )
    );
  });

  lifeAreaErrorHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgLifeActions.deleteLifeAreaFail,
          OgLifeActions.loadLifeAreasFail,
          OgLifeActions.updateLifeAreaFail,
          OgLifeActions.loadLifeAreaFail,
          OgLifeActions.setLifeAreasFail
        ),
        tap(({ error }) => {
          this.toastr.error(error);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  lifeAreaSuccessHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          OgLifeActions.deleteLifeAreaSuccess,
          OgLifeActions.addLifeAreaSuccess,
          OgLifeActions.updateLifeAreaSuccess,
          OgLifeActions.setLifeAreasSortOrderSuccess,
          OgLifeActions.setLifeAreaImportance,
          OgLifeActions.setLifeAreaSatisfaction
        ),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          navigator.serviceWorker?.controller?.postMessage({
            value: 'updateLife',
            uid: this.appService.appUid,
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  tryUpdateLifeAreaName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaName),
      concatLatestFrom(({ lifeArea }) =>
        this.store.select(selectCheckIfLifeAreaNameExists$(lifeArea.name))
      ),
      map(([{ lifeArea }, nameExists]) => {
        if (!nameExists) {
          return OgLifeActions.actuallySetLifeAreaName({ lifeArea });
        } else {
          return OgLifeActions.updateLifeAreaFail({
            error: this.translate.instant('LifeAreaUniqueName'),
          });
        }
      })
    );
  });

  updateLifeAreaName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.actuallySetLifeAreaName),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService.updateLifeAreaName(lifeArea).pipe(
          map(() => {
            return OgLifeActions.updateLifeAreaSuccess({ lifeArea });
          }),
          catchError(({ error }) =>
            of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
          )
        );
      })
    );
  });

  updateLifeAreaIcon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaIcon),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService.updateLifeAreaIcon(lifeArea).pipe(
          map(() => {
            return OgLifeActions.updateLifeAreaSuccess({ lifeArea });
          }),
          catchError(({ error }) =>
            of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
          )
        );
      })
    );
  });

  updateLifeAreaColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OgLifeActions.setLifeAreaColor),
      switchMap(({ lifeArea }) => {
        return this.lifeAreaService.setLifeAreaColor(lifeArea).pipe(
          map(() => {
            return OgLifeActions.updateLifeAreaSuccess({ lifeArea });
          }),
          catchError(({ error }) =>
            of(OgLifeActions.updateLifeAreaFail({ error: error.message }))
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lifeAreaService: LifeAreaService,
    private lifeService: LifeService,
    private toastr: ToastrService,
    private appService: AppService,
    private translate: TranslateService,
    private store: Store
  ) {}
}
