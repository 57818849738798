import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  SidenavComponent,
  SideDrawerComponent,
  HeaderComponent,
} from '@app/core/components';

import { LayoutComponent, NotFoundComponent } from '@app/core/containers';
import { CdkMenuModule } from '@angular/cdk/menu';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '@angular/cdk/dialog';
import { FiltersComponent } from '@components/filters/filters.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MobileMenuComponent } from '@core/components/mobile-menu/mobile-menu.component';
import { LogoutComponent } from './containers/logout/logout.component';
import { ErrorComponent } from './containers/error/error.component';
import { VerifyComponent } from './containers/verify/verify.component';
import { AccountComponent } from './containers/account/account.component';
import { SettingsComponent } from './containers/settings/settings.component';
import { LetDirective } from '@ngrx/component';
import { AccountSettingsComponent } from '@components/account-settings/account-settings.component';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export const COMPONENTS = [
  NotFoundComponent,
  LayoutComponent,
  SidenavComponent,
  SideDrawerComponent,
  HeaderComponent,
  MobileMenuComponent,
  LogoutComponent,
  AccountComponent,
  SettingsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    CdkMenuModule,
    StopPropagationDirective,
    TranslateModule,
    DialogModule,
    FiltersComponent,
    MatTooltipModule,
    LetDirective,
    AccountSettingsComponent,
    MatBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  declarations: [COMPONENTS, ErrorComponent, VerifyComponent],
})
export class CoreModule {}
