<div class="h-[400px] w-[750px] max-w-full rounded-2xl bg-white">
  <div class="container h-full text-slate-700 p-8">
    <h3 class="font-bold text-base">
      {{ 'DialogsReportBugTitle' | translate }}
    </h3>
    <div class="flex mt-2 rounded-lg">
      <div class="text-slate-400 z-10 w-[100%]">
        <form>
          <button
            type="button"
            class="dropdown-button w-[100%] flex justify-between font-bold py-4 pl-2 pr-4 p bg-gray-100 text-xs rounded-lg"
            [cdkMenuTriggerFor]="reportBugMenu">
            {{ selectedTopic }}
            <i
              class="fa-light fa-chevron-down ml-3 text-slate-400 flex text-sm">
            </i>
          </button>
          <div class="text-black">
            <label for="textarea" class></label>
            <textarea
              id="textarea"
              class="w-full p-2 rounded-lg bg-gray-100 resize-none text-xs mt-2"
              rows="11"
              placeholder="{{
                'DialogsReportBugDescriptionPlaceholder' | translate
              }}"></textarea>
          </div>
          <div class="flex justify-end">
            <button
              class="rounded-lg bg-black text-white px-10 py-3 flex flex-end mt-3 text-sm"
              type="submit">
              {{ 'ButtonSend' | translate }}
            </button>
          </div>
        </form>
        <ng-template #reportBugMenu>
          <div
            cdkMenu
            class="flex flex-col shadow p-3 bg-white text-sm z-10000 text-menugray rounded-lg w-[100%]">
            <button
              cdkMenuItem
              (click)="chooseTopic('Report Bug 1')"
              class="h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
              <span>{{ 'DialogsReportBugOne' | translate }}</span>
            </button>
            <button
              cdkMenuItem
              (click)="chooseTopic('Report Bug 2')"
              class="h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
              <span>{{ 'DialogsReportBugTwo' | translate }}</span>
            </button>
            <button
              cdkMenuItem
              (click)="chooseTopic('Report Bug 3')"
              class="h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
              <span>{{ 'DialogsReportBugThree' | translate }}</span>
            </button>
            <button
              cdkMenuItem
              (click)="chooseTopic('Report Bug 4')"
              class="h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-slate-300/20 hover:text-gray-400 rounded-lg">
              <span>{{ 'DialogsReportBugFour' | translate }}</span>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
