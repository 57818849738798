import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'hl-prompt-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './prompt-dialog.component.html',
})
export class PromptDialogComponent {
  promptData: null;
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogData: any
  ) {}

  protected readonly prompt = prompt;
}
