import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OgLifeActions } from '@life/actions';

@Component({
  selector: 'hl-activities',
  templateUrl: './activities.component.html',
})
export class ActivitiesComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(OgLifeActions.loadTemplates());
  }
}
